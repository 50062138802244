var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ProfileEditForm_1;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { replaceUIComponents, setAllXFormElemsPlaceholders, } from "../../utils";
import AddressComponent from "@bundles/UIAppBundle/components/Address/Address.lazy";
import * as Ant from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { ProfileGender } from "@root/api.types";
import { ProfileCreateForm } from "./ProfileCreateForm";
let ProfileEditForm = ProfileEditForm_1 = class ProfileEditForm extends ProfileCreateForm {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
        setAllXFormElemsPlaceholders(this);
        const { t } = this.i18n;
        const profileGenderHooks = {
            options: Object.values(ProfileGender).map((option) => ({
                key: option,
                label: t(`management.profiles.fields.gender_enum.${option}`),
                value: option,
            })),
        };
        this.update("gender", {
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.profiles.fields.gender"), options: profileGenderHooks.options }) })),
        });
        this.update("isACompany", {
            initialValue: false,
            required: true,
            label: null,
            render: (props) => {
                return (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "Particulier" }), _jsx(Ant.Radio, { value: true, children: "Entreprise" })] }) }));
            },
        });
        // this.update("companyName", {
        //   initialValue: "",
        //   render: (props) => (
        //     <Ant.Form.Item {...props}>
        //       <Ant.Input
        //         placeholder={t("management.profiles.fields.companyName")}
        //         maxLength={64}
        //       />
        //     </Ant.Form.Item>
        //   ),
        // });
        replaceUIComponents({
            // phones: (
            //   <div className="flex">
            //     <PhoneNumber />
            //   </div>
            // ),
            address: _jsx(AddressComponent, {}),
        }, this);
        // removeAllFormItemsLabels(this);
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
    onCreateSubmit(document) {
        const { t } = this.i18n;
        document.addresses = [];
        const insertPromise = this.collection.insertOne(document, {
            refetchBody: ProfileEditForm_1.getRequestBody(),
        });
        insertPromise.then((res) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.profiles.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            return res;
        });
        insertPromise.catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: err.reason || err.message,
            });
        });
        return insertPromise;
    }
    onEditSubmit(_id, values) {
        const { t } = this.i18n;
        const updatePromise = this.collection.updateOne(_id, values, {
            refetchBody: ProfileEditForm_1.getRequestBody(),
        });
        updatePromise.then((res) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.profiles.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            return res;
        });
        updatePromise.catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: err.reason || err.message,
            });
        });
        return updatePromise;
    }
};
ProfileEditForm = ProfileEditForm_1 = __decorate([
    Service({ transient: true })
], ProfileEditForm);
export { ProfileEditForm };
