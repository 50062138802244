export var AgenciesTagActivity;
(function (AgenciesTagActivity) {
    AgenciesTagActivity["MECHANICAL_VENTILATION"] = "MECHANICAL_VENTILATION";
    AgenciesTagActivity["PLUMBING"] = "PLUMBING";
    AgenciesTagActivity["RENOVATION"] = "RENOVATION";
    AgenciesTagActivity["HEATING"] = "HEATING";
    AgenciesTagActivity["AIR_CONDITIONING"] = "AIR_CONDITIONING";
    AgenciesTagActivity["MULTISERVICE"] = "MULTISERVICE";
    AgenciesTagActivity["LOCKSMITHING"] = "LOCKSMITHING";
    AgenciesTagActivity["ELECTRICAL"] = "ELECTRICAL";
})(AgenciesTagActivity || (AgenciesTagActivity = {}));
export var AgenciesTagColor;
(function (AgenciesTagColor) {
    AgenciesTagColor["selfcityGreen"] = "selfcityGreen";
    AgenciesTagColor["selfcityRed"] = "selfcityRed";
    AgenciesTagColor["selfcityYellow"] = "selfcityYellow";
    AgenciesTagColor["selfcityBlue"] = "selfcityBlue";
    AgenciesTagColor["selfcityEggplant"] = "selfcityEggplant";
    AgenciesTagColor["transparent"] = "transparent";
})(AgenciesTagColor || (AgenciesTagColor = {}));
export var ClientRequestActivity;
(function (ClientRequestActivity) {
    ClientRequestActivity["MECHANICAL_VENTILATION"] = "MECHANICAL_VENTILATION";
    ClientRequestActivity["PLUMBING"] = "PLUMBING";
    ClientRequestActivity["RENOVATION"] = "RENOVATION";
    ClientRequestActivity["HEATING"] = "HEATING";
    ClientRequestActivity["AIR_CONDITIONING"] = "AIR_CONDITIONING";
    ClientRequestActivity["MULTISERVICE"] = "MULTISERVICE";
    ClientRequestActivity["LOCKSMITHING"] = "LOCKSMITHING";
    ClientRequestActivity["ELECTRICAL"] = "ELECTRICAL";
})(ClientRequestActivity || (ClientRequestActivity = {}));
export var ClientRequestArchivingReason;
(function (ClientRequestArchivingReason) {
    ClientRequestArchivingReason["BILLING_PAID"] = "BILLING_PAID";
    ClientRequestArchivingReason["CANCELED_BY_CLIENT"] = "CANCELED_BY_CLIENT";
    ClientRequestArchivingReason["TOO_FAR"] = "TOO_FAR";
    ClientRequestArchivingReason["QUOTATION_REJECTED_OR_TOO_EXPENSIVE"] = "QUOTATION_REJECTED_OR_TOO_EXPENSIVE";
    ClientRequestArchivingReason["LATE_CLIENT_HANDLING"] = "LATE_CLIENT_HANDLING";
    ClientRequestArchivingReason["CLIENT_UNREACHABLE"] = "CLIENT_UNREACHABLE";
    ClientRequestArchivingReason["CREDIT_NOTE"] = "CREDIT_NOTE";
    ClientRequestArchivingReason["TO_DELETE"] = "TO_DELETE";
    ClientRequestArchivingReason["CANNOT_HANDLE"] = "CANNOT_HANDLE";
    ClientRequestArchivingReason["IRRELEVANT"] = "IRRELEVANT";
})(ClientRequestArchivingReason || (ClientRequestArchivingReason = {}));
export var ClientRequestNextFollowUp;
(function (ClientRequestNextFollowUp) {
    ClientRequestNextFollowUp["QUOTES_1"] = "QUOTES_1";
    ClientRequestNextFollowUp["QUOTES_2"] = "QUOTES_2";
    ClientRequestNextFollowUp["QUOTES_3"] = "QUOTES_3";
    ClientRequestNextFollowUp["QUOTES_4"] = "QUOTES_4";
    ClientRequestNextFollowUp["INVOICE_1"] = "INVOICE_1";
    ClientRequestNextFollowUp["INVOICE_2"] = "INVOICE_2";
    ClientRequestNextFollowUp["INVOICE_3"] = "INVOICE_3";
})(ClientRequestNextFollowUp || (ClientRequestNextFollowUp = {}));
export var ClientRequestPossibleStatus;
(function (ClientRequestPossibleStatus) {
    /** Client request status */
    ClientRequestPossibleStatus["S_00100_CLIENT_REQUEST"] = "S_00100_CLIENT_REQUEST";
    ClientRequestPossibleStatus["S_00230_JOB_ESTIMATION_TO_DO"] = "S_00230_JOB_ESTIMATION_TO_DO";
    ClientRequestPossibleStatus["S_00240_JOB_ESTIMATION_IN_PROGRESS_TECH"] = "S_00240_JOB_ESTIMATION_IN_PROGRESS_TECH";
    ClientRequestPossibleStatus["S_00250_JOB_ESTIMATION_DONE_TECH"] = "S_00250_JOB_ESTIMATION_DONE_TECH";
    ClientRequestPossibleStatus["S_00260_JOB_ESTIMATION_TO_CORRECT_TECH"] = "S_00260_JOB_ESTIMATION_TO_CORRECT_TECH";
    ClientRequestPossibleStatus["S_00270_JOB_ESTIMATION_VALIDATED"] = "S_00270_JOB_ESTIMATION_VALIDATED";
    ClientRequestPossibleStatus["S_00340_QUOTATION_CREATED"] = "S_00340_QUOTATION_CREATED";
    ClientRequestPossibleStatus["S_00350_QUOTATION_SENT"] = "S_00350_QUOTATION_SENT";
    ClientRequestPossibleStatus["S_00360_QUOTATION_FOLLOWED_UP"] = "S_00360_QUOTATION_FOLLOWED_UP";
    ClientRequestPossibleStatus["S_00400_PREPARATION"] = "S_00400_PREPARATION";
    ClientRequestPossibleStatus["S_00500_SITE"] = "S_00500_SITE";
    ClientRequestPossibleStatus["S_00620_INVOICE_DRAFT"] = "S_00620_INVOICE_DRAFT";
    ClientRequestPossibleStatus["S_00630_INVOICE_CREATED"] = "S_00630_INVOICE_CREATED";
    ClientRequestPossibleStatus["S_00640_INVOICE_TO_CORRECT"] = "S_00640_INVOICE_TO_CORRECT";
    ClientRequestPossibleStatus["S_00650_INVOICE_SENT"] = "S_00650_INVOICE_SENT";
    ClientRequestPossibleStatus["S_00660_INVOICE_FOLLOWED_UP"] = "S_00660_INVOICE_FOLLOWED_UP";
    ClientRequestPossibleStatus["S_00670_INVOICE_PAID"] = "S_00670_INVOICE_PAID";
    ClientRequestPossibleStatus["S_00680_INVOICE_COMPLICATED"] = "S_00680_INVOICE_COMPLICATED";
})(ClientRequestPossibleStatus || (ClientRequestPossibleStatus = {}));
export var ClientRequestRelatedContactProfileType;
(function (ClientRequestRelatedContactProfileType) {
    ClientRequestRelatedContactProfileType["TENANT"] = "TENANT";
    ClientRequestRelatedContactProfileType["OWNER"] = "OWNER";
    ClientRequestRelatedContactProfileType["OTHER"] = "OTHER";
})(ClientRequestRelatedContactProfileType || (ClientRequestRelatedContactProfileType = {}));
export var ClientRequestSourceLead;
(function (ClientRequestSourceLead) {
    ClientRequestSourceLead["WEBSITE"] = "WEBSITE";
    ClientRequestSourceLead["EMAIL"] = "EMAIL";
    ClientRequestSourceLead["PHONE"] = "PHONE";
    ClientRequestSourceLead["PROSPECTION"] = "PROSPECTION";
    ClientRequestSourceLead["AGENCY"] = "AGENCY";
    ClientRequestSourceLead["SC_LOCAL"] = "SC_LOCAL";
    ClientRequestSourceLead["FURET"] = "FURET";
    ClientRequestSourceLead["TRAVAUX_COM"] = "TRAVAUX_COM";
    ClientRequestSourceLead["PARTNER_APP"] = "PARTNER_APP";
    ClientRequestSourceLead["OTHER"] = "OTHER";
    ClientRequestSourceLead["MATHIS"] = "MATHIS";
    ClientRequestSourceLead["BROKER"] = "BROKER";
})(ClientRequestSourceLead || (ClientRequestSourceLead = {}));
export var CommentVisibility;
(function (CommentVisibility) {
    CommentVisibility["PRIVATE"] = "PRIVATE";
    CommentVisibility["PUBLIC"] = "PUBLIC";
    CommentVisibility["AGENCY"] = "AGENCY";
})(CommentVisibility || (CommentVisibility = {}));
export var FollowUpMean;
(function (FollowUpMean) {
    FollowUpMean["EMAIL"] = "EMAIL";
    FollowUpMean["PHONE"] = "PHONE";
    FollowUpMean["EMAIL_AUTO"] = "EMAIL_AUTO";
})(FollowUpMean || (FollowUpMean = {}));
export var ProfileGender;
(function (ProfileGender) {
    ProfileGender["MR"] = "MR";
    ProfileGender["MS"] = "MS";
    ProfileGender["OTHER"] = "OTHER";
})(ProfileGender || (ProfileGender = {}));
export var SubscriptionEventType;
(function (SubscriptionEventType) {
    SubscriptionEventType["added"] = "added";
    SubscriptionEventType["changed"] = "changed";
    SubscriptionEventType["removed"] = "removed";
    SubscriptionEventType["ready"] = "ready";
})(SubscriptionEventType || (SubscriptionEventType = {}));
export var UserRoles;
(function (UserRoles) {
    UserRoles["ADMIN"] = "ADMIN";
    UserRoles["SALES"] = "SALES";
    UserRoles["SUPPORT"] = "SUPPORT";
    UserRoles["PLUMBER"] = "PLUMBER";
    UserRoles["AGENCY_STAFF"] = "AGENCY_STAFF";
    UserRoles["BOT"] = "BOT";
})(UserRoles || (UserRoles = {}));
