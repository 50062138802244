import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useMemo } from "react";
import JotformEmbed from "react-jotform-embed";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useGuardian } from "@bluelibs/x-ui-guardian-bundle";
export const IncidentReportModalContent = memo((props) => {
    const userContext = useGuardian().state.user;
    const location = useLocation();
    const incidentFormUrl = useMemo(() => {
        var _a, _b;
        const missionPath = location.pathname.match(/\/client-requests\/(\w+)/);
        const mission = ((_b = (_a = props.clientRequestId) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : missionPath)
            ? missionPath[1]
            : undefined;
        return queryString.stringifyUrl({
            url: "https://form.jotformeu.com/210312833793352",
            query: {
                auteur: userContext.profile.firstName,
                mission,
            },
        });
    }, [userContext, location]);
    return _jsx(JotformEmbed, { src: incidentFormUrl });
});
