import { CommentsList } from "../components/List/CommentsList";
import { CommentsCreate } from "../components/Create/CommentsCreate";
import { CommentsEdit } from "../components/Edit/CommentsEdit";
import { CommentsView } from "../components/View/CommentsView";
import { SettingFilled } from "@ant-design/icons";
export const COMMENTS_LIST = {
    path: "/admin/comments",
    component: CommentsList,
    menu: {
        key: "COMMENTS_LIST",
        label: "management.comments.menu.title",
        icon: SettingFilled,
    },
};
export const COMMENTS_CREATE = {
    path: "/admin/comments/create",
    component: CommentsCreate,
};
export const COMMENTS_EDIT = {
    path: "/admin/comments/:id/edit",
    component: CommentsEdit,
};
export const COMMENTS_VIEW = {
    path: "/admin/comments/:id/view",
    component: CommentsView,
};
