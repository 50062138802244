import { JobEstimationsList } from "../components/List/JobEstimationsList";
import { SettingFilled } from "@ant-design/icons";
import { EstimateView } from "../components/View/EstimateView";
export const JOB_ESTIMATIONS_LIST = {
    path: "/admin/job-estimations",
    component: JobEstimationsList,
    menu: {
        key: "JOB_ESTIMATIONS_LIST",
        label: "management.job_estimations.menu.title",
        icon: SettingFilled,
    },
};
export const ESTIMATE_VIEW = {
    path: "/estimates/:id/view",
    component: EstimateView,
};
