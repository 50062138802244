var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { AgencyCreateForm as BaseAgencyCreateForm } from "./AgencyCreateForm.base";
import { removeAllFormItemsLabels, replaceUIComponents, setAllXFormElemsPlaceholders, } from "../../utils";
import AddressComponent from "@bundles/UIAppBundle/components/Address/Address.lazy";
import PhoneNumber from "@bundles/UIAppBundle/components/PhoneNumber/PhoneNumber.lazy";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { features } from "./features";
import { Routes } from "@bundles/UIAppBundle";
let AgencyCreateForm = class AgencyCreateForm extends BaseAgencyCreateForm {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
        setAllXFormElemsPlaceholders(this);
        replaceUIComponents({
            phone: _jsx(PhoneNumber, {}),
            address: _jsx(AddressComponent, {}),
        }, this);
        removeAllFormItemsLabels(this);
        this.update("name", {
            formItemProps: { style: { flex: 3 } },
            componentProps: {
                maxlength: 32,
            },
        });
        this.update("agenciesTagId", {
            formItemProps: { style: { flex: 1 } },
        });
    }
    onSubmit(document) {
        const { t } = this.i18n;
        document.addresses = [];
        return this.collection
            .insertOne(document, {
            refetchBody: (body) => ({
                ...body,
                address: {
                    streetOne: 1,
                    streetTwo: 1,
                    town: 1,
                    zip: 1,
                    country: 1,
                    formattedAddress: 1,
                    geopoint: {
                        lat: 1,
                        lng: 1,
                    },
                },
                addresses: {
                    streetOne: 1,
                    streetTwo: 1,
                    town: 1,
                    zip: 1,
                    country: 1,
                    formattedAddress: 1,
                    geopoint: {
                        lat: 1,
                        lng: 1,
                    },
                },
            }),
        })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.agencies.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.AGENCIES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.AGENCIES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.AGENCIES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
AgencyCreateForm = __decorate([
    Service({ transient: true })
], AgencyCreateForm);
export { AgencyCreateForm };
