import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Tag, Tooltip } from "antd";
import { memo, useMemo, useState } from "react";
import { CheckOutlined, EditOutlined, LeftOutlined, RightOutlined, } from "@ant-design/icons";
import { buildClass } from "biuty";
import { useTranslate } from "@bluelibs/x-ui";
import { useOpenModal } from "@root/utils/hooks";
function InboxTask(props) {
    const t = useTranslate();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const { title, dueDate, tagName } = props;
    const { clientRequest, unassigned } = props;
    const { activeUserId, assignedToId } = props;
    const { onClick, tooltips, toggleAssigned } = props;
    const openModal = useOpenModal("inboxTask");
    const isOutdated = useMemo(() => new Date(dueDate).getTime() < today.getTime(), [dueDate]);
    const isDueDateDay = useMemo(() => new Date(new Date(dueDate).setHours(0, 0, 0, 0)).getTime() ===
        today.getTime(), [dueDate]);
    const [modifiers, setModifiers] = useState((isOutdated ? "outdated " : isDueDateDay ? "isDueDateDay" : "") +
        (unassigned ? " unassigned" : ""));
    const className = buildClass("inbox-task", modifiers);
    const formattedDueDate = useMemo(() => {
        const localizedDate = new Date(dueDate).toLocaleDateString("fr-FR", {
            weekday: "long",
            month: "long",
            day: "numeric",
        });
        return localizedDate.charAt(0).toUpperCase() + localizedDate.slice(1);
    }, [dueDate]);
    let detailsElement = _jsx("span", {});
    // For tasks on inbox page.
    if (clientRequest !== null && activeUserId === undefined) {
        detailsElement = (_jsx("a", { target: "_blank", className: "inbox-task__details__client-request", title: clientRequest.reference, href: `/client-requests/${clientRequest._id}/edit`, children: clientRequest.reference }));
    }
    // For tasks on client request page.
    else if (activeUserId !== undefined) {
        if (!unassigned && `${activeUserId}` !== `${assignedToId}`) {
            detailsElement = (_jsx("span", { className: "inbox-task__details__assignee", children: "Assign\u00E9e \u00E0 quelqu'un d'autre" }));
        }
        else if (`${activeUserId}` === `${assignedToId}`) {
            detailsElement = (_jsx("span", { className: "inbox-task__details__assignee", children: "Assign\u00E9e \u00E0 moi" }));
        }
        else if (unassigned) {
            detailsElement = (_jsx("span", { className: "inbox-task__details__assign", onClick: toggleAssigned, children: "M'assigner la t\u00E2che" }));
        }
    }
    return (_jsx(_Fragment, { children: _jsxs("div", { className: className, children: [!unassigned || activeUserId !== undefined ? (_jsx(Tooltip, { placement: "left", title: tooltips.markAsDone, children: _jsx(Button, { onClick: () => {
                            setModifiers("inbox-task--done");
                            setTimeout(() => {
                                if (onClick) {
                                    onClick();
                                }
                            }, 150);
                        }, type: "primary", shape: "circle", icon: _jsx(CheckOutlined, {}) }) })) : null, _jsxs("div", { className: "inbox-task__details flex flex-col flex-auto vgap-2", children: [_jsxs("div", { className: "flex items-start hgap-2 items-center", children: [_jsx(Tag, { className: "inbox-task__details__tag", children: tagName }, tagName), _jsx("span", { className: "inbox-task__details__title", title: title, children: title })] }), _jsxs("div", { className: "flex justify-between hgap-2", children: [detailsElement, _jsx("span", { className: "inbox-task__details__due-date", title: formattedDueDate, children: formattedDueDate })] })] }), activeUserId === undefined ? (_jsx(Tooltip, { placement: "left", title: tooltips.toggleAssigned, children: _jsx(Button, { onClick: () => {
                            setModifiers((isOutdated ? "outdated " : "") +
                                (unassigned
                                    ? " unassigned assigned"
                                    : " assigned deassigned"));
                            if (toggleAssigned) {
                                toggleAssigned();
                            }
                        }, shape: "circle", type: unassigned ? "primary" : "default", icon: unassigned ? (_jsx(LeftOutlined, { size: 30 })) : (_jsx(RightOutlined, { size: 30 })), className: "inbox-task__details__assign_btn" }) })) : null, _jsx(Tooltip, { title: t("management.inbox_tasks.actions.edit_task"), children: _jsx(Button, { type: "ghost", onClick: () => openModal(props._id), icon: _jsx(EditOutlined, { size: 30 }), className: "inbox-task__details__edit_btn" }) })] }) }));
}
export default memo(InboxTask);
