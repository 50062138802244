import { TokensList } from "../components/List/TokensList";
import { TokensCreate } from "../components/Create/TokensCreate";
import { TokensEdit } from "../components/Edit/TokensEdit";
import { TokensView } from "../components/View/TokensView";
import { SettingFilled } from "@ant-design/icons";
export const TOKENS_LIST = {
    path: "/admin/tokens",
    component: TokensList,
    menu: {
        key: "TOKENS_LIST",
        label: "management.tokens.menu.title",
        icon: SettingFilled,
    },
};
export const TOKENS_CREATE = {
    path: "/admin/tokens/create",
    component: TokensCreate,
};
export const TOKENS_EDIT = {
    path: "/admin/tokens/:id/edit",
    component: TokensEdit,
};
export const TOKENS_VIEW = {
    path: "/admin/tokens/:id/view",
    component: TokensView,
};
