import { ObjectId } from "@bluelibs/ejson";
import { use, useTranslate } from "@bluelibs/x-ui";
import { useCallback } from "react";
import { ClientRequestRelatedContactsCollection, ClientRequestsCollection, } from "@bundles/UIAppBundle/collections";
import { ClientRequestRelatedContactProfileType, ClientRequestSourceLead, } from "@root/api.types";
import { useClientRequestRelatedContactsByClientRequestId } from "@bundles/UIAppBundle/queries/ClientRequestRelatedContacts.queries";
import { useOpenModal } from "@root/utils/hooks";
import * as Ant from "antd";
const clientRequestRelatedContactsSharedTagsKeys = [
    "isAsker",
    "isPayer",
    "isLocation",
];
export const useContactsAndLocationCardHooks = (props) => {
    var _a;
    const t = useTranslate();
    const relatedContactsCollection = use(ClientRequestRelatedContactsCollection);
    const clientRequestsCollection = use(ClientRequestsCollection);
    const clientRequest = props.clientRequest;
    const { data: relatedContacts, loading, error, refetch, } = useClientRequestRelatedContactsByClientRequestId({
        clientRequestId: clientRequest._id,
    });
    const orderedRelatedContacts = [];
    relatedContacts === null || relatedContacts === void 0 ? void 0 : relatedContacts.forEach((relatedContact) => {
        relatedContact.agency
            ? orderedRelatedContacts.unshift(relatedContact)
            : orderedRelatedContacts.push(relatedContact);
    });
    const doesRelatedContactsIncludeAnAgency = !!((_a = orderedRelatedContacts[0]) === null || _a === void 0 ? void 0 : _a.agency);
    const addReferenceToRefetchBody = useCallback((body) => {
        return {
            ...body,
            clientRequest: { _id: 1, reference: 1 },
        };
    }, []);
    const onSelectAgency = useCallback((_, { value }) => {
        relatedContactsCollection
            .insertOne({
            agencyId: value,
            clientRequestId: clientRequest._id,
        }, { refetchBody: addReferenceToRefetchBody })
            .then(() => {
            // refetch to get sourceLead.
            clientRequestsCollection
                .findOneById(new ObjectId(clientRequest._id), {
                sourceLead: 1,
            })
                .then((clntRqst) => {
                if (!clntRqst.sourceLead) {
                    clientRequestsCollection
                        .updateOne(clientRequest._id, {
                        sourceLead: ClientRequestSourceLead.AGENCY,
                    })
                        .then(() => Ant.message.success("La source vient d’être automatiquement définie comme une “Demande d’agence”"));
                }
            });
            // TODO ! remove that once the cache is auto updated
            refetch();
        });
    }, [relatedContactsCollection, clientRequest._id, refetch]);
    const onProfileSelect = useCallback((_, { value }) => {
        relatedContactsCollection
            .insertOne({
            profileId: value,
            profileType: ClientRequestRelatedContactProfileType.OTHER,
            clientRequestId: clientRequest._id,
        }, { refetchBody: addReferenceToRefetchBody })
            .then(() => {
            // TODO ! remove that once the cache is auto updated
            refetch();
        });
    }, [relatedContactsCollection, clientRequest._id, refetch]);
    const addRelatedContactProfile = useCallback(({ _id }) => {
        relatedContactsCollection
            .insertOne({
            profileId: _id,
            profileType: ClientRequestRelatedContactProfileType.OTHER,
            clientRequestId: clientRequest._id,
        }, { refetchBody: addReferenceToRefetchBody })
            .then(() => {
            // TODO ! remove that once the cache is auto updated
            refetch();
        });
    }, [relatedContactsCollection, clientRequest._id]);
    const onTagToggle = useCallback((tag, value, contactId) => {
        if (clientRequestRelatedContactsSharedTagsKeys.includes(tag) ||
            tag === "locationIndex") {
            if (tag === "locationIndex") {
                openSelectAddressModal(contactId.toString());
            }
            else {
                const newValue = { [tag]: value };
                if (tag === "isLocation") {
                    newValue.locationIndex = 0;
                }
                relatedContactsCollection
                    .updateOne(contactId, newValue, {
                    refetchBody: addReferenceToRefetchBody,
                    apollo: { refetchQueries: ["ClientRequestRelatedContactFind"] },
                })
                    .then(() => {
                    // TODO ! remove that once the cache is auto updated
                    refetch();
                });
            }
        }
        else if ([
            ClientRequestRelatedContactProfileType.OWNER.toString(),
            ClientRequestRelatedContactProfileType.TENANT.toString(),
        ].includes(tag)) {
            if (value) {
                relatedContactsCollection
                    .updateOne(contactId, { profileType: tag }, { refetchBody: addReferenceToRefetchBody })
                    .then(() => {
                    // TODO ! remove that once the cache is auto updated
                    refetch();
                });
            }
            else {
                relatedContactsCollection
                    .updateOne(contactId, { profileType: ClientRequestRelatedContactProfileType.OTHER }, { refetchBody: addReferenceToRefetchBody })
                    .then(() => {
                    // TODO ! remove that once the cache is auto updated
                    refetch();
                });
            }
        }
        if (tag in
            [
                "isPayer",
                "isLocation",
                ClientRequestRelatedContactProfileType.OWNER.toString(),
                ClientRequestRelatedContactProfileType.TENANT.toString(),
            ]) {
            getRelatedContactsAlerts();
        }
    }, [clientRequestRelatedContactsSharedTagsKeys, relatedContactsCollection]);
    const openCreateNewProfileModal = useOpenModal("profile");
    const openSelectAddressModal = useOpenModal("contactAddresses");
    const onCreateNewProfile = useCallback(() => {
        openCreateNewProfileModal("new");
    }, []);
    const getRelatedContactsAlerts = () => {
        let isLocationDefined = false;
        let isPayerDefined = false;
        let isOwnerOrTenantDefined = false;
        relatedContacts === null || relatedContacts === void 0 ? void 0 : relatedContacts.forEach((relatedContact) => {
            if (!isLocationDefined && relatedContact.isLocation) {
                isLocationDefined = true;
            }
            if (!isPayerDefined && relatedContact.isPayer) {
                isPayerDefined = true;
            }
            if (!isOwnerOrTenantDefined &&
                (relatedContact.profileType ===
                    ClientRequestRelatedContactProfileType.OWNER ||
                    relatedContact.profileType ===
                        ClientRequestRelatedContactProfileType.TENANT)) {
                isOwnerOrTenantDefined = true;
            }
        });
        return {
            isLocationDefined,
            isPayerDefined,
            isOwnerOrTenantDefined,
        };
    };
    return {
        t,
        loading,
        error,
        refetch,
        relatedContacts,
        orderedRelatedContacts,
        doesRelatedContactsIncludeAnAgency,
        onSelectAgency,
        onProfileSelect,
        onTagToggle,
        addRelatedContactProfile,
        onCreateNewProfile,
        getRelatedContactsAlerts,
    };
};
