var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ClientRequestRelatedContactList = class ClientRequestRelatedContactList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "profileType",
                title: t("management.client_request_related_contacts.fields.profileType"),
                key: "management.client_request_related_contacts.fields.profileType",
                dataIndex: ["profileType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "locationIndex",
                title: t("management.client_request_related_contacts.fields.locationIndex"),
                key: "management.client_request_related_contacts.fields.locationIndex",
                dataIndex: ["locationIndex"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "iptCustomerId",
                title: t("management.client_request_related_contacts.fields.iptCustomerId"),
                key: "management.client_request_related_contacts.fields.iptCustomerId",
                dataIndex: ["iptCustomerId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isPayer",
                title: t("management.client_request_related_contacts.fields.isPayer"),
                key: "management.client_request_related_contacts.fields.isPayer",
                dataIndex: ["isPayer"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isLocation",
                title: t("management.client_request_related_contacts.fields.isLocation"),
                key: "management.client_request_related_contacts.fields.isLocation",
                dataIndex: ["isLocation"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isAsker",
                title: t("management.client_request_related_contacts.fields.isAsker"),
                key: "management.client_request_related_contacts.fields.isAsker",
                dataIndex: ["isAsker"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "name",
                title: t("management.client_request_related_contacts.fields.name"),
                key: "management.client_request_related_contacts.fields.name",
                dataIndex: ["name"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "email",
                title: t("management.client_request_related_contacts.fields.email"),
                key: "management.client_request_related_contacts.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.streetOne",
                title: t("management.client_request_related_contacts.fields.address.streetOne"),
                key: "management.client_request_related_contacts.fields.address.streetOne",
                dataIndex: ["address", "streetOne"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.streetTwo",
                title: t("management.client_request_related_contacts.fields.address.streetTwo"),
                key: "management.client_request_related_contacts.fields.address.streetTwo",
                dataIndex: ["address", "streetTwo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.zip",
                title: t("management.client_request_related_contacts.fields.address.zip"),
                key: "management.client_request_related_contacts.fields.address.zip",
                dataIndex: ["address", "zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.town",
                title: t("management.client_request_related_contacts.fields.address.town"),
                key: "management.client_request_related_contacts.fields.address.town",
                dataIndex: ["address", "town"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.country",
                title: t("management.client_request_related_contacts.fields.address.country"),
                key: "management.client_request_related_contacts.fields.address.country",
                dataIndex: ["address", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.geopoint",
                title: t("management.client_request_related_contacts.fields.address.geopoint"),
                key: "management.client_request_related_contacts.fields.address.geopoint",
                dataIndex: ["address", "geopoint"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.formattedAddress",
                title: t("management.client_request_related_contacts.fields.address.formattedAddress"),
                key: "management.client_request_related_contacts.fields.address.formattedAddress",
                dataIndex: ["address", "formattedAddress"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.client_request_related_contacts.fields.createdAt"),
                key: "management.client_request_related_contacts.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.client_request_related_contacts.fields.updatedAt"),
                key: "management.client_request_related_contacts.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "clientRequest",
                title: t("management.client_request_related_contacts.fields.clientRequest"),
                key: "management.client_request_related_contacts.fields.clientRequest",
                dataIndex: ["clientRequest"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.CLIENT_REQUESTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "reference",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "profile",
                title: t("management.client_request_related_contacts.fields.profile"),
                key: "management.client_request_related_contacts.fields.profile",
                dataIndex: ["profile"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "agency",
                title: t("management.client_request_related_contacts.fields.agency"),
                key: "management.client_request_related_contacts.fields.agency",
                dataIndex: ["agency"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.AGENCIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                title: t("management.client_request_related_contacts.fields.createdBy"),
                key: "management.client_request_related_contacts.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                title: t("management.client_request_related_contacts.fields.updatedBy"),
                key: "management.client_request_related_contacts.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            clientRequest: "clientRequest.reference",
            profile: "profile.fullName",
            agency: "agency.name",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            profileType: 1,
            locationIndex: 1,
            iptCustomerId: 1,
            isPayer: 1,
            isLocation: 1,
            isAsker: 1,
            name: 1,
            email: 1,
            distanceFromPlaces: {
                distance: 1,
                duration: 1,
                placeId: 1,
                placeName: 1,
            },
            address: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            createdAt: 1,
            updatedAt: 1,
            clientRequest: {
                _id: 1,
                reference: 1,
            },
            clientRequestId: 1,
            profile: {
                _id: 1,
                fullName: 1,
            },
            profileId: 1,
            agency: {
                _id: 1,
                name: 1,
            },
            agencyId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
ClientRequestRelatedContactList = __decorate([
    Service({ transient: true })
], ClientRequestRelatedContactList);
export { ClientRequestRelatedContactList };
