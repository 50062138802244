import { useTranslate, useGuardian } from "@bluelibs/x-ui";
import moment from "moment";
import { useMemo } from "react";
import axios from "axios";
export const useTripCardHooks = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const t = useTranslate();
    const getEventsPerDayPerWorkerTripHoursString = (workerEventsPerDay) => `${workerEventsPerDay.minifiedName} ` +
        workerEventsPerDay.tripsHours
            .map((tripHour) => {
            const startAtHours = new Date(tripHour.startAt).getHours();
            const startAtMinutes = new Date(tripHour.startAt).getMinutes();
            const durationHours = new Date(tripHour.duration).getUTCHours();
            const durationMinutes = new Date(tripHour.duration).getUTCMinutes();
            return "à "
                + (startAtMinutes > 0
                    ? `${startAtHours}h${startAtMinutes}`
                    : `${startAtHours}h`)
                + (durationMinutes > 0
                    ? ` (${durationHours}h${durationMinutes})`
                    : ` (${durationHours}h)`);
        })
            .join(" et ");
    const getDayString = (day) => "• " + moment(Number(`${day}`)).format("ddd DD/MM") + " :";
    const tripListDatesTimeString = useMemo(() => props.tripList.data.appointments.map((dayEvents) => moment(dayEvents.startAt).format("dddd (DD/MM/YYYY) [de] HH[h]mm") +
        moment(new Date(dayEvents.endAt)).format(" [à] HH[h]mm") +
        moment.utc(new Date(dayEvents.endAt).getTime() - new Date(dayEvents.startAt).getTime()).format(" (HH[h]mm)")), [props.tripList.data.appointments]);
    const workersNames = useMemo(() => {
        const durationsPerUser = props.tripList.data.appointments.reduce((acc, event) => {
            var _a;
            var _b;
            const duration = new Date(event.endAt).getTime() - new Date(event.startAt).getTime();
            (_a = acc[_b = `${`${event.user.firstName} ${event.user.lastName}`}`]) !== null && _a !== void 0 ? _a : (acc[_b] = 0);
            acc[`${`${event.user.firstName} ${event.user.lastName}`}`] += duration;
            return acc;
        }, {});
        return Object.keys(durationsPerUser).map((user) => {
            const durationHours = Math.floor(durationsPerUser[user] / (3600 * 1000));
            const durationMinutes = (durationsPerUser[user] - (durationHours * 3600000)) % (60 * 1000);
            return user
                + (durationMinutes > 0
                    ? ` (${durationHours}h${durationMinutes})`
                    : ` (${durationHours}h)`);
        });
    }, []);
    const NOW = Date.now();
    const tripListState = {
        isUpcoming: NOW < ((_b = (_a = props.tripList.data.appointments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.startAt),
        isInProgress: NOW > ((_d = (_c = props.tripList.data.appointments) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.startAt) &&
            NOW < ((_f = (_e = props.tripList.data.appointments) === null || _e === void 0 ? void 0 : _e.at(-1)) === null || _f === void 0 ? void 0 : _f.endAt),
        isOver: NOW > ((_h = (_g = props.tripList.data.appointments) === null || _g === void 0 ? void 0 : _g.at(-1)) === null || _h === void 0 ? void 0 : _h.endAt),
    };
    const isTripReportActionToTake = tripListState.isOver &&
        (!props.tripList.report ||
            (!!props.tripList.report && !props.tripList.report._isProcessed));
    const onProcessedCheck = (checked) => {
        const submit = async () => {
            const token = window.localStorage.getItem("bluelibs-token");
            await axios.request({
                method: 'POST',
                url: global.NEW_API_URL + '/onSiteJobs/' + props.tripList.data._id + '/process',
                headers: { "Authorization": token },
            });
            await axios.request({
                method: 'GET',
                url: (window.location.host === 'belett2.selfcity.fr' || window.location.host === 'staging.belett.selfcity.fr' ? '' : '') + 'https://n8n.selfcity.fr/webhook/d88a4103-dbaf-47d6-818f-255aea04c4bd',
                params: {
                    onSiteJob: props.tripList.data._id,
                    mission: props.tripList.data._mission,
                    env: window.location.host === 'belett2.selfcity.fr' ? 'production' : 'preproduction',
                },
            });
            window.location.reload();
        };
        // Ant.Modal.confirm({
        //   okText: "Confirmer",
        //   cancelText: "Annuler",
        //   content: <div>{'Le compte-rendu sera envoyé au client et à l\'agence. Il ne sera plus possible de modifier celui-ci. Es-tu sûr(e) ?'}</div>,
        //   onOk: submit,
        // });
        submit();
    };
    const guardian = useGuardian();
    const isPlumber = guardian.hasRole("PLUMBER");
    return {
        t,
        tripListDatesTimeString,
        workersNames,
        tripReport: props.tripList.data.report,
        isTripReportActionToTake,
        onProcessedCheck,
        tripListState,
        isPlumber,
        getEventsPerDayPerWorkerTripHoursString,
        getDayString,
    };
};
