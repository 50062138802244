var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { AgenciesCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let ProfileListFiltersForm = class ProfileListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isACompany",
                label: t("management.profiles.fields.isACompany"),
                name: ["isACompany"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "companyName",
                label: t("management.profiles.fields.companyName"),
                name: ["companyName"],
                tooltip: t("management.profiles.fields.companyName_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "gender",
                label: t("management.profiles.fields.gender"),
                name: ["gender"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.profiles.fields.gender"), children: [_jsx(Ant.Select.Option, { value: "MR", children: "Mr" }, "MR"), _jsx(Ant.Select.Option, { value: "MS", children: "Ms" }, "MS"), _jsx(Ant.Select.Option, { value: "OTHER", children: "Other" }, "OTHER")] }) })),
            },
            {
                id: "firstName",
                label: t("management.profiles.fields.firstName"),
                name: ["firstName"],
                tooltip: t("management.profiles.fields.firstName_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "lastName",
                label: t("management.profiles.fields.lastName"),
                name: ["lastName"],
                tooltip: t("management.profiles.fields.lastName_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "phones",
                label: t("management.profiles.fields.phones"),
                name: ["phones"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "emails",
                label: t("management.profiles.fields.emails"),
                name: ["emails"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "notes",
                label: t("management.profiles.fields.notes"),
                name: ["notes"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "addresses",
                label: t("management.profiles.fields.addresses"),
                name: ["addresses"],
                columns: true,
                nest: [
                    {
                        id: "streetOne",
                        label: t("management.profiles.fields.addresses.streetOne"),
                        name: ["addresses", "streetOne"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "streetTwo",
                        label: t("management.profiles.fields.addresses.streetTwo"),
                        name: ["addresses", "streetTwo"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.profiles.fields.addresses.zip"),
                        name: ["addresses", "zip"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.profiles.fields.addresses.town"),
                        name: ["addresses", "town"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.profiles.fields.addresses.country"),
                        name: ["addresses", "country"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "geopoint",
                        label: t("management.profiles.fields.addresses.geopoint"),
                        name: ["addresses", "geopoint"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "formattedAddress",
                        label: t("management.profiles.fields.addresses.formattedAddress"),
                        name: ["addresses", "formattedAddress"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "address",
                label: t("management.profiles.fields.address"),
                name: ["address"],
                columns: true,
                nest: [
                    {
                        id: "streetOne",
                        label: t("management.profiles.fields.address.streetOne"),
                        name: ["address", "streetOne"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "streetTwo",
                        label: t("management.profiles.fields.address.streetTwo"),
                        name: ["address", "streetTwo"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.profiles.fields.address.zip"),
                        name: ["address", "zip"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.profiles.fields.address.town"),
                        name: ["address", "town"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.profiles.fields.address.country"),
                        name: ["address", "country"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "geopoint",
                        label: t("management.profiles.fields.address.geopoint"),
                        name: ["address", "geopoint"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "formattedAddress",
                        label: t("management.profiles.fields.address.formattedAddress"),
                        name: ["address", "formattedAddress"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "iptCustomerId",
                label: t("management.profiles.fields.iptCustomerId"),
                name: ["iptCustomerId"],
                tooltip: t("management.profiles.fields.iptCustomerId_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "createdAt",
                label: t("management.profiles.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.profiles.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.profiles.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.profiles.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "agencyId",
                label: t("management.profiles.fields.agency"),
                name: ["agencyId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: AgenciesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "createdById",
                label: t("management.profiles.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.profiles.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.profiles.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.profiles.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "userId",
                label: t("management.profiles.fields.user"),
                name: ["userId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
ProfileListFiltersForm = __decorate([
    Service({ transient: true })
], ProfileListFiltersForm);
export { ProfileListFiltersForm };
