var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ClientRequestsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let CommentListFiltersForm = class CommentListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "text",
                label: t("management.comments.fields.text"),
                name: ["text"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "visibility",
                label: t("management.comments.fields.visibility"),
                name: ["visibility"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.comments.fields.visibility"), children: [_jsx(Ant.Select.Option, { value: "PRIVATE", children: "Private" }, "PRIVATE"), _jsx(Ant.Select.Option, { value: "PUBLIC", children: "Public" }, "PUBLIC"), _jsx(Ant.Select.Option, { value: "AGENCY", children: "Agency" }, "AGENCY")] }) })),
            },
            {
                id: "createdAt",
                label: t("management.comments.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.comments.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.comments.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.comments.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "clientRequestId",
                label: t("management.comments.fields.clientRequest"),
                name: ["clientRequestId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ClientRequestsCollection, field: "reference", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "createdById",
                label: t("management.comments.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.comments.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.comments.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.comments.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
CommentListFiltersForm = __decorate([
    Service({ transient: true })
], CommentListFiltersForm);
export { CommentListFiltersForm };
