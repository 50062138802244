import { InboxTasksList } from "../components/List/InboxTasksList";
import { InboxTasksCreate } from "../components/Create/InboxTasksCreate";
import { InboxTasksEdit } from "../components/Edit/InboxTasksEdit";
import { InboxTasksView } from "../components/View/InboxTasksView";
import { SettingFilled } from "@ant-design/icons";
export const INBOX_TASKS_LIST = {
    path: "/admin/inbox-tasks",
    component: InboxTasksList,
    menu: {
        key: "INBOX_TASKS_LIST",
        label: "management.inbox_tasks.menu.title",
        icon: SettingFilled,
    },
};
export const INBOX_TASKS_CREATE = {
    path: "/admin/inbox-tasks/create",
    component: InboxTasksCreate,
};
export const INBOX_TASKS_EDIT = {
    path: "/admin/inbox-tasks/:id/edit",
    component: InboxTasksEdit,
};
export const INBOX_TASKS_VIEW = {
    path: "/admin/inbox-tasks/:id/view",
    component: InboxTasksView,
};
