import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { formRenderItems } from "@bundles/UIAppBundle/pages/utils";
import { useClientRequestArchivingModalHooks } from "./ClientRequestArchivingModal.hooks";
import { Modal } from "@bundles/UIAppBundle/components/Modal/Modal.index";
const okButtonProps = {
    htmlType: "submit",
};
export const ClientRequestArchivingModal = memo((props) => {
    const { t, form, xForm, modalRef, onFinish, onCancel, onOk } = useClientRequestArchivingModalHooks(props);
    return (_jsx(_Fragment, { children: _jsx(Ant.Form, { form: form, 
            // requiredMark="optional"
            initialValues: props.clientRequest, onFinish: onFinish, children: _jsx(Modal, { queryParam: "clientRequestArchive", title: t("management.client_requests.actions.archive"), width: 600, okButtonProps: okButtonProps, onOk: onOk, onCancel: onCancel, modalRef: modalRef, closable: true, children: _jsxs("div", { className: "mar-2", children: [_jsx("div", { className: "mar-b-2", children: formRenderItems(xForm, ["archivingReason"]) }), formRenderItems(xForm, ["archivingComment"])] }) }) }) }));
});
