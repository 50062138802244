var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { CommentCreateForm as BaseCommentCreateForm } from "./CommentCreateForm.base";
let CommentCreateForm = class CommentCreateForm extends BaseCommentCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        // Perform additional modifications such as updating rendering functions, labels, description
        this.update("text", {
            label: t("clientRequest.comment"),
            name: ["text"],
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input.TextArea, {}) })),
        });
    }
};
CommentCreateForm = __decorate([
    Service({ transient: true })
], CommentCreateForm);
export { CommentCreateForm };
