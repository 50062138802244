import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
// ###### 3 => Npm imports ###############
import sift from "sift";
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { colors } from "@root/utils/colors";
const imageTypeFilter = sift({
    mimeType: { $regex: "^image/", $options: "i" },
});
const nonImageTypeFilter = sift({
    mimeType: { $not: { $regex: "^image/", $options: "i" } },
});
// const perTypeIcons = {
//   default: FileTextOutlined,
//   pdf: FilePdfOutlined,
//   sheet: FileExcelOutlined,
//   archive: FileZipOutlined,
// };
const getTypeIcon = (mimeType) => {
    if (mimeType === "application/pdf") {
        return _jsx(FilePdfOutlined, {});
    }
    return _jsx(FileTextOutlined, {});
};
const imgPreviewGrpEllipsisConfig = {
    rows: 10,
    expandable: true,
    symbol: "afficher plus d'images",
};
export const FileGroup = memo((props) => {
    const images = props.appFileGroup.files.filter(imageTypeFilter);
    const nonImages = props.appFileGroup.files.filter(nonImageTypeFilter);
    return (_jsxs("div", { className: props.className, children: [_jsx(Ant.Image.PreviewGroup, { children: _jsx(Ant.Typography.Paragraph, { ellipsis: imgPreviewGrpEllipsisConfig, className: "flex wrap gap-1/2", children: images.map((img) => {
                        var _a;
                        return (_jsx(Ant.Image, { height: 80, width: 80, src: ((_a = img.thumbs[0]) === null || _a === void 0 ? void 0 : _a.downloadUrl) || img.downloadUrl, alt: img.name, preview: { src: img.downloadUrl } }, img._id));
                    }) }) }), _jsx("div", { className: "mar-t-1", children: nonImages.map((f) => (_jsx(Ant.Tag, { icon: getTypeIcon(f.mimeType), color: colors.selfcityGreen.opacity(0.15), className: "fc-primary mar-0 mar-b-1/2 mar-r-1", children: _jsx("a", { href: f.downloadUrl, target: "_blank", className: "fc-inherit", children: f.name }) }, f._id))) })] }));
});
