var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { AgenciesTagsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let AgencyListFiltersForm = class AgencyListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "addresses",
                label: t("management.agencies.fields.addresses"),
                name: ["addresses"],
                columns: true,
                nest: [
                    {
                        id: "streetOne",
                        label: t("management.agencies.fields.addresses.streetOne"),
                        name: ["addresses", "streetOne"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "streetTwo",
                        label: t("management.agencies.fields.addresses.streetTwo"),
                        name: ["addresses", "streetTwo"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.agencies.fields.addresses.zip"),
                        name: ["addresses", "zip"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.agencies.fields.addresses.town"),
                        name: ["addresses", "town"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.agencies.fields.addresses.country"),
                        name: ["addresses", "country"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "geopoint",
                        label: t("management.agencies.fields.addresses.geopoint"),
                        name: ["addresses", "geopoint"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "formattedAddress",
                        label: t("management.agencies.fields.addresses.formattedAddress"),
                        name: ["addresses", "formattedAddress"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "name",
                label: t("management.agencies.fields.name"),
                name: ["name"],
                tooltip: t("management.agencies.fields.name_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "siret",
                label: t("management.agencies.fields.siret"),
                name: ["siret"],
                tooltip: t("management.agencies.fields.siret_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "vatNumber",
                label: t("management.agencies.fields.vatNumber"),
                name: ["vatNumber"],
                tooltip: t("management.agencies.fields.vatNumber_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "email",
                label: t("management.agencies.fields.email"),
                name: ["email"],
                tooltip: t("management.agencies.fields.email_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "address",
                label: t("management.agencies.fields.address"),
                name: ["address"],
                columns: true,
                nest: [
                    {
                        id: "streetOne",
                        label: t("management.agencies.fields.address.streetOne"),
                        name: ["address", "streetOne"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "streetTwo",
                        label: t("management.agencies.fields.address.streetTwo"),
                        name: ["address", "streetTwo"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.agencies.fields.address.zip"),
                        name: ["address", "zip"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.agencies.fields.address.town"),
                        name: ["address", "town"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.agencies.fields.address.country"),
                        name: ["address", "country"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "geopoint",
                        label: t("management.agencies.fields.address.geopoint"),
                        name: ["address", "geopoint"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "formattedAddress",
                        label: t("management.agencies.fields.address.formattedAddress"),
                        name: ["address", "formattedAddress"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "phone",
                label: t("management.agencies.fields.phone"),
                name: ["phone"],
                tooltip: t("management.agencies.fields.phone_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "iptCustomerId",
                label: t("management.agencies.fields.iptCustomerId"),
                name: ["iptCustomerId"],
                tooltip: t("management.agencies.fields.iptCustomerId_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "notes",
                label: t("management.agencies.fields.notes"),
                name: ["notes"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.agencies.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.agencies.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.agencies.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.agencies.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "agenciesTagId",
                label: t("management.agencies.fields.agenciesTag"),
                name: ["agenciesTagId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: AgenciesTagsCollection, field: "tag", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "createdById",
                label: t("management.agencies.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.agencies.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.agencies.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.agencies.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
AgencyListFiltersForm = __decorate([
    Service({ transient: true })
], AgencyListFiltersForm);
export { AgencyListFiltersForm };
