import { gql } from "@apollo/client";
export * from "./Users.collection";
import { UsersCollection as BaseUsersCollection } from "./Users.collection";
export class UsersCollection extends BaseUsersCollection {
    generateBotToken(userId) {
        const queryName = "GenerateBotToken";
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation ${queryName}($userId: ObjectId!) {
            ${queryName}(userId: $userId)
          }
        `,
            variables: { userId },
        })
            .then((response) => response.data[queryName]);
    }
    addPermission(userId, permission) {
        const queryName = "UsersAddPermission";
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation ${queryName}($userId: ObjectId!, $permission: String!) {
            ${queryName}(userId: $userId, permission: $permission)
          }
        `,
            variables: { userId, permission },
        })
            .then((response) => response.data[queryName]);
    }
    removePermission(userId, permission) {
        const queryName = "UsersRemovePermission";
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation ${queryName}($userId: ObjectId!, $permission: String!) {
            ${queryName}(userId: $userId, permission: $permission)
          }
        `,
            variables: { userId, permission },
        })
            .then((response) => response.data[queryName]);
    }
}
