import "./i18n";
import { ShopOutlined } from "@ant-design/icons";
import { AGENCIES_LIST as BASE_AGENCIES_LIST, AGENCIES_CREATE as BASE_AGENCIES_CREATE, AGENCIES_EDIT as BASE_AGENCIES_EDIT, AGENCIES_VIEW as BASE_AGENCIES_VIEW, } from "./config/routes";
import { UserRoles } from "@root/api.types";
export const AGENCIES_LIST = {
    ...BASE_AGENCIES_LIST,
    path: "/agencies",
    menu: {
        ...BASE_AGENCIES_LIST.menu,
        icon: ShopOutlined,
        order: 3,
        roles: [UserRoles.ADMIN, UserRoles.SUPPORT],
    },
};
export const AGENCIES_CREATE = {
    ...BASE_AGENCIES_CREATE,
    path: "/agencies/create",
};
export const AGENCIES_EDIT = {
    ...BASE_AGENCIES_EDIT,
    path: "/agencies/:id/edit",
};
export const AGENCIES_VIEW = {
    ...BASE_AGENCIES_VIEW,
    path: "/agencies/:id/view",
};
