export const phrasesFr = {
    fr: {
        management: {
            agencies: {
                fields: {
                    _id: "ID",
                    name: "Nom de l'agence",
                    name_description: "Le nom de l'entreprise",
                    siret: "Siret",
                    siret_description: "L'uid légal de l'entreprise",
                    vatNumber: "Numéro de TVA",
                    vatNumber_description: "L'identifiant TVA international de l'entreprise",
                    email: "E-mail",
                    email_description: "Une adresse e-mail générique associée à cette société",
                    address: "Adresse",
                    streetOne: "Rue",
                    streetTwo: "Complément d'adresse",
                    zip: "Code postal",
                    town: "Ville",
                    country: "Pays",
                    geopoint: "Géopoint",
                    lat: "Latitude",
                    lng: "Longitude",
                    formattedAddress: "Adresse formatée",
                    phone: "Téléphone",
                    phone_description: "Un numéro de téléphone générique associé à cette entreprise",
                    iptCustomerId: "ID client sur IPT",
                    iptCustomerId_description: "L'uid externe identifiant cette entreprise sur IPaidThat",
                    isDeleted: "est supprimé",
                    isDeleted_description: "Ce champ est utilisé pour identifier si cet objet a été supprimé en douceur",
                    createdById: "ID créateur",
                    updatedById: "ID modificateur",
                    createdAt: "Créée le",
                    createdAt_description: "Représente la date à laquelle cet objet a été créé",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Représente la dernière fois que l'objet a été mis à jour",
                    agenciesTagId: "ID Tag d'agence",
                    clientRequestRelatedContacts: "Contacts liés à la demande du client",
                    members: "Membres",
                    agenciesTag: "Tag de l'agence",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé cet objet",
                    updatedBy: "Modifié par",
                    updatedBy_description: "Représente l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                },
                menu: {
                    title: "Agences",
                },
                list: {
                    header: "Agences",
                    create_btn: "Ajouter une agence",
                },
                create: {
                    header: "Créer une agence",
                },
                edit: {
                    header: "Editer l'agence",
                },
                view: {
                    header: "Voir l'agence",
                },
                delete_confirmation: "Vous avez supprimé l'Agence",
                create_confirmation: "Vous avez ajouté une nouvelle agence avec succès",
                edit_confirmation: "Vous avez modifié l'agence avec succès",
                actions: {
                    search: "Rechercher une agence (nom, téléphone, email)",
                },
            },
        },
    },
};
