var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { ClientRequestPossibleStatus } from "@root/api.types";
import { removeAllFormItemsLabels, removeAllFormItemsStyle, renderOnlyWithOrder, } from "../../utils";
import { ClientRequestListFiltersForm as BaseClientRequestListFiltersForm } from "./ClientRequestListFiltersForm.base";
import * as Ant from "antd";
export const JOBS_ESTIMATIONS_STATUSES = [
    ClientRequestPossibleStatus.S_00230_JOB_ESTIMATION_TO_DO,
    ClientRequestPossibleStatus.S_00240_JOB_ESTIMATION_IN_PROGRESS_TECH,
    ClientRequestPossibleStatus.S_00250_JOB_ESTIMATION_DONE_TECH,
    ClientRequestPossibleStatus.S_00260_JOB_ESTIMATION_TO_CORRECT_TECH,
    ClientRequestPossibleStatus.S_00270_JOB_ESTIMATION_VALIDATED,
];
export const QUOTATIONS_STATUSES = [
    ClientRequestPossibleStatus.S_00340_QUOTATION_CREATED,
    ClientRequestPossibleStatus.S_00350_QUOTATION_SENT,
    ClientRequestPossibleStatus.S_00360_QUOTATION_FOLLOWED_UP,
];
export const INVOICES_STATUSES = [
    ClientRequestPossibleStatus.S_00620_INVOICE_DRAFT,
    ClientRequestPossibleStatus.S_00630_INVOICE_CREATED,
    ClientRequestPossibleStatus.S_00650_INVOICE_SENT,
    ClientRequestPossibleStatus.S_00660_INVOICE_FOLLOWED_UP,
    ClientRequestPossibleStatus.S_00670_INVOICE_PAID,
];
export const clientRequestStatuses = [
    {
        title: ClientRequestPossibleStatus.S_00100_CLIENT_REQUEST,
        values: [ClientRequestPossibleStatus.S_00100_CLIENT_REQUEST],
    },
    {
        title: "JOBS_ESTIMATIONS",
        values: JOBS_ESTIMATIONS_STATUSES,
    },
    {
        title: "QUOTATIONS",
        values: QUOTATIONS_STATUSES,
    },
    {
        title: ClientRequestPossibleStatus.S_00400_PREPARATION,
        values: [ClientRequestPossibleStatus.S_00400_PREPARATION],
    },
    {
        title: ClientRequestPossibleStatus.S_00500_SITE,
        values: [ClientRequestPossibleStatus.S_00500_SITE],
    },
    {
        title: "INVOICES",
        values: INVOICES_STATUSES,
    },
];
let ClientRequestListFiltersForm = class ClientRequestListFiltersForm extends BaseClientRequestListFiltersForm {
    build() {
        super.build();
        const { t } = this.i18n;
        // Perform additional modifications such as updating rendering functions, labels, description
        renderOnlyWithOrder(["reference", "isArchived", "status"], this);
        this.update("reference", {
            render: (props) => {
                return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input.Search, { autoFocus: true, 
                        //   onChange={console.log}
                        placeholder: t("management.client_requests.list.filters.reference") }) }));
            },
            //   componentProps: {
            //     ,
            //   },
        });
        this.update("isArchived", {
            component: Ant.Switch,
            formItemProps: { valuePropName: "checked" },
            componentProps: {
                checkedChildren: "Missions archivées",
                unCheckedChildren: "Missions en cours",
                style: { flex: "none" },
                className: "archiving-btn",
            },
        });
        removeAllFormItemsLabels(this);
        removeAllFormItemsStyle(this);
    }
};
ClientRequestListFiltersForm = __decorate([
    Service({ transient: true })
], ClientRequestListFiltersForm);
export { ClientRequestListFiltersForm };
