import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import React, { memo } from "react";
import { ShopOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { colors } from "@root/utils/colors";
export const AgencyTags = memo((props) => {
    var _a;
    return (_jsxs(_Fragment, { children: [_jsx(Ant.Tag, { icon: _jsx(ShopOutlined, {}), color: colors.selfcityEggplant.hex, children: "Agence" }), props.tag && (_jsx(Ant.Tooltip, { overlayClassName: "display-linebreak", title: props.tag.description, children: _jsx(Ant.Tag, { color: !!props.tag.color && ((_a = colors[props.tag.color]) === null || _a === void 0 ? void 0 : _a.hex), children: props.tag.tag }, props.tag._id) }))] }));
});
