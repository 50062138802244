import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ClientRequestArchivingReason } from "@root/api.types";
import { memo } from "react";
import { Card, Descriptions, Dropdown, Menu } from "antd";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import { useClientRequestSumupCardHooks } from "../ClientRequestSumupCard/ClientRequestSumupCard.hooks";
const labelsStyle = { fontWeight: "bold" };
const itemStyle = { paddingBottom: 4 };
export const ClientRequestArchivedCard = memo((props) => {
    var _a, _b;
    const { t, onArchiveClientRequest, restoreClientRequest } = useClientRequestSumupCardHooks(props);
    const ellipsisMenu = (_jsx(Menu, { children: props.clientRequest.isArchived ? (_jsx(Menu.Item, { onClick: restoreClientRequest, children: t("management.client_requests.actions.restore") }, "restoreMenuBtn")) : (_jsx(Menu.Item, { onClick: onArchiveClientRequest, children: t("management.client_requests.actions.archive") }, "archiveMenuBtn")) }));
    return (_jsx(_Fragment, { children: _jsx(Card, { size: "small", className: props.clientRequest.archivingReason ===
                ClientRequestArchivingReason.BILLING_PAID
                ? "bg-sc-blue-2"
                : "bg-sc-yellow-2", children: _jsxs("div", { style: { display: "-webkit-inline-box" }, children: [_jsxs("div", { className: "flex gap-5", children: [_jsx("span", { className: "fs-2 fw-bold no-linebreak", children: t("management.client_requests.fields.isArchived") }), _jsxs(Descriptions, { column: 1, children: [_jsx(Descriptions.Item, { label: t("management.client_requests.fields.archivingReason"), labelStyle: labelsStyle, style: itemStyle, children: t(`management.client_requests.fields.archivingReason_enum.${props.clientRequest.archivingReason}`) }), _jsx(Descriptions.Item, { label: t("management.client_requests.fields.archivingComment"), labelStyle: labelsStyle, style: itemStyle, children: props.clientRequest.archivingComment }), _jsx(Descriptions.Item, { label: t("management.client_requests.fields.archivedAt"), labelStyle: labelsStyle, style: itemStyle, children: `${moment(props.clientRequest.archivedAt).format("DD/MM/YYYY")} (${(_b = (_a = props.clientRequest.archivedBy) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.firstName})` })] })] }), _jsx("span", { style: { marginLeft: "-26px" }, children: _jsx(Dropdown, { overlay: ellipsisMenu, trigger: ["click"], children: _jsx(MoreOutlined, { className: "fs-3", size: 1 }) }) })] }) }) }));
});
