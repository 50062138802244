import { ClientRequestsList } from "../components/List/ClientRequestsList";
import { ClientRequestsCreate } from "../components/Create/ClientRequestsCreate";
import { ClientRequestsEdit } from "../components/Edit/ClientRequestsEdit";
import { ClientRequestsView } from "../components/View/ClientRequestsView";
import { SettingFilled } from "@ant-design/icons";
export const CLIENT_REQUESTS_LIST = {
    path: "/admin/client-requests",
    component: ClientRequestsList,
    menu: {
        key: "CLIENT_REQUESTS_LIST",
        label: "management.client_requests.menu.title",
        icon: SettingFilled,
    },
};
export const CLIENT_REQUESTS_CREATE = {
    path: "/admin/client-requests/create",
    component: ClientRequestsCreate,
};
export const CLIENT_REQUESTS_EDIT = {
    path: "/admin/client-requests/:id/edit",
    component: ClientRequestsEdit,
};
export const CLIENT_REQUESTS_VIEW = {
    path: "/admin/client-requests/:id/view",
    component: ClientRequestsView,
};
