var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let UserEditForm = class UserEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "skills",
                label: t("management.users.fields.skills"),
                name: ["skills"],
                isList: true,
                nest: [
                    {
                        id: "level",
                        label: t("management.users.fields.skills.level"),
                        name: ["skills", "level"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "skillId",
                        label: t("management.users.fields.skills.skillId"),
                        name: ["skills", "skillId"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "notes",
                        label: t("management.users.fields.skills.notes"),
                        name: ["skills", "notes"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                ],
                initialValue: [],
            },
            {
                id: "slackId",
                label: t("management.users.fields.slackId"),
                name: ["slackId"],
                component: Ant.Input,
            },
            {
                id: "botName",
                label: t("management.users.fields.botName"),
                name: ["botName"],
                component: Ant.Input,
            },
            {
                id: "ringOverUserId",
                label: t("management.users.fields.ringOverUserId"),
                name: ["ringOverUserId"],
                component: Ant.Input,
            },
            {
                id: "isEnabled",
                label: t("management.users.fields.isEnabled"),
                name: ["isEnabled"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "profile",
                label: t("management.users.fields.profile"),
                name: ["profile"],
                required: true,
                nest: [
                    {
                        id: "firstName",
                        label: t("management.users.fields.profile.firstName"),
                        name: ["profile", "firstName"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.users.fields.profile.lastName"),
                        name: ["profile", "lastName"],
                        required: true,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "avatarId",
                label: t("management.users.fields.avatar"),
                name: ["avatarId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            skills: {
                level: 1,
                skillId: 1,
                notes: 1,
            },
            slackId: 1,
            botName: 1,
            ringOverUserId: 1,
            isEnabled: 1,
            profile: {
                firstName: 1,
                lastName: 1,
            },
            avatar: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            avatarId: 1,
            placeId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.users.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => UsersCollection),
    __metadata("design:type", typeof (_a = typeof UsersCollection !== "undefined" && UsersCollection) === "function" ? _a : Object)
], UserEditForm.prototype, "collection", void 0);
UserEditForm = __decorate([
    Service({ transient: true })
], UserEditForm);
export { UserEditForm };
