export const phrasesFr = {
    fr: {
        management: {
            tokens: {
                fields: {
                    _id: "ID",
                    isEnabled: "activé",
                    isEnabled_description: "Si ce jeton est toujours valide ou non",
                    name: "Nom",
                    name_description: "L'identifiant du jeton. Utilisez-le pour vous rappeler quand ce jeton est censé être utilisé",
                    value: "Valeur",
                    value_description: "La valeur du jeton lui-même",
                    createdAt: "Créée le",
                    createdAt_description: "Représente la date à laquelle cet objet a été créé",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Représente la dernière fois que l'objet a été mis à jour",
                    createdById: "ID créateur",
                    createdById_description: "Représente l'identifiant de l'utilisateur qui a créé cet objet",
                    updatedById: "ID modificateur",
                    updatedById_description: "Représente l'identifiant de l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé cet objet",
                    updatedBy: "Modifié par",
                    updatedBy_description: "Représente l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                },
                menu: {
                    title: "Jetons",
                },
                list: {
                    header: "Liste des jetons",
                    create_btn: "Nouveau jeton",
                },
                create: {
                    header: "Créer un jeton",
                },
                edit: {
                    header: "Modifier le jeton",
                },
                view: {
                    header: "Afficher le jeton",
                },
                delete_confirmation: "Vous avez supprimé le Token",
                create_confirmation: "Vous avez ajouté avec succès un nouveau jeton",
                edit_confirmation: "Vous avez modifié le jeton avec succès",
            },
        },
    },
};
