import { use, useTranslate } from "@bluelibs/x-ui";
import { useCallback, useRef } from "react";
import * as Ant from "antd";
import { ClientRequestEditForm } from "@bundles/UIAppBundle/pages/ClientRequestsManagement/config/ClientRequestEditForm";
import { Routes } from "@bundles/UIAppBundle";
import { getTouchedFieldsOnlyFromForm } from "@root/utils/forms";
import { useHistory, useLocation } from "react-router-dom";
export const useClientRequestArchivingModalHooks = (props) => {
    const t = useTranslate();
    const history = useHistory();
    const location = useLocation();
    const modalRef = useRef();
    const [form] = Ant.Form.useForm();
    const xForm = use(ClientRequestEditForm, { transient: true });
    xForm.build();
    const onCancel = useCallback(() => {
        modalRef.current.close();
        form.resetFields();
    }, [modalRef, form]);
    const onOk = useCallback(() => {
        const promiseValidateFields = form.validateFields(["archivingReason"]);
        promiseValidateFields.then(() => {
            form.submit();
            modalRef.current.close();
        });
    }, [modalRef, form]);
    const onFinish = useCallback((document) => {
        if (!props.clientRequest.isArchived || form.isFieldsTouched()) {
            const promise = xForm.onEditSubmit(props.clientRequest._id, {
                isArchived: true,
                // archivedAt: new Date(),
                ...getTouchedFieldsOnlyFromForm(form, document),
            });
            promise.then(() => {
                var _a;
                form.resetFields();
                if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.openedModal) {
                    // If user was already navigating through the app, we just pop the previous state from history
                    // to prevent breaking navigation history.
                    history.goBack();
                }
                else {
                    // If user copied/pasted a link containing a reference to the modal, closing it should not
                    // trigger a "go back" in history, but rather go to the CR list page.
                    xForm.router.go(Routes.CLIENT_REQUESTS_LIST);
                }
            });
        }
    }, [form, xForm]);
    return {
        t,
        form,
        xForm,
        modalRef,
        onFinish,
        onCancel,
        onOk,
    };
};
