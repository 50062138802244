var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ProfilesCollection, AgenciesCollection, ClientRequestRelatedContactsCollection, } from "@bundles/UIAppBundle/collections";
let ClientRequestRelatedContactEditForm = class ClientRequestRelatedContactEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "profileType",
                label: t("management.client_request_related_contacts.fields.profileType"),
                name: ["profileType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { allowClear: true, placeholder: t("management.client_request_related_contacts.fields.profileType"), children: [_jsx(Ant.Select.Option, { value: "TENANT", children: "Tenant" }, "TENANT"), _jsx(Ant.Select.Option, { value: "OWNER", children: "Owner" }, "OWNER"), _jsx(Ant.Select.Option, { value: "OTHER", children: "Other" }, "OTHER")] }) })),
            },
            {
                id: "locationIndex",
                label: t("management.client_request_related_contacts.fields.locationIndex"),
                name: ["locationIndex"],
                component: Ant.InputNumber,
            },
            {
                id: "isPayer",
                label: t("management.client_request_related_contacts.fields.isPayer"),
                name: ["isPayer"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isLocation",
                label: t("management.client_request_related_contacts.fields.isLocation"),
                name: ["isLocation"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isAsker",
                label: t("management.client_request_related_contacts.fields.isAsker"),
                name: ["isAsker"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "distanceFromPlaces",
                label: t("management.client_request_related_contacts.fields.distanceFromPlaces"),
                name: ["distanceFromPlaces"],
                isList: true,
                nest: [
                    {
                        id: "distance",
                        label: t("management.client_request_related_contacts.fields.distanceFromPlaces.distance"),
                        name: ["distanceFromPlaces", "distance"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "duration",
                        label: t("management.client_request_related_contacts.fields.distanceFromPlaces.duration"),
                        name: ["distanceFromPlaces", "duration"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "placeId",
                        label: t("management.client_request_related_contacts.fields.distanceFromPlaces.placeId"),
                        name: ["distanceFromPlaces", "placeId"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "placeName",
                        label: t("management.client_request_related_contacts.fields.distanceFromPlaces.placeName"),
                        name: ["distanceFromPlaces", "placeName"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                ],
                initialValue: [],
            },
            {
                id: "profileId",
                label: t("management.client_request_related_contacts.fields.profile"),
                name: ["profileId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProfilesCollection, field: "fullName", required: false }) })),
            },
            {
                id: "agencyId",
                label: t("management.client_request_related_contacts.fields.agency"),
                name: ["agencyId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: AgenciesCollection, field: "name", required: false }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            profileType: 1,
            locationIndex: 1,
            isPayer: 1,
            isLocation: 1,
            isAsker: 1,
            distanceFromPlaces: {
                distance: 1,
                duration: 1,
                placeId: 1,
                placeName: 1,
            },
            address: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            profile: {
                _id: 1,
                fullName: 1,
            },
            profileId: 1,
            agency: {
                _id: 1,
                name: 1,
            },
            agencyId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.client_request_related_contacts.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ClientRequestRelatedContactsCollection),
    __metadata("design:type", typeof (_a = typeof ClientRequestRelatedContactsCollection !== "undefined" && ClientRequestRelatedContactsCollection) === "function" ? _a : Object)
], ClientRequestRelatedContactEditForm.prototype, "collection", void 0);
ClientRequestRelatedContactEditForm = __decorate([
    Service({ transient: true })
], ClientRequestRelatedContactEditForm);
export { ClientRequestRelatedContactEditForm };
