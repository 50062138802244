import { useOpenModal } from "@root/utils/hooks";
import { useCallback } from "react";
export const useContactCardHooks = (props) => {
    var _a, _b;
    const relatedContact = props.relatedContact;
    const lookupKey = relatedContact.agency ? "agency" : "profile";
    const name = relatedContact.agency
        ? relatedContact.agency.name
        : relatedContact.profile.fullName;
    const openEditProfileModal = useOpenModal("profile");
    const onEditProfile = useCallback(() => {
        openEditProfileModal(relatedContact.profile._id);
    }, [(_a = relatedContact.profile) === null || _a === void 0 ? void 0 : _a._id]);
    const openEditAgencyModal = useOpenModal("agency");
    const onEditAgency = useCallback(() => {
        openEditAgencyModal(relatedContact.agency._id);
    }, [(_b = relatedContact.agency) === null || _b === void 0 ? void 0 : _b._id]);
    const onEditContact = useCallback(() => {
        if (relatedContact.agency) {
            onEditAgency();
        }
        else if (relatedContact.profile) {
            onEditProfile();
        }
    }, [relatedContact]);
    return {
        relatedContact,
        lookupKey,
        onTagToggle: props.onTagToggle,
        onEditContact,
        name,
    };
};
