import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { CalendarOutlined, EuroCircleOutlined, PlusSquareOutlined, CopyOutlined, UserOutlined, EyeOutlined, } from "@ant-design/icons";
import { KanbanCardTag } from "./KanbanCardTag";
import { ToolTippedButton } from "../ToolTippedButton/ToolTippedButton.index";
import axios from "axios";
import { useGuardian, useTranslate } from "@bluelibs/x-ui";
export const KanbanCard = memo((props) => {
    var _a, _b;
    const t = useTranslate();
    const guardian = useGuardian();
    const isPlumber = guardian.hasRole("PLUMBER");
    const onInspectJobEstimation = React.useCallback((jobEstim) => {
        const token = window.localStorage.getItem("bluelibs-token");
        window.location.href = global.NEW_APP_URL + '/missions/' + jobEstim._mission + '/estimates/' + jobEstim._id + '/edit?token=' + token;
    }, []);
    const getColumnHeadClassNames = (mission, class1, class2, class3) => {
        let priority = 3;
        const priorities = {
            1: class1,
            2: class2,
            3: class3,
        };
        const todayMidnight = new Date((new Date()).setHours(0, 0, 0, 0));
        mission.estimates.forEach((estimate) => {
            const dueDay = new Date((new Date(estimate.dueDate)).setHours(0, 0, 0, 0));
            if (estimate.dueDate !== null) {
                if (dueDay.getTime() < todayMidnight.getTime()) {
                    priority = 1;
                }
                else if (dueDay.getTime() === todayMidnight.getTime() && priority > 1) {
                    priority = 2;
                }
            }
        });
        return priorities[`${priority}`];
    };
    const duplicateJobEstimation = React.useCallback(async (jobEstimation) => {
        Ant.Modal.confirm({
            content: (_jsx("div", { children: "Es-tu s\u00FBr(e) de vouloir dupliquer cette estimation ?" })),
            okText: `Oui`,
            cancelText: `Annuler`,
            onOk: () => {
                const token = window.localStorage.getItem("bluelibs-token");
                axios.request({
                    method: "GET",
                    url: global.NEW_API_URL + '/estimates/' + jobEstimation._id + '?fields=*',
                    headers: { "Authorization": token },
                }).then((response) => {
                    return axios.request({
                        method: "POST",
                        url: global.NEW_API_URL + '/missions/' + jobEstimation._mission + '/estimates',
                        headers: { "Authorization": token },
                        data: {
                            dueDate: null,
                            assignedTo: response.data.assignedTo,
                            categories: response.data.categories,
                            packages: response.data.packages.map((pack) => ({
                                template: pack.template,
                                options: pack.options,
                                subject: pack.subject,
                            })),
                            attachments: [],
                            // TODO attachments
                        },
                    }).then((res) => {
                        const je = res.data;
                        const token = window.localStorage.getItem("bluelibs-token");
                        window.location.href = global.NEW_APP_URL + '/missions/' + jobEstimation._mission + '/estimates/' + je._id + '/edit?token=' + token;
                    });
                }).catch((e) => {
                    Ant.notification.warn({
                        message: 'Erreur',
                        description: e.response.data.error.code,
                    });
                });
            },
        });
    }, []);
    const totalPriceET = (estimate) => Math.round(estimate.categories.reduce((tot, cat) => (tot + cat.tasks.reduce((sum, task) => sum + task.hours * task.hourlyRate, 0)
        + cat.supplies.reduce((sum, supply) => sum + supply.quantity * supply.unitPrice * (1 + supply.margin / 100), 0)), 0));
    const cRTotalNbrHours = props.clientRequest.estimates.reduce((t, e) => {
        const a = e.categories.reduce((tot, cat) => ({
            main: tot.main + cat.tasks.filter((task) => !task.isSideJob).reduce((sum, task) => sum + task.hours, 0),
            side: tot.side + cat.tasks.filter((task) => task.isSideJob).reduce((sum, task) => sum + task.hours, 0),
        }), { main: 0, side: 0 });
        return {
            main: t.main + a.main,
            side: t.side + a.side,
        };
    }, { main: 0, side: 0 });
    const cRTotalPriceET = Math.round(props.clientRequest.estimates.reduce((acc, estimate) => (acc
        + totalPriceET(estimate)), 0));
    const cRFirstDueDate = props.clientRequest.estimates.reduce((acc, estimate) => (Math.min(acc, estimate.dueDate === null ? Infinity : new Date(estimate.dueDate).getTime())), Infinity);
    const showJECreateConfirmModal = (cRId) => {
        const token = window.localStorage.getItem("bluelibs-token");
        window.location.href = global.NEW_APP_URL + '/missions/' + cRId + '/estimates/create?token=' + token;
    };
    return (_jsxs(Ant.Card, { size: "small", className: getColumnHeadClassNames(props.clientRequest, "bg-sc-red-1", "bg-sc-yellow-1", '') +
            ((props.clientRequest.estimates.length === 1) ? " clickable" : ""), onClick: () => {
            (props.clientRequest.estimates.length === 1) &&
                onInspectJobEstimation(props.clientRequest.estimates[0]);
        }, children: [_jsx("div", { className: "je-kanban-card-title", children: (_a = props.clientRequest) === null || _a === void 0 ? void 0 : _a.reference }), _jsx("div", { className: "mar-b-1", children: props.clientRequest.estimates.map((je) => 
                //@ts-ignore
                _jsx("div", { children: (_jsxs(_Fragment, { children: [_jsxs("a", { onClick: (e) => {
                                    e.stopPropagation();
                                    onInspectJobEstimation(je);
                                }, children: ["\u2022", (je.status === 'DELIVERED' ||
                                        je.status === 'VALIDATED') && (_jsxs(_Fragment, { children: [_jsxs("span", { className: "fw-bold fc-sc-green", children: [" ", t(`management.job_estimations.fields.status_enum.${je._status}`).toLocaleUpperCase(), " "] }), _jsx("span", { children: "-" })] })), ` Estimation (env. ${(totalPriceET(je) * 1.1 / 100).toFixed(2)}€ TTC)`] }), _jsx(ToolTippedButton, { title: "Voir l'estimation", icon: _jsx(EyeOutlined, {}), onClick: (e) => {
                                    e.stopPropagation();
                                    onInspectJobEstimation(je);
                                }, type: "link", className: "show-on-hover" }), _jsx(ToolTippedButton, { title: t("management.job_estimations.actions.duplicate"), icon: _jsx(CopyOutlined, {}), onClick: (e) => {
                                    e.stopPropagation();
                                    duplicateJobEstimation(je);
                                }, type: "link", className: "show-on-hover" })] })) })) }), _jsxs("div", { className: "je-kanban-card-tag-div", children: [_jsx(KanbanCardTag, { icon: _jsx(UserOutlined, {}), text: cRTotalNbrHours
                            ? `${cRTotalNbrHours.main} h (${cRTotalNbrHours.side} h)`
                            : "- h" }), _jsx(KanbanCardTag, { icon: _jsx(EuroCircleOutlined, {}), text: cRTotalPriceET ? `env. ${(cRTotalPriceET * 1.1 / 100).toFixed(2)} € TTC` : "- €" }), _jsx(KanbanCardTag, { icon: _jsx(CalendarOutlined, { className: getColumnHeadClassNames(props.clientRequest, "fc-sc-red", "fc-sc-yellow", "fc-sc-green") }), text: cRFirstDueDate !== Infinity
                            ? (_b = new Date(cRFirstDueDate)) === null || _b === void 0 ? void 0 : _b.toLocaleDateString("fr-FR")
                            : "-", textClassName: getColumnHeadClassNames(props.clientRequest, "fc-sc-red", "fc-sc-yellow", "fc-sc-black") }), _jsx(Ant.Button, { type: "link", icon: _jsx(PlusSquareOutlined, {}), className: "show-on-hover", onClick: (e) => {
                            e.stopPropagation();
                            showJECreateConfirmModal(props.clientRequest._id);
                        } })] })] }));
});
