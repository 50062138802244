import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { useTranslate } from "@bluelibs/x-ui";
const emptyStyle = { color: "var(--empty-card-color)" };
const emptyImgStyle = { display: "none" };
export const CardEmpty = memo((props) => {
    const t = useTranslate();
    return (_jsx(Ant.Empty, { className: "mar-t-2 mar-b-0", style: emptyStyle, image: null, imageStyle: emptyImgStyle, description: props.description }));
});
