var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var UserEditForm_1;
import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { Service } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import { setAllXFormElemsPlaceholders } from "../../utils";
import { UserEditForm as BaseUserEditForm } from "./UserEditForm.base";
let UserEditForm = UserEditForm_1 = class UserEditForm extends BaseUserEditForm {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
        setAllXFormElemsPlaceholders(this);
        const { t } = this.i18n;
        this.update("skills", {
            id: "skills",
            label: t("management.users.fields.skills"),
            name: ["skills"],
            required: true,
            isList: true,
            nest: [
                {
                    id: "level",
                    label: t("management.users.fields.skills.level"),
                    name: ["skills", "level"],
                    required: true,
                    initialValue: [],
                    render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Rate, {}) })),
                },
                {
                    id: "notes",
                    label: t("management.users.fields.skills.notes"),
                    name: ["skills", "notes"],
                    initialValue: "",
                    component: Ant.Input,
                },
            ],
            initialValue: [],
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            roles: 1,
        };
    }
    onCreateSubmit(values, options = {}) {
        var _a;
        const { t } = this.i18n;
        (_a = values.skills) === null || _a === void 0 ? void 0 : _a.forEach((skill, index) => {
            values.skills[index].level *= 10;
        });
        const insertPromise = this.collection.insertOne(values, {
            refetchBody: (body) => ({
                ...body,
                ...((options === null || options === void 0 ? void 0 : options.queryBody) || UserEditForm_1.getRequestBody()),
            }),
        });
        insertPromise.then(({}) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.users.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        });
        insertPromise.catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
        return insertPromise;
    }
    onEditSubmit(_id, values, options) {
        const { t } = this.i18n;
        const promise = this.collection.updateOne(_id, values, {
            refetchBody: (body) => ({
                ...body,
                ...((options === null || options === void 0 ? void 0 : options.queryBody) || UserEditForm_1.getRequestBody()),
            }),
        });
        promise.then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.job_estimations.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        });
        promise.catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: err.reason || err.message,
            });
        });
        return promise;
    }
};
UserEditForm = UserEditForm_1 = __decorate([
    Service({ transient: true })
], UserEditForm);
export { UserEditForm };
