import "./i18n";
import { INBOX_TASKS_LIST as BASE_INBOX_TASKS_LIST, INBOX_TASKS_CREATE as BASE_INBOX_TASKS_CREATE, INBOX_TASKS_EDIT as BASE_INBOX_TASKS_EDIT, INBOX_TASKS_VIEW as BASE_INBOX_TASKS_VIEW, } from "./config/routes";
import { BellOutlined } from "@ant-design/icons";
import { UserRoles } from "@root/api.types";
export const INBOX_TASKS_LIST = {
    ...BASE_INBOX_TASKS_LIST,
    path: "/inbox-tasks",
    menu: {
        key: "INBOX",
        label: "management.profiles.menu.inbox",
        icon: BellOutlined,
        order: 0,
        roles: [UserRoles.ADMIN, UserRoles.SUPPORT],
    },
};
// export const AGENDA: IRoute = {
//   ...BASE_INBOX_TASKS_LIST,
//   path: "/agenda",
//   component: () => {
//     const UIComponents = useUIComponents();
//     return (
//       <UIComponents.AdminLayout>
//         <Layout.Content className="inbox-layout" style={{ padding: '0px' }}>
//         </Layout.Content>
//       </UIComponents.AdminLayout>
//     )
//   },
//   menu: {
//     key: "AGENDA",
//     label: "AGENDA",
//     icon: CalendarOutlined,
//     order: 100,
//     roles: [UserRoles.ADMIN, UserRoles.SUPPORT],
//   },
// };
export const INBOX_TASKS_CREATE = {
    ...BASE_INBOX_TASKS_CREATE,
};
export const INBOX_TASKS_EDIT = {
    ...BASE_INBOX_TASKS_EDIT,
};
export const INBOX_TASKS_VIEW = {
    ...BASE_INBOX_TASKS_VIEW,
};
