import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import * as React from "react";
import { use, useRouter } from "@bluelibs/x-ui";
import * as debounce from "lodash.debounce";
import { ClientRequestListFiltersForm, clientRequestStatuses, } from "../../config/ClientRequestListFiltersForm";
import { ClientRequestsAntTableSmart } from "./ClientRequestsTableSmart";
import { TagsFilter } from "@bundles/UIAppBundle/components/FilterTags/TagsFilter.index";
import { gql, useQuery } from "@apollo/client";
import { useQueryParams } from "@root/utils/hooks";
import { useLocation } from "react-router-dom";
export const ClientRequestsListFilters = React.memo(ClientRequestsListFiltersBase);
const COUNTS_BY_STATUS = gql `
  query ClientRequestsCountByStatus($isArchived: Boolean) {
    ClientRequestsCountByStatus(isArchived: $isArchived) {
      _id
      count
    }
  }
`;
export function ClientRequestsListFiltersBase(props) {
    const [form] = Ant.Form.useForm();
    const router = useRouter();
    const location = useLocation();
    const queryParams = useQueryParams();
    const { data } = useQuery(COUNTS_BY_STATUS, {
        variables: {
            isArchived: form.getFieldValue("isArchived"),
        },
        fetchPolicy: "cache-and-network",
    });
    const counts = {
        ALL: 0,
    };
    if (data !== undefined) {
        data.ClientRequestsCountByStatus.forEach((status) => {
            counts[status._id] = status.count;
            counts.ALL += status.count;
        });
    }
    const updateFilters = React.useCallback((filters) => {
        // Updates URL whenever filters change.
        const newQueryString = `isArchived=${filters.isArchived}&reference=${filters.reference}&status=${filters.status}`;
        if (location.search === "") {
            router.history.replace({
                search: newQueryString,
            });
        }
        else if (location.search.slice(1) !== newQueryString) {
            router.history.push({
                search: newQueryString,
            });
        }
        if (filters.status) {
            const status = Array.isArray(filters.status)
                ? filters.status[0]
                : filters.status;
            const cr = clientRequestStatuses.find((crStatus) => crStatus.title === status);
            if (cr) {
                filters.status = cr.values;
            }
            else if (status !== undefined) {
                filters.status = [status];
            }
        }
        filters.reference = filters.reference
            .split(/[, -]/i)
            .filter((word) => word.trim() !== "")
            .join("(.*)");
        props.onUpdate(filters);
    }, [props.onUpdate]);
    const debouncedFilterUpdates = React.useMemo(() => {
        return debounce((_, filters) => {
            updateFilters(filters);
        }, 1000);
    }, [updateFilters]);
    // Updates filters at first load.
    React.useEffect(() => {
        const reference = queryParams.reference || "";
        const isArchived = queryParams.isArchived === "true";
        const status = (queryParams.status || "") === "" ? [] : [queryParams.status];
        const timeoutId = setTimeout(() => {
            form.setFieldsValue({ reference, status, isArchived });
            updateFilters({ reference, status, isArchived });
        }, 500);
        return () => clearTimeout(timeoutId);
    }, []);
    const filterForm = use(ClientRequestListFiltersForm, { transient: true });
    filterForm.build();
    return (_jsxs(Ant.Form, { form: form, preserve: true, onValuesChange: debouncedFilterUpdates, initialValues: ClientRequestsAntTableSmart.defaultFilters, children: [_jsxs("div", { className: "search-bar", children: [filterForm.render("reference"), filterForm.render("isArchived"), props.newBoilerServiceMission, props.newMission] }), _jsxs("div", { children: [_jsx("div", { className: "hidden", children: filterForm.render("status") }), _jsx(TagsFilter, { counts: counts, value: form.getFieldValue("status"), tags: clientRequestStatuses, onChange: (value, parentTag) => {
                            form.setFieldsValue({ status: value });
                            const valueQueryString = (value === null || value === void 0 ? void 0 : value.length) > 1 ? parentTag : (value || [""])[0];
                            updateFilters({
                                ...form.getFieldsValue(),
                                status: valueQueryString,
                            });
                        } })] })] }));
}
