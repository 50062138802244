import { ClientRequestsCollection, } from "@bundles/UIAppBundle/collections";
import { Routes } from "@bundles/UIAppBundle";
import { AntTableSmart } from "@bluelibs/x-ui-admin";
import { ClientRequestList } from "../../config/ClientRequestList";
import { ClientRequestActivity, ClientRequestPossibleStatus, } from "@root/api.types";
import { setListColumnsOrder } from "@bundles/UIAppBundle/pages/utils";
import { INVOICES_STATUSES, JOBS_ESTIMATIONS_STATUSES, QUOTATIONS_STATUSES, } from "../../config/ClientRequestListFiltersForm";
const orderedGeneralItemsIds = [
    "reference",
    "createdAt",
    "status",
    "estimatedBy",
    "executedBy",
];
const orderedQuotationsAndJobEstimationsItemsIds = [
    "reference",
    "createdAt",
    "status",
    "estimatedBy",
    "executedBy",
    "quotationsTotalPriceIT",
    "quotationsSentAt",
    "quotationslastFollowedUpAt",
];
const orderedInvoicesItemsIds = [
    "reference",
    "createdAt",
    "status",
    "estimatedBy",
    "executedBy",
    "invoicesTotalPriceIT",
    "invoicesSentAt",
    "invoiceslastFollowedUpAt",
    "invoicesPaidAt",
];
export class ClientRequestsAntTableSmart extends AntTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = ClientRequestsCollection;
    }
    getBody() {
        return ClientRequestList.getRequestBody();
    }
    getColumns() {
        var _a, _b, _c, _d;
        const list = this.container.get(ClientRequestList);
        list.build();
        const filters = (_a = this.state) === null || _a === void 0 ? void 0 : _a.filters;
        const statuses = (_d = (_c = (_b = filters === null || filters === void 0 ? void 0 : filters.$and) === null || _b === void 0 ? void 0 : _b.find((filter) => !!filter.status)) === null || _c === void 0 ? void 0 : _c.status) === null || _d === void 0 ? void 0 : _d.$in;
        if ((statuses === null || statuses === void 0 ? void 0 : statuses.length) > 0) {
            const isQuotationParentStatusSelected = statuses === null || statuses === void 0 ? void 0 : statuses.includes(QUOTATIONS_STATUSES[0]);
            const isQuotationChildStatusSelected = QUOTATIONS_STATUSES.includes(statuses[0]);
            const isQuotationFilterSelected = isQuotationParentStatusSelected || isQuotationChildStatusSelected;
            const isJobEstimationParentStatusSelected = statuses === null || statuses === void 0 ? void 0 : statuses.includes(JOBS_ESTIMATIONS_STATUSES[0]);
            const isJobEstimationChildStatusSelected = JOBS_ESTIMATIONS_STATUSES.includes(statuses[0]);
            const isJobEstimationFilterSelected = isJobEstimationParentStatusSelected ||
                isJobEstimationChildStatusSelected;
            const isInvoiceParentStatusSelected = statuses === null || statuses === void 0 ? void 0 : statuses.includes(INVOICES_STATUSES[0]);
            const isInvoiceChildStatusSelected = INVOICES_STATUSES.includes(statuses[0]);
            const isInvoiceFilterSelected = isInvoiceParentStatusSelected || isInvoiceChildStatusSelected;
            isQuotationFilterSelected || isJobEstimationFilterSelected
                ? setListColumnsOrder(orderedQuotationsAndJobEstimationsItemsIds, list)
                : isInvoiceFilterSelected
                    ? setListColumnsOrder(orderedInvoicesItemsIds, list)
                    : setListColumnsOrder(orderedGeneralItemsIds, list);
        }
        else {
            setListColumnsOrder(orderedGeneralItemsIds, list);
        }
        return [...list.rest()];
    }
    getSortMap() {
        return ClientRequestList.getSortMap();
    }
    getTablePropsWithFilters() {
        return this.getTableProps();
    }
    getTableProps() {
        return {
            ...super.getTableProps(),
            onRow: (record, rowIndex) => {
                return {
                    onClick: () => {
                        this.router.go(Routes.CLIENT_REQUESTS_EDIT, {
                            params: { id: record._id.toString() },
                        });
                    },
                };
            },
        };
    }
    async createNewClientRequest() {
        const res = await this.collection.insertOne({
            activity: ClientRequestActivity.PLUMBING,
            status: ClientRequestPossibleStatus.S_00100_CLIENT_REQUEST,
        });
        this.router.go(Routes.CLIENT_REQUESTS_EDIT, {
            params: { id: res._id.toString() },
        });
        return res;
    }
}
ClientRequestsAntTableSmart.defaultFilters = {
    isArchived: false,
    status: [],
    reference: "",
};
