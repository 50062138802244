var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let UserList = class UserList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "skills",
                title: t("management.users.fields.skills"),
                key: "management.users.fields.skills",
                dataIndex: ["skills"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "roles",
                title: t("management.users.fields.roles"),
                key: "management.users.fields.roles",
                dataIndex: ["roles"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "slackId",
                title: t("management.users.fields.slackId"),
                key: "management.users.fields.slackId",
                dataIndex: ["slackId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "botName",
                title: t("management.users.fields.botName"),
                key: "management.users.fields.botName",
                dataIndex: ["botName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "ringOverUserId",
                title: t("management.users.fields.ringOverUserId"),
                key: "management.users.fields.ringOverUserId",
                dataIndex: ["ringOverUserId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "email",
                title: t("management.users.fields.email"),
                key: "management.users.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "fullName",
                title: t("management.users.fields.fullName"),
                key: "management.users.fields.fullName",
                dataIndex: ["fullName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.users.fields.updatedAt"),
                key: "management.users.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.users.fields.createdAt"),
                key: "management.users.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isEnabled",
                title: t("management.users.fields.isEnabled"),
                key: "management.users.fields.isEnabled",
                dataIndex: ["isEnabled"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "profile.firstName",
                title: t("management.users.fields.profile.firstName"),
                key: "management.users.fields.profile.firstName",
                dataIndex: ["profile", "firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "profile.lastName",
                title: t("management.users.fields.profile.lastName"),
                key: "management.users.fields.profile.lastName",
                dataIndex: ["profile", "lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "avatar",
                title: t("management.users.fields.avatar"),
                key: "management.users.fields.avatar",
                dataIndex: ["avatar"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                title: t("management.users.fields.createdBy"),
                key: "management.users.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                title: t("management.users.fields.updatedBy"),
                key: "management.users.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            avatar: "avatar._id",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            skills: {
                level: 1,
                skillId: 1,
                notes: 1,
            },
            roles: 1,
            slackId: 1,
            botName: 1,
            ringOverUserId: 1,
            email: 1,
            fullName: 1,
            updatedAt: 1,
            createdAt: 1,
            isEnabled: 1,
            profile: {
                firstName: 1,
                lastName: 1,
            },
            avatar: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            avatarId: 1,
            placeId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
UserList = __decorate([
    Service({ transient: true })
], UserList);
export { UserList };
