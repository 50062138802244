import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, useDataOne, use, useTranslate, } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { AgenciesTagsCollection, } from "@bundles/UIAppBundle/collections";
import { AgenciesTagViewer } from "../../config/AgenciesTagViewer";
import { features } from "../../config/features";
export function AgenciesTagsView(props) {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const collection = use(AgenciesTagsCollection);
    // If you want to benefit of live data features use useLiveData()
    const { data: document, isLoading, error, } = useDataOne(AgenciesTagsCollection, new ObjectId(props.id), AgenciesTagViewer.getRequestBody());
    let content;
    if (isLoading) {
        content = (_jsx(Ant.Space, { align: "center", children: _jsx(Ant.Spin, { size: "large" }) }));
    }
    else {
        if (error || document === null) {
            content = (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }));
        }
        else {
            content = _jsx(AgenciesTagsViewComponent, { document: document });
        }
    }
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.agencies_tags.view.header"), onBack: () => window.history.back(), extra: getHeaderActions(collection, router, props.id) }), _jsx(Ant.Card, { children: content })] }));
}
export function AgenciesTagsViewComponent(props) {
    const document = props.document;
    const viewer = use(AgenciesTagViewer, { transient: true });
    viewer.setDocument(document);
    viewer.build();
    return (_jsx(Ant.Descriptions, { children: viewer.rest().map((item) => {
            return (_jsx(Ant.Descriptions.Item, { label: item.label, children: viewer.render(item) }, item.id));
        }) }));
}
export function getHeaderActions(collection, router, id) {
    const actions = [];
    const t = useTranslate();
    if (features.edit) {
        actions.push(_jsx(Link, { to: router.path(Routes.AGENCIES_TAGS_EDIT, {
                params: { id },
            }), children: _jsx(Ant.Button, { children: t("generics.edit") }) }, "edit"));
    }
    if (features.delete) {
        actions.push(_jsx(Ant.Popconfirm, { title: "Are you sure you want to delete this AgenciesTag?", onConfirm: () => {
                collection.deleteOne(id).then(() => {
                    router.go(Routes.AGENCIES_TAGS_LIST);
                    Ant.notification.success({
                        message: "Success",
                        description: "You have deleted the AgenciesTag",
                    });
                });
            }, children: _jsx(Ant.Button, { danger: true, children: t("generics.delete") }) }, "delete"));
    }
    const viewRoutePath = router.path(Routes.AGENCIES_TAGS_VIEW, {
        params: { id },
    });
    return actions;
}
