import "./i18n";
import { TOKENS_LIST as BASE_TOKENS_LIST, TOKENS_CREATE as BASE_TOKENS_CREATE, TOKENS_EDIT as BASE_TOKENS_EDIT, TOKENS_VIEW as BASE_TOKENS_VIEW, } from "./config/routes";
import { ADMIN_MENU } from "../const";
import { UserRoles } from "@root/api.types";
export const TOKENS_LIST = {
    ...BASE_TOKENS_LIST,
    path: "/tokens",
    menu: {
        ...BASE_TOKENS_LIST.menu,
        inject: ADMIN_MENU,
        roles: [UserRoles.ADMIN],
    },
};
export const TOKENS_CREATE = {
    ...BASE_TOKENS_CREATE,
};
export const TOKENS_EDIT = {
    ...BASE_TOKENS_EDIT,
};
export const TOKENS_VIEW = {
    ...BASE_TOKENS_VIEW,
};
