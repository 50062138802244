// import "./i18n";
import { ProjectOutlined } from "@ant-design/icons";
import { JobEstimationsKanban } from "./JobEstimationsKanban";
import { UserRoles } from "@root/api.types";
export const KANBAN = {
    path: "/kanban",
    component: JobEstimationsKanban,
    menu: {
        key: "KANBAN",
        label: "management.job_estimations.menu.kanban",
        icon: ProjectOutlined,
        order: 4,
        roles: [UserRoles.ADMIN, UserRoles.PLUMBER, UserRoles.SUPPORT],
    },
};
