import { ObjectId } from "@bluelibs/ejson";
import { use } from "@bluelibs/x-ui";
import { UsersCollection } from "../collections";
export const useTechniciansList = () => {
    const usersCollection = use(UsersCollection);
    const { refetch, data: techniciansList = [], loading, error, } = usersCollection.useQuery({
        queryInput: { filters: { roles: "PLUMBER" } },
        body: {
            _id: 1,
            fullName: 1,
        },
    });
    return {
        techniciansList,
        loading,
        error,
        refetch,
    };
};
export const getUserById = (_id) => {
    if (!_id) {
        return {
            user: null,
            loading: true,
            error: false,
            refetch: () => null,
        };
    }
    const usersCollection = use(UsersCollection);
    const { refetch, data: user, loading, error, } = usersCollection.useQueryOne({
        queryInput: { filters: { _id: new ObjectId(_id) } },
        body: {
            fullName: 1,
        },
    });
    return {
        user,
        loading,
        error,
        refetch,
    };
};
