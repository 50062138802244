import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from "react";
import { ProfileEditForm } from "@bundles/UIAppBundle/pages/ProfilesManagement/config/ProfileEditForm";
import { defaultOkBtnProps, Modal, } from "@bundles/UIAppBundle/components/Modal/Modal.index";
import { useFormModalHooks } from "@bundles/UIAppBundle/components/Modal/Modal.hooks";
import { ClientRequestRelatedContactsCollection, ProfilesCollection, AgenciesCollection, } from "@bundles/UIAppBundle/collections";
import { use } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import * as Ant from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Address from "@bundles/UIAppBundle/components/Address/Address.index";
export const ContactAddressesFormModal = memo((props) => {
    const newAddress = React.useRef(null);
    const [selectedAddressIndex, setSelectedAddressIndex] = React.useState(-1);
    const [loading, setLoading] = React.useState(true);
    const [contact, setContact] = React.useState(null);
    const { isLoading, modalRef, queryParams } = useFormModalHooks({
        collection: ProfilesCollection,
        queryParam: "contactAddresses",
        form: ProfileEditForm,
    });
    const contactId = queryParams.contactAddresses;
    const relatedContactsCollection = use(ClientRequestRelatedContactsCollection);
    const profilesCollection = use(ProfilesCollection);
    const agenciesCollection = use(AgenciesCollection);
    const selectAddress = React.useCallback((index) => () => {
        setSelectedAddressIndex(index);
    }, []);
    const setNewAddress = React.useCallback((address) => {
        newAddress.current = address;
    }, []);
    const addNewAddress = React.useCallback(() => {
        setContact({
            ...contact,
            addresses: contact.addresses.concat(newAddress.current),
        });
        setSelectedAddressIndex(contact.addresses.length);
        newAddress.current = null;
    }, [contact]);
    const deleteAddress = React.useCallback((index) => () => {
        setContact({
            ...contact,
            addresses: contact.addresses
                .slice(0, index)
                .concat(contact.addresses.slice(index + 1)),
        });
    }, [contact]);
    React.useEffect(() => {
        if (contactId) {
            relatedContactsCollection
                .findOne({ filters: { _id: new ObjectId(contactId) } }, {
                clientRequestId: 1,
                profile: {
                    _id: 1,
                    address: {
                        streetOne: 1,
                        streetTwo: 1,
                        zip: 1,
                        town: 1,
                        country: 1,
                        formattedAddress: 1,
                        geopoint: {
                            lat: 1,
                            lng: 1,
                        },
                    },
                    addresses: {
                        streetOne: 1,
                        streetTwo: 1,
                        zip: 1,
                        town: 1,
                        country: 1,
                        formattedAddress: 1,
                        geopoint: {
                            lat: 1,
                            lng: 1,
                        },
                    },
                },
                agency: {
                    _id: 1,
                    address: {
                        streetOne: 1,
                        streetTwo: 1,
                        zip: 1,
                        town: 1,
                        country: 1,
                        formattedAddress: 1,
                        geopoint: {
                            lat: 1,
                            lng: 1,
                        },
                    },
                    addresses: {
                        streetOne: 1,
                        streetTwo: 1,
                        zip: 1,
                        town: 1,
                        country: 1,
                        formattedAddress: 1,
                        geopoint: {
                            lat: 1,
                            lng: 1,
                        },
                    },
                },
            })
                .then((response) => {
                if (response.profile !== null) {
                    setContact({
                        type: "profile",
                        id: response.profile._id,
                        clientRequestId: response.clientRequestId,
                        addresses: (response.profile.address
                            ? [response.profile.address]
                            : []).concat(response.profile.addresses || []),
                    });
                }
                else if (response.agency !== null) {
                    setContact({
                        type: "agency",
                        id: response.agency._id,
                        clientRequestId: response.clientRequestId,
                        addresses: (response.agency.address
                            ? [response.agency.address]
                            : []).concat(response.agency.addresses || []),
                    });
                }
                setLoading(false);
            });
        }
    }, [contactId]);
    return (_jsx(_Fragment, { children: !isLoading && !loading && contact !== null && (_jsx(Modal, { queryParam: "contactAddresses", title: "D\u00E9finir une adresse d'intervention", width: 800, okButtonProps: defaultOkBtnProps, onOk: async () => {
                var _a, _b;
                if (contact.type === "profile") {
                    if (contact.addresses.length > 1) {
                        await profilesCollection.updateOne(new ObjectId(contact.id), {
                            addresses: contact.addresses.slice(1),
                            address: contact.addresses[0],
                        }, { refetchBody: { _id: 1 } });
                    }
                    if (selectedAddressIndex >= 0) {
                        await relatedContactsCollection.updateOne(new ObjectId(`${contactId}`), { isLocation: true, locationIndex: selectedAddressIndex });
                    }
                }
                else {
                    if (contact.addresses.length > 1) {
                        await agenciesCollection.updateOne(new ObjectId(`${contact.id}`), {
                            addresses: contact.addresses.slice(1),
                            address: contact.addresses[0],
                        }, { refetchBody: { _id: 1 } });
                    }
                    if (selectedAddressIndex >= 0) {
                        await relatedContactsCollection.updateOne(new ObjectId(`${contactId}`), { isLocation: true, locationIndex: selectedAddressIndex });
                    }
                }
                (_b = (_a = modalRef === null || modalRef === void 0 ? void 0 : modalRef.current) === null || _a === void 0 ? void 0 : _a.close) === null || _b === void 0 ? void 0 : _b.call(_a);
                window.location.href = `/client-requests/${contact.clientRequestId}/edit`;
            }, onCancel: () => {
                var _a, _b;
                (_b = (_a = modalRef === null || modalRef === void 0 ? void 0 : modalRef.current) === null || _a === void 0 ? void 0 : _a.close) === null || _b === void 0 ? void 0 : _b.call(_a);
            }, modalRef: modalRef, children: _jsxs("div", { className: "flex flex-col vgap-3", style: { padding: "var(--gaps-4) 0" }, children: [_jsxs("div", { className: "flex flex-col vgap-2", children: [_jsx("div", { children: "S\u00E9lectionnez l'addresse du lieu d'intervention:" }), _jsx("div", { className: "flex flex-col vgap-2", children: contact.addresses.map((address, key) => (_jsxs("div", { className: "flex hgap-2", children: [_jsx(Ant.Button, { className: "green-btn w-full", onClick: selectAddress(key), type: key === selectedAddressIndex ? "primary" : "default", children: address.formattedAddress }, address.formattedAddress + "" + key), _jsx(Ant.Button, { onClick: deleteAddress(key), icon: _jsx(DeleteOutlined, {}), type: "primary", ghost: true, danger: true, disabled: key === 0, title: key === 0
                                                ? "Vous ne pouvez pas supprimer l'adresse principale d'un contact."
                                                : undefined })] }))) })] }), _jsxs("div", { className: "flex flex-col vgap-2", children: [_jsx("div", { children: "Ou ajoutez-en une nouvelle:" }), _jsx(Address, { onChange: setNewAddress }), _jsx(Ant.Button, { className: "green-btn w-full", onClick: addNewAddress, children: "Ajouter cette adresse" })] })] }) })) }));
});
