var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let AgenciesTagListFiltersForm = class AgenciesTagListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "createdAt",
                label: t("management.agencies_tags.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.agencies_tags.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.agencies_tags.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.agencies_tags.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "tag",
                label: t("management.agencies_tags.fields.tag"),
                name: ["tag"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "description",
                label: t("management.agencies_tags.fields.description"),
                name: ["description"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "activity",
                label: t("management.agencies_tags.fields.activity"),
                name: ["activity"],
                tooltip: t("management.agencies_tags.fields.activity_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.agencies_tags.fields.activity"), children: [_jsx(Ant.Select.Option, { value: "MECHANICAL_VENTILATION", children: "Mechanical Ventilation" }, "MECHANICAL_VENTILATION"), _jsx(Ant.Select.Option, { value: "PLUMBING", children: "Plumbing" }, "PLUMBING"), _jsx(Ant.Select.Option, { value: "RENOVATION", children: "Renovation" }, "RENOVATION"), _jsx(Ant.Select.Option, { value: "HEATING", children: "Heating" }, "HEATING"), _jsx(Ant.Select.Option, { value: "AIR_CONDITIONING", children: "Air Conditioning" }, "AIR_CONDITIONING"), _jsx(Ant.Select.Option, { value: "MULTISERVICE", children: "Multiservice" }, "MULTISERVICE"), _jsx(Ant.Select.Option, { value: "LOCKSMITHING", children: "Locksmithing" }, "LOCKSMITHING"), _jsx(Ant.Select.Option, { value: "ELECTRICAL", children: "Electrical" }, "ELECTRICAL")] }) })),
            },
            {
                id: "color",
                label: t("management.agencies_tags.fields.color"),
                name: ["color"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.agencies_tags.fields.color"), children: [_jsx(Ant.Select.Option, { value: "selfcityGreen", children: "Selfcitygreen" }, "selfcityGreen"), _jsx(Ant.Select.Option, { value: "selfcityRed", children: "Selfcityred" }, "selfcityRed"), _jsx(Ant.Select.Option, { value: "selfcityYellow", children: "Selfcityyellow" }, "selfcityYellow"), _jsx(Ant.Select.Option, { value: "selfcityBlue", children: "Selfcityblue" }, "selfcityBlue"), _jsx(Ant.Select.Option, { value: "selfcityEggplant", children: "Selfcityeggplant" }, "selfcityEggplant"), _jsx(Ant.Select.Option, { value: "transparent", children: "Transparent" }, "transparent")] }) })),
            },
            {
                id: "createdById",
                label: t("management.agencies_tags.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.agencies_tags.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.agencies_tags.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.agencies_tags.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
AgenciesTagListFiltersForm = __decorate([
    Service({ transient: true })
], AgenciesTagListFiltersForm);
export { AgenciesTagListFiltersForm };
