var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { ProfileCreateForm as BaseProfileCreateForm } from "./ProfileCreateForm.base";
import { replaceUIComponents, setAllXFormElemsPlaceholders, } from "../../utils";
import AddressComponent from "@bundles/UIAppBundle/components/Address/Address.lazy";
import PhoneNumber from "@bundles/UIAppBundle/components/PhoneNumber/PhoneNumber.lazy";
import { MinusOutlined, PlusOutlined, SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ProfileGender } from "@root/api.types";
import { useRef } from "react";
let ProfileCreateForm = class ProfileCreateForm extends BaseProfileCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        const profileGenderHooks = {
            options: Object.values(ProfileGender).map((option) => ({
                key: option,
                label: t(`management.profiles.fields.gender_enum.${option}`),
                value: option,
            })),
        };
        this.update("gender", {
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.profiles.fields.gender"), options: profileGenderHooks.options }) })),
        });
        this.remove("phones");
        this.add([
            {
                id: "phones",
                name: ["phones"],
                required: false,
                initialValue: [],
                label: t("management.profiles.fields.phones"),
                render: (props) => {
                    const methodsRef = useRef(null);
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Form.List, { name: "phones", children: (fields, methods) => {
                                methodsRef.current = methods;
                                return (_jsxs(_Fragment, { children: [fields.map((field, index) => {
                                            return (_jsxs("div", { className: "form-items", children: [_jsx(Ant.Form.Item, { ...field, name: [field.name], fieldKey: [field.key], className: "mar-b-1-i", children: _jsx(PhoneNumber, {}) }), _jsx(Ant.Button, { danger: true, icon: _jsx(MinusOutlined, {}), onClick: () => methods.remove(index) })] }, field.key));
                                        }), _jsx(Ant.Button, { icon: _jsx(PlusOutlined, {}), className: "add-btn", type: "text", onClick: () => methods.add(""), children: t("management.profiles.actions.addPhoneNumber") })] }));
                            } }) }));
                },
            },
        ]);
        this.remove("emails");
        this.add([
            {
                id: "emails",
                name: ["emails"],
                required: false,
                initialValue: [],
                component: null,
                label: t("management.profiles.fields.emails"),
                render: (props) => {
                    const methodsRef = useRef(null);
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Form.List, { name: "emails", children: (fields, methods) => {
                                methodsRef.current = methods;
                                return (_jsxs(_Fragment, { children: [fields.map((field, index) => {
                                            return (_jsxs("div", { className: "form-items", children: [_jsx(Ant.Form.Item, { ...field, name: [field.name], fieldKey: [field.key], className: "mar-b-1-i", children: _jsx(Ant.Input, { type: "email" }) }), _jsx(Ant.Button, { danger: true, icon: _jsx(MinusOutlined, {}), onClick: () => methods.remove(index) })] }, field.key));
                                        }), _jsx(Ant.Button, { icon: _jsx(PlusOutlined, {}), className: "add-btn", type: "text", onClick: () => methods.add(""), children: t("management.profiles.actions.addEmail") })] }));
                            } }) }));
                },
            },
        ]);
        replaceUIComponents({
            // phone: <PhoneNumber />,
            address: _jsx(AddressComponent, {}),
        }, this);
        setAllXFormElemsPlaceholders(this);
        // removeAllFormItemsLabels(this);
    }
    static getRequestBody() {
        return {
            _id: 1,
            isACompany: 1,
            companyName: 1,
            gender: 1,
            firstName: 1,
            lastName: 1,
            phones: 1,
            emails: 1,
            notes: 1,
            address: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            iptCustomerId: 1,
            agency: {
                _id: 1,
                name: 1,
            },
            agencyId: 1,
            providerId: 1,
            user: {
                _id: 1,
                fullName: 1,
            },
            userId: 1,
        };
    }
    onCreateSubmit(document) {
        const { t } = this.i18n;
        const insertPromise = this.collection.insertOne(document);
        insertPromise.then((res) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.profiles.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            return res;
        });
        insertPromise.catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: err.reason || err.message,
            });
        });
        return insertPromise;
    }
};
ProfileCreateForm = __decorate([
    Service({ transient: true })
], ProfileCreateForm);
export { ProfileCreateForm };
