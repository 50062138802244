import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, use, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { ProfileCreateForm } from "../../config/ProfileCreateForm";
import { formRenderItems } from "../../../utils";
export function ProfilesCreate() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(ProfileCreateForm, { transient: true });
    form.build();
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.profiles.create.header"), onBack: () => window.history.back() }), _jsx(Ant.Card, { children: _jsxs(Ant.Form, { requiredMark: "optional", onFinish: (document) => form.onCreateSubmit(document), children: [_jsx("div", { className: "input-row", children: formRenderItems(form, ["gender", "firstName", "lastName"]) }), _jsx("div", { className: "input-row", children: formRenderItems(form, ["phone", "email"]) }), formRenderItems(form, ["address"]), _jsx(Ant.Form.Item, { children: _jsx(Ant.Button, { type: "primary", htmlType: "submit", children: t("generics.submit") }) })] }) })] }));
}
