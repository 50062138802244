export const phrasesFr = {
    fr: {
        authentication: {
            forgotPassword: {
                header: "Mot de passe oublié",
                fields: {
                    email: "E-mail",
                },
                success: "Si l'e-mail existe dans notre base de données, vous recevrez un e-mail avec des instructions.",
                submit: "Récupérer le mot de passe",
            },
            login: {
                header: "Connexion",
                fields: {
                    username: "Votre e-mail",
                    password: "Mot de passe",
                },
                forgotPassword_btn: "Mot de passe oublié?",
                login: "Connexion",
                or: "Ou",
                register_action: "S'inscrire maintenant!",
                invalid_credentials: "Informations d'identification invalides",
            },
            register: {
                header: "S'inscrire",
                fields: {
                    firstName: "Prénom",
                    lastName: "Nom de famille",
                    email: "E-mail",
                    password: "Mot de passe",
                },
                submit: "S'inscrire",
                success: {
                    header: "Inscrit!",
                    description: "Vous avez créé votre compte avec succès. Nous vous avons envoyé un e-mail pour vérifier votre compte.",
                },
            },
            resetPassword: {
                header: "Réinitialiser le mot de passe",
                success_msg: "Votre mot de passe a été réinitialisé. Redirection...",
                fields: {
                    email: "E-mail",
                    password: "Mot de passe",
                },
                submit: "Réinitialiser le mot de passe",
            },
            verifyEmail: {
                header: "Étape de vérification",
                verifying: "Vérification de votre e-mail...",
                success: "Votre email a été vérifié. Redirection...",
                errored: "Nous n'avons pas pu vérifier votre e-mail. Peut-être qu'il a déjà été vérifié.",
            },
        },
        management: {
            change_password: {
                list: {
                    header: "Changement de mot de passe",
                },
                fields: {
                    current: "Mot de passe actuel",
                    newPassword: "Nouveau mot de passe",
                },
                submit: "Changer le mot de passe",
                successMessage: "Votre mot de passe a été changé.",
            },
        },
    },
};
