import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import * as Ant from "antd";
import moment from "moment";
import { StatusCard } from "./StatusCard.index";
import { colors } from "@root/utils/colors";
import { DoubleRightOutlined } from "@ant-design/icons";
import { getUserById } from "@bundles/UIAppBundle/queries/Users.queries";
const timelineItemStyle = { paddingBottom: "4px" };
const commentIconItemStyle = {
    borderRadius: 2,
    padding: 2,
    backgroundColor: colors.hexToRgbaString("#e1e5ee"),
    color: colors.selfcityBlue.hex,
};
export const TimelineStatusCard = memo((props) => {
    var _a, _b;
    const { refetch, loading, user, error } = getUserById((_a = props.statusChangeHistory) === null || _a === void 0 ? void 0 : _a.updatedById);
    return (_jsx("div", { children: _jsxs(Ant.Timeline.Item, { style: timelineItemStyle, dot: _jsx(DoubleRightOutlined, { style: commentIconItemStyle }), className: props.disabled ? "disabled" : "", children: [_jsxs(Ant.Typography.Text, { type: "secondary", className: "fs-s", children: [!((_b = props.statusChangeHistory) === null || _b === void 0 ? void 0 : _b.updatedById)
                            ? "Utilisateur inconnu"
                            : !loading && !error && (user === null || user === void 0 ? void 0 : user.fullName), " - ", moment(props.statusChangeHistory.updatedAt).format("DD/MM/YYYY [à] HH[h]mm")] }), _jsx(StatusCard, { status: props.statusChangeHistory.status })] }, props.statusChangeHistory.updatedAt.toString()) }));
});
