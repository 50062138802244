import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useState } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { Form, Input, Button, Row, Col, Alert, Card } from "antd";
import { emailSuffix, getEmailWithSuffix } from "../Login/Login";
export function ResetPassword(props) {
    const guardian = useGuardian();
    const router = useRouter();
    const tl = useTranslate("authentication.resetPassword");
    const [submitError, setSubmitError] = useState(null);
    const [isCompleted, setIsComplete] = useState(false);
    const onSubmit = (data) => {
        const { email, password } = data;
        guardian
            .resetPassword(getEmailWithSuffix(email, emailSuffix), props.token, password)
            .then(() => {
            setIsComplete(true);
            setTimeout(() => {
                router.go(Routes.HOME);
            }, 4500);
        })
            .catch((err) => {
            setSubmitError(err.toString());
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(Row, { justify: "center", align: "middle", style: style, className: "reset-password-page", children: _jsx(Col, { sm: 24, md: 12, lg: 6, children: _jsxs(Card, { title: tl("header"), children: [isCompleted && _jsx(Alert, { type: "success", message: tl("success_msg") }), !isCompleted && (_jsxs(Form, { onFinish: (data) => onSubmit(data), className: "authentication-form", children: [_jsx(Form.Item, { name: "email", rules: [{ required: true }], children: _jsx(Input, { placeholder: tl("fields.email"), suffix: emailSuffix }) }), _jsx(Form.Item, { name: "password", rules: [{ required: true }], children: _jsx(Input, { prefix: _jsx(LockOutlined, { className: "site-form-item-icon" }), type: "password", placeholder: tl("fields.password") }) }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "authentication-form-button", children: tl("submit") }) }), submitError && _jsx(Alert, { message: submitError, type: "error" })] }))] }) }) }));
}
