var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { removeAddressDetailsFromList, setListColumnsOrder } from "../../utils";
import { AgencyList as BaseAgencyList } from "./AgencyList.base";
let AgencyList = class AgencyList extends BaseAgencyList {
    build() {
        super.build();
        removeAddressDetailsFromList(this);
        setListColumnsOrder([
            "name",
            "agenciesTag",
            "phone",
            "email",
            "address.formattedAddress",
            "siret",
            "vatNumber",
            // "iptCustomerId",
            // "createdById",
            // "updatedById",
            // "createdAt",
            // "updatedAt",
            // "clientRequestRelatedContacts",
            // "members",
            // "createdBy",
            // "updatedBy",
        ], this);
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
AgencyList = __decorate([
    Service({ transient: true })
], AgencyList);
export { AgencyList };
