import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, use, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { UserEditForm } from "../../config/UserEditForm";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function UsersCreate() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(UserEditForm, { transient: true });
    const router = useRouter();
    form.build();
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.users.create.header"), onBack: () => window.history.back() }), _jsx(Ant.Card, { children: _jsxs(Ant.Form, { ...formLayout, requiredMark: "optional", onFinish: (document) => form
                        .onCreateSubmit(document, {
                        queryBody: { skills: { skillId: 1, level: 1, notes: 1 } },
                    })
                        .then(({ _id }) => {
                        router.go(Routes.USERS_VIEW, { params: { id: _id } });
                    }), children: [form.render(), _jsx(Ant.Form.Item, { ...formTailLayout, children: _jsx(Ant.Button, { type: "primary", htmlType: "submit", children: t("generics.submit") }) })] }) })] }));
}
