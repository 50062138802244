import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { Link } from "react-router-dom";
// ###### 3 => Npm imports ###############
import moment from "moment";
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { linkify, withLineBreakRaw } from "@root/utils/rlib";
import { FileGroup } from "../FileGroup";
import { ToolTippedButton } from "../ToolTippedButton/ToolTippedButton.index";
import { DeleteOutlined, PushpinOutlined } from "@ant-design/icons";
import { CommentsCollection } from "@bundles/UIAppBundle/collections";
import { use } from "@bluelibs/x-ui";
export const CommentItem = memo((props) => {
    var _a, _b, _c, _d, _e;
    const commentsCollection = use(CommentsCollection);
    const deleteComment = (commentId) => {
        // @ts-ignore
        commentsCollection.deleteOne(commentId, {
            refetchQueries: ["CommentsFind"],
        });
    };
    const toggleCommentPin = (commentId, isPined) => {
        commentsCollection.updateOne(commentId, { isPined: !isPined }, { apollo: { refetchQueries: ["CommentsFind"] } });
    };
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex spaced a-center", children: [_jsxs(Ant.Typography.Text, { type: "secondary", className: "fs-s", children: [((_b = (_a = props.comment) === null || _a === void 0 ? void 0 : _a.createdBy) === null || _b === void 0 ? void 0 : _b.fullName) || "Utilisateur supprimé", " -", " ", moment(props.comment.createdAt).format("DD/MM/YYYY [à] HH[h]mm")] }), props.isMainTimeLine && props.comment.jobEstimation && (_jsxs(_Fragment, { children: [" - ", _jsxs(Ant.Typography.Text, { type: "secondary", className: "fs-s", children: ["A propos de l'estimation", " ", _jsxs(Link, { className: "fw-bold fc-sc-green", to: `?jobEstimationId=${props.comment.jobEstimation._id}`, children: ["\"", props.comment.jobEstimation.title, "\""] })] })] })), !props.isMainTimeLine && !props.comment.jobEstimationId && (_jsxs(_Fragment, { children: [" - ", _jsx(Ant.Typography.Text, { type: "secondary", className: "fs-s", children: "Commentaire et fichier(s) provenant d\u2019autres \u00E9tapes de la mission" })] })), _jsx(ToolTippedButton, { title: props.comment.isPined ? "Desépingler le commentaire" : "Epingler le commentaire", className: props.comment.isPined ? "red-btn" : "green-btn", type: "link", size: "small", icon: _jsx(PushpinOutlined, {}), onClick: () => toggleCommentPin(props.comment._id, props.comment.isPined) }), _jsx(ToolTippedButton, { title: "Supprimer le commentaire", className: "green-btn ", type: "link", size: "small", icon: _jsx(DeleteOutlined, {}), onClick: () => deleteComment(props.comment._id) })] }), _jsx(Ant.Card, { className: "child-pad-1-i", size: "small", children: _jsx("div", { dangerouslySetInnerHTML: {
                        __html: linkify(withLineBreakRaw(((_c = props.comment) === null || _c === void 0 ? void 0 : _c.text) || "")) || "-",
                    } }) }), !!((_e = (_d = props.comment.attachments) === null || _d === void 0 ? void 0 : _d.files) === null || _e === void 0 ? void 0 : _e.length) && (_jsx(FileGroup, { className: "mar-t-1 timeline-comment-img", appFileGroup: props.comment.attachments }))] }));
});
