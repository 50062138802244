import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import React, { memo } from "react";
import clsx from "clsx";
import { EditableText } from "../../EditableText/EditableText.index";
import "moment/locale/fr";
import { useTranslate } from "@bluelibs/x-ui";
const optionNames = {
    ON_THE_ROOF: 'Accessible par le toit',
    EXTRA_SPLITS: 'Split(s) supplémentaire(s)',
    EXTRA_HEATERS: 'Radiateur(s) supplémentaire(s)',
    EXTRA_SMALL_ELEMENTS: 'Élements(s) supplémentaire(s)',
    OVER_THREE_YEARS: 'Entretien réalisé il y a plus de 3 ans',
};
export const PackageSumup = memo((props) => {
    const t = useTranslate();
    const totalHours = props.package.options.reduce((duration, option) => duration + (option.quantity * option.unitDuration), props.package._baseDuration);
    const totalPrice = (props.package.options.reduce((price, option) => price + (option.quantity * option.unitPriceIT), props.package._basePriceIT) / 100).toFixed(0);
    return (_jsx("div", { className: clsx("mar-v-2", props.className), children: _jsxs("div", { className: "flex spaced gap-1", children: [_jsx("div", { children: _jsxs("div", { className: "a-start fw-bold", children: [_jsx(EditableText, { readOnly: true, text: props.package._name, className: "fc-sc-green" }), _jsx("div", { className: "mar-t-1 flex a-start", children: _jsxs("div", { children: [_jsxs("span", { className: "fw-450", children: ['Options', ": "] }), props.package.options.length
                                            ? props.package.options.map((option) => {
                                                return ((option.type === 'boolean')
                                                    ? (_jsxs("span", { className: "fw-bold", children: [optionNames[option.name], ",", " "] }, option.name))
                                                    : (_jsxs("span", { className: "fw-bold", children: [option.quantity, " ", optionNames[option.name], ",", " "] }, option.name)));
                                            })
                                            : "-"] }) })] }) }), _jsxs("div", { children: [_jsxs("div", { className: "align-right", children: [_jsxs("span", { className: "fw-450", children: [t("misc.price"), ": "] }), _jsx("span", { className: "fw-bold", children: `${totalPrice} € TTC` })] }), _jsxs("div", { className: "mar-t-1 align-right", children: [_jsxs("span", { className: "fw-450", children: [t("misc.workforce"), ": "] }), _jsxs("span", { className: "fw-bold", children: [totalHours, "h\u00A0"] })] })] })] }) }));
});
