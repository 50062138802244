import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
import { ObjectId } from "@bluelibs/ejson";
// ###### 2 => React imports #############
import React, { memo } from "react";
import * as Ant from "antd";
import { EuroOutlined, FileProtectOutlined, HomeOutlined, MoreOutlined, ThunderboltOutlined, } from "@ant-design/icons";
// ###### 3 => Npm imports ###############
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { ClientRequestRelatedContactProfileType, } from "@root/api.types";
import { AgencyTags } from "../Agency/AgencyTags";
import { colors } from "@root/utils/colors";
import axios from "axios";
export const RelatedContactTags = memo((props) => {
    const lookupKey = props.relatedContact.agency ? "agency" : "profile";
    const isTenant = props.relatedContact.profileType ===
        ClientRequestRelatedContactProfileType.TENANT;
    const isOwner = props.relatedContact.profileType ===
        ClientRequestRelatedContactProfileType.OWNER;
    const isEditMode = !!props.onTagToggle;
    const [ongoingSubscriptions, setOngoingSubscriptions] = React.useState(null);
    React.useEffect(() => {
        var _a, _b, _c;
        if (props.relatedContact.profile || props.relatedContact.agency)
            getOngoingSubscriptions((_b = (_a = props.relatedContact.profile) === null || _a === void 0 ? void 0 : _a._id) !== null && _b !== void 0 ? _b : (_c = props.relatedContact.agency) === null || _c === void 0 ? void 0 : _c._id).then(setOngoingSubscriptions);
    }, []);
    const renderSubscriptionsTag = (subscriptions, tagText) => subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.map((sub) => (_jsx(Ant.Tooltip, { title: sub.address, children: _jsxs(Ant.Tag, { icon: _jsx(FileProtectOutlined, {}), color: colors.selfcityEggplant.hex, children: [tagText, " ", sub.renewalTerm, sub.renewalTerm === "1" ? "an" : "ans"] }) })));
    return (_jsxs("div", { className: "tags", children: [props.relatedContact.agency && (_jsx(AgencyTags, { tag: props.relatedContact.agency.agenciesTag })), isEditMode ? (_jsxs(_Fragment, { children: [_jsxs(Ant.Tag.CheckableTag, { checked: props.relatedContact.isLocation, className: !props.relatedContact.isLocation
                            ? "show-on-hover-flex card-tag-checkable not-checked"
                            : "card-tag-checkable checked", onChange: (checked) => props.onTagToggle("isLocation", checked, new ObjectId(props.relatedContact._id)), children: [_jsx(HomeOutlined, { className: "mar-r-1" }), "Lieu d'inter"] }), props.relatedContact.isLocation && (_jsx(Ant.Tag.CheckableTag, { checked: false, className: "card-tag-checkable not-checked", onChange: () => props.onTagToggle("locationIndex", true, new ObjectId(props.relatedContact._id)), children: _jsx(MoreOutlined, {}) })), _jsxs(Ant.Tag.CheckableTag, { checked: props.relatedContact.isAsker, className: !props.relatedContact.isAsker
                            ? "show-on-hover-flex card-tag-checkable not-checked"
                            : "card-tag-checkable checked", onChange: (checked) => props.onTagToggle("isAsker", checked, new ObjectId(props.relatedContact._id)), children: [_jsx(ThunderboltOutlined, { className: "mar-r-1" }), "Demandeur"] }), _jsxs(Ant.Tag.CheckableTag, { checked: props.relatedContact.isPayer, className: !props.relatedContact.isPayer
                            ? "show-on-hover-flex card-tag-checkable not-checked"
                            : "card-tag-checkable checked", onChange: (checked) => props.onTagToggle("isPayer", checked, new ObjectId(props.relatedContact._id)), children: [_jsx(EuroOutlined, { className: "mar-r-1" }), "Payeur"] }), props.relatedContact.profileType && (_jsxs(_Fragment, { children: [_jsx(Ant.Tag.CheckableTag, { className: !isTenant
                                    ? "show-on-hover-flex card-tag-checkable not-checked blue"
                                    : "card-tag-checkable checked blue", checked: isTenant, onChange: (checked) => props.onTagToggle(ClientRequestRelatedContactProfileType.TENANT, checked, new ObjectId(props.relatedContact._id)), children: "Locataire" }), _jsx(Ant.Tag.CheckableTag, { className: !isOwner
                                    ? "show-on-hover-flex card-tag-checkable not-checked blue"
                                    : "card-tag-checkable checked blue", checked: isOwner, onChange: (checked) => props.onTagToggle(ClientRequestRelatedContactProfileType.OWNER, checked, new ObjectId(props.relatedContact._id)), children: "Propri\u00E9taire" })] }))] })) : (_jsxs(_Fragment, { children: [props.relatedContact.isLocation && (_jsx(Ant.Tag, { className: "card-tag-checkable checked", icon: _jsx(HomeOutlined, {}), children: "Lieu d'inter" })), props.relatedContact.isAsker && (_jsx(Ant.Tag, { className: "card-tag-checkable checked", icon: _jsx(EuroOutlined, {}), children: "Demandeur" })), props.relatedContact.isPayer && (_jsx(Ant.Tag, { className: "card-tag-checkable checked", icon: _jsx(EuroOutlined, {}), children: "Payeur" })), isTenant && (_jsx(Ant.Tag, { className: "card-tag-checkable checked blue", children: "Locataire" })), isOwner && (_jsx(Ant.Tag, { className: "card-tag-checkable checked blue", children: "Propri\u00E9taire" }))] })), renderSubscriptionsTag(ongoingSubscriptions === null || ongoingSubscriptions === void 0 ? void 0 : ongoingSubscriptions.heater, "Chaudière"), renderSubscriptionsTag(ongoingSubscriptions === null || ongoingSubscriptions === void 0 ? void 0 : ongoingSubscriptions.airConditioner, "Clim")] }));
});
const getOngoingSubscriptions = async (payerId) => {
    const token = window.localStorage.getItem("bluelibs-token");
    return (await axios.request({
        method: "GET",
        headers: { "bluelibs-token": token },
        url: `${process.env.API_URL.replace("/graphql", "").concat(`/subscriptions/ongoingSubscriptions?payerId=${payerId}`)}`,
    })).data.ongoingSubscriptions;
};
