import { use, useTranslate, } from "@bluelibs/x-ui";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Ant from "antd";
import { useQueryParams } from "@root/utils/hooks";
import { getTouchedFieldsOnlyFromForm } from "@root/utils/forms";
import { ObjectId } from "@bluelibs/ejson";
import { withoutTypename } from "@root/utils/lib";
import { useApolloClient } from "@apollo/client";
export const useModalhooks = (props) => {
    const queryParams = useQueryParams();
    const visible = !!queryParams[props.queryParam];
    const history = useHistory();
    const location = useLocation();
    const close = useCallback(() => {
        var _a;
        if (((_a = location.state) === null || _a === void 0 ? void 0 : _a.openedModal) &&
            location.state.openedModal === props.queryParam) {
            // If user was already navigating through the app, we just pop the previous state from history
            // to prevent breaking navigation history.
            history.goBack();
        }
        else {
            // If user copied/pasted a link containing a reference to the modal, closing it should not
            // trigger a "go back" in history, but rather display the underlying page.
            history.push(location.pathname);
        }
    }, [history, location]);
    return {
        queryParams,
        visible,
        close,
    };
};
export const useFormModalHooks = (options) => {
    var _a, _b, _c;
    const t = useTranslate();
    const queryParams = useQueryParams();
    const queryParamValue = queryParams[options.queryParam];
    const isEditMode = queryParamValue !== "new";
    const closeAfterSubmit = (_a = options.closeAfterSubmit) !== null && _a !== void 0 ? _a : true;
    const resetWithInitialValuesAfterSubmit = (_b = options.resetWithInitialValuesAfterSubmit) !== null && _b !== void 0 ? _b : false;
    // @ts-ignore
    const queryBody = options.queryBody || options.form.getRequestBody();
    const xForm = use(options.form, { transient: true });
    xForm.build();
    const [document, setDocument] = useState(null);
    //   const [isLoading, setIsLoading] = useState(false);
    const [isWriting, setIsWriting] = useState(false);
    const collection = use(options.collection);
    const [query, { loading: isLoading, data }] = collection.useLazyQueryOne({
        queryInput: {
            // @ts-ignore
            filters: {
                _id: queryParamValue && isEditMode
                    ? new ObjectId(queryParamValue)
                    : null,
            },
        },
        body: typeof queryBody === "function" ? queryBody({}) : queryBody,
    });
    const modalRef = useRef();
    const [form] = Ant.Form.useForm();
    const resetFormFields = useCallback((fetchedDocument, touched = false) => {
        var _a;
        const prunedDoc = ((_a = options === null || options === void 0 ? void 0 : options.pruneDocument) === null || _a === void 0 ? void 0 : _a.call(options, withoutTypename(fetchedDocument))) ||
            withoutTypename(fetchedDocument);
        setDocument((prevDoc) => ({ ...prevDoc, ...prunedDoc }));
        form.setFields(Object.entries(prunedDoc).map(([name, value]) => ({
            errors: [],
            name,
            touched,
            validating: false,
            value,
        })));
    }, [form, options === null || options === void 0 ? void 0 : options.pruneDocument]);
    useEffect(() => {
        if (!!queryParamValue && isEditMode) {
            query().then((res) => {
                resetFormFields(res.data[Object.keys(res.data)[0]]);
            });
        }
    }, [queryParamValue, isEditMode]);
    const onCancel = useCallback((reset = true) => {
        modalRef.current.close();
        if (reset) {
            form.resetFields();
        }
        setDocument(null);
    }, [modalRef, form]);
    const onOk = useCallback(() => {
        form.submit();
    }, [modalRef, form]);
    const apolloClient = useApolloClient();
    const onFinish = useCallback(async (formDocument, endCallback = () => null) => {
        var _a;
        // - In create mode, we don't want to check if any field has been touched, as some of them may
        // have been updated before user closed the modal a first time, and then reopened it.
        // - In edit mode, we definitely want to make sure that at least one field has been updated.
        if (form.isFieldsTouched() || !isEditMode) {
            let hasFailed = false; // ! we need this to prevent the form to decome untouched when a network query fails by forcing it to be dirty
            const touchedFieldsOnly = isEditMode
                ? getTouchedFieldsOnlyFromForm(form, formDocument)
                : formDocument;
            const prunedDocument = ((_a = options.transformFormValue) === null || _a === void 0 ? void 0 : _a.call(options, withoutTypename(touchedFieldsOnly))) || withoutTypename(touchedFieldsOnly);
            // ! WE CAN'T HAVE THIS (or any other setState)
            // ! BEFORE WE HAVE THE INPUT READY TO BE FED TO THE API CALL
            // ! AS IT FORCES THE RERENDER OF FORM INPUTS AND RESETS THE FIELDS ".touched"
            setIsWriting(true);
            const promise = isEditMode
                ? xForm
                    // @ts-ignore
                    .onEditSubmit(
                // @ts-ignore
                document._id, prunedDocument, { refetchBody: queryBody })
                    .then((editedDocument) => {
                    if (editedDocument && options.onEditSuccess) {
                        options.onEditSuccess(editedDocument);
                    }
                    return editedDocument;
                })
                : xForm
                    // @ts-ignore
                    .onCreateSubmit(prunedDocument, { refetchBody: queryBody })
                    .then((newDocument) => {
                    if (newDocument && options.onCreateSuccess) {
                        options.onCreateSuccess(newDocument);
                    }
                    return newDocument;
                });
            promise.then((r) => {
                var _a, _b;
                resetWithInitialValuesAfterSubmit
                    ? form.resetFields()
                    : resetFormFields(r);
                closeAfterSubmit && ((_b = (_a = modalRef === null || modalRef === void 0 ? void 0 : modalRef.current) === null || _a === void 0 ? void 0 : _a.close) === null || _b === void 0 ? void 0 : _b.call(_a));
            });
            promise.then(() => {
                var _a;
                if ((_a = options.refetchQueries) === null || _a === void 0 ? void 0 : _a.length) {
                    apolloClient.refetchQueries({
                        include: options.refetchQueries,
                    });
                }
            });
            promise.catch((error) => {
                hasFailed = true;
                console.error("error", error);
            });
            promise.finally(() => {
                setIsWriting(false);
                if (hasFailed) {
                    resetFormFields(prunedDocument, true);
                }
                endCallback();
            });
            return promise;
        }
        else {
            Ant.message.warning("Aucun champs n'a été modifié !");
        }
    }, [
        // @ts-ignore
        (_c = document === null || document === void 0 ? void 0 : document._id) === null || _c === void 0 ? void 0 : _c.toString(),
        isEditMode,
        resetFormFields,
        form,
        xForm,
        closeAfterSubmit,
        resetWithInitialValuesAfterSubmit,
    ]);
    const { visible } = useModalhooks({ queryParam: options.queryParam });
    return {
        t,
        isEditMode,
        document,
        isLoading,
        isWriting,
        form,
        xForm,
        modalRef,
        onFinish,
        onCancel,
        onOk,
        setDocument,
        collection,
        queryParams,
        isModalOpened: visible,
    };
};
