import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const withLineBreakTags = (str) => str.split("\n").map((text, idx) => (_jsxs("span", { children: [text, _jsx("br", {})] }, idx)));
export const withLineBreakRaw = (str) => {
    return str.replaceAll("\n", "<br />");
};
export const linkify = (string) => {
    // http://, https://, ftp://
    const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;
    // www. sans http:// or https://
    const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    // Email addresses
    const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;
    return string
        .replace(urlPattern, '<a href="$&">$&</a>')
        .replace(pseudoUrlPattern, '$1<a href="http://$2">$2</a>')
        .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
};
