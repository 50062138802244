import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useMemo, useState, useEffect } from "react";
import { useSimpleModal } from "../../../../utils/hooks";
import { convertLocationInput, emptyAddress } from "./utils";
import GooglePlacesAutocomplete, { geocodeByPlaceId, } from "react-google-places-autocomplete";
import * as Ant from "antd";
import { useTranslate } from "@bluelibs/x-ui";
const autocompletionRequestProp = {
    componentRestrictions: { country: ["fr"] },
};
const AddressComponent = (props) => {
    var _a, _b, _c;
    const t = useTranslate();
    const [address, setAddress] = useState(
    // here we check the value cuz currently, we do not get the initial values in the props fromthe ant.form.item, we don't know why yet
    props.initialValue || props.value || emptyAddress);
    const onSelect = useCallback(async ({ value }) => {
        const result = await geocodeByPlaceId(value.place_id);
        const location = convertLocationInput(result[0]);
        setAddress((prevAddress) => ({
            ...location,
            streetTwo: prevAddress.streetTwo,
        }));
        props.onChange({ ...location, streetTwo: address.streetTwo });
    }, [address]);
    const selectProps = useMemo(() => ({
        onChange: onSelect,
        placeholder: t("forms.address.fields.placeholders.googleAutoComplete"),
        defaultInputValue: address.formattedAddress || null,
    }), [onSelect, props.placeholder, props.initialValue, address.formattedAddress]);
    const handleStreetTwoChange = useCallback((event) => {
        setAddress((prevAddress) => ({
            ...prevAddress,
            streetTwo: event.target.value,
        }));
        props.onChange({ ...address, streetTwo: event.target.value });
    }, [props.onChange, address]);
    const { openModal, closeModal, isVisible } = useSimpleModal();
    useEffect(() => {
        if (props.initialValue || props.value) {
            setAddress(props.initialValue || props.value);
        }
    }, [(_a = props.initialValue) === null || _a === void 0 ? void 0 : _a.formattedAddress, (_b = props.value) === null || _b === void 0 ? void 0 : _b.formattedAddress]);
    return (_jsxs(_Fragment, { children: [_jsx(GooglePlacesAutocomplete, { apiKey: process.env.GOOGLE_MAP_API_KEY, selectProps: selectProps, autocompletionRequest: autocompletionRequestProp }, address.formattedAddress), _jsx(_Fragment, { children: _jsx(Ant.Input, { placeholder: t("forms.address.fields.placeholders.streetTwo"), value: ((_c = props.value) === null || _c === void 0 ? void 0 : _c.streetTwo) || (address === null || address === void 0 ? void 0 : address.streetTwo), onChange: handleStreetTwoChange, className: "mar-t-1" }) })] }));
};
export default memo(AddressComponent);
