import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import React, { memo } from "react";
import * as Ant from "antd";
import EditIcon from "@ant-design/icons/EditOutlined";
import ViewIcon from "@ant-design/icons/EyeOutlined";
import DeleteIcon from "@ant-design/icons/DeleteOutlined";
import CopyOutlinedIcon from "@ant-design/icons/CopyOutlined";
// ###### 3 => Npm imports ###############
import moment from "moment";
import clsx from "clsx";
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { UserRoles } from "@root/api.types";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
import { ToolTippedButton } from "../../ToolTippedButton/ToolTippedButton.index";
import { JobEstimationStatusTag } from "../../StatusTag/StatusTag.index";
import { TODAY } from "@root/utils/consts";
import { CheckOutlined, } from "@ant-design/icons";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import axios from "axios";
import { useTranslate } from "@bluelibs/x-ui";
import LazyOptions from "../../LazyOptions";
const lazy = [["profile.firstName", "profile.lastName"], ["name"]];
const additionalQueryFilters = [
    { roles: UserRoles.PLUMBER },
    { type: "SUBCONTRACTOR" },
];
const filterSort = (optionA, optionB) => optionA.children < optionB.children ? -1 : 1;
const collectionClasses = [UsersCollection];
const fields = ["fullName", "name"];
const getSuppliesMargin = (supplies) => ((supplies.reduce((acc, supply) => acc + supply.quantity * supply.unitPrice * supply.margin / 100, 0) / 100).toFixed(2));
const getTotalPrice = (estimate) => (estimate.categories.reduce((acc, cat) => acc + (((cat.supplies.reduce((acc, supply) => acc + supply.quantity * supply.unitPrice * (100 + supply.margin) / 100, 0)
    + cat.tasks.reduce((acc, task) => acc + task.hours * task.hourlyRate, 0)) / 100)), 0) + estimate.packages.reduce((acc, pack) => (acc + (pack.options.reduce((price, option) => price + (option.quantity * option.unitPriceIT), pack._basePriceIT) / 100)), 0)).toFixed(2);
const cRTotalNbrHours = (estimate) => ({
    main: estimate.categories.reduce((acc, cat) => acc + cat.tasks.filter((task) => !task.isSideJob).reduce((sum, task) => sum + task.hours, 0), 0)
        + estimate.packages.reduce((acc, pack) => acc + pack.options.reduce((duration, option) => duration + (option.quantity * option.unitDuration), pack._baseDuration), 0),
    side: estimate.categories.reduce((acc, cat) => acc + cat.tasks.filter((task) => task.isSideJob).reduce((sum, task) => sum + task.hours, 0), 0),
});
export const JobEstimationSumup = memo((props) => {
    var _a, _b, _c;
    const t = useTranslate();
    const inspectJobEstimation = React.useCallback(() => props.onInspectJobEstimation(props.jobEstimation), [props.onInspectJobEstimation, props.jobEstimation]);
    const [isUpdatingSubject, setIsUpdatingSubject] = React.useState(false);
    const handleChangeDueDate = React.useCallback((momentDate) => {
        const token = window.localStorage.getItem("bluelibs-token");
        axios.request({
            method: "PUT",
            url: global.NEW_API_URL + '/estimates/' + props.jobEstimation._id,
            headers: { "Authorization": token },
            data: {
                dueDate: momentDate.toDate(),
            },
        }).then(() => {
            props.onReload();
        }).catch((e) => {
            Ant.notification.warn({
                message: 'Erreur',
                description: e.response.data.error.code,
            });
        });
    }, []);
    const onChangeUser = React.useCallback((value) => {
        const [type, id] = value.split('_');
        const token = window.localStorage.getItem("bluelibs-token");
        axios.request({
            method: "PUT",
            url: global.NEW_API_URL + '/estimates/' + props.jobEstimation._id,
            headers: { "Authorization": token },
            data: {
                assignedTo: id,
            },
        }).then(() => {
            props.onReload();
        }).catch((e) => {
            Ant.notification.warn({
                message: 'Erreur',
                description: e.response.data.error.code,
            });
        });
    }, [(_a = props.jobEstimation) === null || _a === void 0 ? void 0 : _a._id]);
    const onDeleteEstimation = React.useCallback(() => {
        const token = window.localStorage.getItem("bluelibs-token");
        axios.request({
            method: "DELETE",
            url: global.NEW_API_URL + '/estimates/' + props.jobEstimation._id,
            headers: { "Authorization": token },
        }).then(() => {
            props.onReload();
        }).catch((e) => {
            Ant.notification.warn({
                message: 'Erreur',
                description: e.response.data.error.code,
            });
        });
    }, [(_b = props.jobEstimation) === null || _b === void 0 ? void 0 : _b._id]);
    const duplicateJobEstimation = React.useCallback(async () => {
        Ant.Modal.confirm({
            content: (_jsx("div", { children: "Es-tu s\u00FBr(e) de vouloir dupliquer cette estimation ?" })),
            okText: `Oui`,
            cancelText: `Annuler`,
            onOk: () => {
                const token = window.localStorage.getItem("bluelibs-token");
                axios.request({
                    method: "GET",
                    url: global.NEW_API_URL + '/estimates/' + props.jobEstimation._id + '?fields=*',
                    headers: { "Authorization": token },
                }).then((response) => {
                    return axios.request({
                        method: "POST",
                        url: global.NEW_API_URL + '/missions/' + props.jobEstimation._mission + '/estimates',
                        headers: { "Authorization": token },
                        data: {
                            dueDate: null,
                            assignedTo: response.data.assignedTo,
                            categories: response.data.categories,
                            packages: response.data.packages.map((pack) => ({
                                template: pack.template,
                                options: pack.options,
                                subject: pack.subject,
                            })),
                            attachments: [],
                            // TODO attachments
                        },
                    }).then(() => {
                        props.onReload();
                    });
                }).catch((e) => {
                    Ant.notification.warn({
                        message: 'Erreur',
                        description: e.response.data.error.code,
                    });
                });
            },
        });
    }, [(_c = props.jobEstimation) === null || _c === void 0 ? void 0 : _c._id]);
    const loadOptions = async (text) => {
        const token = window.localStorage.getItem("bluelibs-token");
        const { data: users } = await axios.request({
            method: "POST",
            url: global.NEW_API_URL + '/users/search?fields=firstName,lastName&limit=100',
            headers: { "Authorization": token },
            data: {
                "filters": { _isTechnician: true },
                query: { on: ['firstName', 'lastName'], text: text !== null && text !== void 0 ? text : '' },
            },
        });
        return [
            {
                type: "header",
                value: '1',
                label: 'Techniciens Selfcity',
                disabled: true,
            },
        ].concat(users.results.length === 0 ? [
            {
                type: 'option',
                value: 'null1',
                disabled: true,
                label: 'Pas de résultat.',
            },
        ] : users.results.map(({ _id, firstName, lastName }) => ({
            value: `user_${_id}`,
            label: `${firstName} ${lastName}`,
            type: 'option',
        })));
    };
    const totalHours = cRTotalNbrHours(props.jobEstimation);
    const needsDelivery = props.jobEstimation.categories.reduce((acc, cat) => acc || cat.needsClearing || cat.tools.some((tool) => tool.needsDelivery) || cat.supplies.some((supply) => supply.needsDelivery), false);
    const tools = props.jobEstimation.categories.map((cat) => cat.tools.map((tool) => tool.tool)).flat();
    const supplies = props.jobEstimation.categories.map((cat) => cat.supplies.map((supply) => supply)).flat();
    return (_jsxs("div", { className: clsx("mar-v-2", props.className), children: [_jsxs("div", { className: "flex spaced gap-1", children: [_jsx("div", { children: _jsxs("div", { className: "mar-t-1 flex a-start", children: [needsDelivery && (_jsx(Ant.Tooltip, { title: "Livraison en camionnette requise ", children: _jsx(Ant.Tag, { color: "#F5222D", children: "Livraison" }) })), _jsxs("div", { children: [_jsxs("span", { className: "fw-450", children: [t("misc.tools"), ": "] }), tools.length
                                            ? [...new Set(tools)].map((tool) => {
                                                return (_jsxs("span", { className: "fw-bold", children: [tool, ",", " "] }, tool));
                                            })
                                            : "-"] })] }) }), _jsxs("div", { children: [_jsxs("div", { children: [_jsxs("span", { className: "fw-450", children: [t("misc.margin"), ": "] }), _jsx("span", { className: "fw-bold", children: `${getSuppliesMargin(supplies)}€` || "-" })] }), _jsxs("div", { className: "mar-t-1", children: [_jsxs("span", { className: "fw-450", children: [t("misc.material"), ": "] }), _jsx("span", { className: "fw-bold", children: supplies.length
                                            ? `${supplies.length} élément(s)`
                                            : "-" })] })] }), _jsxs("div", { children: [_jsxs("div", { className: "align-right", children: [_jsxs("span", { className: "fw-450", children: [t("misc.price"), ": "] }), _jsx("span", { className: "fw-bold", children: `${getTotalPrice(props.jobEstimation)} € HT` })] }), _jsxs("div", { className: "mar-t-1 align-right", children: [_jsxs("span", { className: "fw-450", children: [t("misc.workforce"), ": "] }), _jsxs("span", { className: "fw-bold", children: [totalHours.main, "h\u00A0(+", totalHours.side, "h)"] })] })] })] }), _jsxs("div", { className: `flex spaced mar-t-1 child-mar-r-1 nl`, children: [_jsx("div", { className: `${props.jobEstimation._status === 'VALIDATED' ? 'disabled' : ''} flex flex-1`, children: !props.readOnly && (_jsxs(_Fragment, { children: [_jsx(Ant.DatePicker, { className: clsx("mar-r-1 flex-1", {
                                        danger: props.jobEstimation._status !==
                                            // JobEstimationStatus.VALIDATED &&
                                            props.jobEstimation.dueDate &&
                                            new Date(props.jobEstimation.dueDate).getTime() < TODAY.getTime(),
                                    }), locale: locale, format: "DD-MM-YYYY", placeholder: t("management.job_estimations.fields.dueDate"), value: 
                                    // moment(props.jobEstimation.dueDate)
                                    props.jobEstimation.dueDate
                                        ? moment(props.jobEstimation.dueDate)
                                        : null, allowClear: true, onChange: handleChangeDueDate }), _jsx(LazyOptions, { loadOptions: loadOptions, initialOptions: props.jobEstimation.assignedTo !== null ? [{
                                            type: 'option',
                                            value: `user_${props.jobEstimation.assignedTo._id}`,
                                            label: props.jobEstimation.assignedTo.firstName + ' ' + props.jobEstimation.assignedTo.lastName,
                                        }] : [], placeholder: "Assigner \u00E0...", value: props.jobEstimation.assignedTo !== null ? `user_${props.jobEstimation.assignedTo._id}` : null, onChange: onChangeUser })] })) }), _jsxs("div", { className: "flex child-mar-r-1 nl", children: [_jsx("div", { className: "job-estimation-status-wrapper", children: props.jobEstimation._status === 'DELIVERED' ? (_jsx(Ant.Button, { icon: _jsx(CheckOutlined, {}), type: "primary", onClick: inspectJobEstimation, children: "V\u00E9rifier l'estimation" })) : (_jsx(JobEstimationStatusTag, { status: props.jobEstimation._status })) }), _jsx("div", { className: "child-mar-r-1 nl", children: !props.readOnly && (_jsxs(_Fragment, { children: [props.jobEstimation._status !== 'VALIDATED' && (_jsx(ToolTippedButton, { title: t("management.job_estimations.actions.delete"), icon: _jsx(DeleteIcon, {}), type: "primary", ghost: true, onClick: onDeleteEstimation, danger: true })), _jsx(ToolTippedButton, { title: t("management.job_estimations.actions.duplicate"), icon: _jsx(CopyOutlinedIcon, {}), onClick: duplicateJobEstimation, type: "primary", ghost: true }), props.jobEstimation._status !== 'VALIDATED' && (_jsx(ToolTippedButton, { title: t("management.job_estimations.actions.edit"), icon: _jsx(EditIcon, {}), onClick: inspectJobEstimation, type: "primary" })), props.jobEstimation._status === 'VALIDATED' && (_jsx(ToolTippedButton, { title: "Voir cette estimation", icon: _jsx(ViewIcon, {}), onClick: inspectJobEstimation, type: "primary" }))] })) })] })] })] }));
});
