import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
import { use, useTranslate } from "@bluelibs/x-ui";
// ###### 2 => React imports #############
import React, { memo, useCallback, useMemo } from "react";
import * as Ant from "antd";
import { CaretDownOutlined, FileTextOutlined, EditOutlined, CopyOutlined, PlusOutlined, SendOutlined, InfoOutlined, EyeOutlined, } from "@ant-design/icons";
// ###### 3 => Npm imports ###############
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { colors } from "@root/utils/colors";
import { FollowUpsCollection, } from "@bundles/UIAppBundle/collections";
import { copyToClipboard, displayDate } from "@root/utils/lib";
import { DocumentTypePath, DownloadFileButton, getDocumentUrl, getInvoiceFileNameAndUrl, } from "../DownloadFileButton";
import { InvoiceStatusTag } from "../StatusTag/StatusTag.index";
import clsx from "clsx";
import { PaymentMethodSelector } from "../Selects/PaymentMethodSelector";
import { CardEmpty } from "../CardEmpty/CardEmpty.index";
import { ToolTippedButton } from "../ToolTippedButton/ToolTippedButton.index";
import moment from "moment";
import { useApolloClient } from "@apollo/client";
import { getTotalDiscountedPriceIT } from "@root/utils/prices";
import axios from "axios";
import { ObjectId } from "@bluelibs/ejson";
export const getFollowUpsTooltipString = (followUps, t) => {
    return followUps
        .map((followUp) => `${t("management.invoices.fields.lastFollowedUpAt")} ${moment(followUp.createdAt).format("DD/MM/YYYY [à] HH[h]mm")}`)
        .join("\n");
    // par ${followUp.createdBy}
};
const downloadBtnProps = { type: "text" };
const canceledOrRejected = ['COMPLICATED'];
const isCanceledOrRejected = (status) => canceledOrRejected.includes(status);
const paymentLabels = {
    EFT: 'Virement',
    STRIPE: 'Stripe',
    CHECK: 'Chèque',
    CASH: 'Espèces',
    PAYPAL: 'Paypal',
    PLEDG: 'Pledg',
    WIDR_PAY: 'Recouvrement (WidrPay)',
};
export const invoiceTexts = {
    // export const invoiceTexts = {
    ['DEFAULT']: { create: "Créer une facture", type: "", word: "" },
    ['DEPOSIT']: {
        create: "Créer une facture d'acompte",
        type: "d'acompte",
        word: "Acompte",
    },
    ['BALANCE']: {
        create: "Créer une facture de solde",
        type: "de solde",
        word: "Solde",
    },
    ['CREDIT_NOTE']: {
        create: "Créer une facture d'avoir",
        type: "d'avoir",
        word: "Avoir",
    },
};
export const ClientRequestInvoicesCard = memo((props) => {
    var _a;
    const t = useTranslate();
    const client = useApolloClient();
    const onOpenInvoiceModal = useCallback((id, invoiceType) => {
        const type = {
            DEFAULT: 'invoices',
            DEPOSIT: 'deposits',
            BALANCE: 'balances',
        };
        const token = window.localStorage.getItem("bluelibs-token");
        if (id) {
            window.location.href = global.NEW_APP_URL + '/missions/' + props.clientRequest._id + '/' + type[invoiceType] + '/' + id + '/edit?token=' + token;
        }
        else {
            window.location.href = global.NEW_APP_URL + '/missions/' + props.clientRequest._id + '/' + type[invoiceType] + '/create?token=' + token;
        }
    }, [`${props.clientRequest._id}`, props.invoices]);
    const followUpsCollection = use(FollowUpsCollection);
    const { data: followUps, loading: loadingFollowUps, 
    // refetch,
    error: followUpsError, } = followUpsCollection.useQuery({
        queryInput: {
            filters: { clientRequestId: new ObjectId(`${props.clientRequest._id}`) },
            options: { sort: { createdAt: -1 } },
        },
        body: {
            _id: 1,
            quotationId: 1,
            invoiceId: 1,
            createdAt: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
        },
    });
    const invoiceBtns = useMemo(() => {
        var _a;
        const hasADeposit = props.invoices &&
            ((_a = props.invoices) === null || _a === void 0 ? void 0 : _a.find((invoice) => invoice._type === 'DEPOSIT'));
        const buttons = {
            default: {
                text: invoiceTexts['DEFAULT'].create,
                onClick: () => onOpenInvoiceModal(null, 'DEFAULT'),
                type: 'DEFAULT',
            },
            menus: [
                {
                    text: invoiceTexts['DEPOSIT'].create,
                    onClick: () => onOpenInvoiceModal(null, 'DEPOSIT'),
                    type: 'DEPOSIT',
                },
                {
                    text: invoiceTexts['BALANCE'].create,
                    onClick: () => onOpenInvoiceModal(null, 'BALANCE'),
                    type: 'BALANCE',
                },
            ],
        };
        return buttons;
    }, [props.invoices, onOpenInvoiceModal]);
    const showCreateRefundInvoiceConfirmModal = (invoiceId) => {
        Ant.Modal.confirm({
            okText: "Confirmer",
            cancelText: "Annuler",
            content: _jsx("div", { children: "Es-tu s\u00FBr(e) de vouloir cr\u00E9er une facture d'avoir ? Cette action est irr\u00E9versible." }),
            onOk: () => createRefundInvoice(invoiceId),
        });
    };
    const createRefundInvoice = (invoiceId) => {
        const token = window.localStorage.getItem("bluelibs-token");
        axios.request({
            method: "POST",
            url: global.NEW_API_URL + '/invoices/' + invoiceId + '/refund',
            headers: { "Authorization": token },
        }).then(() => {
            props.onReload();
        }).catch((e) => {
            Ant.notification.warn({
                message: 'Erreur',
                description: e.response.data.error.code,
            });
        });
    };
    return (_jsx(_Fragment, { children: _jsxs(Ant.Card, { size: "small", children: [_jsxs("div", { className: "flex spaced mar-b-2", children: [_jsx("div", { className: "fw-bold fs-2", children: "Facture(s)" }), _jsxs(Ant.Dropdown.Button, { className: "blue-btn", type: "primary", onClick: invoiceBtns.default.onClick, icon: _jsx(CaretDownOutlined, {}), overlay: _jsx(Ant.Menu, { children: invoiceBtns.menus.map((button) => (_jsx(Ant.Menu.Item, { onClick: button.onClick, children: button.text }, 'DEFAULT'))) }), children: [_jsx(PlusOutlined, {}), invoiceBtns.default.text] })] }), _jsx("div", { style: { overflow: "auto" }, children: ((_a = props.invoices) !== null && _a !== void 0 ? _a : []).length > 0 ? (props.invoices.map((invoice) => {
                        const addFollowUp = () => {
                            followUpsCollection
                                .insertOne({
                                clientRequestId: props.clientRequest._id,
                                invoiceId: invoice._id,
                            }, {
                                refetchBody: (body) => ({
                                    ...body,
                                    clientRequest: {
                                        _id: 1,
                                        status: 1,
                                    },
                                }),
                            })
                                .then(() => {
                                props.onReload();
                            });
                        };
                        return (_jsxs("div", { children: [_jsxs("div", { className: "flex spaced", children: [_jsxs("div", { children: [invoice._type !== 'DEFAULT' && (_jsx(Ant.Tag, { color: colors.selfcityEggplant.opacity(0.25), className: "fc-sc-eggplant", children: invoiceTexts[invoice._type].word })), _jsxs(Ant.Tag, { icon: _jsx(FileTextOutlined, {}), color: colors.selfcityGreen.opacity(0.15), className: "fc-primary", title: "Voir sur IPaidThat", onClick: () => {
                                                        window.open(`https://ipaidthat.io/inv/edit-invoice/${invoice._iPaidThatId}`, "_blank");
                                                    }, children: ["Facture ", invoiceTexts[invoice._type].type, " n\u00B0", invoice._number, " | ", (getTotalDiscountedPriceIT(invoice.rows) / 100).toFixed(2), "\u20AC TTC"] }), invoice._status !== 'PAID' && invoice.status !== 'REFUNDED' && invoice._type !== 'CREDIT_NOTE' && (_jsx(Ant.Button, { type: "text", icon: _jsx(EditOutlined, {}), onClick: () => onOpenInvoiceModal(invoice._id, invoice._type) })), _jsx(DownloadFileButton, { downloadBtnProps: downloadBtnProps, ...getInvoiceFileNameAndUrl({
                                                        invoiceId: invoice._id,
                                                        clientRequestId: props.clientRequest._id,
                                                        invoiceNumber: invoice._number,
                                                    }) }), _jsx(Ant.Tooltip, { title: "Visualiser la facture", children: _jsx(Ant.Button, { type: "text", icon: _jsx(EyeOutlined, {}), onClick: () => window.open(getDocumentUrl(DocumentTypePath.INVOICE, invoice._id)) }) }), _jsx(Ant.Tooltip, { style: { width: "max-content" }, overlayClassName: "display-linebreak", title: `Facture créée à partir de :\n${invoice.quotes !== null
                                                        ? invoice.quotes
                                                            .map((quotation) => `- Devis n°${quotation._number}`)
                                                            .join("\n")
                                                        : invoice.packages
                                                            .map((pack) => `- ${pack._name}`)
                                                            .join("\n")}`, children: _jsx(Ant.Button, { type: "text", icon: _jsx(InfoOutlined, {}) }) })] }), _jsxs("div", { children: [invoice._type !== 'CREDIT_NOTE' && invoice._status !== 'REFUNDED' && invoice._status !== 'TO_SEND' && (_jsxs(Ant.Tag, { color: colors.selfcityBlue.opacity(0.7), className: "clickable", onClick: () => showCreateRefundInvoiceConfirmModal(invoice._id), children: [_jsx(PlusOutlined, {}), "\u00A0Cr\u00E9er un avoir"] })), _jsxs(Ant.Tag, { color: colors.selfcityBlue.opacity(0.4), className: "clickable", onClick: () => {
                                                        copyToClipboard(`https://www.selfcity.fr/payment?invoiceId=${invoice._id}`);
                                                        Ant.message.success("Lien Stripe copié dans le presse papier !");
                                                    }, children: ["Lien Stripe \u00A0", _jsx(CopyOutlined, {})] })] })] }), _jsxs("div", { className: "flex a-center child-pad-r-1/2 nl", children: [_jsxs("div", { className: "fs-s ta-center", children: ["Cr\u00E9\u00E9 le\u00A0", _jsx("span", { className: "fw-bold", children: displayDate(invoice._createdAt) })] }), invoice._status !== 'TO_SEND' ? (_jsxs("div", { className: "w-1/5 fs-s ta-center", children: ["Envoy\u00E9e le\u00A0", _jsx("span", { className: "fw-bold", children: displayDate(invoice._status === 'SENT' ? invoice._updatedAt : new Date(new Date(invoice._createdAt).getTime() + 5 * 60 * 1000)) })] })) : (_jsx("div", { className: "w-1/5", children: _jsx(Ant.Button, { className: "green-btn w-full", size: "small", disabled: invoice._status !== 'TO_SEND', onClick: () => {
                                                    const token = window.localStorage.getItem("bluelibs-token");
                                                    axios.request({
                                                        method: "POST",
                                                        url: global.NEW_API_URL + '/invoices/' + invoice._id + '/send',
                                                        headers: { "Authorization": token },
                                                    }).then(() => {
                                                        props.onReload();
                                                    }).catch((e) => {
                                                        Ant.notification.warn({
                                                            message: 'Erreur',
                                                            description: e.response.data.error.code,
                                                        });
                                                    });
                                                }, children: "C'est envoy\u00E9" }) })), _jsx("div", { className: "flex spaced a-center w-1/4", children: followUps.filter((followUp) => `${followUp.invoiceId}` === `${invoice._id}`).length ? (_jsxs("div", { className: "w-full fs-s ta-center no-linebreak", children: ["Relanc\u00E9 le\u00A0", _jsx("span", { className: "fw-bold", children: displayDate(invoice._updatedAt) }), followUps.filter((followUp) => `${followUp.invoiceId}` === `${invoice._id}`).length && (_jsx(ToolTippedButton, { title: getFollowUpsTooltipString(followUps.filter((followUp) => `${followUp.invoiceId}` === `${invoice._id}`), t), className: "green-btn ", type: "link", size: "small", icon: _jsx(SendOutlined, {}), onClick: addFollowUp, disabled: invoice.status !== 'SENT', children: followUps.filter((followUp) => `${followUp.invoiceId}` === `${invoice._id}`).length }))] })) : (_jsx(Ant.Button, { className: "green-btn w-full", size: "small", disabled: invoice._status !== 'SENT', onClick: addFollowUp, children: "J'ai relanc\u00E9" })) }), _jsxs("div", { style: { width: "38%" }, children: [invoice._status === 'TO_SEND' && _jsx(InvoiceStatusTag, { status: invoice._status }), invoice._status === 'PAID' && (_jsx(_Fragment, { children: _jsx("div", { className: "flex a-center w-full child-pad-r-1/2 nl", children: _jsxs("div", { className: "ta-center no-linebreak", children: [_jsx("span", { className: "fw-bold fc-sc-green w-1/2", children: "Pay\u00E9e\u00A0" }), _jsxs("span", { className: "fs-s", children: ["le", " ", _jsx("span", { className: "fw-bold", children: displayDate(invoice._updatedAt) }), ' ', "par", " ", paymentLabels[invoice._transaction._method]] })] }) }) })), invoice._type !== 'CREDIT_NOTE' && invoice._status === 'SENT' && (_jsx("div", { className: "flex a-center", children: _jsx(Ant.Button, { className: "green-btn w-full", size: "small", onClick: () => {
                                                            let paymentMethod = null;
                                                            Ant.Modal.info({
                                                                title: "Indique le mode de paiement utilisé par le client",
                                                                content: (_jsx("div", { className: "flex column j-center child-mar-b-2 nl", children: _jsx(PaymentMethodSelector, { onChange: (value) => {
                                                                            paymentMethod = value;
                                                                        }, className: clsx("w-1/2") }) })),
                                                                onOk: () => {
                                                                    const token = window.localStorage.getItem("bluelibs-token");
                                                                    axios.request({
                                                                        method: "POST",
                                                                        url: global.NEW_API_URL + '/invoices/' + invoice._id + '/pay',
                                                                        headers: { "Authorization": token },
                                                                        data: {
                                                                            method: paymentMethod,
                                                                            externalId: null,
                                                                        },
                                                                    }).then(() => {
                                                                        props.onReload();
                                                                    }).catch((e) => {
                                                                        Ant.notification.warn({
                                                                            message: 'Erreur',
                                                                            description: e.response.data.error.code,
                                                                        });
                                                                    });
                                                                },
                                                            });
                                                        }, children: "C'est pay\u00E9 !" }) })), invoice._type !== 'CREDIT_NOTE' && invoice._status === 'REFUNDED' && (_jsx(_Fragment, { children: _jsx("div", { className: "flex a-center w-full child-pad-r-1/2 nl", children: _jsxs("div", { className: "ta-center no-linebreak", children: [_jsx("span", { className: "fw-bold fc-sc-green w-1/2", children: "Rembours\u00E9e\u00A0" }), _jsxs("span", { className: "fs-s", children: ["le", " ", _jsx("span", { className: "fw-bold", children: displayDate(invoice._updatedAt) })] })] }) }) }))] })] })] }, invoice._id));
                    })) : (_jsx(CardEmpty, { description: 'Pas de facture.' })) })] }) }));
});
