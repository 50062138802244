export const phrasesFr = {
    fr: {
        management: {
            kanban: {
                // fields: {},
                // menu: {
                //   title: "Contacts",
                // },
                list: {
                    header: "Estimations",
                    // create_btn: "Nouveau contact",
                },
                create: {
                // header: "Ajouter un contact",
                },
                edit: {
                // header: "Editer le contact",
                },
                view: {
                // header: "Afficher le contact",
                },
                // delete_confirmation: "Vous avez supprimé le contact",
                // create_confirmation: "Vous avez ajouté un nouveau contact avec succès",
                // edit_confirmation: "Vous avez modifié le contact avec succès",
                // actions: {
                //   search: "Rechercher un contact (nom, prénom, téléphone, email)",
                //   add: "Ajouter un contact",
                // },
            },
        },
    },
};
