import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UIOptions, UITextfield } from "biuty/react";
import React from "react";
export default function LazyOptions(props) {
    var _a, _b, _c;
    const [options, setOptions] = React.useState((_a = props.initialOptions) !== null && _a !== void 0 ? _a : []);
    const [value, setValue] = React.useState(props.multiple ? [] : null);
    const timeout = React.useRef(-1);
    const val = (_b = props.value) !== null && _b !== void 0 ? _b : value;
    const selectedOptions = options.filter((option) => Array.isArray(val)
        ? val.includes(option.value)
        : option.value === val);
    React.useEffect(() => {
        if (props.initialOptions === undefined) {
            props.loadOptions().then((options) => {
                setOptions(options);
            });
        }
    }, []);
    const load = React.useCallback(() => {
        setOptions([{
                type: "header",
                value: 'null',
                label: 'Chargement...',
                disabled: true,
            }]);
        props.loadOptions().then((options) => {
            setOptions(options);
        });
    }, [setOptions, props.loadOptions]);
    return (_jsxs("div", { className: 'lazy-options ' + ((_c = props.className) !== null && _c !== void 0 ? _c : ''), children: [_jsx(UITextfield, { value: selectedOptions.length > 0 ? selectedOptions.map((option) => option.label).join(', ') : '', placeholder: props.placeholder, onFocus: load, onChange: (v) => {
                    window.clearTimeout(timeout.current);
                    window.setTimeout(() => {
                        props.loadOptions(v).then((options) => {
                            setOptions(options);
                        });
                    }, 300);
                } }), _jsx(UIOptions, { select: true, modifiers: "open", value: val, multiple: props.multiple, onChange: (v) => {
                    setValue(v);
                    props.onChange(v);
                }, options: options })] }));
}
