import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import * as Ant from "antd";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
export const EditableText = (props) => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState(props.text);
    const onChange = useCallback((event) => {
        setText(event.target.value);
    }, []);
    const onEdit = useCallback(() => setEditing(true), []);
    const onReset = useCallback((newText) => {
        setEditing(false);
        setText(newText);
    }, []);
    const onCancel = useCallback(() => onReset(props.text), []);
    const onValidate = useCallback(() => {
        var _a;
        setLoading(true);
        (_a = props
            .onValidate) === null || _a === void 0 ? void 0 : _a.call(props, text).then(() => onReset(text)).finally(() => {
            setLoading(false);
        });
    }, [props.onValidate, text]);
    return (_jsx("div", { className: clsx("flex a-center", props.className), style: props.style, children: editing ? (_jsx(Ant.Input, { value: text, disabled: props.disabled || loading, onChange: onChange, suffix: _jsxs(_Fragment, { children: [_jsx(Ant.Button, { type: "text", icon: _jsx(CheckOutlined, {}), loading: loading, onClick: onValidate, size: "small" }), _jsx(Ant.Button, { type: "text", icon: _jsx(CloseCircleOutlined, {}), disabled: loading, onClick: onCancel, size: "small" })] }) })) : (_jsxs("span", { children: [props.text, !props.readOnly && (_jsx(Ant.Button, { type: "text", icon: _jsx(EditOutlined, {}), disabled: props.disabled, onClick: onEdit, size: "small" }))] })) }));
};
