import { i18n } from "@bundles/UIAppBundle/i18n";
import phrases from "./config/ClientRequests.i18n.json";
import { phrasesFr } from "./config/ClientRequests.fr.i18n";
i18n.push(phrases, phrasesFr);
// i18n.push({
//   fr: {
//     clientRequest: {
//       noLinkedAgency: "Pas d'agence liée.",
//       linkAgency: "Lier à une agence",
//       isArchived: "Archivée",
//       description: "Description",
//       relatedContacts: "Contacts",
//       addAComment: "Ajouter un commentaire",
//       comment: "Commentaire",
//       askJobEstimation: "Créer une estimation technique",
//       statuses: {
//         [ClientRequestStatus.ONGOING]: "En cours",
//         [ClientRequestStatus.LOST]: "Perdue",
//         [ClientRequestStatus.CANCELED]: "Annulée",
//         [ClientRequestStatus.REFUSED]: "Refusée",
//       },
//       relatedContactTypes: {
//         [relatedContactType.OTHER]: "Autre",
//         [relatedContactType.TENANT]: "Locataire",
//         [relatedContactType.OWNER]: "Propriétaire",
//       },
//     },
//   },
//   en: {
//     clientRequest: {
//       noLinkedAgency: "No related agency.",
//       linkAgency: "Link to an agency",
//       isArchived: "Archived",
//       description: "Description",
//       relatedContacts: "Contacts",
//       addAComment: "Add a comment",
//       comment: "Comment",
//       askJobEstimation: "Create job estimation",
//       statuses: {
//         [ClientRequestStatus.ONGOING]: "Ongoing",
//         [ClientRequestStatus.LOST]: "Lost",
//         [ClientRequestStatus.CANCELED]: "Canceled",
//         [ClientRequestStatus.REFUSED]: "Refused",
//       },
//       relatedContactTypes: {
//         [relatedContactType.OTHER]: "Other",
//         [relatedContactType.TENANT]: "Tenant",
//         [relatedContactType.OWNER]: "Land Owner",
//       },
//     },
//   },
// });
// You can override additional messages here by using i18n.push();
