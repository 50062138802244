var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ClientRequestsCollection, ProfilesCollection, AgenciesCollection, ClientRequestRelatedContactsCollection, } from "@bundles/UIAppBundle/collections";
let ClientRequestRelatedContactCreateForm = class ClientRequestRelatedContactCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "profileType",
                label: t("management.client_request_related_contacts.fields.profileType"),
                name: ["profileType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { allowClear: true, placeholder: t("management.client_request_related_contacts.fields.profileType"), children: [_jsx(Ant.Select.Option, { value: "TENANT", children: "Tenant" }, "TENANT"), _jsx(Ant.Select.Option, { value: "OWNER", children: "Owner" }, "OWNER"), _jsx(Ant.Select.Option, { value: "OTHER", children: "Other" }, "OTHER")] }) })),
            },
            {
                id: "locationIndex",
                label: t("management.client_request_related_contacts.fields.locationIndex"),
                name: ["locationIndex"],
                component: Ant.InputNumber,
            },
            {
                id: "isPayer",
                label: t("management.client_request_related_contacts.fields.isPayer"),
                name: ["isPayer"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isLocation",
                label: t("management.client_request_related_contacts.fields.isLocation"),
                name: ["isLocation"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isAsker",
                label: t("management.client_request_related_contacts.fields.isAsker"),
                name: ["isAsker"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "clientRequestId",
                label: t("management.client_request_related_contacts.fields.clientRequest"),
                name: ["clientRequestId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ClientRequestsCollection, field: "reference", required: true }) })),
            },
            {
                id: "profileId",
                label: t("management.client_request_related_contacts.fields.profile"),
                name: ["profileId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProfilesCollection, field: "fullName", required: false }) })),
            },
            {
                id: "agencyId",
                label: t("management.client_request_related_contacts.fields.agency"),
                name: ["agencyId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: AgenciesCollection, field: "name", required: false }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.client_request_related_contacts.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.CLIENT_REQUEST_RELATED_CONTACTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.CLIENT_REQUEST_RELATED_CONTACTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.CLIENT_REQUEST_RELATED_CONTACTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ClientRequestRelatedContactsCollection),
    __metadata("design:type", typeof (_a = typeof ClientRequestRelatedContactsCollection !== "undefined" && ClientRequestRelatedContactsCollection) === "function" ? _a : Object)
], ClientRequestRelatedContactCreateForm.prototype, "collection", void 0);
ClientRequestRelatedContactCreateForm = __decorate([
    Service({ transient: true })
], ClientRequestRelatedContactCreateForm);
export { ClientRequestRelatedContactCreateForm };
