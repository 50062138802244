import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { Form, Input, Button, Row, Col, Alert, Card, notification, } from "antd";
export const emailSuffix = "@selfcity.fr";
export const getEmailWithSuffix = (username, emailSuffix) => (username.indexOf("@") === -1 ? username + emailSuffix : username);
export function Login() {
    const guardian = useGuardian();
    const tl = useTranslate("authentication.login");
    const router = useRouter();
    const [loginError, setLoginError] = useState(null);
    // const { register, handleSubmit, errors } = useForm<FormInput>();
    const onSubmit = (data) => {
        const { username, password } = data;
        guardian
            .login(getEmailWithSuffix(username, emailSuffix), password)
            .then(() => {
            notification.success({
                message: "Welcome!",
            });
            router.go(Routes.HOME);
        })
            .catch((err) => {
            setLoginError(err);
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(Row, { justify: "center", align: "middle", style: style, className: "login-page", children: _jsx(Col, { sm: 24, md: 12, lg: 6, children: _jsx(Card, { title: tl("header"), children: _jsxs(Form, { className: "authentication-form", onFinish: (data) => onSubmit(data), children: [_jsx(Form.Item, { name: "username", rules: [{ required: true }], children: _jsx(Input, { prefix: _jsx(UserOutlined, { className: "site-form-item-icon" }), suffix: emailSuffix, placeholder: tl("fields.username") }) }), _jsx(Form.Item, { name: "password", rules: [{ required: true }], children: _jsx(Input, { prefix: _jsx(LockOutlined, { className: "site-form-item-icon" }), type: "password", placeholder: tl("fields.password") }) }), _jsx(Form.Item, { children: _jsx(Link, { className: "authentication-form-forgot", to: router.path(Routes.FORGOT_PASSWORD), children: tl("forgotPassword_btn") }) }), _jsxs(Form.Item, { children: [_jsx(Button, { type: "primary", htmlType: "submit", className: "authentication-form-button", children: tl("login") }), tl("or"), " ", _jsx(Link, { to: router.path(Routes.REGISTER), children: tl("register_action") })] }), loginError && (_jsx(Alert, { message: loginError.networkError
                                ? loginError.toString()
                                : tl("invalid_credentials"), type: "error" }))] }) }) }) }));
}
