import { Collection, } from "@bluelibs/x-ui";
import { Agency } from "@root/api.types";
import { ClientRequestRelatedContactsCollection, ProfilesCollection, AgenciesTagsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class AgenciesCollection extends Collection {
    getName() {
        return "Agencies";
    }
    getInputs() {
        return {
            insert: "AgencyInsertInput!",
            update: "AgencyUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => AgenciesTagsCollection,
                name: "agenciesTag",
                field: "agenciesTagId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => ClientRequestRelatedContactsCollection,
                name: "clientRequestRelatedContacts",
            },
            {
                collection: () => ProfilesCollection,
                name: "members",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
