import { i18n } from "@bundles/UIAppBundle/i18n";
import phrases from "./config/InboxTasks.i18n.json";
i18n.push(phrases);
i18n.push({
    fr: {
        management: {
            agenda: {
                list: {
                    header: "Agenda",
                },
            },
            profiles: {
                menu: {
                    inbox: "Tâches",
                },
            },
            inbox_tasks: {
                fields: {
                    title: "Titre",
                    tag: "Tag",
                    dueDate: "Date butoir",
                    assignedTo: "Assigné à",
                },
                list: {
                    header: "Tâches",
                },
                create_confirmation: "Tâche créée avec succès.",
                actions: {
                    search_user: "Rechercher un utilisateur",
                    search_tag: "Type de tâche",
                    add_task: "Ajouter une tache",
                    add_task_to_myself: "Ajouter une tache à moi",
                    edit_task: "Modifier la tache",
                },
                headers: {
                    assigned_tasks: "Filtres : ",
                    unassigned_tasks: "Tâches non assignées",
                },
                mode: {
                    label: "Tâches:",
                    assigned: "assignées",
                    unassigned: "non-assignées",
                },
                filters: {
                    unassigned: "Tâches non assignées",
                    assignee: "Tâches de :",
                    tag: "Filtrer par :",
                },
                tooltips: {
                    assign: "Assigner",
                    unassign: "Dé-assigner",
                    mark_as_done: "Marquer comme fait",
                },
                card: {
                    empty: "Vous n'avez aucune tâche pour le moment :(",
                    unassigned_empty: "Aucune tâche à assigner :)",
                },
            },
        },
    },
});
// You can override additional messages here by using i18n.push();
