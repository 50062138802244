import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { formRenderItems } from "@bundles/UIAppBundle/pages/utils";
import { InboxTasksCollection } from "@bundles/UIAppBundle/collections";
import { useFormModalHooks } from "@bundles/UIAppBundle/components/Modal/Modal.hooks";
import { defaultOkBtnProps, Modal, } from "@bundles/UIAppBundle/components/Modal/Modal.index";
import { InboxTaskEditForm } from "../../config/InboxTaskEditForm";
export const InboxTaskFormModal = memo((props) => {
    const { t, document, isLoading, form, xForm, modalRef, onFinish, onCancel, collection, onOk, } = useFormModalHooks({
        collection: InboxTasksCollection,
        queryParam: "inboxTask",
        form: InboxTaskEditForm,
        onEditSuccess: props.onEditSuccess,
        onCreateSuccess: props.onCreateSuccess,
        closeAfterSubmit: true,
    });
    return (_jsx(_Fragment, { children: !isLoading && (_jsx(Ant.Form, { form: form, requiredMark: "optional", initialValues: document, onFinish: onFinish, children: _jsx(Modal, { queryParam: "inboxTask", title: document ? "Editer la tache" : "Créer une nouvelle tâche", width: 600, okButtonProps: defaultOkBtnProps, onOk: onOk, onCancel: onCancel, modalRef: modalRef, children: _jsxs("div", { className: `mar-1 ${props.isCRPage ? "inbox-modal" : ""}`, children: [_jsx("div", { className: "input-row", children: formRenderItems(xForm, ["title"]) }), _jsx("div", { className: "input-row", children: formRenderItems(xForm, ["assignedToId"]) }), _jsx("div", { className: "input-row", children: formRenderItems(xForm, ["clientRequestId"]) }), formRenderItems(xForm, ["dueDate"]), _jsx("div", { className: "input-row", children: formRenderItems(xForm, ["tagId"]) })] }) }) })) }));
});
