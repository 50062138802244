import "./i18n";
import { SearchOutlined } from "@ant-design/icons";
import { JOB_ESTIMATIONS_LIST as BASE_JOB_ESTIMATIONS_LIST, ESTIMATE_VIEW as BASE_ESTIMATE_VIEW, } from "./config/routes";
import { UserRoles } from "@root/api.types";
export const JOB_ESTIMATIONS_LIST = {
    ...BASE_JOB_ESTIMATIONS_LIST,
    // label: "Rechercher estimations",
    path: "/job-estimations",
    menu: {
        ...BASE_JOB_ESTIMATIONS_LIST.menu,
        icon: SearchOutlined,
        order: 5,
        roles: [UserRoles.ADMIN, UserRoles.PLUMBER],
    },
};
export const ESTIMATE_VIEW = {
    ...BASE_ESTIMATE_VIEW,
    path: "/estimates/:id/view",
    roles: [UserRoles.ADMIN, UserRoles.SUPPORT, UserRoles.PLUMBER],
};
