import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { colors } from "@root/utils/colors";
import { CommentOutlined } from "@ant-design/icons";
import { CommentItem } from "./Comment";
const timelineItemStyle = { paddingBottom: "4px" };
const commentIconItemStyle = {
    borderRadius: 2,
    padding: 2,
    backgroundColor: colors.hexToRgbaString("#fef7eb"),
    color: colors.selfcityYellow.hex,
};
export const TimelineComment = (props) => {
    return (_jsx(Ant.Timeline.Item, { style: timelineItemStyle, dot: _jsx(CommentOutlined, { style: commentIconItemStyle }), className: props.disabled ? "disabled" : "", children: _jsx(CommentItem, { comment: props.comment, isMainTimeLine: props.isMainTimeLine }) }, props.comment._id));
};
