import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { extract } from "@root/utils/lib";
import { UserRoles } from "@root/api.types";
/**
 *
 * Replace blueprint Components by new specific Belette Components
 *
 * @param componentsMap : {blueprintComponent1: newUIComponent1, blueprintComponent2: newUIComponent2, ...}
 * @param object : to pass this
 */
export const replaceUIComponents = (componentsMap, object) => {
    Object.entries(componentsMap).map(([blueprintComponent, newUIComponent /* : JSX.Element */]) => {
        object.update(blueprintComponent, {
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: newUIComponent })),
        });
    });
};
/**
 *
 * Remove all form items not listed in orderedItemsIdsToRender
 * and reorder items
 *
 * @param itemsIdsToRender
 * @param object
 */
export const renderOnlyWithOrder = (orderedItemsIdsToRender, object) => {
    object.elements.forEach((item) => {
        const itemIndex = orderedItemsIdsToRender.indexOf(item.id);
        itemIndex !== -1
            ? object.update(item.id, {
                order: itemIndex,
            })
            : object.remove(item.id);
    });
};
export const removeFormItemsLabels = (itemsIds) => {
    // TODO
};
export const removeAllFormItemsLabels = (object, ids) => {
    object.elements.forEach((item) => {
        if (ids === undefined || ((ids === null || ids === void 0 ? void 0 : ids.length) && ids.includes(item.id))) {
            item.label = null;
        }
    });
};
/**
 *
 * @param itemsIds []
 * @param style ex: { flexGrow: 1, marginBottom: 0 }
 * @param object
 */
export const setFormItemsStyle = (itemsIds, style, object) => {
    itemsIds.map((itemId) => object.elements.map((item) => {
        if (item.id === itemId) {
            item.formItemProps = {
                ...item.formItemProps,
                style: style,
            };
            return;
        }
    }));
};
export const setAllFormItemsStyle = (style, object) => {
    object.elements.map((item) => (item.formItemProps = {
        ...item.formItemProps,
        style: style,
    }));
};
export const removeFormItemsStyle = (itemsIds) => {
    // TODO
};
export const removeAllFormItemsStyle = (object) => {
    object.elements.map((item) => {
        item.formItemProps = {
            ...item.formItemProps,
            noStyle: true,
        };
    });
};
export const formRenderItems = (form, itemsIds) => {
    return itemsIds.map((itemId) => {
        return form.render(itemId);
    });
};
export const removeAddressDetailsFromList = (object) => {
    const addressDetailsToRemove = [
        "streetOne",
        "streetTwo",
        "zip",
        "town",
        "country",
        "geopoint",
    ];
    addressDetailsToRemove.map((item) => object.remove("address." + item));
};
export const removeListColumns = (itemsIdsToRemove, object) => {
    itemsIdsToRemove.forEach((attributeId) => object.remove(attributeId));
};
export const setListColumnsOrder = (orderedItemsIds, object) => {
    var _a;
    if (((_a = object === null || object === void 0 ? void 0 : object.elements) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        const itemsIdsToRemove = object.elements
            .filter((elem) => !orderedItemsIds.includes(elem.id))
            .map(extract("id"));
        !!itemsIdsToRemove &&
            itemsIdsToRemove.length > 0 &&
            removeListColumns(itemsIdsToRemove, object);
        orderedItemsIds.forEach((attributeId, index) => object.update(attributeId, {
            order: index++,
        }));
    }
};
export const setPlaceholder = (XFormElementId, placeholder, object) => {
    object.update(XFormElementId, {
        componentProps: {
            placeholder: placeholder,
        },
    });
};
export const setAllXFormElemsPlaceholders = (object) => {
    object.elements.map((item) => object.update(item.id, {
        componentProps: {
            placeholder: item.label,
        },
    }));
};
/**
 * Translate enum labels in List tables and Viewers
 *
 * @param enumItemsProps: { enumItemId: string, enumItemI18nPath: string}[]
 * @param t: t (i18n)
 * @param object: this
 */
export const translateEnumValues = (enumItemsProps, t, object) => {
    const { UIComponents } = object;
    enumItemsProps.forEach((itemProp) => {
        object.update(itemProp.enumItemId, {
            render: (value, model) => {
                const props = {
                    type: "enum",
                    value: t(`${itemProp.enumItemI18nPath}.${value}`),
                    labelify: true,
                };
                return _jsx(UIComponents.AdminListItemRenderer, { ...props });
            },
        });
    });
};
export const hasRoles = (roles, user) => {
    return roles.every((role) => {
        if (!user.roles.includes(role))
            return false;
        // if (!userRoles.includes(role)) return false;
        return true;
    });
};
export const isTechnician = (user) => {
    return hasRoles([UserRoles.PLUMBER], user);
};
