import { Kernel } from "@bluelibs/core";
import { XUIBundle } from "@bluelibs/x-ui";
import { UIAppBundle } from "../bundles/UIAppBundle/UIAppBundle";
import { XUIAdminBundle } from "@bluelibs/x-ui-admin";
import { UIApolloBundle } from "@bluelibs/ui-apollo-bundle";
// All UI bundles need to be prefixed with UI
// All X-Framework bundles have the first prefix X
export const kernel = new Kernel({
    bundles: [
        new UIApolloBundle({
            client: {
                // ApolloClientOptions<any>;
                uri: process.env.API_URL,
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: "cache-and-network",
                    },
                    query: {
                        fetchPolicy: "cache-and-network",
                    },
                },
            },
            enableSubscriptions: true, // true by default
        }),
        new XUIBundle({
            i18n: {
                defaultLocale: "fr",
            },
        }),
        new UIAppBundle(),
        new XUIAdminBundle(),
    ],
});
