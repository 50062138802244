var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ClientRequestsCollection, ProfilesCollection, AgenciesCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let ClientRequestRelatedContactListFiltersForm = class ClientRequestRelatedContactListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "profileType",
                label: t("management.client_request_related_contacts.fields.profileType"),
                name: ["profileType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.client_request_related_contacts.fields.profileType"), children: [_jsx(Ant.Select.Option, { value: "TENANT", children: "Tenant" }, "TENANT"), _jsx(Ant.Select.Option, { value: "OWNER", children: "Owner" }, "OWNER"), _jsx(Ant.Select.Option, { value: "OTHER", children: "Other" }, "OTHER")] }) })),
            },
            {
                id: "locationIndex",
                label: t("management.client_request_related_contacts.fields.locationIndex"),
                name: ["locationIndex"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "isPayer",
                label: t("management.client_request_related_contacts.fields.isPayer"),
                name: ["isPayer"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isLocation",
                label: t("management.client_request_related_contacts.fields.isLocation"),
                name: ["isLocation"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isAsker",
                label: t("management.client_request_related_contacts.fields.isAsker"),
                name: ["isAsker"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "createdAt",
                label: t("management.client_request_related_contacts.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.client_request_related_contacts.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.client_request_related_contacts.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.client_request_related_contacts.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "clientRequestId",
                label: t("management.client_request_related_contacts.fields.clientRequest"),
                name: ["clientRequestId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ClientRequestsCollection, field: "reference", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "profileId",
                label: t("management.client_request_related_contacts.fields.profile"),
                name: ["profileId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "agencyId",
                label: t("management.client_request_related_contacts.fields.agency"),
                name: ["agencyId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: AgenciesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "createdById",
                label: t("management.client_request_related_contacts.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.client_request_related_contacts.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.client_request_related_contacts.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.client_request_related_contacts.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
ClientRequestRelatedContactListFiltersForm = __decorate([
    Service({ transient: true })
], ClientRequestRelatedContactListFiltersForm);
export { ClientRequestRelatedContactListFiltersForm };
