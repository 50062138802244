import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { useCallback, useState } from "react";
export var DocumentTypePath;
(function (DocumentTypePath) {
    DocumentTypePath["QUOTATION"] = "quotations";
    DocumentTypePath["INVOICE"] = "invoices";
})(DocumentTypePath || (DocumentTypePath = {}));
export const getDocumentUrl = (documentType, id, download = false) => `${process.env.SERVER_URL}${documentType}/download-pdf/${id}${download ? "?download" : ""}`;
export const getQuotationFileNameAndUrl = (params) => ({
    fileUrl: getDocumentUrl(DocumentTypePath.QUOTATION, params.quotationId, true),
    fileName: `selfcity_mission-${params.clientRequestId}_id-${params.quotationId}_quotation-${params.quotationNumber}`,
});
export const getInvoiceFileNameAndUrl = (params) => ({
    fileUrl: getDocumentUrl(DocumentTypePath.INVOICE, params.invoiceId, true),
    fileName: `selfcity_mission-${params.clientRequestId}_id-${params.invoiceId}_invoice-${params.invoiceNumber}`,
});
export const DownloadFileButton = (props) => {
    var _a;
    const [loading, setLoading] = useState(false);
    const handleDownload = useCallback(() => {
        var _a;
        setLoading(true);
        const fileName = props.fileName ? `${props.fileName}.pdf` : `document.pdf`;
        if ((_a = props.file) === null || _a === void 0 ? void 0 : _a.data) {
            saveAs(new Blob([props === null || props === void 0 ? void 0 : props.file.data], { type: "application/pdf" }), fileName);
        }
        else if (props.fileUrl) {
            window.open(props.fileUrl, "_blank").focus();
        }
        setTimeout(() => {
            var _a;
            (_a = props.onDownload) === null || _a === void 0 ? void 0 : _a.call(props);
            setLoading(false);
        }, 500);
    }, [(_a = props === null || props === void 0 ? void 0 : props.file) === null || _a === void 0 ? void 0 : _a.data, props === null || props === void 0 ? void 0 : props.fileUrl, props.onDownload]);
    return (_jsx(Ant.Button, { shape: "circle", onClick: handleDownload, icon: _jsx(CloudDownloadOutlined, {}), loading: loading, disabled: loading, ...props.downloadBtnProps }));
};
