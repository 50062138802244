import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
export const useSimpleModal = (initialState = false) => {
    const [visible, setVisible] = useState(initialState);
    const closeModal = useCallback(() => {
        setVisible(false);
    }, []);
    const openModal = useCallback(() => {
        setVisible(true);
    }, []);
    return {
        openModal,
        closeModal,
        isVisible: visible,
    };
};
export const useQueryParams = () => {
    const location = useLocation();
    const queryParams = useMemo(() => queryString.parse(location.search), [location.search]);
    return queryParams;
};
export const useOpenModal = (queryParam) => {
    const location = useLocation();
    const history = useHistory();
    const openModal = (id, additionalQueryParams = {}) => {
        history.push(`${location.pathname}?${queryString.stringify({
            // TODO using `window.location` instead of `location` is a quick fix to prevent
            // multiple modals from opening at the same time, because `useLocation` keeps all query
            // params and never gets updated even when URL changes. This because multiple React routers
            // are instanciated at different places in the code. Ideally, we would need to centralize
            // the router instanciation somewhere.
            ...queryString.parse(window.location.search),
            ...additionalQueryParams,
            [queryParam]: id,
        })}`, { openedModal: queryParam });
    };
    return openModal;
};
export const useIsFirstRun = () => {
    const isFirstRunRef = useRef(true);
    useEffect(() => {
        isFirstRunRef.current = false;
    }, []);
    return isFirstRunRef;
};
export const useEasyMemo = (target) => {
    // ? Possible thanks to https://stackoverflow.com/questions/5525795/does-javascript-guarantee-object-property-order
    return useMemo(() => target, Object.values(target));
};
