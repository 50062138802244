import "./i18n";
import { CLIENT_REQUEST_RELATED_CONTACTS_LIST as BASE_CLIENT_REQUEST_RELATED_CONTACTS_LIST, CLIENT_REQUEST_RELATED_CONTACTS_CREATE as BASE_CLIENT_REQUEST_RELATED_CONTACTS_CREATE, CLIENT_REQUEST_RELATED_CONTACTS_EDIT as BASE_CLIENT_REQUEST_RELATED_CONTACTS_EDIT, CLIENT_REQUEST_RELATED_CONTACTS_VIEW as BASE_CLIENT_REQUEST_RELATED_CONTACTS_VIEW, } from "./config/routes";
import { ADMIN_MENU } from "../const";
import { UserRoles } from "@root/api.types";
export const CLIENT_REQUEST_RELATED_CONTACTS_LIST = {
    ...BASE_CLIENT_REQUEST_RELATED_CONTACTS_LIST,
    path: "/client-request-related-contacts",
    menu: {
        ...BASE_CLIENT_REQUEST_RELATED_CONTACTS_LIST.menu,
        inject: ADMIN_MENU,
        roles: [UserRoles.ADMIN],
    },
};
export const CLIENT_REQUEST_RELATED_CONTACTS_CREATE = {
    ...BASE_CLIENT_REQUEST_RELATED_CONTACTS_CREATE,
};
export const CLIENT_REQUEST_RELATED_CONTACTS_EDIT = {
    ...BASE_CLIENT_REQUEST_RELATED_CONTACTS_EDIT,
};
export const CLIENT_REQUEST_RELATED_CONTACTS_VIEW = {
    ...BASE_CLIENT_REQUEST_RELATED_CONTACTS_VIEW,
};
