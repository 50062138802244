var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { removeAddressDetailsFromList, setListColumnsOrder, translateEnumValues, } from "../../utils";
import { ProfileList as BaseProfileList } from "./ProfileList.base";
let ProfileList = class ProfileList extends BaseProfileList {
    build() {
        super.build();
        // Perform additional modifications such as updating how a list item renders or add additional ones
        const { t } = this.i18n;
        removeAddressDetailsFromList(this);
        setListColumnsOrder([
            "gender",
            "lastName",
            "firstName",
            "phone",
            "email",
            "address.formattedAddress",
            "agency",
            // "iptCustomerId",
            // "createdAt",
            // "updatedAt",
            // "provider",
            // "createdBy",
            // "updatedBy",
            // "user",
        ], this);
        translateEnumValues([
            {
                enumItemId: "gender",
                enumItemI18nPath: "management.profiles.fields.gender_enum",
            },
        ], t, this);
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
ProfileList = __decorate([
    Service({ transient: true })
], ProfileList);
export { ProfileList };
