import { CommentVisibility } from "@root/api.types";
export const phrasesFr = {
    fr: {
        management: {
            comments: {
                fields: {
                    _id: "ID",
                    text: "Texte",
                    visibility: "Visibilité",
                    visibility_enum: {
                        [CommentVisibility.PRIVATE]: "Privé",
                        [CommentVisibility.PUBLIC]: "Publique",
                        [CommentVisibility.AGENCY]: "Agence",
                    },
                    isDeleted: "supprimé",
                    isDeleted_description: "Ce champ est utilisé pour identifier si cet objet a été supprimé en douceur",
                    createdAt: "Créée le",
                    createdAt_description: "Représente la date à laquelle cet objet a été créé",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Représente la dernière fois que l'objet a été mis à jour",
                    clientRequestId: "ID Mission",
                    jobEstimationId: "ID estimation devis",
                    attachmentsId: "ID pièces jointes",
                    createdById: "ID créateur",
                    createdById_description: "Représente l'identifiant de l'utilisateur qui a créé cet objet",
                    updatedById: "ID modificateur",
                    updatedById_description: "Représente l'identifiant de l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                    clientRequest: "Mission",
                    jobEstimation_description: "L'estimation de devis auquel le commentaire appartient",
                    attachments: "Pièces jointes",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé cet objet",
                    updatedBy: "Modifié par",
                    updatedBy_description: "Représente l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                },
                menu: {
                    title: "Commentaires",
                },
                list: {
                    header: "Liste des commentaires",
                    create_btn: "Nouveau commentaire",
                },
                create: {
                    header: "Créer un commentaire",
                },
                edit: {
                    header: "Modifier le commentaire",
                },
                view: {
                    header: "Afficher le commentaire",
                },
                delete_confirmation: "Vous avez supprimé le commentaire",
                create_confirmation: "Vous avez ajouté un nouveau commentaire avec succès",
                edit_confirmation: "Vous avez modifié le commentaire avec succès",
                actions: {
                    add: "Ajouter un commentaire",
                },
            },
        },
    },
};
