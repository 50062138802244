import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
import { PlusOutlined, } from "@ant-design/icons";
// ###### 2 => React imports #############
import React, { memo } from "react";
import * as Ant from "antd";
// ###### 5 => Local app imports #########
import { JobEstimationSumup } from "../JobEstimations/JobEstimationSumup/JobEstimationSumup.index";
import { CardEmpty } from "../CardEmpty/CardEmpty.index";
import { useTranslate } from "@bluelibs/x-ui";
import { useParams } from "react-router-dom";
import { subjects } from "@root/subjects";
export const ClientRequestJobEstimationsCard = memo((props) => {
    const t = useTranslate();
    const params = useParams();
    const onInspectJobEstimation = React.useCallback((jobEstim) => {
        const token = window.localStorage.getItem("bluelibs-token");
        window.location.href = global.NEW_APP_URL + '/missions/' + props.clientRequest._id + '/estimates/' + jobEstim._id + '/edit?token=' + token;
    }, []);
    return (_jsx(_Fragment, { children: _jsxs(Ant.Card, { size: "small", children: [_jsxs("div", { className: "flex spaced mar-b-2", children: [_jsx("div", { className: "fw-bold fs-2", children: "Estimation(s)" }), _jsx(Ant.Button, { className: "blue-btn", type: "primary", icon: _jsx(PlusOutlined, {}), onClick: () => {
                                const token = window.localStorage.getItem("bluelibs-token");
                                window.location.href = global.NEW_APP_URL + '/missions/' + props.clientRequest._id + '/estimates/create?token=' + token;
                            }, children: "Cr\u00E9er une estimation" })] }), (_jsx("div", { style: { overflow: "visible" }, children: props.estimates.length > 0 ? (props.estimates.map((jobEstimation, i) => (_jsxs(React.Fragment, { children: [i > 0 && _jsx(Ant.Divider, { className: "mar-v-1" }), _jsx(JobEstimationSumup, { subjects: subjects, jobEstimation: jobEstimation, onInspectJobEstimation: onInspectJobEstimation, onReload: props.onReload }, jobEstimation._id)] }, jobEstimation._id)))) : (_jsx(CardEmpty, { description: t("management.job_estimations.card.empty") })) }))] }) }));
});
