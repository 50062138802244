import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { use, useGuardian, useTranslate, useUIComponents, } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { useOpenModal } from "@root/utils/hooks";
import { buildClass } from "biuty";
import { Layout, Button, Switch, Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { UsersCollection } from "@bundles/UIAppBundle/collections";
import { InboxTasksCollection } from "@bundles/UIAppBundle/collections/InboxTasks";
import { CardEmpty } from "@bundles/UIAppBundle/components/CardEmpty/CardEmpty.index";
import InboxTask from "@root/bundles/UIAppBundle/pages/InboxTasksManagement/components/List/InboxTask";
import { InboxTaskFormModal } from "./InboxTaskFormModal.index";
import { PlusOutlined } from "@ant-design/icons";
import { RemoteSelectLazy } from "@bundles/UIAppBundle/overrides";
import { UserRoles } from "@root/api.types";
import InboxTasksFiltersTags from "@bundles/UIAppBundle/components/InboxTasksFiltersTags/InboxTasksFiltersTags";
import axios from "axios";
import { createInboxTask, inboxTasksTitle } from '@bundles/UIAppBundle/components/ClientRequestQuotationsCard/ClientRequestQuotationsCard.index';
const body = {
    _id: 1,
    title: 1,
    done: 1,
    dueDate: 1,
    priority: 1,
    assignedToId: 1,
    clientRequestId: 1,
    tag: {
        _id: 1,
        name: 1,
    },
    clientRequest: {
        _id: 1,
        reference: 1,
    },
};
const options = {
    sort: {
        dueDate: 1,
        priority: -1,
    },
};
const usersLazyFilter = ["profile.firstName", "profile.lastName"];
const usersQueryFilter = {
    roles: UserRoles.SUPPORT,
};
export function InboxTasksList() {
    const t = useTranslate();
    const timeout = useRef(null);
    const guardian = useGuardian();
    const { user } = guardian.state;
    const UIComponents = useUIComponents();
    const inboxTasksCollection = use(InboxTasksCollection);
    const [activeUserId, setActiveUserId] = useState(user === null || user === void 0 ? void 0 : user._id.toString());
    const [assignedTasksTagId, setAssignedTasksTagId] = useState(null);
    const [unassignedTasksTagId, setUnassignedTasksTagId] = useState(null);
    const openModal = useOpenModal("inboxTask");
    const [tasks, setTasks] = useState({ assigned: [], unassigned: [] });
    const [mode, setMode] = useState("assigned");
    const className = buildClass("inbox-page", mode);
    const switchMode = useCallback(() => {
        setMode((mode) => (mode === "assigned" ? "unassigned" : "assigned"));
    }, []);
    // Quickfix that prevents modal from emitting errors because form is already consumed.
    const [key, setKey] = useState("___");
    const reRender = (newKey) => {
        if (key !== newKey) {
            setKey(null);
            setTimeout(() => {
                setKey(newKey);
            }, 500);
        }
    };
    const { data: assignedTasks, refetch: refetchAssignedTasks } = inboxTasksCollection.useQuery({
        body,
        queryInput: {
            filters: {
                assignedToId: activeUserId ? new ObjectId(activeUserId) : "",
                tagId: assignedTasksTagId
                    ? new ObjectId(assignedTasksTagId)
                    : undefined,
                done: { $not: { $eq: true } },
            },
            options,
        },
    });
    const { data: unassignedTasks, refetch: refetchUnassignedTasks } = inboxTasksCollection.useQuery({
        body,
        queryInput: {
            filters: {
                assignedToId: null,
                tagId: unassignedTasksTagId
                    ? new ObjectId(unassignedTasksTagId)
                    : undefined,
                done: { $not: { $eq: true } },
            },
            options,
        },
    });
    const [inboxTasksFiltersCount, setInboxTasksFiltersCount] = useState(null);
    const updateTasks = () => {
        clearTimeout(timeout.current);
        Promise.all([refetchAssignedTasks(), refetchUnassignedTasks()])
            .then(([assigned, unassigned]) => {
            setTasks({
                assigned: assigned.data.InboxTasksFind,
                unassigned: unassigned.data.InboxTasksFind,
            });
            timeout.current = setTimeout(updateTasks, 5000);
        })
            .then(() => {
            if (activeUserId)
                getInboxTasksFiltersCount(activeUserId).then(setInboxTasksFiltersCount);
        });
    };
    const onCreateSuccess = useCallback(() => {
        reRender(`_${Math.random()}`);
        updateTasks();
    }, []);
    const handleChangeUser = useCallback(async (userId) => {
        setActiveUserId(userId.toString());
    }, []);
    const filterAssignedTasksByTag = useCallback(async (tagId) => {
        setAssignedTasksTagId(tagId === null || tagId === void 0 ? void 0 : tagId.toString());
    }, []);
    useEffect(() => {
        refetchAssignedTasks().then(updateTasks);
    }, [assignedTasksTagId]);
    const filterUnassignedTasksByTag = useCallback(async (tagId) => {
        setUnassignedTasksTagId(tagId === null || tagId === void 0 ? void 0 : tagId.toString());
    }, []);
    useEffect(() => {
        refetchUnassignedTasks().then(updateTasks);
    }, [unassignedTasksTagId]);
    const toggleAssigned = useCallback((taskId, isAssigned) => async () => {
        await inboxTasksCollection.updateOne(taskId, {
            assignedToId: isAssigned ? null : activeUserId,
        });
        updateTasks();
    }, [activeUserId]);
    // const markAsDone = useCallback(
    //   (taskId: string) => async () => {
    //     await inboxTasksCollection.updateOne(taskId, { done: true });
    //     updateTasks();
    //   },
    //   [],
    // );
    const markAsDoneAndCreateAutomatedTasks = useCallback((task) => async () => {
        await inboxTasksCollection.updateOne(task._id, { done: true });
        if (task.title === inboxTasksTitle.askForDeposit
            && task.clientRequest.quotations
                .filter(quotation => quotation.status === 'ACCEPTED'
                || quotation.status === 'ACCEPTED_BY_DEFAULT')
                .map(quotation => quotation.fromJobEstimations
                .map(je => je.requiredConsumables
                .filter(cons => cons.supplier.type !== 'STOCK')))
                .length > 0) {
            await createInboxTask(inboxTasksTitle.order, task.clientRequestId, inboxTasksCollection);
        }
        if (task.title === inboxTasksTitle.order) {
            const taskJEs = task.clientRequest.quotations
                .filter(quotation => quotation.status === 'ACCEPTED'
                || quotation.status === 'ACCEPTED_BY_DEFAULT')
                .map(quotation => quotation.fromJobEstimations).flat();
            // makeAppointmentWithDeliveryAndClearing
            // if (taskJEs.filter(je => je.requiresTruck && je.requiresClearing).length > 0) {
            //   await createInboxTask(
            //     inboxTasksTitle.makeAppointmentWithDeliveryAndClearing,
            //     task.clientRequestId,
            //     inboxTasksCollection,
            //   )
            // } else
            if (taskJEs.filter(je => je.requiresTruck).length > 0) {
                // makeAppointmentWithDelivery
                await createInboxTask(inboxTasksTitle.makeAppointmentWithDelivery, task.clientRequestId, inboxTasksCollection);
                // } else if (taskJEs.filter(je => je.requiresClearing).length > 0) {
                //   // makeAppointmentWithClearing
                //   await createInboxTask(
                //     inboxTasksTitle.makeAppointmentWithClearing,
                //     task.clientRequestId,
                //     inboxTasksCollection,
                //   )
            }
            else {
                // makeAppointmentWithOUTDeliveryNORClearing
                await createInboxTask(inboxTasksTitle.makeAppointment, task.clientRequestId, inboxTasksCollection);
            }
        }
        updateTasks();
    }, []);
    // Updates tasks list whenever active user changes.
    useEffect(() => {
        if (activeUserId) {
            updateTasks();
        }
        return () => clearTimeout(timeout.current);
    }, [activeUserId, assignedTasksTagId]);
    // Updates active user whenever logged-in user changes.
    useEffect(() => {
        if (!activeUserId && user) {
            setActiveUserId(user._id.toString());
        }
    }, [user === null || user === void 0 ? void 0 : user._id.toString()]);
    if (!unassignedTasks || !assignedTasks) {
        return null;
    }
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Layout.Content, { className: "inbox-layout", children: _jsxs("div", { className: className, children: [_jsxs("div", { className: "inbox-page__mode flex justify-between l:none", children: [_jsx("label", { children: t("management.inbox_tasks.mode.label") }), _jsxs("div", { className: "flex hgap-2 s:hgap-4", children: [_jsx("span", { children: t("management.inbox_tasks.mode.assigned") }), _jsx(Switch, { onChange: switchMode }), _jsx("span", { children: t("management.inbox_tasks.mode.unassigned") })] })] }), _jsxs("div", { className: "flex hgap-4", children: [_jsxs("div", { className: "w-1/2", children: [_jsxs("div", { className: "inbox-page__assigned-tasks__filters flex justify-between", children: [_jsxs("div", { className: "flex justify-between m:justify-start items-center hgap-3", children: [_jsx("label", { children: t("management.inbox_tasks.filters.assignee") }), _jsx(RemoteSelectLazy, { style: { minWidth: "200px" }, showSearch: true, field: "fullName", value: activeUserId, onChange: handleChangeUser, collectionClass: UsersCollection, lazy: usersLazyFilter, additionalQueryFilter: usersQueryFilter, placeholder: t("management.inbox_tasks.actions.search_user") })] }), _jsx(Tooltip, { title: t("management.inbox_tasks.actions.add_task_to_myself"), children: _jsx(Button, { onClick: () => openModal("new", { userId: activeUserId }), type: "primary", shape: "circle", icon: _jsx(PlusOutlined, {}) }) })] }), inboxTasksFiltersCount && (_jsx(InboxTasksFiltersTags, { inboxTasksFiltersCount: inboxTasksFiltersCount.assigned, onTagToggle: filterAssignedTasksByTag })), tasks.assigned.length === 0 ? (_jsx("div", { className: "page-inbox-tasks-list__empty", children: _jsx(CardEmpty, { description: t("management.inbox_tasks.card.empty") }) })) : (_jsx(_Fragment, { children: tasks.assigned.map((task) => (_jsx(InboxTask, { _id: task._id, title: task.title, dueDate: task.dueDate, 
                                                // onClick={markAsDone(task._id)}
                                                onClick: markAsDoneAndCreateAutomatedTasks(task), clientRequest: task.clientRequest, tagName: task.tag.name, tooltips: {
                                                    markAsDone: t("management.inbox_tasks.tooltips.mark_as_done"),
                                                    toggleAssigned: t("management.inbox_tasks.tooltips.unassign"),
                                                }, toggleAssigned: toggleAssigned(task._id, true) }, task._id))) }))] }), _jsxs("div", { className: "w-1/2", children: [_jsxs("div", { className: "inbox-page__unassigned-tasks__filters flex justify-between", children: [_jsx("div", { className: "flex justify-between items-center", children: _jsx("label", { children: t("management.inbox_tasks.filters.unassigned") }) }), _jsx(Tooltip, { title: t("management.inbox_tasks.actions.add_task"), children: _jsx(Button, { onClick: () => openModal("new"), type: "primary", shape: "circle", icon: _jsx(PlusOutlined, {}) }) })] }), inboxTasksFiltersCount && (_jsx(InboxTasksFiltersTags, { inboxTasksFiltersCount: inboxTasksFiltersCount.unassigned, onTagToggle: filterUnassignedTasksByTag })), tasks.unassigned.length === 0 ? (_jsx("div", { className: "page-inbox-tasks-list__empty", children: _jsx(CardEmpty, { description: t("management.inbox_tasks.card.unassigned_empty") }) })) : (_jsx("div", { className: "flex flex-col vgap-2", children: tasks.unassigned.map((task) => (_jsx(InboxTask, { _id: task._id, unassigned: true, title: task.title, tagName: task.tag.name, dueDate: task.dueDate, clientRequest: task.clientRequest, tooltips: {
                                                    markAsDone: t("management.inbox_tasks.tooltips.mark_as_done"),
                                                    toggleAssigned: t("management.inbox_tasks.tooltips.assign"),
                                                }, toggleAssigned: toggleAssigned(task._id, false) }, task._id))) }))] })] })] }) }), key === null ? null : (_jsx(InboxTaskFormModal, { onCreateSuccess: onCreateSuccess, onEditSuccess: onCreateSuccess }, key))] }));
}
const getInboxTasksFiltersCount = async (userId) => {
    const token = window.localStorage.getItem("bluelibs-token");
    return (await axios.request({
        method: "GET",
        headers: { "bluelibs-token": token },
        url: `${process.env.API_URL.replace("/graphql", "").concat(`/inbox-tasks-filters-count/?userId=${userId}`)}`,
    })).data.inboxTasksFiltersCount;
};
