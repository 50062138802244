import { useCallback, useEffect } from "react";
import { ObjectId } from "@bluelibs/ejson";
import { use } from "@bluelibs/x-ui";
import { useState } from "react";
import { CommentsCollection } from "../collections";
export const useCommentByClientRequestId = (clientRequestId, options = {}) => {
    const [nbComments, setNbComments] = useState(0);
    const commentsCollection = use(CommentsCollection);
    const queryInput = {
        filters: { clientRequestId: new ObjectId(clientRequestId) },
        options: { sort: { createdAt: -1 } },
    };
    const isMainTimeLine = !options.jobEstimationId;
    if (options.queryOptions) {
        queryInput.options = {
            ...queryInput.options,
            ...options.queryOptions,
        };
    }
    if (options.jobEstimationId) {
        queryInput.filters.$or = [
            // we want comments about this JE
            { jobEstimationId: new ObjectId(options.jobEstimationId) },
            // or comment not about another JE that have some files attached
            {
                jobEstimationId: { $exists: false },
                attachmentsId: { $exists: true },
            },
        ];
    }
    const body = {
        _id: 1,
        createdAt: 1,
        createdBy: { _id: 1, fullName: 1 },
        text: 1,
        isPined: 1,
        attachmentsId: 1,
        jobEstimationId: 1,
        attachments: {
            _id: 1,
            name: 1,
            files: {
                _id: 1,
                name: 1,
                downloadUrl: 1,
                mimeType: 1,
                thumbs: {
                    type: 1,
                    path: 1,
                    downloadUrl: 1,
                },
            },
        },
    };
    const { data: comments, loading } = commentsCollection.useQuery({
        body,
        queryInput,
    });
    const countComments = useCallback(() => {
        commentsCollection
            .count(queryInput.filters)
            .then(setNbComments)
            .catch(console.error);
    }, [commentsCollection]);
    useEffect(() => {
        countComments();
    }, [setNbComments]); // nbComments
    return { comments, loading, isMainTimeLine, nbComments, countComments };
};
