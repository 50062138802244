import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
import { Collapse } from "antd";
import clsx from "clsx";
import { KanbanCard } from "./KanbanCard";
import React from "react";
const { Panel } = Collapse;
const isPhoneMode = window.innerWidth < 570;
export const Kanban = memo((props) => {
    return (_jsx(Collapse, { ghost: true, className: "kanban-collapse", expandIconPosition: "right", defaultActiveKey: !isPhoneMode && props.columns.map((column) => column.id), accordion: isPhoneMode ? true : false, collapsible: isPhoneMode ? undefined : "disabled", children: props.columns.map((column) => {
            var _a;
            return (_jsx(Panel, { className: "kanban-column", showArrow: isPhoneMode ? true : false, header: _jsxs("div", { className: clsx("column-title", column.headClassName), children: [column.title, " (", (_a = column.cards.length) !== null && _a !== void 0 ? _a : 0, ")"] }), children: !!column.cards &&
                    column.cards.length > 0 &&
                    column.cards.map((card) => (_jsx("div", { className: "mar-b-1", children: _jsx(KanbanCard, { clientRequest: card }, card._id) }))) }, column.id));
        }) }));
});
