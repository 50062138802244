export const hexToRgba = (hex, opacity = 1) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const hex2 = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex2);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: opacity,
        }
        : null;
};
const hexToRgbaString = (hex, opacity = 1) => {
    const { r, g, b, a } = hexToRgba(hex, opacity);
    return `rgba(${r},${g},${b},${a})`;
};
const makeColorObject = (hex) => {
    return {
        hex,
        opacity: (opacity) => hexToRgbaString(hex, opacity),
    };
};
export const colors = {
    selfcityGreen: makeColorObject("#69B28F"),
    selfcityRed: makeColorObject("#F5222D"),
    selfcityYellow: makeColorObject("#F5C97C"),
    selfcityBlue: makeColorObject("#6272AF"),
    selfcityEggplant: makeColorObject("#4A3557"),
    tripBlue: makeColorObject("#40A9FF"),
    hexToRgbaString,
    hexToRgba,
};
