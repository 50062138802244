import { AgenciesTagActivity, AgenciesTagColor } from "@root/api.types";
export const phrasesFr = {
    fr: {
        management: {
            agencies_tags: {
                fields: {
                    _id: "ID",
                    isDeleted: "supprimé",
                    isDeleted_description: "Ce champ est utilisé pour identifier si cet objet a été supprimé en douceur",
                    createdAt: "Créée le",
                    createdAt_description: "Représente la date à laquelle cet objet a été créé",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Représente la dernière fois que l'objet a été mis à jour",
                    tag: "Tag",
                    description: "Description",
                    activity: "Métier",
                    activity_description: "Le métier que la mission concerne",
                    activity_enum: {
                        [AgenciesTagActivity.PLUMBING]: "Plomberie",
                        [AgenciesTagActivity.MULTISERVICE]: "Multiservice",
                        [AgenciesTagActivity.LOCKSMITHING]: "Serrurerie",
                        [AgenciesTagActivity.ELECTRICAL]: "Electricité",
                    },
                    color: "Couleur",
                    color_enum: {
                        [AgenciesTagColor.selfcityGreen]: "Vert",
                        [AgenciesTagColor.selfcityRed]: "Rouge",
                        [AgenciesTagColor.selfcityYellow]: "Jaune",
                        [AgenciesTagColor.selfcityBlue]: "Bleu",
                        [AgenciesTagColor.selfcityEggplant]: "Violet",
                        [AgenciesTagColor.transparent]: "transparent",
                    },
                    createdById: "ID créateur",
                    createdById_description: "Représente l'identifiant de l'utilisateur qui a créé cet objet",
                    updatedById: "ID modificateur",
                    updatedById_description: "Représente l'identifiant de l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé cet objet",
                    updatedBy: "Modifié par",
                    updatedBy_description: "Représente l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                    agencies: "Agences",
                },
                menu: {
                    title: "Tags agences",
                },
                list: {
                    header: "Liste des Tags des agences",
                    create_btn: "Nouveau Tag d'agence",
                },
                create: {
                    header: "Créer un Tag d'agence",
                },
                edit: {
                    header: "Modifier le tag d'agence",
                },
                view: {
                    header: "Afficher le tag d'agence",
                },
                delete_confirmation: "Vous avez supprimé le Tag Agence",
                create_confirmation: "Vous avez ajouté avec succès un nouveau tag d'agences",
                edit_confirmation: "Vous avez modifié avec succès le tag d'agence",
            },
        },
    },
};
