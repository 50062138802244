import { ClientRequestRelatedContactsList } from "../components/List/ClientRequestRelatedContactsList";
import { ClientRequestRelatedContactsCreate } from "../components/Create/ClientRequestRelatedContactsCreate";
import { ClientRequestRelatedContactsEdit } from "../components/Edit/ClientRequestRelatedContactsEdit";
import { ClientRequestRelatedContactsView } from "../components/View/ClientRequestRelatedContactsView";
import { SettingFilled } from "@ant-design/icons";
export const CLIENT_REQUEST_RELATED_CONTACTS_LIST = {
    path: "/admin/client-request-related-contacts",
    component: ClientRequestRelatedContactsList,
    menu: {
        key: "CLIENT_REQUEST_RELATED_CONTACTS_LIST",
        label: "management.client_request_related_contacts.menu.title",
        icon: SettingFilled,
    },
};
export const CLIENT_REQUEST_RELATED_CONTACTS_CREATE = {
    path: "/admin/client-request-related-contacts/create",
    component: ClientRequestRelatedContactsCreate,
};
export const CLIENT_REQUEST_RELATED_CONTACTS_EDIT = {
    path: "/admin/client-request-related-contacts/:id/edit",
    component: ClientRequestRelatedContactsEdit,
};
export const CLIENT_REQUEST_RELATED_CONTACTS_VIEW = {
    path: "/admin/client-request-related-contacts/:id/view",
    component: ClientRequestRelatedContactsView,
};
