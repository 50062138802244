export const labels = {
    CATEGORY_TITLE: 'Il s\'agit',
    ELEMENT_TITLE: 'De',
    CATEGORIES: {
        SERVICE: 'd\'un entretien',
        UNBLOCKING: 'd\'un débouchage',
        RENOVATION: 'd\'une rénovation',
        REPAIRING: 'd\'un dépannage / diagnostic',
        LOCKSMITHING: 'd\'une intervention serrurerie',
        SILICONE: 'd\'une réfection de joints silicone',
        MULTISERVICE: 'd\'une intervention multiservice',
        INSTALLATION: 'd\'une installation / remplacement',
    },
    ELEMENTS: {
        DOOR: 'Porte',
        TOILET: 'WC',
        ROOM: 'Pièce',
        SEALS: 'Joints',
        MIXER: 'Mitigeur',
        BOILER: 'Chaudière',
        CLASSIC_TOILET: 'WC',
        HOT_WATER_TANK: 'BEC',
        FAUCETS: 'Robinetterie',
        MISCELLANEOUS: 'Divers',
        LEAK: 'Recherche de fuite',
        HEAT_PUMP: 'Pompe à chaleur',
        SANITARY_ELEMENT: 'Élément',
        MECHANICAL_VENTILATION: 'VMC',
        AIR_CONDITIONER: 'Climatisation',
        EVACUATION_CIRCUIT: 'Évacuation',
        SIMPLE_BOILER: 'Chaudière simple',
        WALL_HUNG_TOILET: 'WC suspendus',
        COMPLEX_BOILER: 'Chaudière complexe',
        HEATING_CIRCUIT: 'Circuit chauffage',
        WATER_SUPPLY_CIRCUIT: 'Alimentation',
        TOILET_WITH_SANIBROYEUR: 'WC avec sanibroyeur',
        THERMODYNAMIC_BOILER: 'Chauffe-eau thermodynamique',
    },
    MECHANICAL_VENTILATION_SERVICE: 'Entretien VMC',
    SIMPLE_BOILER_SERVICE: 'Entretien chaudière simple',
    COMPLEX_BOILER_SERVICE: 'Entretien chaudière complexe',
    HEAT_PUMP_SERVICE: 'Entretien pompe à chaleur',
    THERMODYNAMIC_BOILER_SERVICE: 'Entretien chauffe-eau thermodynamique',
    AIR_CONDITIONER_SERVICE: 'Entretien climatisation',
    BOILER_INSTALLATION: 'Installation/remplacement chaudière',
    AIR_CONDITIONER_INSTALLATION: 'Installation/remplacement climatisation',
    HEAT_PUMP_INSTALLATION: 'Installation/remplacement pompe à chaleur',
    MECHANICAL_VENTILATION_INSTALLATION: 'Installation/remplacement VMC',
    FAUCETS_INSTALLATION: 'Installation/remplacement robinetterie',
    SANITARY_ELEMENT_INSTALLATION: 'Installation/remplacement élement',
    EVACUATION_CIRCUIT_INSTALLATION: 'Installation/remplacement évacuation',
    WATER_SUPPLY_CIRCUIT_INSTALLATION: 'Installation/remplacement alimentation',
    HOT_WATER_TANK_INSTALLATION: 'Installation/remplacement BEC',
    WALL_HUNG_TOILET_INSTALLATION: 'Installation/remplacement WC suspendus',
    TOILET_WITH_SANIBROYEUR_INSTALLATION: 'Installation/remplacement WC avec sanibroyeur',
    HEATING_CIRCUIT_INSTALLATION: 'Installation/remplacement circuit chauffage',
    MECHANICAL_VENTILATION_REPAIRING: 'Dépannage/diagnostic VMC',
    HEAT_PUMP_REPAIRING: 'Dépannage/diagnostic pompe à chaleur',
    BOILER_REPAIRING: 'Dépannage/diagnostic chaudière',
    WATER_SUPPLY_CIRCUIT_REPAIRING: 'Dépannage/diagnostic alimentation',
    AIR_CONDITIONER_REPAIRING: 'Dépannage/diagnostic climatisation',
    EVACUATION_CIRCUIT_REPAIRING: 'Dépannage/diagnostic évacuation',
    MIXER_REPAIRING: 'Dépannage/diagnostic mitigeur',
    SANITARY_ELEMENT_REPAIRING: 'Dépannage/diagnostic élément',
    LEAK_REPAIRING: 'Recherche de fuite',
    CLASSIC_TOILET_REPAIRING: 'Dépannage/diagnostic WC',
    WALL_HUNG_TOILET_REPAIRING: 'Dépannage/diagnostic WC suspendus',
    HOT_WATER_TANK_REPAIRING: 'Dépannage/diagnostic BEC',
    HEATING_CIRCUIT_REPAIRING: 'Dépannage/diagnostic circuit chauffage',
    ROOM_RENOVATION: 'Rénovation de pièce',
    DOOR_LOCKSMITHING: 'Serrurerie',
    MISCELLANEOUS_MULTISERVICE: 'Multiservice',
    SEALS_SILICONE: 'Réfection de joints silicone',
    TOILET_UNBLOCKING: 'Débouchage WC',
};
export const subjects = [
    {
        _id: '63de942172063fcd86e8ea9d',
        category: 'SERVICE',
        element: 'MECHANICAL_VENTILATION',
    },
    {
        _id: '63de942172063fcd86e8ea65',
        category: 'SERVICE',
        element: 'SIMPLE_BOILER',
    },
    {
        _id: '626adcd0bfffbd0fec9e1467',
        category: 'SERVICE',
        element: 'COMPLEX_BOILER',
    },
    {
        _id: '645caf1a2e1089e3193eea88',
        category: 'SERVICE',
        element: 'HEAT_PUMP',
    },
    {
        _id: '6554e68020de4cc66006ecc0',
        category: 'SERVICE',
        element: 'THERMODYNAMIC_BOILER',
    },
    {
        _id: '63de942172063fcd86e8ea6c',
        category: 'SERVICE',
        element: 'AIR_CONDITIONER',
    },
    {
        _id: '63de942172063fcd86e8ea68',
        category: 'INSTALLATION',
        element: 'BOILER',
    },
    {
        _id: '63de942172063fcd86e8ea6f',
        category: 'INSTALLATION',
        element: 'AIR_CONDITIONER',
    },
    {
        _id: '645caf392e1089e3193eea8c',
        category: 'INSTALLATION',
        element: 'HEAT_PUMP',
    },
    {
        _id: '63de942172063fcd86e8ea9f',
        category: 'INSTALLATION',
        element: 'MECHANICAL_VENTILATION',
    },
    {
        _id: '63de942172063fcd86e8ea3b',
        category: 'INSTALLATION',
        element: 'FAUCETS',
    },
    {
        _id: '63de942172063fcd86e8ea74',
        category: 'INSTALLATION',
        element: 'SANITARY_ELEMENT',
    },
    {
        _id: '63de942172063fcd86e8ea37',
        category: 'INSTALLATION',
        element: 'EVACUATION_CIRCUIT',
    },
    {
        _id: '63de942172063fcd86e8ea57',
        category: 'INSTALLATION',
        element: 'WATER_SUPPLY_CIRCUIT',
    },
    {
        _id: '63de942172063fcd86e8ea59',
        category: 'INSTALLATION',
        element: 'HOT_WATER_TANK',
    },
    {
        _id: '65c5061b8147eb8bacca87b3',
        category: 'INSTALLATION',
        element: 'WALL_HUNG_TOILET',
    },
    {
        _id: '646e271a5b4cf2e494bacc80',
        category: 'INSTALLATION',
        element: 'TOILET_WITH_SANIBROYEUR',
    },
    {
        _id: '63de942172063fcd86e8ea60',
        category: 'INSTALLATION',
        element: 'HEATING_CIRCUIT',
    },
    {
        _id: '63de942172063fcd86e8ea9e',
        category: 'REPAIRING',
        element: 'MECHANICAL_VENTILATION',
    },
    {
        _id: '645caf2f2e1089e3193eea8a',
        category: 'REPAIRING',
        element: 'HEAT_PUMP',
    },
    {
        _id: '63de942172063fcd86e8ea66',
        category: 'REPAIRING',
        element: 'BOILER',
    },
    {
        _id: '63de942172063fcd86e8ea4b',
        category: 'REPAIRING',
        element: 'WATER_SUPPLY_CIRCUIT',
    },
    {
        _id: '63de942172063fcd86e8ea6d',
        category: 'REPAIRING',
        element: 'AIR_CONDITIONER',
    },
    {
        _id: '63de942172063fcd86e8ea36',
        category: 'REPAIRING',
        element: 'EVACUATION_CIRCUIT',
    },
    {
        _id: '63de942172063fcd86e8ea3c',
        category: 'REPAIRING',
        element: 'MIXER',
    },
    {
        _id: '63de942172063fcd86e8ea4e',
        category: 'REPAIRING',
        element: 'SANITARY_ELEMENT',
    },
    {
        _id: '63de942172063fcd86e8ea55',
        category: 'REPAIRING',
        element: 'LEAK',
    },
    {
        _id: '63de942172063fcd86e8ea42',
        category: 'REPAIRING',
        element: 'CLASSIC_TOILET',
    },
    {
        _id: '63de942172063fcd86e8ea47',
        category: 'REPAIRING',
        element: 'WALL_HUNG_TOILET',
    },
    {
        _id: '63de942172063fcd86e8ea5f',
        category: 'REPAIRING',
        element: 'HEATING_CIRCUIT',
    },
    {
        _id: '63de942172063fcd86e8ea58',
        category: 'REPAIRING',
        element: 'HOT_WATER_TANK',
    },
    {
        _id: '63de942172063fcd86e8ea77',
        category: 'RENOVATION',
        element: 'ROOM',
    },
    // {
    //   _id: '63de942172063fcd86e8ea85',
    //   category: 'LOCKSMITHING',
    //   element: 'DOOR',
    // },
    // {
    //   _id: '63de942172063fcd86e8eaa3',
    //   category: 'MULTISERVICE',
    //   element: 'MISCELLANEOUS',
    // },
    {
        _id: '63de942172063fcd86e8ea4d',
        category: 'SILICONE',
        element: 'SEALS',
    },
    {
        _id: '63de942172063fcd86e8ea45',
        category: 'UNBLOCKING',
        element: 'TOILET',
    },
];
