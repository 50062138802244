import { AgenciesList } from "../components/List/AgenciesList";
import { AgenciesCreate } from "../components/Create/AgenciesCreate";
import { AgenciesEdit } from "../components/Edit/AgenciesEdit";
import { AgenciesView } from "../components/View/AgenciesView";
import { SettingFilled } from "@ant-design/icons";
export const AGENCIES_LIST = {
    path: "/admin/agencies",
    component: AgenciesList,
    menu: {
        key: "AGENCIES_LIST",
        label: "management.agencies.menu.title",
        icon: SettingFilled,
    },
};
export const AGENCIES_CREATE = {
    path: "/admin/agencies/create",
    component: AgenciesCreate,
};
export const AGENCIES_EDIT = {
    path: "/admin/agencies/:id/edit",
    component: AgenciesEdit,
};
export const AGENCIES_VIEW = {
    path: "/admin/agencies/:id/view",
    component: AgenciesView,
};
