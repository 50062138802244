var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { AgenciesCollection, UsersCollection, ProfilesCollection, } from "@bundles/UIAppBundle/collections";
let ProfileCreateForm = class ProfileCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isACompany",
                label: t("management.profiles.fields.isACompany"),
                name: ["isACompany"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "companyName",
                label: t("management.profiles.fields.companyName"),
                name: ["companyName"],
                tooltip: t("management.profiles.fields.companyName_description"),
                component: Ant.Input,
            },
            {
                id: "gender",
                label: t("management.profiles.fields.gender"),
                name: ["gender"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { allowClear: true, placeholder: t("management.profiles.fields.gender"), children: [_jsx(Ant.Select.Option, { value: "MR", children: "Mr" }, "MR"), _jsx(Ant.Select.Option, { value: "MS", children: "Ms" }, "MS"), _jsx(Ant.Select.Option, { value: "OTHER", children: "Other" }, "OTHER")] }) })),
            },
            {
                id: "firstName",
                label: t("management.profiles.fields.firstName"),
                name: ["firstName"],
                tooltip: t("management.profiles.fields.firstName_description"),
                component: Ant.Input,
            },
            {
                id: "lastName",
                label: t("management.profiles.fields.lastName"),
                name: ["lastName"],
                tooltip: t("management.profiles.fields.lastName_description"),
                component: Ant.Input,
            },
            {
                id: "phones",
                label: t("management.profiles.fields.phones"),
                name: ["phones"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "emails",
                label: t("management.profiles.fields.emails"),
                name: ["emails"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "notes",
                label: t("management.profiles.fields.notes"),
                name: ["notes"],
                component: Ant.Input,
            },
            {
                id: "addresses",
                label: t("management.profiles.fields.addresses"),
                name: ["addresses"],
                isList: true,
                nest: [
                    {
                        id: "streetOne",
                        label: t("management.profiles.fields.addresses.streetOne"),
                        name: ["addresses", "streetOne"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "streetTwo",
                        label: t("management.profiles.fields.addresses.streetTwo"),
                        name: ["addresses", "streetTwo"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.profiles.fields.addresses.zip"),
                        name: ["addresses", "zip"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.profiles.fields.addresses.town"),
                        name: ["addresses", "town"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.profiles.fields.addresses.country"),
                        name: ["addresses", "country"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "geopoint",
                        label: t("management.profiles.fields.addresses.geopoint"),
                        name: ["addresses", "geopoint"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "formattedAddress",
                        label: t("management.profiles.fields.addresses.formattedAddress"),
                        name: ["addresses", "formattedAddress"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                ],
                initialValue: null,
            },
            {
                id: "address",
                label: t("management.profiles.fields.address"),
                name: ["address"],
                nest: [
                    {
                        id: "streetOne",
                        label: t("management.profiles.fields.address.streetOne"),
                        name: ["address", "streetOne"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "streetTwo",
                        label: t("management.profiles.fields.address.streetTwo"),
                        name: ["address", "streetTwo"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.profiles.fields.address.zip"),
                        name: ["address", "zip"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.profiles.fields.address.town"),
                        name: ["address", "town"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.profiles.fields.address.country"),
                        name: ["address", "country"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "geopoint",
                        label: t("management.profiles.fields.address.geopoint"),
                        name: ["address", "geopoint"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "formattedAddress",
                        label: t("management.profiles.fields.address.formattedAddress"),
                        name: ["address", "formattedAddress"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                ],
                initialValue: null,
            },
            {
                id: "iptCustomerId",
                label: t("management.profiles.fields.iptCustomerId"),
                name: ["iptCustomerId"],
                tooltip: t("management.profiles.fields.iptCustomerId_description"),
                component: Ant.InputNumber,
            },
            {
                id: "agencyId",
                label: t("management.profiles.fields.agency"),
                name: ["agencyId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: AgenciesCollection, field: "name", required: false }) })),
            },
            {
                id: "userId",
                label: t("management.profiles.fields.user"),
                name: ["userId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: false }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.profiles.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.PROFILES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.PROFILES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.PROFILES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProfilesCollection),
    __metadata("design:type", typeof (_a = typeof ProfilesCollection !== "undefined" && ProfilesCollection) === "function" ? _a : Object)
], ProfileCreateForm.prototype, "collection", void 0);
ProfileCreateForm = __decorate([
    Service({ transient: true })
], ProfileCreateForm);
export { ProfileCreateForm };
