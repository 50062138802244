import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import React, { memo, useCallback, useMemo, useState } from "react";
import * as Ant from "antd";
// ###### 3 => Npm imports ###############
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { use } from "@bluelibs/x-ui";
import { FollowUpsCollection, } from "@bundles/UIAppBundle/collections";
import { ObjectId } from "@bluelibs/ejson";
import clsx from "clsx";
import { useCommentByClientRequestId, } from "@bundles/UIAppBundle/queries/Comments";
import { TimelineTripCard } from "../TripCard/TimelineTripCard.index";
import { TimelineComment } from "../Comments/TimelineComment.index";
import { TimelineFollowUpCard } from "../FollowUpCard/TimelineFollowUpCard.index";
import { TimelineStatusCard } from "../StatusCard/TimelineStatusCardCard.index";
import { TimeLineFilterTags } from "./TimeLineFilterTags.index";
import axios from "axios";
const getAttributeSorter = (timelineElem) => {
    if (timelineElem.__typename === "TripList") {
        const tripListIndex = timelineElem.data.appointments.reduce((accumulator, currentValue, i, array) => {
            const index = new Date(array[i].endAt).getTime() > new Date(array[accumulator].endAt).getTime() ? i : accumulator;
            return index;
        }, 0);
        return new Date(timelineElem.data.appointments[tripListIndex].endAt).getTime();
    }
    if (timelineElem.__typename === "Comment") {
        return timelineElem.createdAt;
    }
    if (timelineElem.__typename === "FollowUp") {
        return timelineElem.createdAt;
    }
    if (timelineElem.__typename === "ClientRequestStatusChangeHistory") {
        return timelineElem.updatedAt;
    }
};
const renderTimelineCard = (timelineItem, isMainTimeLine, isArchived) => {
    if (timelineItem.__typename === "TripList") {
        return (_jsx(TimelineTripCard, { tripList: timelineItem, disabled: isArchived }, timelineItem.data._id));
    }
    if (timelineItem.__typename === "Comment") {
        return (_jsx(TimelineComment, { comment: timelineItem, isMainTimeLine: isMainTimeLine, disabled: isArchived }, timelineItem._id));
    }
    if (timelineItem.__typename === "FollowUp") {
        return (_jsx(TimelineFollowUpCard, { followUp: timelineItem, disabled: isArchived }, timelineItem._id));
    }
    if (timelineItem.__typename === "ClientRequestStatusChangeHistory") {
        return (_jsx(TimelineStatusCard, { statusChangeHistory: timelineItem, disabled: isArchived }, timelineItem.updatedAt));
    }
    return null;
};
export const ClientRequestTimeLine = memo((props) => {
    var _a;
    const [showingMore, setShowingMore] = useState(false);
    const [queryOptions, setQueryOptions] = useState(props.defaultLimit ? { limit: props.defaultLimit } : {});
    const [tripLists, setTripLists] = React.useState(null);
    React.useEffect(() => {
        const token = window.localStorage.getItem("bluelibs-token");
        axios.request({
            method: "POST",
            url: global.NEW_API_URL + '/onSiteJobs/search?fields=*,appointments.*,appointments.user.firstName,appointments.user.lastName',
            headers: { "Authorization": token },
            data: {
                filters: { _mission: props.clientRequestId },
            },
        }).then((response) => {
            setTripLists(response.data.results.map((result) => ({
                __typename: 'TripList',
                data: result,
            })));
        });
    }, []);
    const [commentsFilter, setCommentsFilter] = useState(false);
    const [tripListsFilter, setTripListsFilter] = useState(false);
    const [followUpsFilter, setFollowUpsFilter] = useState(false);
    const [statusesFilter, setStatusesFilter] = useState(false);
    const isFiltering = commentsFilter || tripListsFilter || followUpsFilter || statusesFilter;
    const { comments, loading: loadingComments, isMainTimeLine, nbComments, countComments, } = useCommentByClientRequestId(props.clientRequestId, {
        queryOptions,
        jobEstimationId: props.jobEstimationId,
    });
    const onShowMoreClick = useCallback(() => {
        setShowingMore(true);
        setQueryOptions((prevQueryOptions) => ({
            ...prevQueryOptions,
            limit: undefined,
        }));
    }, [showingMore, queryOptions]);
    const onShowLessClick = useCallback(() => {
        setShowingMore(false);
        setQueryOptions((prevQueryOptions) => ({
            ...prevQueryOptions,
            limit: props.defaultLimit,
        }));
    }, [showingMore, queryOptions]);
    const followUpsCollection = use(FollowUpsCollection);
    const { data: followUps, loading: loadingFollowUps, 
    // refetch,
    error: followUpsError, } = followUpsCollection.useQuery({
        queryInput: {
            filters: { clientRequestId: new ObjectId(props.clientRequestId) },
            options: { sort: { createdAt: -1 } },
        },
        body: {
            _id: 1,
            createdAt: 1,
            quotationId: 1,
            invoiceId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
        },
    });
    const getTimelineContent = useMemo(() => {
        var _a, _b;
        const timelineContent = [];
        (!isFiltering || (isFiltering && commentsFilter)) &&
            !loadingComments &&
            timelineContent.push(...comments.filter((comment) => {
                var _a;
                return (!comment.isPined &&
                    (props.isView !== true ||
                        (props.isView === true &&
                            (comment.jobEstimationId !== null ||
                                ((_a = comment.attachments) === null || _a === void 0 ? void 0 : _a.files.length) > 0))));
            }));
        (!isFiltering || (isFiltering && tripListsFilter)) &&
            tripLists !== null &&
            !!(tripLists === null || tripLists === void 0 ? void 0 : tripLists.length) &&
            timelineContent.push(...tripLists);
        //
        if (isMainTimeLine && props.isView !== true) {
            (!isFiltering || (isFiltering && followUpsFilter)) &&
                !loadingFollowUps &&
                !followUpsError &&
                !!(followUps === null || followUps === void 0 ? void 0 : followUps.length) &&
                timelineContent.push(...followUps);
            (!isFiltering || (isFiltering && statusesFilter)) &&
                !!((_a = props.clientRequest) === null || _a === void 0 ? void 0 : _a.statusChangeHistory) &&
                timelineContent.push(...(_b = props.clientRequest) === null || _b === void 0 ? void 0 : _b.statusChangeHistory);
        }
        return timelineContent.sort((a, b) => getAttributeSorter(b) - getAttributeSorter(a));
    }, [
        isFiltering,
        loadingComments,
        comments,
        commentsFilter,
        tripLists,
        tripListsFilter,
        followUps,
        followUpsFilter,
        tripLists === null,
        (_a = props.clientRequest) === null || _a === void 0 ? void 0 : _a.statusChangeHistory,
        statusesFilter,
    ]);
    const pinedComments = useMemo(() => {
        return comments.filter((comment) => comment.isPined);
    }, [comments]);
    if (loadingComments || tripLists === null) {
        return _jsx("div", { children: "Loading" });
    }
    return (_jsxs(_Fragment, { children: [isMainTimeLine && (_jsxs(_Fragment, { children: [pinedComments.map((pinedComment) => {
                        var _a;
                        return (renderTimelineCard(pinedComment, isMainTimeLine, (_a = props.clientRequest) === null || _a === void 0 ? void 0 : _a.isArchived));
                    }), _jsx(TimeLineFilterTags, { commentsUseState: { commentsFilter, setCommentsFilter }, tripListsUseState: { tripListsFilter, setTripListsFilter }, followUpsUseState: { followUpsFilter, setFollowUpsFilter }, statusesUseState: { statusesFilter, setStatusesFilter } })] })), _jsxs("div", { className: props.className, children: [_jsx(Ant.Timeline, { className: clsx("mar-t-1/2 pad-l-1", {
                            timeline: props.scrollable !== false,
                        }), style: { overflow: "auto " }, children: getTimelineContent.map((timelineItem) => {
                            var _a;
                            return renderTimelineCard(timelineItem, isMainTimeLine, (_a = props.clientRequest) === null || _a === void 0 ? void 0 : _a.isArchived);
                        }) }), !isMainTimeLine &&
                        !!(props.displayShowMore && props.defaultLimit) &&
                        (countComments(),
                            nbComments > props.defaultLimit &&
                                (showingMore ? (_jsx("div", { className: "ta-center txt-underline fc-sc-green fw-bold clickable", onClick: onShowLessClick, children: "Voir moins de commentaires" })) : (_jsx("div", { className: "ta-center txt-underline fc-sc-green fw-bold clickable", onClick: onShowMoreClick, children: "Voir plus de commentaires" }))))] })] }));
});
