import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { use } from "@bluelibs/x-ui";
import { Select, Spin } from "antd";
import { ObjectId } from "@bluelibs/ejson";
import * as debounce from "lodash.debounce";
function DebounceSelect({ fetchOptions, initialLoad, loadOnFocus, localSearchOnly, allowClear, debounceTimeout = 250, additionalQueryFilter, ...props }) {
    var _a;
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    const [initialized, setInitialized] = React.useState(false);
    const [searchedValue, setSearchedValue] = React.useState("");
    const loadOptions = (value, valueIsAnId = false) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        //   setOptions([]);
        if (localSearchOnly === false || initialized === false) {
            setFetching(true);
            fetchOptions(value, valueIsAnId).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        }
    };
    const debounceFetcher = debounce(loadOptions, debounceTimeout);
    const onSearch = (value) => {
        setSearchedValue(value);
        debounceFetcher(value);
    };
    const handleOnFocus = React.useCallback((e) => {
        var _a;
        // Fetch only after the first focus.
        if (initialized === false && loadOnFocus !== false) {
            loadOptions(null);
            setInitialized(true);
        }
        (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, e);
    }, [props.value, initialized, loadOnFocus, loadOptions]);
    const handleOnChange = React.useCallback((value, option) => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, value, option);
        //   loadOptions(props.value, true);
    }, [props.onChange]);
    React.useEffect(() => {
        if (initialLoad === true || localSearchOnly === true || props.value) {
            setInitialized(false);
            loadOptions(localSearchOnly === true ? null : props.value, true);
        }
    }, [initialLoad, JSON.stringify(additionalQueryFilter), localSearchOnly]);
    const filteredOptions = localSearchOnly === true && searchedValue.trim() !== ""
        ? options.filter((option) => new RegExp(searchedValue, "i").test(`${option.label}`))
        : options;
    return (_jsx(Select, { filterOption: false, showSearch: true, onSearch: onSearch, 
        // allowClear={allowClear}
        notFoundContent: fetching ? _jsx(Spin, { size: "small" }) : null, ...props, className: `${props.className} ${(_a = props.selectedOptClsName) === null || _a === void 0 ? void 0 : _a[props.value]}`, onChange: handleOnChange, onFocus: handleOnFocus, children: filteredOptions.map((option) => (_jsx(Select.Option, { value: option.value, children: option.label }, option.key))) }));
}
export function RemoteSelectLazy(props) {
    const { field, value, collectionClass, idAsString, onChange, lazy, limit, loadOnFocus = true, placeholder, initialLoad, allowClear = false, localSearchOnly = false, additionalQueryFilter, ...rest } = props;
    let currentValue = value;
    //    || props.defaultValue;
    if (value) {
        if (Array.isArray(value)) {
            currentValue = value.map((v) => v.toString());
        }
        else {
            currentValue = value.toString();
        }
    }
    const collection = use(collectionClass);
    return (_jsx(DebounceSelect, { localSearchOnly: localSearchOnly, value: currentValue, initialLoad: initialLoad, allowClear: allowClear, loadOnFocus: loadOnFocus, additionalQueryFilter: additionalQueryFilter, onChange: (value, option) => {
            if (Array.isArray(value)) {
                onChange &&
                    onChange(!idAsString ? value.map((v) => new ObjectId(v)) : value, option);
            }
            else {
                onChange &&
                    onChange(!idAsString ? new ObjectId(value) : value, option);
            }
        }, placeholder: placeholder, fetchOptions: (value, valueIsAnId = false) => {
            let filters = getLazyFilters(lazy ? lazy : field, field, value, valueIsAnId);
            if (additionalQueryFilter !== undefined) {
                filters = { ...filters, ...additionalQueryFilter };
            }
            return collection
                .find({
                filters,
                options: {
                    limit: limit || 10,
                },
            }, {
                _id: 1,
                [field]: 1,
            })
                .then((result) => {
                return result.map((r) => {
                    return {
                        key: r._id.toString(),
                        value: r._id.toString(),
                        label: r[field] ? r[field] : "N/A",
                    };
                });
            });
        }, ...rest }));
}
function getDefaultLazyFilters(field, value) {
    return {
        [field]: {
            $regex: `(${value.split(" ").join("|")})`,
            $options: "i",
        },
    };
}
/**
 * Extracts the set of filters based on the lazy search configuration
 *
 * @param lazy
 * @param field
 * @param value
 * @returns
 */
function getLazyFilters(lazy, field, value, valueIsAnId = false) {
    if (lazy === undefined ||
        value === "" ||
        value === undefined ||
        value === null ||
        value.length === 0) {
        return {};
    }
    // TODO handle multiple values case where value is an array
    if (valueIsAnId) {
        return Array.isArray(value)
            ? { _id: { $in: value.map((val) => new ObjectId(val)) } }
            : { _id: new ObjectId(value) };
    }
    if (lazy === true) {
        return getDefaultLazyFilters(field, value);
    }
    else if (typeof lazy === "function") {
        return lazy(value);
    }
    else if (typeof lazy === "string") {
        return getDefaultLazyFilters(lazy, value);
    }
    else if (Array.isArray(lazy)) {
        return {
            $or: lazy.map((field) => {
                return getDefaultLazyFilters(field, value);
            }),
        };
    }
}
