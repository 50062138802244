var _a, _b, _c;
const matches = /token=([^&]+)/.exec(window.location.search);
if ((matches === null || matches === void 0 ? void 0 : matches.length) > 0) {
    localStorage.setItem('bluelibs-token', matches[1]);
    window.iframeMode = true;
}
const APP_URLS = {
    'belett2.selfcity.fr': 'https://belett.selfcity.fr',
    'staging.belett.selfcity.fr': 'https://preprod.belett.selfcity.fr',
};
const API_URLS = {
    'belett2.selfcity.fr': 'https://belett.selfcity.fr/api/v1',
    'staging.belett.selfcity.fr': 'https://preprod.belett.selfcity.fr/api/v1',
};
const SANDBOX_URLS = {
    'belett2.selfcity.fr': 'https://sandbox.selfcity.fr',
    'staging.belett.selfcity.fr': 'https://sandbox.selfcity.fr',
};
global.NEW_APP_URL = (_a = APP_URLS[window.location.host]) !== null && _a !== void 0 ? _a : 'http://localhost:5000';
global.NEW_API_URL = (_b = API_URLS[window.location.host]) !== null && _b !== void 0 ? _b : 'http://localhost:9000/api/v1';
global.SANDBOX_URL = (_c = SANDBOX_URLS[window.location.host]) !== null && _c !== void 0 ? _c : 'http://localhost:8888';
require("./startup/index");
export {};
