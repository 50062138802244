import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, useState } from "react";
import * as Ant from "antd";
import { HomeOutlined, UserAddOutlined } from "@ant-design/icons";
import { ContactCard } from "../ContactCard/ContactCard.index";
import { AgenciesCollection, ProfilesCollection, } from "@bundles/UIAppBundle/collections";
import { colors } from "@root/utils/colors";
import { useContactsAndLocationCardHooks } from "./ContactsAndLocationCard.hooks";
import { ProfileFormModal } from "./Modals/Profile/ProfileFormModal.index";
import { ContactAddressesFormModal } from "./Modals/ContactAddressesFormModal.index";
import { AgencyFormModal } from "./Modals/Agency/AgencyFormModal.index";
import { CardEmpty } from "../CardEmpty/CardEmpty.index";
import { RemoteSelectLazy } from "@bundles/UIAppBundle/overrides";
import { ObjectId } from "@bluelibs/ejson";
import { getLocationContactAddress } from "@root/utils/lib";
import { tripsConfiguration } from '@root/store';
const styles = {
    noWrap: { flexWrap: "nowrap" },
    width: { width: "180px" },
    profileSelector: { width: "300px" },
};
const contactLazyFilter = ["firstName", "lastName", "companyName", "emails"];
const getDistanceEmojiAndColor = (distance) => {
    if (distance <= tripsConfiguration.zone1AreaRadius) {
        return {
            color: colors.selfcityBlue.hex,
            emoji: "🛵",
        };
    }
    if (distance <= tripsConfiguration.zone2AreaRadius) {
        return {
            color: colors.selfcityYellow.hex,
            emoji: "🚗",
        };
    }
    return {
        color: colors.selfcityRed.hex,
        emoji: "🚫",
    };
};
const getLocation = (relatedContacts) => {
    const locationContacts = relatedContacts.filter((contact) => contact.isLocation);
    if (locationContacts.length === 0) {
        return null;
    }
    const [locationContact] = locationContacts;
    return getLocationContactAddress(locationContact).formattedAddress;
};
export const ContactsAndLocationCard = memo((props) => {
    const { t, loading, error, relatedContacts, orderedRelatedContacts, doesRelatedContactsIncludeAnAgency, onSelectAgency, onProfileSelect, onTagToggle, addRelatedContactProfile, onCreateNewProfile, refetch, getRelatedContactsAlerts, } = useContactsAndLocationCardHooks(props);
    const contactQueryFilter = useMemo(() => ({
        _id: {
            $not: {
                $in: (relatedContacts === null || relatedContacts === void 0 ? void 0 : relatedContacts.filter((contact) => contact.profile).map((contact) => new ObjectId(contact.profile._id))) || [],
            },
        },
    }), [relatedContacts]);
    // Quickfix that forces modal recreation after closing it.
    const [key, setKey] = useState("___");
    const reRender = () => {
        setKey(null);
        setTimeout(() => {
            setKey(`_${Math.random()}`);
        }, 500);
    };
    const renderRelatedContactsAlerts = useMemo(() => Object.entries(getRelatedContactsAlerts()).map(([alertKey, alertValue]) => {
        if (!alertValue) {
            return (_jsx(Ant.Alert, { showIcon: true, type: "warning", message: t(`management.client_request_related_contacts.card.alerts.${alertKey}`), className: "no-linebreak mar-b-1 mar-r-1" }));
        }
    }), [getRelatedContactsAlerts()]);
    return (_jsxs(_Fragment, { children: [_jsxs(Ant.Card, { className: "card-body-overflow-x", style: { overflow: "auto" }, title: _jsx("span", { className: "fs-2 fw-bold", children: t("management.client_request_related_contacts.card.title") }), loading: loading && !(relatedContacts === null || relatedContacts === void 0 ? void 0 : relatedContacts.length), size: "small", extra: _jsxs("div", { className: "card-extra", style: styles.noWrap, children: [_jsx("div", { style: styles.width, children: _jsx(RemoteSelectLazy, { showSearch: true, disabled: doesRelatedContactsIncludeAnAgency, field: "name", collectionClass: AgenciesCollection, value: null, placeholder: t("management.agencies.actions.search"), onSelect: onSelectAgency }) }), _jsx(RemoteSelectLazy, { showSearch: true, field: "fullNameAndEmail", collectionClass: ProfilesCollection, value: null, lazy: contactLazyFilter, style: styles.profileSelector, 
                            // We want to exclude contacts that are already involved in the client request.
                            additionalQueryFilter: contactQueryFilter, placeholder: t("management.profiles.actions.search"), onSelect: onProfileSelect }), _jsx(Ant.Tooltip, { title: t("management.profiles.actions.add"), children: _jsx(Ant.Button, { className: "green-btn", icon: _jsx(UserAddOutlined, {}), onClick: onCreateNewProfile }) })] }), children: [!loading && (_jsxs(_Fragment, { children: [_jsx("div", { className: "contacts-card-alerts flex", style: { gap: "8px" }, children: renderRelatedContactsAlerts }), (relatedContacts === null || relatedContacts === void 0 ? void 0 : relatedContacts.length) ? (_jsx("div", { className: "contacts-card-body", children: (relatedContacts === null || relatedContacts === void 0 ? void 0 : relatedContacts.length) > 0 &&
                                    (orderedRelatedContacts === null || orderedRelatedContacts === void 0 ? void 0 : orderedRelatedContacts.map((relatedContact) => (_jsx(ContactCard, { relatedContact: relatedContact, onTagToggle: onTagToggle }, relatedContact._id)))) })) : (_jsx(CardEmpty, { description: t("management.client_request_related_contacts.card.empty") }))] })), getLocation(relatedContacts) !== null && (_jsxs("div", { className: "flex", style: { marginTop: "40px" }, children: [_jsxs(Ant.Tag.CheckableTag, { checked: true, className: "card-tag-checkable checked", style: { pointerEvents: "none" }, children: [_jsx(HomeOutlined, { className: "mar-r-1" }), "Lieu d'inter"] }), getLocation(relatedContacts)] })), relatedContacts
                        .filter((relatedContact) => relatedContact.isLocation)
                        .map((relatedContact) => {
                        var _a;
                        return (_jsx("div", { children: (_a = relatedContact.distanceFromPlaces) === null || _a === void 0 ? void 0 : _a.map((distanceFromPlace) => {
                                const { color, emoji } = getDistanceEmojiAndColor(distanceFromPlace.distance);
                                return (_jsxs(Ant.Tag, { 
                                    // icon={<FileProtectOutlined />}
                                    color: color, children: [_jsx("span", { className: "fw-bold", children: `${emoji}${distanceFromPlace.placeName}` }), " ", "( ~", (distanceFromPlace.duration / 60).toFixed(0), "min., ~", (distanceFromPlace.distance / 1000).toFixed(0), "Km)"] }, distanceFromPlace.placeId));
                            }) }));
                    })] }), key === null ? null : (_jsxs(_Fragment, { children: [_jsx(ProfileFormModal, { onCreateSuccess: addRelatedContactProfile, onEditSuccess: refetch, onClose: reRender }), _jsx(ContactAddressesFormModal, { onClose: reRender })] })), _jsx(AgencyFormModal, {})] }));
});
