import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import React, { useMemo } from "react";
import EditOutlinedIcon from "@ant-design/icons/EditOutlined";
import CheckCircleOutlinedIcon from "@ant-design/icons/CheckCircleOutlined";
// ###### 3 => Npm imports ###############
import clsx from "clsx";
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { colors } from "@root/utils/colors";
const makeStatusTag = (config) => {
    const component = (props) => {
        const { icon, textKey } = config[props.status];
        const memoizedStyle = useMemo(() => {
            const style = {};
            if (config[props.status].background) {
                style.background = config[props.status].background;
            }
            if (config[props.status].color) {
                style.color = config[props.status].color;
            }
            return style;
        }, [props.status]);
        return (_jsxs("div", { className: clsx("status-tag flex center pad-h-2 mar-r-0", props.className), style: memoizedStyle, children: [icon && _jsx("div", { className: "mar-r-1", children: icon() }), textKey] }));
    };
    return component;
};
export const JobEstimationStatusTag = makeStatusTag({
    ['DELIVERED']: {
        background: colors.selfcityGreen.opacity(0.15),
        color: colors.selfcityGreen.hex,
        // icon: () => </>,
        textKey: "Livré",
    },
    ['TO_DO']: {
        background: colors.selfcityRed.opacity(0.15),
        color: colors.selfcityRed.hex,
        icon: () => _jsx(EditOutlinedIcon, {}),
        textKey: "À faire",
    },
    ['VALIDATED']: {
        background: colors.selfcityGreen.opacity(0.15),
        color: colors.selfcityGreen.hex,
        icon: () => _jsx(CheckCircleOutlinedIcon, {}),
        textKey: "Validé",
    },
    ['IN_PROGRESS']: {
        background: colors.selfcityYellow.opacity(0.15),
        color: colors.selfcityYellow.hex,
        icon: () => _jsx(EditOutlinedIcon, {}),
        textKey: "En cours",
    },
});
export const QuotationStatusTag = makeStatusTag({
    ['TO_SEND']: {
        background: "transparent",
        color: colors.selfcityGreen.hex,
        // icon: () => </>,
        textKey: "Brouillon",
    },
    ['REJECTED']: {
        background: colors.selfcityYellow.opacity(0.15),
        color: colors.selfcityYellow.hex,
        // icon: () => </>,
        textKey: "Refusé",
    },
    ['CANCELLED']: {
        background: colors.selfcityYellow.opacity(0.15),
        color: colors.selfcityYellow.hex,
        // icon: () => </>,
        textKey: "Annulé",
    },
});
export const InvoiceStatusTag = makeStatusTag({
    ['TO_SEND']: {
        background: "transparent",
        color: colors.selfcityGreen.hex,
        // icon: () => </>,
        textKey: "Créée",
    },
    ['REFUNDED']: {
        background: colors.selfcityYellow.opacity(0.15),
        color: colors.selfcityYellow.hex,
        // icon: () => </>,
        textKey: "Remboursée",
    },
});
