import { ProfileGender } from "@root/api.types";
export const phrasesFr = {
    fr: {
        management: {
            profiles: {
                fields: {
                    _id: "ID",
                    isACompany: "Est une entreprise",
                    companyName: "Nom de l'entreprise.",
                    gender: "Civilité",
                    gender_enum: {
                        [ProfileGender.MR]: "M.",
                        [ProfileGender.MS]: "Mme",
                        [ProfileGender.OTHER]: "Autre",
                    },
                    firstName: "Prénom",
                    lastName: "Nom",
                    fullName: "Nom complet",
                    phone: "Téléphone principal",
                    phones: "Téléphones",
                    email: "Email principal",
                    emails: "Emails",
                    address: "Adresse",
                    streetOne: "Rue",
                    streetTwo: "Complément d'adresse",
                    zip: "Code postal",
                    town: "Ville",
                    country: "Pays",
                    geopoint: "Géopoint",
                    lat: "Latitude",
                    lng: "Longitude",
                    formattedAddress: "Adresse formatée",
                    iptCustomerId: "ID client sur IPT",
                    iptCustomerId_description: "L'uid externe identifiant cette entreprise sur IPaidThat",
                    isDeleted: "supprimé",
                    isDeleted_description: "Ce champ est utilisé pour identifier si cet objet a été supprimé en douceur",
                    createdAt: "Créée le",
                    createdAt_description: "Représente la date à laquelle cet objet a été créé",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Représente la dernière fois que l'objet a été mis à jour",
                    agencyId: "ID l'agence",
                    providerId: "ID fournisseur",
                    createdById: "ID créateur",
                    createdById_description: "Représentant l'identifiant de l'utilisateur qui a créé cet objet",
                    updatedById: "ID modificateur",
                    updatedById_description: "Représentant l'identifiant de l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                    userId: "ID utilisateur",
                    agency: "Agence",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé cet objet",
                    updatedBy: "Modifié par",
                    updatedBy_description: "Représente l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                    user: "Utilisateur",
                },
                menu: {
                    title: "Contacts",
                },
                list: {
                    header: "Contacts",
                    create_btn: "Nouveau contact",
                },
                create: {
                    header: "Ajouter un contact",
                },
                edit: {
                    header: "Editer le contact",
                },
                view: {
                    header: "Afficher le contact",
                },
                delete_confirmation: "Vous avez supprimé le contact",
                create_confirmation: "Vous avez ajouté un nouveau contact avec succès",
                edit_confirmation: "Vous avez modifié le contact avec succès",
                actions: {
                    search: "Rechercher un contact (nom, prénom, téléphone, email)",
                    add: "Ajouter un contact",
                    addPhoneNumber: "Ajouter un numéro",
                    addEmail: "Ajouter un email",
                },
            },
        },
    },
};
