import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useMemo } from "react";
import * as Ant from "antd";
import { formRenderItems } from "@bundles/UIAppBundle/pages/utils";
import { ProfileEditForm } from "@bundles/UIAppBundle/pages/ProfilesManagement/config/ProfileEditForm";
import { defaultOkBtnProps, Modal, } from "@bundles/UIAppBundle/components/Modal/Modal.index";
import { useFormModalHooks } from "@bundles/UIAppBundle/components/Modal/Modal.hooks";
import { ProfilesCollection } from "@bundles/UIAppBundle/collections";
import { withoutTypenameDeep } from "@root/utils/lib";
export const ProfileFormModal = memo((props) => {
    const { t, document: profile, isLoading, form, xForm, modalRef, onFinish, onCancel, onOk, } = useFormModalHooks({
        collection: ProfilesCollection,
        queryParam: "profile",
        form: ProfileEditForm,
        onEditSuccess: props.onEditSuccess,
        onCreateSuccess: props.onCreateSuccess,
        transformFormValue: (document) => {
            var _a, _b;
            // Removes address from document if it is empty.
            const { address, ...rest } = document;
            if ((_a = document.emails) === null || _a === void 0 ? void 0 : _a.length) {
                document.emails = document.emails.filter(Boolean);
            }
            if ((_b = document.phones) === null || _b === void 0 ? void 0 : _b.length) {
                document.phones = document.phones.filter(Boolean);
            }
            if (address &&
                (address === null || address === void 0 ? void 0 : address.streetOne.trim()) === "" &&
                (address === null || address === void 0 ? void 0 : address.streetTwo.trim()) === "" &&
                (address === null || address === void 0 ? void 0 : address.town.trim()) === "" &&
                (address === null || address === void 0 ? void 0 : address.zip.trim()) === "") {
                return rest;
            }
            return document;
        },
        closeAfterSubmit: true,
        // @ts-ignore
        pruneDocument: (profile) => {
            const prunedProfile = withoutTypenameDeep(profile);
            return prunedProfile;
        },
    });
    const initialValues = useMemo(() => {
        var _a, _b;
        return ({
            ...(profile || {}),
            phones: ((_a = profile === null || profile === void 0 ? void 0 : profile.phones) === null || _a === void 0 ? void 0 : _a.length) || [""],
            emails: ((_b = profile === null || profile === void 0 ? void 0 : profile.emails) === null || _b === void 0 ? void 0 : _b.length) || [""],
        });
    }, [profile]);
    return (_jsx(_Fragment, { children: !isLoading && (_jsx(Ant.Form, { form: form, requiredMark: "optional", initialValues: initialValues, onFinish: (arg) => {
                if (arg.lastName || arg.companyName) {
                    onFinish(arg, props.onClose);
                    return;
                }
                form.setFields([
                    {
                        name: "lastName",
                        errors: ["Nom de famille ou Nom de société requis"],
                    },
                    {
                        name: "companyName",
                        errors: ["Nom de famille ou Nom de société requis"],
                    },
                ]);
            }, labelCol: { span: 24 }, children: _jsx(Modal, { queryParam: "profile", title: profile
                    ? t("management.profiles.edit.header")
                    : t("management.profiles.create.header"), width: 800, okButtonProps: defaultOkBtnProps, onOk: onOk, onCancel: () => {
                    onCancel(false);
                    // We reset the modal's form ONLY when editing an existing profile, as we want to
                    // keep the ongoing profile data when creating a new one and closing the modal.
                    if (profile !== null) {
                        props.onClose();
                    }
                }, modalRef: modalRef, children: _jsxs("div", { className: "mar-1", children: [_jsxs("div", { className: "input-row flex a-center", children: [formRenderItems(xForm, ["isACompany"]), 
                                //   form.getFieldValue("isACompany") &&
                                _jsx(Ant.Form.Item, { name: "companyName", initialValue: "", 
                                    //   dependencies={["isACompany"]}
                                    shouldUpdate: true, label: t("management.profiles.fields.companyName"), children: _jsx(Ant.Input, { placeholder: t("management.profiles.fields.companyName"), maxLength: 64 }) })] }), _jsx("div", { className: "input-row", children: formRenderItems(xForm, ["gender", "firstName", "lastName"]) }), _jsx("div", { className: "input-row", children: formRenderItems(xForm, ["phones", "emails"]) }), formRenderItems(xForm, ["address"])] }) }) })) }));
});
