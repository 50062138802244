import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import { FileTextOutlined } from "@ant-design/icons";
import React, { memo } from "react";
import * as Ant from "antd";
import clsx from "clsx";
// ###### 3 => Npm imports ###############
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { colors } from "@root/utils/colors";
import { getTotalDiscountedPriceIT } from "@root/utils/prices";
export const QuotationTag = memo((props) => {
    var _a;
    return (_jsxs(Ant.Tag, { icon: _jsx(FileTextOutlined, {}), color: colors.selfcityGreen.opacity(0.15), className: clsx("fc-primary", props.className), children: [(_a = props.type) !== null && _a !== void 0 ? _a : 'Devis', " n\u00B0", props.quotation._number, " | ", (getTotalDiscountedPriceIT(props.quotation.rows) / 100).toFixed(2), "\u20AC TTC"] }));
});
