import Store from "diox";
import axios from "axios";
import { deepCopy, deepMerge } from "basx";
import router from "diox/extensions/router";
import useStore from "diox/connectors/react";
import { activationQueryParams } from "@root/modal.config";
const store = new Store();
export const tripsConfiguration = {
    zone1Duration: 15 * 60,
    zone2Duration: 30 * 60,
    zone1AreaRadius: 7 * 1000,
    zone2AreaRadius: 15 * 1000, // in m
};
store.register("router", router(["/client-requests/:id/edit"]));
store.register("jobEstimations", {
    state: {},
    mutations: {
        DELETE({ state }, data) {
            return deepCopy({
                entities: Object.keys(state).reduce((entities, id) => {
                    if (id === data.id) {
                        return entities;
                    }
                    return { ...entities, [id]: state[id] };
                }, {}),
            });
        },
        REFRESH({ state }, data) {
            return deepMerge(state, data.results.reduce((entities, entity) => ({
                ...entities,
                [entity._id]: entity,
            }), {}));
        },
    },
});
store.register("modal", {
    state: {
        show: false,
        component: null,
        queryParam: null,
        queryValue: null,
        componentProps: {},
    },
    mutations: {
        SHOW({ state }, data) {
            return deepMerge(state, {
                show: true,
                queryParam: null,
                queryValue: null,
                component: data.component || "",
                componentProps: data.componentProps || {},
            });
        },
        HIDE({ state }) {
            return {
                ...deepMerge(state, {
                    show: false,
                    component: null,
                    queryValue: null,
                    queryParam: null,
                }), componentProps: {},
            };
        },
    },
});
store.register("clientRequests", {
    state: {},
    mutations: {
        DELETE({ state }, data) {
            return deepCopy({
                entities: Object.keys(state).reduce((entities, id) => {
                    if (id === data.id) {
                        return entities;
                    }
                    return { ...entities, [id]: state[id] };
                }, {}),
            });
        },
        REFRESH({ state }, data) {
            return deepMerge(state, data.results.reduce((entities, entity) => ({
                ...entities,
                [entity._id]: entity,
            }), {}));
        },
    },
});
store.register("results", {
    state: {},
    mutations: {
        UPDATE_STATUS({ state }, { actionId, status }) {
            return deepMerge(state, { [actionId]: { status } });
        },
        UPDATE_RESULTS({ state }, { actionId, status, total, results }) {
            return deepMerge(state, { [actionId]: { status, total, results } });
        },
    },
    actions: {
        view({ hash, mutate }, data) {
            if (data.collection === "jobEstimations") {
                const actionId = `${data.collection}_${data.from}`;
                mutate(hash, "UPDATE_STATUS", { actionId, status: "pending" });
                const token = window.localStorage.getItem("bluelibs-token");
                axios.request({
                    method: "GET",
                    url: global.NEW_API_URL + '/estimates/' + data.id + '?fields=*,categories',
                    headers: { "Authorization": token },
                })
                    .then((response) => {
                    const result = response.data;
                    if (result !== null) {
                        mutate(data.collection, "REFRESH", { results: [result] });
                        mutate(hash, "UPDATE_RESULTS", {
                            actionId,
                            status: "success",
                            total: 1,
                            results: [result._id],
                        });
                    }
                    else {
                        mutate(hash, "UPDATE_RESULTS", {
                            actionId,
                            status: "success",
                            total: 0,
                            results: [],
                        });
                    }
                });
            }
            if (data.collection === "clientRequests") {
                const actionId = `${data.collection}_${data.from}`;
                mutate(hash, "UPDATE_STATUS", { actionId, status: "pending" });
                const token = window.localStorage.getItem("bluelibs-token");
                axios
                    .request({
                    method: "POST",
                    url: process.env.API_URL,
                    headers: { "bluelibs-token": token },
                    data: {
                        operationName: "ClientRequestsFindOne",
                        variables: {
                            query: {
                                filters: '{"_id":{"$objectId":"' + data.id + '"}}',
                                options: {},
                            },
                        },
                        query: "query ClientRequestsFindOne($query: QueryInput!) {\n  ClientRequestsFindOne(query: $query) {\n    _id\n    reference\n    sourceLead\n    sourceLeadPhone\n    nextFollowUp\n    nextFollowUpDate\n    activity\n    description\n    emergencyLevel\n    statusChangeHistory {\n      status\n      updatedAt\n      updatedById\n      __typename\n    }\n    status\n    estimatedBy {\n      fullName\n      __typename\n    }\n    executedBy {\n      fullName\n      __typename\n    }\n    isArchived\n    archivedAt\n    archivingReason\n    archivingComment\n    subjectId\n    archivedBy {\n      _id\n      fullName\n      profile {\n        firstName\n        __typename\n      }\n      __typename\n    }\n    archivedById\n    comments {\n      __typename\n      _id\n      text\n      createdAt\n      createdBy {\n        fullName\n        _id\n        __typename\n      }\n    }\n    relatedContacts {\n      _id\n      profileType\n      locationIndex\n      isPayer\n      isLocation\n      isAsker\n      distanceFromPlaces {\n        distance\n        duration\n        placeId\n        placeName\n        __typename\n      }\n      address {\n        streetOne\n        streetTwo\n        zip\n        town\n        country\n        geopoint {\n          lat\n          lng\n          __typename\n        }\n        formattedAddress\n        __typename\n      }\n      profile {\n        _id\n        fullName\n        __typename\n      }\n      profileId\n      agency {\n        _id\n        name\n        __typename\n      }\n      agencyId\n      __typename\n    }\n    __typename\n  }\n}\n",
                    },
                })
                    .then((response) => {
                    const result = response.data.data.ClientRequestsFindOne;
                    if (result !== null) {
                        mutate(data.collection, "REFRESH", { results: [result] });
                        mutate(hash, "UPDATE_RESULTS", {
                            actionId,
                            status: "success",
                            total: 1,
                            results: [result._id],
                        });
                    }
                    else {
                        mutate(hash, "UPDATE_RESULTS", {
                            actionId,
                            status: "success",
                            total: 0,
                            results: [],
                        });
                    }
                });
            }
        },
    },
});
const defaultUseCombiner = useStore(store);
const defaultReducer = (newState) => newState;
const defaultState = { total: 0, results: [], status: "pending" };
const useCombiner = (hash, reducer = defaultReducer) => defaultUseCombiner(hash, (newState) => {
    if (hash === "results") {
        return { ...defaultState, ...reducer(newState) };
    }
    return reducer(newState);
});
const mutate = store.mutate.bind(store);
const dispatch = store.dispatch.bind(store);
export const closeModal = (queryParam, queryValue) => {
    if (queryParam === null) {
        mutate("modal", "HIDE");
    }
    else {
        if (window.history.length <= 2) {
            // If user copied/pasted a link containing a reference to the modal, closing it should not
            // trigger a "go back" in history, but rather display the underlying page.
            navigate(window.location.pathname.replace(`${queryParam}=${queryValue}`, ""))();
        }
        else {
            // If user was already navigating through the app, we just pop the previous state from
            // history to prevent breaking navigation history.
            window.history.back();
        }
    }
};
// TODO fix in diox
window.addEventListener("popstate", () => {
    store.mutate("router", "POP_STATE");
});
store.combine("routedModal", ["modal", "router"], (modal, router) => {
    if (modal.show) {
        return modal;
    }
    const queryParams = Object.keys(router.query);
    for (let index = 0, { length } = queryParams; index < length; index += 1) {
        const queryParam = queryParams[index];
        const component = activationQueryParams[queryParam];
        if (component !== undefined) {
            return {
                show: true,
                queryParam,
                ...component,
                queryValue: router.query[queryParam],
            };
        }
    }
    return { show: false };
});
/**
 * Navigates to `url`, without reloading the page.
 * Caveat: the `router` diox module must be registered.
 *
 * @param {string} url Target URL.
 *
 * @returns {(event?: React.MouseEvent<HTMLElement>): void} The actual navigation function.
 */
export default function navigate(url) {
    return (event) => {
        if (event !== undefined) {
            event.preventDefault();
        }
        mutate("router", "NAVIGATE", url);
    };
}
export { navigate, mutate, dispatch, useCombiner };
