var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let AgencyViewer = class AgencyViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.agencies.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "addresses",
                label: t("management.agencies.fields.addresses"),
                dataIndex: ["addresses"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "name",
                label: t("management.agencies.fields.name"),
                dataIndex: ["name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "siret",
                label: t("management.agencies.fields.siret"),
                dataIndex: ["siret"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "vatNumber",
                label: t("management.agencies.fields.vatNumber"),
                dataIndex: ["vatNumber"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "email",
                label: t("management.agencies.fields.email"),
                dataIndex: ["email"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.streetOne",
                label: t("management.agencies.fields.address.streetOne"),
                dataIndex: ["address", "streetOne"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.streetTwo",
                label: t("management.agencies.fields.address.streetTwo"),
                dataIndex: ["address", "streetTwo"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.zip",
                label: t("management.agencies.fields.address.zip"),
                dataIndex: ["address", "zip"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.town",
                label: t("management.agencies.fields.address.town"),
                dataIndex: ["address", "town"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.country",
                label: t("management.agencies.fields.address.country"),
                dataIndex: ["address", "country"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.geopoint",
                label: t("management.agencies.fields.address.geopoint"),
                dataIndex: ["address", "geopoint"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.formattedAddress",
                label: t("management.agencies.fields.address.formattedAddress"),
                dataIndex: ["address", "formattedAddress"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "phone",
                label: t("management.agencies.fields.phone"),
                dataIndex: ["phone"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "iptCustomerId",
                label: t("management.agencies.fields.iptCustomerId"),
                dataIndex: ["iptCustomerId"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "notes",
                label: t("management.agencies.fields.notes"),
                dataIndex: ["notes"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdById",
                label: t("management.agencies.fields.createdById"),
                dataIndex: ["createdById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedById",
                label: t("management.agencies.fields.updatedById"),
                dataIndex: ["updatedById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                label: t("management.agencies.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                label: t("management.agencies.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "clientRequestRelatedContacts",
                label: t("management.agencies.fields.clientRequestRelatedContacts"),
                dataIndex: ["clientRequestRelatedContacts"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.CLIENT_REQUEST_RELATED_CONTACTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "members",
                label: t("management.agencies.fields.members"),
                dataIndex: ["members"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "agenciesTag",
                label: t("management.agencies.fields.agenciesTag"),
                dataIndex: ["agenciesTag"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.AGENCIES_TAGS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "tag",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                label: t("management.agencies.fields.createdBy"),
                dataIndex: ["createdBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                label: t("management.agencies.fields.updatedBy"),
                dataIndex: ["updatedBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            addresses: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            name: 1,
            siret: 1,
            vatNumber: 1,
            email: 1,
            address: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            phone: 1,
            iptCustomerId: 1,
            notes: 1,
            createdById: 1,
            updatedById: 1,
            createdAt: 1,
            updatedAt: 1,
            clientRequestRelatedContacts: {
                _id: 1,
            },
            members: {
                _id: 1,
            },
            agenciesTag: {
                _id: 1,
                tag: 1,
            },
            agenciesTagId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
        };
    }
};
AgencyViewer = __decorate([
    Service({ transient: true })
], AgencyViewer);
export { AgencyViewer };
