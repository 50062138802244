import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { isTechnician } from "@bundles/UIAppBundle/pages/utils";
import { SearchOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { Kanban } from "../Kanban/Kanban.index";
import { useGuardian, useTranslate } from "@bluelibs/x-ui";
import { useCallback, useState } from "react";
import { useTechniciansList } from "@bundles/UIAppBundle/queries/Users.queries";
import axios from "axios";
export const ClientRequestsKanban = memo((props) => {
    const t = useTranslate();
    const [columns, setColumns] = React.useState({ TO_DO: [], IN_PROGRESS: [], DELIVERED: [] });
    const getColumnHeadClassNames = (missions) => {
        let priority = 3;
        const priorities = {
            1: 'fc-sc-red bg-sc-red-1',
            2: 'fc-sc-yellow bg-sc-yellow-1',
            3: 'fc-sc-green bg-sc-green-1',
        };
        const todayMidnight = new Date((new Date()).setHours(0, 0, 0, 0));
        missions.forEach((mission) => {
            mission.estimates.forEach((estimate) => {
                const dueDay = new Date((new Date(estimate.dueDate)).setHours(0, 0, 0, 0));
                if (estimate.dueDate !== null) {
                    if (dueDay.getTime() < todayMidnight.getTime()) {
                        priority = 1;
                    }
                    else if (dueDay.getTime() === todayMidnight.getTime() && priority > 1) {
                        priority = 2;
                    }
                }
            });
        });
        return priorities[`${priority}`];
    };
    const toLabeledValue = (user) => ({
        key: user.fullName,
        value: user._id.toString(),
        label: user.fullName,
    });
    const extractUsersData = (users) => {
        return users.map((user) => {
            return {
                _id: user === null || user === void 0 ? void 0 : user._id.toString(),
                fullName: user === null || user === void 0 ? void 0 : user.fullName,
                roles: user === null || user === void 0 ? void 0 : user.roles,
            };
        });
    };
    const [loggedInUser] = extractUsersData([useGuardian().state.user]);
    const { techniciansList } = useTechniciansList();
    const techniciansSelectOptions = techniciansList === null || techniciansList === void 0 ? void 0 : techniciansList.map((tech) => toLabeledValue(tech));
    const [selectedTechnicians, setSelectedTechnicians] = useState(isTechnician(loggedInUser) &&
        !/jobEstimationId/.test(window.location.search)
        ? [loggedInUser]
        : []);
    React.useEffect(() => {
        if (selectedTechnicians.length > 0) {
            const token = window.localStorage.getItem("bluelibs-token");
            axios.request({
                method: "POST",
                url: global.NEW_API_URL + '/estimates/kanban?limit=100&offset=0&sortBy=_createdAt&sortOrder=-1',
                headers: {
                    "Authorization": token,
                },
                data: {
                    filters: {
                        assignedTo: [selectedTechnicians[0]._id],
                    },
                },
            }).then((response) => {
                const missions = response.data.results.filter((result) => result._status !== 'VALIDATED').reduce((acc, estimate) => {
                    var _a;
                    const mission = (_a = acc[estimate._mission]) !== null && _a !== void 0 ? _a : {
                        _id: estimate._mission,
                        reference: estimate.name.split('____')[1],
                        estimates: [],
                    };
                    mission.estimates.push({
                        ...estimate,
                        name: estimate.name.split('____')[0],
                    });
                    return {
                        ...acc,
                        [estimate._mission]: mission,
                    };
                }, {});
                const missionsPerStatus = {
                    TO_DO: [],
                    IN_PROGRESS: [],
                    DELIVERED: [],
                };
                Object.keys(missions).forEach((missionId) => {
                    const mission = missions[missionId];
                    let status = 'DELIVERED';
                    mission.estimates.forEach((estimate) => {
                        if (estimate._status === 'TO_DO') {
                            status = 'TO_DO';
                        }
                        else if (estimate._status === 'IN_PROGRESS' && status !== 'TO_DO') {
                            status = 'IN_PROGRESS';
                        }
                    });
                    missionsPerStatus[status].push(mission);
                });
                setColumns(missionsPerStatus);
            });
        }
    }, [selectedTechnicians]);
    const getUsersByIdFromUsersList = (usersIds, users) => {
        return usersIds.map((userId) => users.filter((tech) => tech._id === (userId === null || userId === void 0 ? void 0 : userId.toString()))[0]);
    };
    const onTechniciansSelect = useCallback((selectedTechniciansIds) => {
        setSelectedTechnicians(() => extractUsersData(getUsersByIdFromUsersList(Array.isArray(selectedTechniciansIds)
            ? selectedTechniciansIds
            : [selectedTechniciansIds], techniciansList)));
    }, [techniciansList]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mar-h-2 mar-b-1 flex wrap spaced gap-1", children: [(_jsx(Ant.Select, { showSearch: true, allowClear: true, className: "technician-select", style: { maxWidth: "285px", flexGrow: 1 }, filterOption: (input, option) => {
                            return new RegExp(input, "i").test(option.label);
                        }, placeholder: "S\u00E9lectionner un tech", 
                        // mode="multiple"
                        options: techniciansSelectOptions, defaultValue: isTechnician(loggedInUser) &&
                            !/jobEstimationId/.test(window.location.search) &&
                            toLabeledValue(loggedInUser).value, onChange: onTechniciansSelect })), _jsx(Ant.Button, { type: "primary", onClick: () => window.location.href = (Routes.JOB_ESTIMATIONS_LIST.path), icon: _jsx(SearchOutlined, {}), style: { maxWidth: "285px", flexGrow: 1 }, children: "Rechercher estimations" }, "1")] }), (_jsx(_Fragment, { children: _jsx(Kanban, { columns: [
                        {
                            id: "todo",
                            title: "A faire",
                            cards: columns.TO_DO,
                            headClassName: getColumnHeadClassNames(columns.TO_DO),
                        },
                        {
                            id: "wip",
                            title: "En cours",
                            cards: columns.IN_PROGRESS,
                            headClassName: getColumnHeadClassNames(columns.IN_PROGRESS),
                        },
                        {
                            id: "done",
                            title: "Livré",
                            cards: columns.DELIVERED,
                            headClassName: "fc-sc-green bg-sc-green-1",
                        },
                    ] }) }))] }));
});
