var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { InboxTaskEditForm as BaseInboxTaskEditForm } from "./InboxTaskEditForm.base";
import { useGuardian, useTranslate } from "@bluelibs/x-ui";
import { InboxTagsCollection, } from "@bundles/UIAppBundle/collections";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
let InboxTaskEditForm = class InboxTaskEditForm extends BaseInboxTaskEditForm {
    build() {
        super.build();
        const t = useTranslate();
        const guardian = useGuardian();
        const { user } = guardian.state;
        this.update("tagId", {
            label: "Tag",
            name: ["tagId"],
            initialValue: null,
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(this.UIComponents.RemoteSelect, { collectionClass: InboxTagsCollection, field: "name", required: true }) })),
        });
        // this.update("assignedToId", {
        //   initialValue: user._id,
        // });
        this.update("dueDate", {
            label: t("management.inbox_tasks.fields.dueDate"),
            name: ["dueDate"],
            required: true,
            initialValue: new Date(),
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(this.UIComponents.DatePicker, { required: true }) })),
        });
        this.update("clientRequestId", {
            label: "Mission",
            name: ["clientRequestId"],
            required: false,
            // initialValue: null,
            componentProps: {
                placeholder: "ID de la mission",
            },
            component: Ant.Input,
            // render: (props) => (
            //   <Ant.Form.Item {...props}>
            //     <this.UIComponents.RemoteSelect
            //       collectionClass={ClientRequestsCollection}
            //       field="reference"
            //     />
            //   </Ant.Form.Item>
            // ),
        });
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        const body = super.getRequestBody();
        body.tagId = 1;
        return body;
    }
    onCreateSubmit(document) {
        var _a, _b;
        const searchParams = new URLSearchParams(window.location.search);
        const userId = searchParams.get("userId");
        const clientRequestId = (_a = document.clientRequestId) !== null && _a !== void 0 ? _a : searchParams.get("clientRequestId");
        const promise = this.collection.insertOne({
            ...document,
            assignedToId: (_b = document.assignedToId) !== null && _b !== void 0 ? _b : (userId ? new ObjectId(userId) : null),
            clientRequestId: clientRequestId
                ? new ObjectId(clientRequestId)
                : undefined,
            priority: 0,
        });
        return promise;
    }
    onEditSubmit(_id, values) {
        const valuesTemp = values.clientRequestId
            ? { ...values, clientRequestId: new ObjectId(values.clientRequestId) }
            : values;
        const promise = this.collection.updateOne(_id, valuesTemp);
        return promise;
    }
};
InboxTaskEditForm = __decorate([
    Service({ transient: true })
], InboxTaskEditForm);
export { InboxTaskEditForm };
