import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, use, useDataOne, useTranslate, } from "@bluelibs/x-ui";
import { UserEditForm } from "../../config/UserEditForm";
import { features } from "../../config/features";
import { UsersCollection } from "@bundles/UIAppBundle/collections";
import { UserRoles } from "@root/api.types";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function UsersEdit(props) {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(UserEditForm, { transient: true });
    const router = useRouter();
    const collection = use(UsersCollection);
    const { data: document, isLoading, error, } = useDataOne(UsersCollection, new ObjectId(props.id), UserEditForm.getRequestBody());
    form.build();
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.users.edit.header"), onBack: () => window.history.back(), extra: features.view
                    ? [
                        _jsx(Link, { to: router.path(Routes.USERS_VIEW, {
                                params: { id: props.id },
                            }), children: _jsx(Ant.Button, { children: t("generics.view") }) }, "view"),
                    ]
                    : [] }), _jsxs(Ant.Card, { children: [isLoading && (_jsx(Ant.Space, { align: "center", children: _jsx(Ant.Spin, { size: "large" }) })), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" })), !isLoading && !error && (_jsxs(Ant.Form, { ...formLayout, requiredMark: "optional", initialValues: document, onFinish: (document) => form.onEditSubmit(props.id, document), children: [form.render(), _jsx(Ant.Form.Item, { ...formTailLayout, children: _jsx(Ant.Button, { type: "primary", htmlType: "submit", children: t("generics.submit") }) })] })), !isLoading && !!document && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mar-b-2", children: [_jsx("div", { children: "Permissions" }), _jsx("div", { children: _jsx(Ant.Select, { mode: "tags", placeholder: "Roles, Permissions", defaultValue: document.roles, className: "full-w", onSelect: (value) => {
                                                collection.addPermission(props.id, value.toString());
                                            }, onDeselect: (value) => {
                                                collection.removePermission(props.id, value.toString());
                                            }, children: Object.values(UserRoles).map((role) => (_jsx(Ant.Select.Option, { value: role, children: role }, role))) }) })] }), (document === null || document === void 0 ? void 0 : document.roles.includes(UserRoles.BOT)) && (_jsx(Ant.Button, { onClick: () => {
                                    collection.generateBotToken(props.id).then((token) => Ant.Modal.warning({
                                        title: "Token généré",
                                        width: 700,
                                        content: (_jsxs("div", { children: ["Header HTTP :", _jsx("br", {}), _jsx(Ant.Typography.Text, { copyable: true, code: true, children: "bluelibs-token" }), _jsx("br", {}), _jsx("br", {}), "Token :", _jsx("br", {}), _jsx(Ant.Typography.Text, { copyable: true, code: true, type: "warning", children: token })] })),
                                    }));
                                }, children: "G\u00E9n\u00E9rer un token de session" }))] }))] })] }));
}
