import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useState } from "react";
import { Button, Card, DatePicker } from "antd";
import { useTranslate } from "@bluelibs/x-ui";
import locale from "antd/es/date-picker/locale/fr_FR";
import PauseIcon from "@ant-design/icons/PauseOutlined";
import PlayIcon from "@ant-design/icons/PlaySquareOutlined";
const labelsStyle = { fontWeight: "bold" };
const itemStyle = { paddingBottom: 4 };
export const ClientRequestFollowUpCard = memo((props) => {
    const t = useTranslate();
    const [selectedDate, setSelectedDate] = useState(null);
    const nextFollowUpLabel = t(`management.client_requests.fields.followUp_enum.${props.clientRequest.nextFollowUp}`);
    const changeDate = useCallback((date) => {
        setSelectedDate(new Date(new Date(date).setHours(0, 0, 0, 0)));
    }, []);
    return (_jsx(_Fragment, { children: _jsx(Card, { size: "small", className: "follow-ups-card", children: props.clientRequest.nextFollowUpDate !== null ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col vgap-2", children: [_jsx("h3", { children: "Relances automatiques activ\u00E9es" }), _jsxs("p", { children: [_jsx("strong", { children: "Prochaine action: " }), `${nextFollowUpLabel}, le ${new Date(props.clientRequest.nextFollowUpDate).toLocaleDateString("fr-FR", {
                                        weekday: "long",
                                        month: "long",
                                        day: "numeric",
                                    })}.`] })] }), _jsxs(Button, { onClick: props.toggleFollowUps(), children: [_jsx(PauseIcon, {}), "Mettre en pause"] })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col vgap-2", children: [_jsx("h3", { children: "Relances automatiques en pause" }), _jsxs("p", { children: [_jsx("strong", { children: "Prochaine action: " }), nextFollowUpLabel] })] }), _jsxs("div", { className: "flex flex-col vgap-2", children: [_jsx(DatePicker, { locale: locale, placeholder: "Relancer le", onChange: changeDate }), _jsxs(Button, { onClick: props.toggleFollowUps(selectedDate), disabled: selectedDate === null, children: [_jsx(PlayIcon, {}), "R\u00E9-activer"] })] })] })) }) }));
});
