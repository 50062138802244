import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Layout } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useUIComponents } from "@bluelibs/x-ui";
import clsx from "clsx";
import axios from "axios";
import Modal from "../components/Modal/Modal";
import { components } from "@root/modal.config";
const { Header, Content, Sider } = Layout;
let firstWeirdOnCollapseCallAvoided = false;
const defaultCollapsed = window.localStorage.getItem("sideBarIsCollapsed") === "true";
const zeroWidthTriggerStyle = { display: "contents" };
const isPhoneMode = window.innerWidth < 570;
const reloadPage = (event) => {
    event.preventDefault();
    window.location.reload();
};
global.version = null;
export function AdminLayout(props) {
    const protect = props.protect === undefined ? true : props.protect;
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
    const Components = useUIComponents();
    const onCollapse = useCallback((collapsed) => {
        if (firstWeirdOnCollapseCallAvoided) {
            window.localStorage.setItem("sideBarIsCollapsed", collapsed);
            setIsCollapsed(collapsed);
        }
        firstWeirdOnCollapseCallAvoided = true;
    }, []);
    const [versionsMismatch, setVersionMismatch] = useState(false);
    // Periodically checks app version to let user know whenever a new one is available.
    useEffect(() => {
        const getVersion = () => axios
            .request({
            method: "GET",
            url: `${process.env.API_URL.replace("/graphql", "")}/version`,
        })
            .then((response) => {
            if (global.version === null) {
                const { log } = console;
                global.version = response.data.version;
                log("[CURRENT VERSION]", global.version);
            }
            else if (global.version !== response.data.version) {
                setVersionMismatch(true);
            }
        });
        getVersion();
        const interval = setInterval(() => {
            getVersion();
        }, 10 * 60 * 1000); // Every 10 min.
        return () => clearInterval(interval);
    }, []);
    const content = (_jsxs(_Fragment, { children: [_jsxs(Layout, { style: { minHeight: "100vh" }, className: "x-ui-admin", children: [versionsMismatch && (_jsxs("div", { className: "version-banner", children: ["Votre version de Belett n'est pas \u00E0 jour ! Certaines fonctionnalit\u00E9s risquent de ne pas marcher.", _jsx("br", {}), _jsx("a", { href: "#", onClick: reloadPage, children: "Cliquez ici pour mettre \u00E0 jour Belett" })] })), !window.iframeMode && _jsxs(Sider, { breakpoint: "md", collapsedWidth: isPhoneMode ? 0 : undefined, zeroWidthTriggerStyle: zeroWidthTriggerStyle, trigger: _jsx("div", { className: "logo sider-trigger", children: _jsx("img", { src: "/public/images/selfcity_logo.png", alt: "Selfcity" }) }), theme: "light", collapsed: isCollapsed, onCollapse: () => onCollapse(!isCollapsed), defaultCollapsed: defaultCollapsed, className: "x-ui-admin-sider", children: [_jsxs("div", { className: clsx("flex a-center", isCollapsed && "j-center"), children: [_jsx("div", { className: "logo", children: _jsx("img", { src: "/public/images/selfcity_logo.png", alt: "Selfcity" }) }), !isCollapsed && (_jsx("div", { className: "fw-bold fs-3 fc-primary f-spacing-4", children: "Belett" }))] }), _jsx("div", { className: "flex column spaced", children: _jsxs("div", { className: "x-ui-admin-sider-menu flex column spaced", children: [_jsx(Components.AdminMenu, {}), _jsx("div", { className: "flex column center", children: _jsx(Button, { type: "link", onClick: () => onCollapse(!isCollapsed), icon: isCollapsed ? _jsx(RightOutlined, {}) : _jsx(LeftOutlined, {}) }) })] }) })] }), _jsxs(Layout, { className: "x-ui-admin-layout", children: [!window.iframeMode &&
                                _jsx(Header, { className: "x-ui-admin-header", children: _jsx(Components.AdminTopHeader, {}) }), _jsx(Content, { className: "x-ui-admin-content mar-1", children: _jsx(Components.AdminContent, { children: props.children }) })] })] }), _jsx(Modal, { components: components })] }));
    if (protect) {
        return _jsx(Components.Protect, { children: content });
    }
    return content;
}
