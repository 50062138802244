import OnSiteJobModal from "@root/bundles/UIAppBundle/components/Modals/OnSiteJob";
import OnSiteJobCancellationModal from "@root/bundles/UIAppBundle/components/Modals/OnSiteJobCancellationModal";
import TechChoiceModal from "@root/bundles/UIAppBundle/components/Modals/TechChoiceModal/TechChoiceModal";
export const activationQueryParams = {
    jobEstimationId: {
        componentProps: {},
        component: "estimate",
    },
};
export const components = {
    onSiteJobCancellation: OnSiteJobCancellationModal,
    onSiteJob: OnSiteJobModal,
    techChoice: TechChoiceModal,
};
