import { InboxTagsList } from "../components/List/InboxTagsList";
import { InboxTagsCreate } from "../components/Create/InboxTagsCreate";
import { InboxTagsEdit } from "../components/Edit/InboxTagsEdit";
import { InboxTagsView } from "../components/View/InboxTagsView";
import { SettingFilled } from "@ant-design/icons";
export const INBOX_TAGS_LIST = {
    path: "/admin/inbox-tags",
    component: InboxTagsList,
    menu: {
        key: "INBOX_TAGS_LIST",
        label: "management.inbox_tags.menu.title",
        icon: SettingFilled,
    },
};
export const INBOX_TAGS_CREATE = {
    path: "/admin/inbox-tags/create",
    component: InboxTagsCreate,
};
export const INBOX_TAGS_EDIT = {
    path: "/admin/inbox-tags/:id/edit",
    component: InboxTagsEdit,
};
export const INBOX_TAGS_VIEW = {
    path: "/admin/inbox-tags/:id/view",
    component: InboxTagsView,
};
