import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from "react";
import { Card } from "antd";
import { ClientRequestPossibleStatus } from "@root/api.types";
import { useTranslate } from "@bluelibs/x-ui";
import { colors } from "@root/utils/colors";
const cardStyle = {
    backgroundColor: colors.selfcityBlue.opacity(0.1),
    borderColor: "transparent",
};
export const StatusCard = memo((props) => {
    const t = useTranslate();
    return (_jsx(_Fragment, { children: _jsxs(Card, { size: "small", className: "child-pad-1-i", style: cardStyle, children: [_jsx("span", { children: "La mission passe en " }), _jsx("span", { className: "fw-bold", children: t(`management.client_requests.fields.status_enum.${ClientRequestPossibleStatus[props.status]}`) })] }) }));
});
