import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { useGuardian, useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import * as Ant from "antd";
export function NotFound() {
    const guardian = useGuardian();
    const router = useRouter();
    const style = { minHeight: "100vh" };
    const { isLoggedIn } = guardian.state;
    return (_jsx(Ant.Row, { justify: "center", align: "middle", style: style, children: _jsx(Ant.Col, { sm: 24, md: 16, lg: 8, children: _jsxs(Ant.Card, { title: "404 Error", children: [_jsx("p", { children: "The route you tried to access could not be found." }), _jsx("p", { children: _jsxs(Ant.Space, { children: [isLoggedIn && (_jsx(Link, { to: router.path(Routes.HOME), children: _jsx(Ant.Button, { children: "Dashboard" }) })), !isLoggedIn && (_jsx(Link, { to: router.path(Routes.LOGIN), children: _jsx(Ant.Button, { children: "Login" }) }))] }) })] }) }) }));
}
