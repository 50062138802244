import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import React, { memo } from "react";
import * as Ant from "antd";
import { TitledCard } from "../TitledCard/TitledCard.index";
import { CardEmpty } from "../CardEmpty/CardEmpty.index";
import { useTranslate } from "@bluelibs/x-ui";
import { PackageSumup } from "../JobEstimations/JobEstimationSumup/PackageSumup.index";
export const ClientRequestPackagesCard = memo((props) => {
    const t = useTranslate();
    return (_jsx(TitledCard, { title: 'Forfait(s)', loading: false, children: (_jsx("div", { style: { overflow: "visible" }, children: props.packages && props.packages.length > 0 ? (props.packages.map((pack, i) => (_jsxs(React.Fragment, { children: [i > 0 && _jsx(Ant.Divider, { className: "mar-v-1" }), _jsx(PackageSumup, { package: pack, onReload: props.onReload }, pack._id)] }, pack._id)))) : (_jsx(CardEmpty, { description: 'Aucun forfait.' })) })) }));
});
