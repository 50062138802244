export const phrasesFr = {
    fr: {
        management: {
            job_estimations: {
                fields: {
                    _id: "ID",
                    isUntouchedPackagedOffer: "Est un forfait intacte",
                    title: "Titre",
                    isDeprecated: "Obsolète",
                    dueDate: "Date limite pour le faire",
                    status: "Statut",
                    status_enum: {
                        ['TODO']: "à Faire",
                        ['WIP']: "en cours",
                        ['DELIVERED']: "Livré",
                        ['TOFIX']: "à Réparer",
                        ['VALIDATED']: "Validé",
                    },
                    fixedPrice: "Prix fixe",
                    totalPriceET: "Prix HT",
                    totalConsumablesPriceET: "Prix total des consommables HT",
                    totalTasksPriceET: "Prix total des tâches HT",
                    totalConsumablesMargin: "Marge Totale des Consommables",
                    totalTasksNbrHours: "Heures de main d’oeuvre",
                    requiresTruck: " Une livraison en camionnette est nécessaire ?",
                    isDeleted: "supprimé",
                    isDeleted_description: "Ce champ est utilisé pour identifier si cet objet a été supprimé en douceur",
                    createdAt: "Créée le",
                    createdAt_description: "Représente la date à laquelle cette estimation a été créé",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Représente la dernière fois que cette estimation a été mis à jour",
                    clientRequestId: "ID Mission",
                    assignedToId: "attribué à ID",
                    requiredToolsIds: "ID des outils requis",
                    builtFromModelId: "construit à partir du modèle ID",
                    createdById: "créé par ID",
                    createdById_description: "Représente l'identifiant de l'utilisateur qui a créé cette estimation",
                    updatedById: "Modifié par ID",
                    updatedById_description: "Représente l'identifiant de l'utilisateur qui a fait la dernière mise à jour sur cette estimation",
                    clientRequest: "Mission",
                    assignedTo: "Assigné à",
                    requiredTools: "Outils nécessaires",
                    requiredConsumables: "Consommables",
                    requiredTasks: "Tâches",
                    builtFromModel: "Construit à partir du modèle",
                    comments: "Commentaires",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé cette estimation",
                    updatedBy: "Mis à jour par",
                    updatedBy_description: "Représente l'utilisateur qui a fait la dernière mise à jour sur cette estimation",
                    notes: "Notes techniques (visible par les techniciens & magasinier)",
                },
                menu: {
                    title: "Rechercher estimations",
                    kanban: "Estimations",
                },
                list: {
                    header: "Estimations",
                    create_btn: "Nouvelle Estimation",
                },
                create: {
                    header: "Créer une Estimation",
                },
                edit: {
                    header: "Modifier l'estimation",
                },
                view: {
                    header: "Afficher l'estimation",
                },
                delete_confirmation: "Vous avez supprimé l'estimation",
                create_confirmation: "Vous avez ajouté avec succès une nouvelle estimation",
                edit_confirmation: "Vous avez modifié avec succès l'estimation",
                card: {
                    title: "Estimation(s)",
                    empty: "Aucune estimation à ce jours.",
                },
                actions: {
                    addConsumable: "Ajouter un consomable",
                    addEmptyConsumable: "Ajouter un consommable",
                    importConsumable: "Ref (Tereva) ou URL (Sider, Cédéo, Dispart, Plateforme, Hammel, Clim+)",
                    addEmptyTask: "Ajouter une tâche",
                    assignTo: "Assigner à un technicien",
                    select: "Importer un modèle d'estimation",
                    duplicate: "Dupliquer",
                    add: "Ajouter une estimation vide",
                    edit: "Modifier cette estimation",
                    delete: "Supprimer cette estimation",
                },
            },
        },
    },
};
