var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ProfileList = class ProfileList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isACompany",
                title: t("management.profiles.fields.isACompany"),
                key: "management.profiles.fields.isACompany",
                dataIndex: ["isACompany"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "companyName",
                title: t("management.profiles.fields.companyName"),
                key: "management.profiles.fields.companyName",
                dataIndex: ["companyName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "gender",
                title: t("management.profiles.fields.gender"),
                key: "management.profiles.fields.gender",
                dataIndex: ["gender"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "firstName",
                title: t("management.profiles.fields.firstName"),
                key: "management.profiles.fields.firstName",
                dataIndex: ["firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "lastName",
                title: t("management.profiles.fields.lastName"),
                key: "management.profiles.fields.lastName",
                dataIndex: ["lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "phones",
                title: t("management.profiles.fields.phones"),
                key: "management.profiles.fields.phones",
                dataIndex: ["phones"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "phone",
                title: t("management.profiles.fields.phone"),
                key: "management.profiles.fields.phone",
                dataIndex: ["phone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "emails",
                title: t("management.profiles.fields.emails"),
                key: "management.profiles.fields.emails",
                dataIndex: ["emails"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "email",
                title: t("management.profiles.fields.email"),
                key: "management.profiles.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "notes",
                title: t("management.profiles.fields.notes"),
                key: "management.profiles.fields.notes",
                dataIndex: ["notes"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "addresses",
                title: t("management.profiles.fields.addresses"),
                key: "management.profiles.fields.addresses",
                dataIndex: ["addresses"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "address.streetOne",
                title: t("management.profiles.fields.address.streetOne"),
                key: "management.profiles.fields.address.streetOne",
                dataIndex: ["address", "streetOne"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.streetTwo",
                title: t("management.profiles.fields.address.streetTwo"),
                key: "management.profiles.fields.address.streetTwo",
                dataIndex: ["address", "streetTwo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.zip",
                title: t("management.profiles.fields.address.zip"),
                key: "management.profiles.fields.address.zip",
                dataIndex: ["address", "zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.town",
                title: t("management.profiles.fields.address.town"),
                key: "management.profiles.fields.address.town",
                dataIndex: ["address", "town"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.country",
                title: t("management.profiles.fields.address.country"),
                key: "management.profiles.fields.address.country",
                dataIndex: ["address", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.geopoint",
                title: t("management.profiles.fields.address.geopoint"),
                key: "management.profiles.fields.address.geopoint",
                dataIndex: ["address", "geopoint"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "address.formattedAddress",
                title: t("management.profiles.fields.address.formattedAddress"),
                key: "management.profiles.fields.address.formattedAddress",
                dataIndex: ["address", "formattedAddress"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "iptCustomerId",
                title: t("management.profiles.fields.iptCustomerId"),
                key: "management.profiles.fields.iptCustomerId",
                dataIndex: ["iptCustomerId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.profiles.fields.createdAt"),
                key: "management.profiles.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.profiles.fields.updatedAt"),
                key: "management.profiles.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "agency",
                title: t("management.profiles.fields.agency"),
                key: "management.profiles.fields.agency",
                dataIndex: ["agency"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.AGENCIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "clientRequestRelatedContacts",
                title: t("management.profiles.fields.clientRequestRelatedContacts"),
                key: "management.profiles.fields.clientRequestRelatedContacts",
                dataIndex: ["clientRequestRelatedContacts"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.CLIENT_REQUEST_RELATED_CONTACTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                title: t("management.profiles.fields.createdBy"),
                key: "management.profiles.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                title: t("management.profiles.fields.updatedBy"),
                key: "management.profiles.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "user",
                title: t("management.profiles.fields.user"),
                key: "management.profiles.fields.user",
                dataIndex: ["user"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            agency: "agency.name",
            clientRequestRelatedContacts: "clientRequestRelatedContacts._id",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
            user: "user.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            isACompany: 1,
            companyName: 1,
            gender: 1,
            firstName: 1,
            lastName: 1,
            phones: 1,
            phone: 1,
            emails: 1,
            email: 1,
            notes: 1,
            addresses: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            address: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            iptCustomerId: 1,
            createdAt: 1,
            updatedAt: 1,
            agency: {
                _id: 1,
                name: 1,
            },
            agencyId: 1,
            providerId: 1,
            clientRequestRelatedContacts: {
                _id: 1,
            },
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
            user: {
                _id: 1,
                fullName: 1,
            },
            userId: 1,
        };
    }
};
ProfileList = __decorate([
    Service({ transient: true })
], ProfileList);
export { ProfileList };
