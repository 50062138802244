import { XRouter, XUIBundle } from "@bluelibs/x-ui";
import * as Routes from "./routes";
import { Bundle } from "@bluelibs/core";
import { AppGuardian } from "./services/AppGuardian";
import { i18n } from "./i18n";
import * as ComponentOverrides from "./overrides";
export class UIAppBundle extends Bundle {
    //   async prepare() {}
    async init() {
        // All routes are added via the routing service
        const router = this.container.get(XRouter);
        const xui = this.container.get(XUIBundle);
        router.add(Routes);
        xui.updateComponents(ComponentOverrides);
        xui.setGuardianClass(AppGuardian);
        xui.storeI18N(i18n);
        // const i18nService = this.container.get(I18NService);
        // i18nService.setLocale(getLocaleLang());
    }
}
