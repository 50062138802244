import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { ALL, useTagsFilterHooks } from "./TagsFilter.hooks";
global.propsList = [];
export const TagsFilter = memo(
// ! WE NEED THE DXISABLE NEXT LINE TO PREVENT THE TRALING TYPE PARAM COMA TO BE REMOVED
// eslint-disable-next-line
(props) => {
    global.propsList.push(props);
    const { t, tags, counts, selectedParentStatusTag, selectedChildStatus, setSelectedChildStatus, onParentTagClick, } = useTagsFilterHooks(props);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "mar-t-2 mar-b-1", children: tags.map((tag) => {
                    var _a;
                    let parentCount = counts[tag.title] || 0;
                    // For filters groups, we sum counts of all their children to get the accurate total.
                    if (((_a = tag.values) === null || _a === void 0 ? void 0 : _a.length) > 1) {
                        parentCount = tag.values.reduce((total, filterName) => total + (counts[filterName] || 0), 0);
                    }
                    const countLabel = counts !== undefined ? ` (${parentCount})` : "";
                    return (_jsx(Ant.Tag.CheckableTag, { checked: selectedParentStatusTag.title === tag.title, onClick: () => {
                            if (props.onChange) {
                                if (tag.title === ALL) {
                                    props.onChange(undefined);
                                }
                                else {
                                    props.onChange(tag.values, tag.title);
                                }
                            }
                            onParentTagClick(tag);
                        }, children: t(`management.client_requests.fields.status_tags_titles_enum.${tag.title}`) + countLabel }, tag.title));
                }) }), !!(selectedParentStatusTag === null || selectedParentStatusTag === void 0 ? void 0 : selectedParentStatusTag.values) &&
                selectedParentStatusTag.values.length > 1 && (_jsx("div", { className: "mar-t-1 mar-b-2", children: [ALL, ...selectedParentStatusTag.values].map((status) => {
                    const tagCount = status === "ALL"
                        ? selectedParentStatusTag.values.reduce((total, filterName) => total + (counts[filterName] || 0), 0)
                        : counts[status] || 0;
                    const countLabel = counts !== undefined ? ` (${tagCount})` : "";
                    return (_jsx(Ant.Tag.CheckableTag, { checked: selectedChildStatus === status, onClick: () => {
                            if (props.onChange) {
                                if (status === ALL) {
                                    props.onChange(selectedParentStatusTag.values, selectedParentStatusTag.title);
                                }
                                else {
                                    props.onChange([status], selectedParentStatusTag.title);
                                }
                            }
                            setSelectedChildStatus(status);
                        }, children: t(`management.client_requests.fields.status_enum.${status}`) + countLabel }, status));
                }) }))] }));
});
