const getValue = (row, fieldName) => {
    let value = row;
    fieldName.split(".").forEach((field) => {
        if (value) {
            value = value[field];
        }
    });
    return value;
};
const formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
/**
 * Initializes the translation function from given labels.
 *
 * @param {boolean} [quietMode = false] Whether to display a warning when a label is not found.
 *
 * @param {Labels} labels Set of labels for translation.
 *
 * @returns {Translate} The actual translation function,
 * accepting the following parameters:
 *  - `label`, the label to translate
 *  - `values`, the variables `key`- `value` mapping for replacement
 */
function i18n(labels, quietMode = false) {
    return function translate(label, values = {}, type) {
        const { warn } = console;
        if (typeof label === "number" && type === "currency") {
            return formatter.format(label);
        }
        let translation = getValue(labels, label);
        if (typeof translation !== "string" && quietMode === false) {
            warn(`Missing translation for label: "${label}".`);
            return label;
        }
        Object.keys(values).forEach((key) => {
            translation = translation.replace(new RegExp(`{{${key}}}`, "g"), values[key]);
        });
        return translation;
    };
}
export default i18n({
    ENUMS: {
        "SUBJECTS": {
            "62d696333f450a51d089e66c": "Évacuation",
            "62d696333f450a51d089e66f": "Colonne de douche",
            "62d696333f450a51d089e670": "Mitigeur / Robinet évier",
            "62d696333f450a51d089e672": "Mitigeur / Robinet Bain / Douche",
            "62d696333f450a51d089e673": "Mitigeur / Robinet Lavabo",
            "62d696333f450a51d089e674": "Mitigeur / Robinet Général / Extérieur",
            "62d696333f450a51d089e675": "WC",
            "62d696333f450a51d089e676": "Abattant WC",
            "62d696333f450a51d089e678": "Débouchage",
            "62d696333f450a51d089e67b": "WC SUSPENDU mécanisme chasse d'eau",
            "62d696333f450a51d089e67d": "WC CLASSIQUE mécanisme chasse d'eau",
            "62d696333f450a51d089e67e": "Compteur d'eau",
            "62d696333f450a51d089e67f": "Réducteur de pression",
            "62d696333f450a51d089e680": "Réfection joints silicone",
            "62d6979147a9d4422979632e": "Jardin / Arrosage / Extérieur",
            "62d696333f450a51d089e682": "Évier",
            "62d696333f450a51d089e683": "Bidet",
            "62d696333f450a51d089e685": "Baignoire",
            "62d696333f450a51d089e686": "Douche",
            "62d696333f450a51d089e687": "Machine (à laver/vaisselle)",
            "62d696333f450a51d089e689": "Recherche de fuite avec rapport",
            "62d696333f450a51d089e68a": "Circuits d'eau sanitaire Ch/Fr",
            "62d696333f450a51d089e68b": "BEC / Cumulus elec",
            "62d6979147a9d44229796328": "Thermostat",
            "62d696333f450a51d089e68f": "Radiateur à eau",
            "62d696333f450a51d089e690": "Circuits de chauffage",
            "62d6979147a9d4422979632c": "Circuits gaz",
            "62d696333f450a51d089e692": "Chaudière",
            "62d696333f450a51d089e695": "Chauffe Bain / Cumulus gaz",
            "62d696333f450a51d089e696": "Clim",
            "62d696333f450a51d089e69a": "Trappe de visite",
            "62d696333f450a51d089e684": "Lavabo",
            "62d696333f450a51d089e69c": "Douche / Paroie / Bac",
            "62d696333f450a51d089e69d": "Tablier / habillage baignoire",
            "62d696333f450a51d089e69e": "Placo",
            "62d696333f450a51d089e6a0": "Carrelage",
            "62d6979147a9d44229796327": "Faïence",
            "62d6979147a9d4422979632d": "Plomberie",
            "62d696333f450a51d089e6a2": "Lino",
            "62d696333f450a51d089e6a3": "Parquet",
            "62d696333f450a51d089e6a4": "Peinture",
            "62d696333f450a51d089e6a7": "Chantier (Démolition préparation et débarassage)",
            "62d696333f450a51d089e6a8": "Meubles SdB / Cuisine",
            "62d696333f450a51d089e6aa": "WC suspendu",
            "62d6979147a9d44229796324": "Salle de bain",
            "62d6979147a9d44229796325": "Cuisine",
            "62d696333f450a51d089e6ab": "Boîte aux lettres",
            "62d696333f450a51d089e6ad": "Porte claquée",
            "62d696333f450a51d089e6ae": "Porte (clé cassée / perdue)",
            "62d696333f450a51d089e6b0": "Box et Cave",
            "62d696333f450a51d089e6b2": "Cylindre",
            "62d696333f450a51d089e6b4": "Verrou bouton clé plate",
            "62d696333f450a51d089e6b5": "Verrou bouton clef sécurité",
            "62d696333f450a51d089e6b6": "Coffre de porte",
            "62d696333f450a51d089e6b7": "Coffre multipoint en applique",
            "62d696333f450a51d089e6b8": "Poignée de porte (de simple à blindée)",
            "62d696333f450a51d089e6b9": "Cylindre de boîte aux lettre",
            "62d696333f450a51d089e6ba": "Juda",
            "62d696333f450a51d089e6bb": "Porte",
            "62d696333f450a51d089e6bd": "Gouttière",
            "62d696333f450a51d089e6bf": "Volet",
            "62d696333f450a51d089e6c0": "Plaque / Gazinière",
            "62d696333f450a51d089e6c1": "Portes de placard",
            "62d696333f450a51d089e6c3": "Multiservice",
            "62d696333f450a51d089e6c4": "VMC",
            "62d696333f450a51d089e6c6": "Radiateur électrique",
            "62d696333f450a51d089e6c7": "Seche serviette",
            "62d696333f450a51d089e6c8": "Interrupteurs prises et éclairages",
            "62d696333f450a51d089e6c9": "Tableau electrique",
        },
        REQUESTTYPES: {
            "62d696333f450a51d089e66d": "Dépannage / Fuite / Changement pièce",
            "62d696333f450a51d089e66e": "Remplacement / Installation / Dépose",
            "62d696333f450a51d089e677": "Remplacement",
            "62d696333f450a51d089e679": "WC",
            "62d696333f450a51d089e67a": "Autres",
            "62d696333f450a51d089e67c": "Dépannage / Fuite / Remplacement",
            "62d696333f450a51d089e681": "Divers",
            "62d696333f450a51d089e688": "Remplacement / Installation alimentation et évacuation",
            "62d696333f450a51d089e68c": "Dépannage / Diagnostic",
            "62d696333f450a51d089e68d": "Groupe de sécurité",
            "62d696333f450a51d089e68e": "Contacteur jour/nuit",
            "62d6979147a9d44229796329": "Dépannage / Diagnostic / Changement pièce",
            "62d6979147a9d4422979632a": "Remplacement / Installation / Dépose",
            "62d696333f450a51d089e691": "Désembouage",
            "62d696333f450a51d089e693": "Entretien",
            "62d6979147a9d44229796360": "Dépannage / Diagnostic",
            "62d6979147a9d4422979632b": "Changement / Remplacement d'une pièce",
            "62d696333f450a51d089e694": "Remplacement / Installation / Dépose",
            "62d696333f450a51d089e697": "Installation / Remplacement système complet",
            "62d696333f450a51d089e698": "Installation / Remplacement d'un SPLIT",
            "62d696333f450a51d089e699": "Recherche de fuite",
            "62d696333f450a51d089e69b": "Dépannage / Remplacement / Installation / Dépose",
            "62d696333f450a51d089e69f": "Réparation / Installation / Dépose",
            "62d696333f450a51d089e6a1": "Reprise / Remplacement / Installation / Dépose",
            "62d696333f450a51d089e6a5": "Surface complète",
            "62d696333f450a51d089e6a9": "Installation / Dépose",
            "62d6979147a9d44229796326": "Rénovation complète",
            "62d696333f450a51d089e6ac": "Ouverture",
            "62d696333f450a51d089e6af": "cylindre classique / Ouverture",
            "62d696333f450a51d089e6b1": "cylindre sécurité / Ouverture",
            "62d696333f450a51d089e6b3": "Remplacement / Installation",
            "62d696333f450a51d089e6bc": "Sécurisation",
            "62d696333f450a51d089e6be": "Réparation / Entretien",
            "62d696333f450a51d089e6c2": "Retouche",
            "62d696333f450a51d089e6c5": "Entretien / Nettoyage",
        },
        ACTIVITIES: {
            MECHANICAL_VENTILATION: "VMC / VMI",
            PLUMBING: "Plomberie",
            MULTISERVICE: "Multiservice",
            LOCKSMITHING: "Serrurerie",
            ELECTRICITY: "Électricité",
            RENOVATION: "Rénovation",
            HEATING: "Chauffage",
            AIR_CONDITIONING: "Climatisation",
            HEAT_PUMP: "Pompe à chaleur",
        },
    },
    PAGES: {
        CLIENTREQUESTS: {
            VIEW: {
                SUMMARY: "Résumé de la mission",
                NO_DESCRIPTION: "Aucune description.",
                TIMELINE_CTA: "Voir CR & Photos",
                ESTIMATES_CTA: "Voir les estimations",
                SUPPLIES: {
                    TITLE: "Matériel",
                    QUANTITY: "x{{quantity}}",
                    LINK: "Lien",
                },
                TASKS: {
                    TITLE: "Tâches",
                    HOURS: "{{nbHours}}h",
                },
                TRUCK: {
                    TITLE: "Livraison nécessaire",
                },
                NOTES: {
                    TITLE: "Notes techniques",
                },
                TOOLS: {
                    TITLE: "Outils",
                },
            },
        },
        ESTIMATES: {
            EDIT: {
                FORM_TITLE: "Estimation",
                DESCRIPTION_TITLE: "Résumé de la mission",
                NAME: {
                    LABEL: "Nom de l'estimation",
                    REQUIRED: "Ce champ est requis",
                },
                ESTIMATETEMPLATES: {
                    LABEL: "Importer un modèle d'estimation",
                    PLACEHOLDER: "Entretien chaudière, WC, ...",
                },
                TASKS: {
                    LABEL: "Tâches",
                    ADD: "Ajouter une tâche",
                    SIDE_JOB: "Aide chantier",
                    ADD_SIDE_JOB: "Ajouter une aide chantier",
                    TITLE: {
                        LABEL: "Nom de la tâche",
                        REQUIRED: "Ce champ est requis",
                    },
                    HOURLYRATE: {
                        LABEL: "€ HT / heure",
                        REQUIRED: "Ce champ est requis",
                    },
                    HOURS: {
                        LABEL: "Heure(s)",
                        REQUIRED: "Ce champ est requis",
                        BELOW_MINIMUM: "Doit être positif",
                    },
                    DESCRIPTION: {
                        PLACEHOLDER: "Description",
                        REQUIRED: "Ce champ est requis",
                    },
                },
                SUPPLIES: {
                    LABEL: "Matériel",
                    ADD: "Ajouter du matériel",
                    DUPLICATE: "Ce matériel est en double",
                    PLACEHOLDER: "Ref (Tereva, Hammel) ou URL (Sider, Cédéo, Dispart, Plateforme, Clim+)",
                    NAME: {
                        REQUIRED: "Ce champ est requis",
                        PLACEHOLDER: "Nom de l'article",
                    },
                    QUANTITY: {
                        LABEL: "Qté",
                        REQUIRED: "Ce champ est requis",
                        BELOW_MINIMUM: "Doit être positive",
                    },
                    UNITPRICE: {
                        LABEL: "€ HT / unité",
                        REQUIRED: "Ce champ est requis",
                        BELOW_MINIMUM: "Doit être positif",
                    },
                    MARGIN: {
                        LABEL: "% Marge",
                        REQUIRED: "Ce champ est requis",
                        BELOW_MINIMUM: "Doit être positive",
                    },
                    MARGINIT: {
                        LABEL: "TTC margé",
                    },
                    URL: {
                        REQUIRED: "Ce champ est requis",
                        PLACEHOLDER: "Lien de l'article",
                    },
                    REFERENCE: {
                        LABEL: "Réf.",
                        REQUIRED: "Ce champ est requis",
                        PLACEHOLDER: "Référence de l'article",
                    },
                    PROVIDER: {
                        PLACEHOLDER: "Fournisseur",
                        REQUIRED: "Ce champ est requis",
                    },
                },
                NOTES: {
                    LABEL: "Notes techniques (visibles par les techniciens & magasiniers)",
                    PLACEHOLDER: "Ajouter des notes => Détails du petit consommables (2 Coude PVC 45° D32)  - OU -  Penser à prendre l'échelle",
                },
                TOOLS: {
                    LABEL: "Outils nécessaires",
                },
                REQUIRESTRUCK: {
                    LABEL: "Une livraison en camionnette est nécessaire ?",
                },
                STATUS: {
                    CANCEL: "Annuler",
                    SAVE: "Enregistrer",
                    VALIDATE: "Valider",
                    CONFIRM: "Confirmer",
                    VALIDATED: "L'estimation a été validée",
                    DELIVERED: "L'estimation a été livrée",
                    SAVE_AND_DELIVER: "Enregistrer et Livrer",
                    REQUEST_FIX: "Demander une correction au technicien",
                    REQUEST_FIX_COMMENT: "Commentaire à envoyer au technicien (slack)",
                    SEND_REQUEST_FIX: "Envoyer la demande",
                },
                SUMMARY: {
                    MARGIN_PRICE: "*Marge :* {{totalMarginET}} HT",
                    SUPPLIES_PRICE: "*Prix Client :* {{totalSuppliesPriceET}} HT",
                    TASKS_PRICE: "*Prix Client :* {{totalTasksPriceET}} HT",
                    DURATION: "*Durée :* {{totalMainHours}}h _(+{{totalSideHours}}h)_",
                    TOTAL_MARGIN_PRICE: "*Marge Totale :* {{totalMarginET}} HT",
                    TOTAL_PRICE: "*Prix Total Client :* {{totalPriceET}} HT",
                },
                IMAGES: {
                    LABEL: "Photo(s) à joindre au devis",
                    TITLE: "Nom de l'image (obligatoire)",
                    IMPORT: "Importer une image",
                    PREVIEW: "Voir",
                    DELETE: "Supprimer",
                },
            },
        },
    },
});
