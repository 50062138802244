var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { AgenciesTagsCollection, } from "@bundles/UIAppBundle/collections";
let AgenciesTagCreateForm = class AgenciesTagCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "tag",
                label: t("management.agencies_tags.fields.tag"),
                name: ["tag"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.agencies_tags.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
            {
                id: "activity",
                label: t("management.agencies_tags.fields.activity"),
                name: ["activity"],
                tooltip: t("management.agencies_tags.fields.activity_description"),
                initialValue: "PLUMBING",
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { allowClear: true, placeholder: t("management.agencies_tags.fields.activity"), children: [_jsx(Ant.Select.Option, { value: "MECHANICAL_VENTILATION", children: "Mechanical Ventilation" }, "MECHANICAL_VENTILATION"), _jsx(Ant.Select.Option, { value: "PLUMBING", children: "Plumbing" }, "PLUMBING"), _jsx(Ant.Select.Option, { value: "RENOVATION", children: "Renovation" }, "RENOVATION"), _jsx(Ant.Select.Option, { value: "HEATING", children: "Heating" }, "HEATING"), _jsx(Ant.Select.Option, { value: "AIR_CONDITIONING", children: "Air Conditioning" }, "AIR_CONDITIONING"), _jsx(Ant.Select.Option, { value: "MULTISERVICE", children: "Multiservice" }, "MULTISERVICE"), _jsx(Ant.Select.Option, { value: "LOCKSMITHING", children: "Locksmithing" }, "LOCKSMITHING"), _jsx(Ant.Select.Option, { value: "ELECTRICAL", children: "Electrical" }, "ELECTRICAL")] }) })),
            },
            {
                id: "color",
                label: t("management.agencies_tags.fields.color"),
                name: ["color"],
                initialValue: "transparent",
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { allowClear: true, placeholder: t("management.agencies_tags.fields.color"), children: [_jsx(Ant.Select.Option, { value: "selfcityGreen", children: "Selfcitygreen" }, "selfcityGreen"), _jsx(Ant.Select.Option, { value: "selfcityRed", children: "Selfcityred" }, "selfcityRed"), _jsx(Ant.Select.Option, { value: "selfcityYellow", children: "Selfcityyellow" }, "selfcityYellow"), _jsx(Ant.Select.Option, { value: "selfcityBlue", children: "Selfcityblue" }, "selfcityBlue"), _jsx(Ant.Select.Option, { value: "selfcityEggplant", children: "Selfcityeggplant" }, "selfcityEggplant"), _jsx(Ant.Select.Option, { value: "transparent", children: "Transparent" }, "transparent")] }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.agencies_tags.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.AGENCIES_TAGS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.AGENCIES_TAGS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.AGENCIES_TAGS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => AgenciesTagsCollection),
    __metadata("design:type", typeof (_a = typeof AgenciesTagsCollection !== "undefined" && AgenciesTagsCollection) === "function" ? _a : Object)
], AgenciesTagCreateForm.prototype, "collection", void 0);
AgenciesTagCreateForm = __decorate([
    Service({ transient: true })
], AgenciesTagCreateForm);
export { AgenciesTagCreateForm };
