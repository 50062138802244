var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { AgenciesTagsCollection, AgenciesCollection, } from "@bundles/UIAppBundle/collections";
let AgencyEditForm = class AgencyEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "addresses",
                label: t("management.agencies.fields.addresses"),
                name: ["addresses"],
                isList: true,
                nest: [
                    {
                        id: "streetOne",
                        label: t("management.agencies.fields.addresses.streetOne"),
                        name: ["addresses", "streetOne"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "streetTwo",
                        label: t("management.agencies.fields.addresses.streetTwo"),
                        name: ["addresses", "streetTwo"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.agencies.fields.addresses.zip"),
                        name: ["addresses", "zip"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.agencies.fields.addresses.town"),
                        name: ["addresses", "town"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.agencies.fields.addresses.country"),
                        name: ["addresses", "country"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "geopoint",
                        label: t("management.agencies.fields.addresses.geopoint"),
                        name: ["addresses", "geopoint"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "formattedAddress",
                        label: t("management.agencies.fields.addresses.formattedAddress"),
                        name: ["addresses", "formattedAddress"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                ],
                initialValue: null,
            },
            {
                id: "name",
                label: t("management.agencies.fields.name"),
                name: ["name"],
                required: true,
                tooltip: t("management.agencies.fields.name_description"),
                component: Ant.Input,
            },
            {
                id: "siret",
                label: t("management.agencies.fields.siret"),
                name: ["siret"],
                tooltip: t("management.agencies.fields.siret_description"),
                initialValue: "",
                component: Ant.Input,
            },
            {
                id: "vatNumber",
                label: t("management.agencies.fields.vatNumber"),
                name: ["vatNumber"],
                tooltip: t("management.agencies.fields.vatNumber_description"),
                initialValue: "",
                component: Ant.Input,
            },
            {
                id: "email",
                label: t("management.agencies.fields.email"),
                name: ["email"],
                required: true,
                tooltip: t("management.agencies.fields.email_description"),
                component: Ant.Input,
            },
            {
                id: "address",
                label: t("management.agencies.fields.address"),
                name: ["address"],
                nest: [
                    {
                        id: "streetOne",
                        label: t("management.agencies.fields.address.streetOne"),
                        name: ["address", "streetOne"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "streetTwo",
                        label: t("management.agencies.fields.address.streetTwo"),
                        name: ["address", "streetTwo"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.agencies.fields.address.zip"),
                        name: ["address", "zip"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.agencies.fields.address.town"),
                        name: ["address", "town"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.agencies.fields.address.country"),
                        name: ["address", "country"],
                        required: true,
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "geopoint",
                        label: t("management.agencies.fields.address.geopoint"),
                        name: ["address", "geopoint"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                    {
                        id: "formattedAddress",
                        label: t("management.agencies.fields.address.formattedAddress"),
                        name: ["address", "formattedAddress"],
                        initialValue: null,
                        component: Ant.Input,
                    },
                ],
                initialValue: null,
            },
            {
                id: "phone",
                label: t("management.agencies.fields.phone"),
                name: ["phone"],
                tooltip: t("management.agencies.fields.phone_description"),
                component: Ant.Input,
            },
            {
                id: "iptCustomerId",
                label: t("management.agencies.fields.iptCustomerId"),
                name: ["iptCustomerId"],
                tooltip: t("management.agencies.fields.iptCustomerId_description"),
                component: Ant.InputNumber,
            },
            {
                id: "notes",
                label: t("management.agencies.fields.notes"),
                name: ["notes"],
                component: Ant.Input,
            },
            {
                id: "agenciesTagId",
                label: t("management.agencies.fields.agenciesTag"),
                name: ["agenciesTagId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: AgenciesTagsCollection, field: "tag", required: false }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            addresses: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            name: 1,
            siret: 1,
            vatNumber: 1,
            email: 1,
            address: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
            phone: 1,
            iptCustomerId: 1,
            notes: 1,
            agenciesTag: {
                _id: 1,
                tag: 1,
            },
            agenciesTagId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.agencies.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => AgenciesCollection),
    __metadata("design:type", typeof (_a = typeof AgenciesCollection !== "undefined" && AgenciesCollection) === "function" ? _a : Object)
], AgencyEditForm.prototype, "collection", void 0);
AgencyEditForm = __decorate([
    Service({ transient: true })
], AgencyEditForm);
export { AgencyEditForm };
