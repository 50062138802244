import { jsx as _jsx } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import React, { memo, useMemo } from "react";
import * as Ant from "antd";
// const values = Object.values(InvoicePaymentMethod);
const labels = {
    EFT: 'Virement',
    CHECK: 'Chèque',
    CASH: 'Espèces',
    PAYPAL: 'Paypal',
    PLEDG: 'Pledg',
    WIDR_PAY: 'Recouvrement (WidrPay)',
};
export const PaymentMethodSelector = memo((props) => {
    // ? if props.withFormItem
    const paymentMethodHooks = {
        options: useMemo(() => Object.keys(labels).map((option) => ({
            key: option,
            label: labels[option],
            value: option,
        })), []),
    };
    return (_jsx(Ant.Select, { className: props.className, placeholder: 'Payée par...', value: props.value, options: paymentMethodHooks.options, onChange: props.onChange }));
});
