var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { ClientRequestEditForm as BaseClientRequestEditForm } from "./ClientRequestEditForm.base";
import * as Ant from "antd";
import { setAllFormItemsStyle, setAllXFormElemsPlaceholders, } from "../../utils";
import { SmileOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import { ClientRequestArchivingReason } from "@root/api.types";
let ClientRequestEditForm = class ClientRequestEditForm extends BaseClientRequestEditForm {
    build() {
        super.build();
        const { t } = this.i18n;
        const { UIComponents } = this;
        const Form = this;
        const archivingReasonHooks = {
            options: useMemo(() => Object.values(ClientRequestArchivingReason).map((option) => ({
                key: option,
                label: t(`management.client_requests.fields.archivingReason_enum.${option}`),
                value: option,
            })), []),
        };
        // Perform additional modifications such as updating rendering functions, labels, description
        setAllXFormElemsPlaceholders(this);
        this.update("description", {
            label: t("management.client_requests.fields.description"),
            name: ["description"],
            tooltip: t("management.client_requests.fields.description_description"),
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input.TextArea, { style: { minHeight: 100 } }) })),
        });
        this.update("archivingReason", {
            rules: [
                { required: true, message: "Sélectionner une raison d'archivage" },
            ],
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.client_requests.fields.archivingReason"), options: archivingReasonHooks.options, listHeight: 352 }) })),
        });
        this.update("archivingComment", { component: Ant.Input.TextArea });
        // removeAllFormItemsLabels(this);
        setAllFormItemsStyle({ flexGrow: 1, marginBottom: 0 }, this);
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            reference: 1,
            archivedBy: {
                _id: 1,
                fullName: 1,
                profile: {
                    firstName: 1,
                },
            },
            comments: {
                __typename: 1,
                _id: 1,
                text: 1,
                createdAt: 1,
                createdBy: {
                    fullName: 1,
                    _id: 1,
                },
            },
            relatedContacts: {
                _id: 1,
                profileType: 1,
                locationIndex: 1,
                isPayer: 1,
                isLocation: 1,
                isAsker: 1,
                distanceFromPlaces: {
                    distance: 1,
                    duration: 1,
                    placeId: 1,
                    placeName: 1,
                },
                address: {
                    streetOne: 1,
                    streetTwo: 1,
                    zip: 1,
                    town: 1,
                    country: 1,
                    geopoint: {
                        lat: 1,
                        lng: 1,
                    },
                    formattedAddress: 1,
                },
                profile: {
                    _id: 1,
                    fullName: 1,
                },
                profileId: 1,
                agency: {
                    _id: 1,
                    name: 1,
                },
                agencyId: 1,
            },
        };
    }
    onEditSubmit(_id, values) {
        const { t } = this.i18n;
        const promise = this.collection.updateOne(_id, values);
        promise
            .then((res) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.client_requests.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            return res;
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: err.reason || err.message,
            });
        });
        return promise;
    }
};
ClientRequestEditForm = __decorate([
    Service({ transient: true })
], ClientRequestEditForm);
export { ClientRequestEditForm };
