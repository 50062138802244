import { ClientRequestActivity, ClientRequestArchivingReason, ClientRequestPossibleStatus, ClientRequestSourceLead, } from "@root/api.types";
export const phrasesFr = {
    fr: {
        management: {
            client_requests: {
                fields: {
                    _id: "ID",
                    reference: "Nom de la mission",
                    reference_description: "Réference de la mission",
                    sourceLead: "Source",
                    sourceLead_description: "Comment le client nous a contacté",
                    followUp_enum: {
                        QUOTES_1: "Première relance devis (via email automatique)",
                        QUOTES_2: "Seconde relance devis (tâche)",
                        QUOTES_3: "Troisième relance devis (tâche)",
                        QUOTES_4: "Dernière relance devis (via email automatique)",
                        INVOICE_1: "Première relance facture (via email automatique)",
                        INVOICE_2: "Seconde relance facture (via email automatique)",
                        INVOICE_3: "Troisième relance facture (tâche)",
                    },
                    sourceLead_enum: {
                        [ClientRequestSourceLead.WEBSITE]: "Site Web",
                        [ClientRequestSourceLead.EMAIL]: "E-mail",
                        [ClientRequestSourceLead.PHONE]: "Téléphone",
                        [ClientRequestSourceLead.PROSPECTION]: "Prospection",
                        [ClientRequestSourceLead.FURET]: "Demande Furet - Agence",
                        [ClientRequestSourceLead.AGENCY]: "Demande d'agence",
                        [ClientRequestSourceLead.SC_LOCAL]: "Visite d'un local Selfcity",
                        [ClientRequestSourceLead.TRAVAUX_COM]: "Travaux.com",
                        [ClientRequestSourceLead.PARTNER_APP]: "App Partenaires",
                        [ClientRequestSourceLead.MATHIS]: "Mathis",
                        [ClientRequestSourceLead.BROKER]: "Courtier",
                        [ClientRequestSourceLead.OTHER]: "Autre",
                    },
                    activity: "Métier",
                    activity_description: "Le métier concernant cette demande",
                    activity_enum: {
                        [ClientRequestActivity.PLUMBING]: "Plomberie",
                        [ClientRequestActivity.MULTISERVICE]: "Multiservice",
                        [ClientRequestActivity.LOCKSMITHING]: "Serrurerie",
                        [ClientRequestActivity.ELECTRICAL]: "Electricité",
                    },
                    description: "Résumé de la mission",
                    description_description: "Brève description de la demande du client",
                    emergencyLevel: "Niveau d'urgence",
                    emergencyLevel_description: "Niveau d'urgence",
                    emergencyLevel_enum: {
                        AS_SOON_AS_POSSIBLE: "Dès que possible",
                        IN_THE_NEXT_FEW_DAYS: "Dans les prochains jours",
                        I_M_FLEXIBLE: "Je suis flexible", // 0,
                    },
                    status: "État",
                    status_enum: {
                        ALL: "Toutes",
                        [ClientRequestPossibleStatus.S_00100_CLIENT_REQUEST]: "Demande client",
                        [ClientRequestPossibleStatus.S_00230_JOB_ESTIMATION_TO_DO]: "Estimation à faire",
                        [ClientRequestPossibleStatus.S_00240_JOB_ESTIMATION_IN_PROGRESS_TECH]: "Estimation en cours (Tech)",
                        [ClientRequestPossibleStatus.S_00250_JOB_ESTIMATION_DONE_TECH]: "Estimation livrée (Tech)",
                        [ClientRequestPossibleStatus.S_00260_JOB_ESTIMATION_TO_CORRECT_TECH]: "Estimation à corriger (Tech)",
                        [ClientRequestPossibleStatus.S_00270_JOB_ESTIMATION_VALIDATED]: "Estimation validée",
                        [ClientRequestPossibleStatus.S_00340_QUOTATION_CREATED]: "Devis créé",
                        [ClientRequestPossibleStatus.S_00350_QUOTATION_SENT]: "Devis envoyé",
                        [ClientRequestPossibleStatus.S_00360_QUOTATION_FOLLOWED_UP]: "Devis relancé",
                        [ClientRequestPossibleStatus.S_00400_PREPARATION]: "Préparation",
                        [ClientRequestPossibleStatus.S_00500_SITE]: "Intervention",
                        [ClientRequestPossibleStatus.S_00620_INVOICE_DRAFT]: "Facture à faire",
                        [ClientRequestPossibleStatus.S_00630_INVOICE_CREATED]: "Facture créée",
                        [ClientRequestPossibleStatus.S_00640_INVOICE_TO_CORRECT]: "Facture à corriger",
                        [ClientRequestPossibleStatus.S_00650_INVOICE_SENT]: "Facture envoyée",
                        [ClientRequestPossibleStatus.S_00660_INVOICE_FOLLOWED_UP]: "Facture relancée",
                        [ClientRequestPossibleStatus.S_00670_INVOICE_PAID]: "Facture payée",
                        [ClientRequestPossibleStatus.S_00680_INVOICE_COMPLICATED]: "Facture (compliqué)",
                    },
                    status_tags_titles_enum: {
                        ALL: "Toutes",
                        [ClientRequestPossibleStatus.S_00100_CLIENT_REQUEST]: "Demande clients",
                        JOBS_ESTIMATIONS: "Estimations",
                        QUOTATIONS: "Devis",
                        [ClientRequestPossibleStatus.S_00400_PREPARATION]: "Préparations",
                        [ClientRequestPossibleStatus.S_00500_SITE]: "Interventions",
                        INVOICES: "Factures",
                    },
                    estimatedBy: "Estimé par",
                    estimatedBy_description: "Liste des techniciens affectés aux estimations",
                    "estimatedBy._id": "Estimé par — Id",
                    "estimatedBy.fullName": "Estimé par — Nom",
                    executedBy: "Executé par",
                    executedBy_description: "Liste des techniciens affectés à l'intervention",
                    "executedBy._id": "Executé par — Id",
                    "executedBy.fullName": "Executé par — Nom",
                    isArchived: "Mission archivée",
                    archivedAt: "Archivé le",
                    archivedAt_description: "Date d'archivage",
                    archivingReason: "Raison d'archivage",
                    archivingReason_enum: {
                        [ClientRequestArchivingReason.BILLING_PAID]: "Facture payée",
                        [ClientRequestArchivingReason.CANCELED_BY_CLIENT]: "Finalement plus de problème ou de besoin",
                        [ClientRequestArchivingReason.TOO_FAR]: "Hors-zone",
                        [ClientRequestArchivingReason.QUOTATION_REJECTED_OR_TOO_EXPENSIVE]: "Prix trop élevé (Concurrence)",
                        [ClientRequestArchivingReason.LATE_CLIENT_HANDLING]: "Délai trop long (Concurrence)",
                        [ClientRequestArchivingReason.CLIENT_UNREACHABLE]: "Plus de nouvelle du client",
                        [ClientRequestArchivingReason.CREDIT_NOTE]: "Mission non payée car avoir client",
                        [ClientRequestArchivingReason.TO_DELETE]: "Mission à supprimer",
                        [ClientRequestArchivingReason.CANNOT_HANDLE]: "On n’a pas les compétences (même nos sous-traitants)",
                        [ClientRequestArchivingReason.IRRELEVANT]: "Demande non pertinente (Démarchage, Emploi, etc.)",
                    },
                    archivingComment: "Commentaire",
                    archivingComment_description: "Commentaire de raison d'archivage",
                    utmMedium: "Utm Moyen",
                    utmSource: "Source Utm",
                    utmCampaign: "Campagne Utm",
                    fromWebsitePath: "Chemin du site Web",
                    isDeleted: "Supprimé",
                    isDeleted_description: "Ce champ est utilisé pour identifier si cet objet a été supprimé en douceur",
                    createdAt: "Créée le",
                    createdAt_description: "Date de creation",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Date de modification",
                    subjectId: "ID du Sujet",
                    createdById: "ID du créateur",
                    createdById_description: "ID de l'utilisateur qui a créé la mission",
                    updatedById: "ID du modificateur",
                    updatedById_description: "ID de l'utilisateur qui a modifié la mission",
                    subject_description: "Où se situe le problème",
                    comments: "Commentaires",
                    trips: "Voyages",
                    archivedById: "Archivé par",
                    relatedContacts: "Contacts liés à la mission",
                    relatedContacts_description: "Personnes ou agence liées à cette demande : locataire, propriétaire ou toute autre personne impliquée que nous pourrions avoir besoin de contacter à un moment donné.",
                    quotations: "Devis",
                    invoices: "Factures",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé cette mission",
                    updatedBy: "Modifié par",
                    updatedBy_description: "Représente l'utilisateur qui a modifié cet objet",
                },
                card: {
                    title: "Mission",
                },
                menu: {
                    title: "Missions",
                },
                list: {
                    header: "Missions",
                    create_btn: "Nouvelle Mission",
                    filters: {
                        reference: "Rechercher (mission, agence)",
                    },
                },
                create: {
                    header: "Créer une mission",
                },
                edit: {
                    header: "Modifier la mission",
                },
                view: {
                    header: "Résumé de la mission",
                },
                delete_confirmation: "Vous avez sélectionner la mission",
                create_confirmation: "Vous avez ajouté avec succès une nouvelle mission",
                edit_confirmation: "Vous avez modifié avec succès la mission",
                actions: {
                    archive: "Archiver la mission",
                    restore: "Restaurer la mission",
                    duplicate: "Créer une mission à partir de celle-ci (Contacts & CR)",
                    create_new: "Créer une nouvelle mission",
                    create_task: "Ajouter une tâche",
                    report_incident: "Ajouter une note de disfonctionnement",
                },
            },
        },
    },
};
