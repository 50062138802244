var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { removeAllFormItemsLabels, removeAllFormItemsStyle, } from "../../utils";
import { JobEstimationListFiltersForm as BaseJobEstimationListFiltersForm } from "./JobEstimationListFiltersForm.base";
import { useGuardian } from "@bluelibs/x-ui";
let JobEstimationListFiltersForm = class JobEstimationListFiltersForm extends BaseJobEstimationListFiltersForm {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
        // const t = useTranslate();
        // this.add({
        //   id: "clientRequestReference",
        //   label: t("management.job_estimations.fields.clientRequest"),
        //   name: ["clientRequestReference"],
        //   render: (props) => (
        //     <Ant.Form.Item {...props}>
        //       <Ant.Input />
        //     </Ant.Form.Item>
        //   ),
        // });
        removeAllFormItemsLabels(this);
        removeAllFormItemsStyle(this);
        const guardian = useGuardian();
        const isPlumber = guardian.hasRole("PLUMBER");
        this.update("assignedToId", {
            formItemProps: {
                style: { minWidth: "250px", margin: 0 },
                initialValue: isPlumber && guardian.state.user._id,
            },
        });
    }
};
JobEstimationListFiltersForm = __decorate([
    Service({ transient: true })
], JobEstimationListFiltersForm);
export { JobEstimationListFiltersForm };
