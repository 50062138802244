import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { formRenderItems } from "@bundles/UIAppBundle/pages/utils";
import { Modal } from "@bundles/UIAppBundle/components/Modal/Modal.index";
import { useFormModalHooks } from "@bundles/UIAppBundle/components/Modal/Modal.hooks";
import { AgenciesCollection } from "@bundles/UIAppBundle/collections";
import { AgencyEditForm } from "@bundles/UIAppBundle/pages/AgenciesManagement/config/AgencyEditForm";
import { withoutTypename } from "@root/utils/lib";
export const AgencyFormModal = memo((props) => {
    const { t, document: agency, isLoading, form, xForm, modalRef, onFinish, onCancel, onOk, } = useFormModalHooks({
        collection: AgenciesCollection,
        queryParam: "agency",
        form: AgencyEditForm,
        onCreateSuccess: props.onCreateSuccess,
        closeAfterSubmit: true,
        // @ts-ignore
        pruneDocument: (agency) => {
            const prunedAgency = {
                ...agency,
                address: withoutTypename(agency.address),
            };
            return prunedAgency;
        },
    });
    {
        t("management.client_requests.fields.archivedAt");
    }
    return (_jsx(_Fragment, { children: !isLoading && (_jsx(Ant.Form, { form: form, requiredMark: "optional", initialValues: agency, onFinish: onFinish, children: _jsx(Modal, { queryParam: "agency", title: agency
                    ? t("management.agencies.edit.header")
                    : t("management.agencies.create.header"), width: 600, okButtonProps: { htmlType: "submit" }, onOk: onOk, onCancel: onCancel, modalRef: modalRef, children: _jsxs("div", { className: "mar-1", children: [_jsx("div", { className: "input-row", children: formRenderItems(xForm, ["name", "agenciesTagId"]) }), _jsx("div", { className: "input-row", children: formRenderItems(xForm, ["phone", "email"]) }), formRenderItems(xForm, ["address"]), formRenderItems(xForm, ["siret"]), formRenderItems(xForm, ["vatNumber"])] }) }) })) }));
});
