import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
// ###### 2 => React imports #############
import React, { memo, useState } from "react";
import * as Ant from "antd";
import { FileAddOutlined, SendOutlined } from "@ant-design/icons";
import { use, useTranslate } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { CommentsCollection } from "@bundles/UIAppBundle/collections";
import { CommentVisibility } from "@root/api.types";
import clsx from "clsx";
import { XUploader } from "@bluelibs/x-ui-admin";
import { useApolloClient } from "@apollo/client";
export const AddComment = memo((props) => {
    const commentsCollection = use(CommentsCollection);
    const uploader = use(XUploader);
    const [text, setText] = useState("");
    const [files, setFiles] = useState([]);
    const [inserting, setInserting] = useState(false);
    const client = useApolloClient();
    const t = useTranslate();
    return (_jsxs("div", { className: clsx("flex column", props.className), children: [_jsxs("div", { className: "flex", children: [_jsx(Ant.Input.TextArea, { rows: props.oneLine ? 1 : undefined, placeholder: t("management.comments.actions.add"), onChange: (e) => setText(e.target.value), disabled: props.disabled || inserting, value: text, autoSize: true }), _jsxs("div", { className: clsx("comment-btns", props.oneLine ? "comment-btns-one-line" : "comment-btns-two-lines"), children: [_jsx(Ant.Upload, { multiple: true, showUploadList: false, disabled: props.disabled || inserting, beforeUpload: (file) => {
                                    setFiles((files) => [
                                        ...files,
                                        {
                                            originFileObj: file,
                                            name: file.name,
                                            url: URL.createObjectURL(file),
                                        },
                                    ]);
                                    return false;
                                }, children: _jsx(Ant.Button, { ghost: true, type: "primary", icon: _jsx(FileAddOutlined, {}), disabled: props.disabled || inserting }) }), _jsx(Ant.Button, { icon: _jsx(SendOutlined, {}), type: "primary", disabled: props.disabled || text.length < 3, loading: inserting, onClick: () => {
                                    setInserting(true);
                                    const input = {
                                        text,
                                        visibility: CommentVisibility.PRIVATE,
                                        clientRequestId: new ObjectId(props.clientRequestId),
                                    };
                                    if (props.jobEstimationId) {
                                        input.jobEstimationId = new ObjectId(props.jobEstimationId);
                                    }
                                    if (files.length) {
                                        input.attachments = {};
                                    }
                                    commentsCollection
                                        .insertOne(input, {
                                        apollo: { refetchQueries: ["CommentsFind"] },
                                        refetchBody: { _id: 1, attachments: { _id: 1 } },
                                    })
                                        .then(async (comment) => {
                                        return Promise.all(files.map(async (file) => {
                                            const appFile = await uploader.uploadFileToGroup(comment.attachments._id, file.originFileObj);
                                        }));
                                    })
                                        .then(() => {
                                        client.refetchQueries({ include: ["CommentsFind"] });
                                    })
                                        .then(() => {
                                        setText("");
                                        setFiles([]);
                                    })
                                        .finally(() => setInserting(false));
                                } })] })] }), _jsx(Ant.Upload
            // className="mar-t-1"
            // listType="picture-card"
            , { 
                // className="mar-t-1"
                // listType="picture-card"
                fileList: files, onRemove: (removedFile) => setFiles((prevFiles) => prevFiles.filter((file) => file !== removedFile)) })] }));
});
