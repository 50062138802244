import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter, useUIComponents, useTranslate, } from "@bluelibs/x-ui";
import { useMemo, useRef } from "react";
import * as Ant from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { JobEstimationsListFilters } from "./JobEstimationsListFilters";
import * as React from "react";
import axios from "axios";
export function JobEstimationsList() {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const filtersRef = useRef({
        assignedToId: [],
        searchInput: "",
    });
    const [estimates, setEstimates] = React.useState({ total: 0, results: [] });
    const [search, setSearch] = React.useState({
        page: 1,
        users: [],
        query: '',
    });
    React.useEffect(() => {
        const assignedToId = search.users;
        const searchInput = search.query;
        const token = window.localStorage.getItem("bluelibs-token");
        axios.request({
            method: "POST",
            url: global.NEW_API_URL + '/estimates/customSearch?limit=20&offset=' + (search.page - 1) * 20 + '&sortBy=_createdAt&sortOrder=-1',
            headers: {
                "Authorization": token,
            },
            data: {
                filters: assignedToId.length > 0 && assignedToId[0] ? {
                    assignedTo: assignedToId,
                } : null,
                query: {
                    on: ['categories.name'],
                    text: searchInput,
                },
            },
        }).then((response) => {
            setEstimates(response.data);
        });
    }, [search]);
    const onFiltersUpdate = useMemo(() => {
        return (newFilters) => {
            const assignedToId = typeof newFilters !== "string"
                ? newFilters.assignedToId
                : null;
            const searchInput = typeof newFilters === "string"
                ? newFilters
                : null;
            setSearch((s) => ({
                page: 1,
                users: assignedToId !== null && assignedToId !== void 0 ? assignedToId : s.users,
                query: searchInput !== null && searchInput !== void 0 ? searchInput : s.query,
            }));
        };
    }, []);
    return (_jsx(UIComponents.AdminLayout, { children: _jsx(Ant.Layout.Content, { children: _jsxs("div", { className: "page-job-estimations-list", children: [_jsx(JobEstimationsListFilters, { onUpdate: onFiltersUpdate, searchInput: _jsx(Ant.Input.Search, { name: "Search", placeholder: t("generics.list_search"), onKeyUp: (e) => {
                                const value = e.target.value;
                                onFiltersUpdate(value);
                            } }), actionBtn: _jsx(Ant.Button, { type: "primary", onClick: () => router.go(Routes.KANBAN), icon: _jsx(InboxOutlined, {}), children: "Voir mes estimations \u00E0 faire" }, "1") }), _jsxs("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'stretch',
                            alignItems: 'stretch',
                        }, children: [_jsxs("div", { style: { justifyContent: 'space-between', display: 'flex', padding: '0.5rem 1rem', borderBottom: '1px solid #DDDDDD', background: '#fafafa', fontWeight: '900' }, children: [_jsx("span", { style: { width: '25%' }, children: "Nom / mission" }), _jsx("span", { style: { flex: '1 1 auto', textAlign: 'center' }, children: "Assign\u00E9e \u00E0" }), _jsx("span", { style: { flex: '1 1 auto', textAlign: 'right' }, children: "Cr\u00E9\u00E9e le" })] }), estimates.results.map((estimate) => (_jsxs("div", { style: { justifyContent: 'space-between', display: 'flex', padding: '0.5rem 1rem', borderBottom: '1px solid #DDDDDD', background: 'white', cursor: 'pointer' }, onClick: () => {
                                    const token = window.localStorage.getItem("bluelibs-token");
                                    window.location.href = global.NEW_APP_URL + '/missions/' + estimate._mission + '/estimates/' + estimate._id + '/edit?token=' + token;
                                }, children: [_jsx("span", { style: { width: '25%' }, children: estimate.name }), estimate.user !== null
                                        ? _jsx("span", { style: { flex: '1 1 auto', textAlign: 'center' }, children: estimate.user.firstName + ' ' + estimate.user.lastName })
                                        : _jsx("span", { style: { flex: '1 1 auto', textAlign: 'center' }, children: "-" }), _jsx("span", { style: { flex: '1 1 auto', textAlign: 'right' }, children: estimate._createdAt.split('T')[0].split('-').reverse().join('/') })] }, estimate._id)))] }), _jsxs("div", { style: { display: 'flex', marginTop: '1rem', justifyContent: 'center' }, children: [search.page > 1 && _jsx("button", { style: {
                                    cursor: 'pointer',
                                    border: '1px solid grey',
                                    padding: '0.2rem 1rem',
                                    background: 'white',
                                }, onClick: () => {
                                    setSearch({ ...search, page: search.page - 1 });
                                }, children: "Pr\u00E9c\u00E9dent" }), search.page * 20 < estimates.total && _jsx("button", { style: {
                                    cursor: 'pointer',
                                    border: '1px solid grey',
                                    padding: '0.2rem 1rem',
                                    background: 'white',
                                }, onClick: () => {
                                    setSearch({ ...search, page: search.page + 1 });
                                }, children: "Suivant" })] })] }) }) }));
}
