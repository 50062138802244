import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { SmileOutlined } from "@ant-design/icons";
export const getTouchedFieldsOnlyFromForm = (form, document) => {
    return Object.fromEntries(Object.entries(document).filter(([key]) => {
        return form.isFieldTouched(key);
    }));
};
export const defineOnCreateSubmit = (document, object, typeTranslationPath) => {
    // typeof(document)
    const { t } = object.i18n;
    const insertPromise = object.collection.insertOne(document);
    insertPromise.then((res) => {
        Ant.notification.success({
            message: t("generics.success"),
            description: t(`management.${typeTranslationPath}.create_confirmation`),
            icon: _jsx(SmileOutlined, {}),
        });
        return res;
    });
    insertPromise.catch((err) => {
        Ant.notification.warn({
            message: t("generics.error"),
            description: t("generics.error_message"),
        });
    });
    return insertPromise;
};
export const onEditSubmit = (_id, values, object, typeTranslationPath) => {
    const { t } = object.i18n;
    return object.collection
        .updateOne(_id, { $set: values })
        .then((res) => {
        Ant.notification.success({
            message: t("generics.success"),
            description: t(`management.${typeTranslationPath}.edit_confirmation`),
            icon: _jsx(SmileOutlined, {}),
        });
        return res;
    })
        .catch((err) => {
        Ant.notification.warn({
            message: t("generics.error"),
            description: t("generics.error_message"),
        });
    });
};
