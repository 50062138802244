var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ClientRequestsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let JobEstimationListFiltersForm = class JobEstimationListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isUntouchedPackagedOffer",
                label: t("management.job_estimations.fields.isUntouchedPackagedOffer"),
                name: ["isUntouchedPackagedOffer"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "title",
                label: t("management.job_estimations.fields.title"),
                name: ["title"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "notes",
                label: t("management.job_estimations.fields.notes"),
                name: ["notes"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "isDeprecated",
                label: t("management.job_estimations.fields.isDeprecated"),
                name: ["isDeprecated"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "dueDate",
                label: t("management.job_estimations.fields.dueDate"),
                name: ["dueDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "status",
                label: t("management.job_estimations.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.job_estimations.fields.status"), children: [_jsx(Ant.Select.Option, { value: "TODO", children: "Todo" }, "TODO"), _jsx(Ant.Select.Option, { value: "WIP", children: "Wip" }, "WIP"), _jsx(Ant.Select.Option, { value: "IN_PROGRESS", children: "Wip" }, "IN_PROGRESS"), _jsx(Ant.Select.Option, { value: "DONE", children: "Done" }, "DONE"), _jsx(Ant.Select.Option, { value: "TOFIX", children: "Tofix" }, "TOFIX"), _jsx(Ant.Select.Option, { value: "VALIDATED", children: "Validated" }, "VALIDATED")] }) })),
            },
            {
                id: "fixedPrice",
                label: t("management.job_estimations.fields.fixedPrice"),
                name: ["fixedPrice"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "statusComment",
                label: t("management.job_estimations.fields.statusComment"),
                name: ["statusComment"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "images",
                label: t("management.job_estimations.fields.images"),
                name: ["images"],
                tooltip: t("management.job_estimations.fields.images_description"),
                columns: true,
                nest: [
                    {
                        id: "title",
                        label: t("management.job_estimations.fields.images.title"),
                        name: ["images", "title"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "src",
                        label: t("management.job_estimations.fields.images.src"),
                        name: ["images", "src"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "requiresTruck",
                label: t("management.job_estimations.fields.requiresTruck"),
                name: ["requiresTruck"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "activity",
                label: t("management.job_estimations.fields.activity"),
                name: ["activity"],
                tooltip: t("management.job_estimations.fields.activity_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.job_estimations.fields.activity"), children: [_jsx(Ant.Select.Option, { value: "MECHANICAL_VENTILATION", children: "Mechanical Ventilation" }, "MECHANICAL_VENTILATION"), _jsx(Ant.Select.Option, { value: "PLUMBING", children: "Plumbing" }, "PLUMBING"), _jsx(Ant.Select.Option, { value: "RENOVATION", children: "Renovation" }, "RENOVATION"), _jsx(Ant.Select.Option, { value: "HEATING", children: "Heating" }, "HEATING"), _jsx(Ant.Select.Option, { value: "AIR_CONDITIONING", children: "Air Conditioning" }, "AIR_CONDITIONING"), _jsx(Ant.Select.Option, { value: "MULTISERVICE", children: "Multiservice" }, "MULTISERVICE"), _jsx(Ant.Select.Option, { value: "LOCKSMITHING", children: "Locksmithing" }, "LOCKSMITHING"), _jsx(Ant.Select.Option, { value: "ELECTRICAL", children: "Electrical" }, "ELECTRICAL")] }) })),
            },
            {
                id: "createdAt",
                label: t("management.job_estimations.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.job_estimations.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.job_estimations.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.job_estimations.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "clientRequestId",
                label: t("management.job_estimations.fields.clientRequest"),
                name: ["clientRequestId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ClientRequestsCollection, field: "reference", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "assignedToId",
                label: t("management.job_estimations.fields.assignedTo"),
                name: ["assignedToId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "createdById",
                label: t("management.job_estimations.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.job_estimations.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.job_estimations.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.job_estimations.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
JobEstimationListFiltersForm = __decorate([
    Service({ transient: true })
], JobEstimationListFiltersForm);
export { JobEstimationListFiltersForm };
