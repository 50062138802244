import { ClientRequestActivity, ClientRequestPossibleStatus, } from "@root/api.types";
import { ClientRequestsCollection as ClientRequestsCollectionBase } from "./ClientRequests.collection";
export class ClientRequestsCollection extends ClientRequestsCollectionBase {
    async createNewClientRequest() {
        const res = await this.insertOne({
            activity: ClientRequestActivity.PLUMBING,
            status: ClientRequestPossibleStatus.S_00100_CLIENT_REQUEST,
        });
        return res;
    }
}
export * from "./ClientRequests.collection";
