var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let TokenListFiltersForm = class TokenListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isEnabled",
                label: t("management.tokens.fields.isEnabled"),
                name: ["isEnabled"],
                tooltip: t("management.tokens.fields.isEnabled_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "name",
                label: t("management.tokens.fields.name"),
                name: ["name"],
                tooltip: t("management.tokens.fields.name_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "value",
                label: t("management.tokens.fields.value"),
                name: ["value"],
                tooltip: t("management.tokens.fields.value_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.tokens.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.tokens.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.tokens.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.tokens.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "createdById",
                label: t("management.tokens.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.tokens.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.tokens.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.tokens.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
TokenListFiltersForm = __decorate([
    Service({ transient: true })
], TokenListFiltersForm);
export { TokenListFiltersForm };
