var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Service } from "@bluelibs/core";
import { Routes } from "@bundles/UIAppBundle";
import { translateEnumValues } from "../../utils";
import { ClientRequestList as BaseClientRequestList } from "./ClientRequestList.base";
let ClientRequestList = class ClientRequestList extends BaseClientRequestList {
    build() {
        super.build();
        // Perform additional modifications such as updating how a list item renders or add additional ones
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.update("reference", {
            className: "text-one-line",
            fixed: "left",
            // width: "200px",
            // colSpan
            // align
        });
        this.update("estimatedBy", {
            render: (value, model) => {
                return (_jsx(_Fragment, { children: value &&
                        value.map((value, idx) => {
                            const props = {
                                type: "relation",
                                value,
                                relation: {
                                    path: router.path(Routes.USERS_VIEW, {
                                        params: {
                                            id: value === null || value === void 0 ? void 0 : value._id,
                                        },
                                    }),
                                    dataIndex: "fullName",
                                },
                            };
                            return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                        }) }));
            },
        });
        this.update("executedBy", {
            render: (value, model) => {
                return (_jsx(_Fragment, { children: value &&
                        value.map((value, idx) => {
                            const props = {
                                type: "relation",
                                value,
                                relation: {
                                    path: router.path(Routes.USERS_VIEW, {
                                        params: {
                                            id: value === null || value === void 0 ? void 0 : value._id,
                                        },
                                    }),
                                    dataIndex: "fullName",
                                },
                            };
                            return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                        }) }));
            },
        });
        this.add([
            // {
            //   id: "quotationsFromJobEstimationsAssignedTo",
            //   title: t("management.job_estimations.fields.assignedTo"),
            //   key: "management.job_estimations.fields.assignedTo",
            //   dataIndex: ["quotations", "0", "fromJobEstimations", "0", "assignedTo"],
            //   sorter: true,
            //   render: (value, model) => {
            //     const props = {
            //       type: "relation",
            //       value,
            //       relation: {
            //         path: router.path(Routes.PROFILES_VIEW, {
            //           params: {
            //             id: value?._id,
            //           },
            //         }),
            //         dataIndex: "fullName",
            //       },
            //     };
            //     return <UIComponents.AdminListItemRenderer {...props} />;
            //   },
            // },
            {
                id: "quotationsTotalPriceIT",
                title: t("management.quotations.fields.totalPriceIT"),
                key: "management.quotations.fields.totalPriceIT",
                dataIndex: ["quotations", "0", "totalPriceIT"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "quotationsSentAt",
                title: t("management.quotations.fields.sentAt"),
                key: "management.quotations.fields.sentAt",
                dataIndex: ["quotations", "0", "sentAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "quotationslastFollowedUpAt",
                title: t("management.quotations.fields.lastFollowedUpAt"),
                key: "management.quotations.fields.lastFollowedUpAt",
                dataIndex: ["quotations", "0", "lastFollowedUpAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "invoicesTotalPriceIT",
                title: t("management.invoices.fields.totalPriceIT"),
                key: "management.invoices.fields.totalPriceIT",
                dataIndex: ["invoices", "0", "totalPriceIT"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "invoicesSentAt",
                title: t("management.invoices.fields.sentAt"),
                key: "management.invoices.fields.sentAt",
                dataIndex: ["invoices", "0", "sentAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "invoiceslastFollowedUpAt",
                title: t("management.invoices.fields.lastFollowedUpAt"),
                key: "management.invoices.fields.lastFollowedUpAt",
                dataIndex: ["invoices", "0", "lastFollowedUpAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "invoicesPaidAt",
                title: t("management.invoices.fields.paidAt"),
                key: "management.invoices.fields.paidAt",
                dataIndex: ["invoices", "0", "paidAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
        translateEnumValues([
            {
                enumItemId: "status",
                enumItemI18nPath: "management.client_requests.fields.status_enum",
            },
            {
                enumItemId: "activity",
                enumItemI18nPath: "management.client_requests.fields.activity_enum",
            },
        ], t, this);
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
        };
    }
};
ClientRequestList = __decorate([
    Service({ transient: true })
], ClientRequestList);
export { ClientRequestList };
