import generics from "./generics.json";
import genericsFr from "./generics.fr.i18n.json";
import forms from "./forms.i18n.json";
import misc from "./misc.i18n.json";
import { phrasesFr } from "../pages/JobEstimationsKanban/JobEstimationsKanban.fr.i18n";
export const i18n = [];
i18n.push(generics);
i18n.push(genericsFr);
i18n.push(forms);
i18n.push(misc);
i18n.push(phrasesFr);
