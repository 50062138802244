export const phrasesFr = {
    fr: {
        management: {
            users: {
                fields: {
                    updatedById: "ID modificateur",
                    updatedById_description: "Représente l'identifiant de l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                    createdById: "ID créateur",
                    createdById_description: "Représente l'identifiant de l'utilisateur qui a créé cet objet",
                    avatarId: "ID avatar",
                    skills: "Compétences",
                    "skills.level": "Compétences — Niveau",
                    "skills.skillId": "Compétences — ID de compétence",
                    "skills.notes": "Compétences — Remarques",
                    roles: "Roles",
                    slackId: "Slack Channel ID",
                    botName: "Nom du Bot",
                    email: "E-mail",
                    fullName: "Nom complet",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Représente la dernière fois que l'objet a été mis à jour",
                    createdAt: "Créée le",
                    createdAt_description: "Représente la date à laquelle cet objet a été créé",
                    isDeleted: "supprimé",
                    isDeleted_description: "Ce champ est utilisé pour identifier si cet objet a été supprimé en douceur",
                    password: "Mot de passe",
                    password_description: "Il s'agit du modèle qui stocke les données d'authentification de mot de passe telles que les e-mails, le mot de passe haché, le sel et d'autres données liées à la sécurité",
                    isEnabled: "activé",
                    profile: "Profil",
                    "profile.firstName": "Profil — Prénom",
                    "profile.lastName": "Profil — Nom",
                    _id: "ID",
                    avatar: "Avatar",
                    trips: "Déplacement",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé cet objet",
                    updatedBy: "Modifié par",
                    updatedBy_description: "Représente l'utilisateur qui a fait la dernière mise à jour sur cet objet",
                },
                menu: {
                    title: "Utilisateurs",
                },
                list: {
                    header: "Liste des utilisateurs",
                    create_btn: "Nouvel utilisateur",
                },
                create: {
                    header: "Créer un utilisateur",
                },
                edit: {
                    header: "Modifier l'utilisateur",
                },
                view: {
                    header: "Afficher l'utilisateur",
                },
                delete_confirmation: "Vous avez supprimé l'utilisateur",
                create_confirmation: "Vous avez ajouté avec succès un nouvel utilisateur",
                edit_confirmation: "Vous avez modifié l'utilisateur avec succès",
            },
        },
    },
};
