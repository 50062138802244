import { Collection, } from "@bluelibs/x-ui";
import { ClientRequest } from "@root/api.types";
import { CommentsCollection, ClientRequestRelatedContactsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class ClientRequestsCollection extends Collection {
    getName() {
        return "ClientRequests";
    }
    getInputs() {
        return {
            insert: "ClientRequestInsertInput!",
            update: "ClientRequestUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "archivedBy",
                field: "archivedById",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => CommentsCollection,
                name: "comments",
            },
            {
                collection: () => ClientRequestRelatedContactsCollection,
                name: "relatedContacts",
                many: true,
            },
            {
                collection: () => ClientRequestRelatedContactsCollection,
                name: "payer",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            nextFollowUpDate: (v) => (v && new Date(v) ? new Date(v) : v),
            archivedAt: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
