var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ClientRequestsCollection, InboxTagsCollection, UsersCollection, InboxTasksCollection, } from "@bundles/UIAppBundle/collections";
import { UserRoles } from "@root/api.types";
import { RemoteSelectLazy } from "@bundles/UIAppBundle/overrides";
let InboxTaskEditForm = class InboxTaskEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                label: t("management.inbox_tasks.fields.title"),
                name: ["title"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "priority",
                label: t("management.inbox_tasks.fields.priority"),
                name: ["priority"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "dueDate",
                label: t("management.inbox_tasks.fields.dueDate"),
                name: ["dueDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
            },
            {
                id: "done",
                label: t("management.inbox_tasks.fields.done"),
                name: ["done"],
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "clientRequestId",
                label: t("management.inbox_tasks.fields.clientRequest"),
                name: ["clientRequestId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ClientRequestsCollection, field: "reference", required: false }) })),
            },
            {
                id: "tagId",
                label: t("management.inbox_tasks.fields.tag"),
                name: ["tagId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: InboxTagsCollection, field: "_id", required: true }) })),
            },
            {
                id: "assignedToId",
                label: t("management.inbox_tasks.fields.assignedTo"),
                name: ["assignedToId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectLazy, { collectionClass: UsersCollection, field: "fullName", additionalQueryFilter: {
                            roles: UserRoles.SUPPORT,
                        } }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            title: 1,
            priority: 1,
            dueDate: 1,
            done: 1,
            clientRequest: {
                _id: 1,
                reference: 1,
            },
            clientRequestId: 1,
            tag: {
                _id: 1,
            },
            tagId: 1,
            assignedTo: {
                _id: 1,
                fullName: 1,
            },
            assignedToId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.inbox_tasks.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => InboxTasksCollection),
    __metadata("design:type", typeof (_a = typeof InboxTasksCollection !== "undefined" && InboxTasksCollection) === "function" ? _a : Object)
], InboxTaskEditForm.prototype, "collection", void 0);
InboxTaskEditForm = __decorate([
    Service({ transient: true })
], InboxTaskEditForm);
export { InboxTaskEditForm };
