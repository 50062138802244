import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import * as React from "react";
import * as debounce from "lodash.debounce";
import { use, useTranslate } from "@bluelibs/x-ui";
import { JobEstimationListFiltersForm } from "../../config/JobEstimationListFiltersForm";
export const JobEstimationsListFilters = React.memo(JobEstimationsListFiltersBase);
export function JobEstimationsListFiltersBase(props) {
    const [form] = Ant.Form.useForm();
    const t = useTranslate();
    const debouncedFilterUpdates = React.useMemo(() => {
        const setFilters = (_, filters) => {
            props.onUpdate(filters);
        };
        return debounce(setFilters, 500);
    }, [props.onUpdate]);
    React.useEffect(() => {
        setTimeout(() => {
            props.onUpdate({ assignedToId: [form.getFieldValue("assignedToId")] });
        }, 250);
    }, []);
    const filterForm = use(JobEstimationListFiltersForm, { transient: true });
    filterForm.build();
    return (_jsx(Ant.Form, { form: form, labelCol: { span: 24 }, onValuesChange: debouncedFilterUpdates, children: _jsxs("div", { className: "search-bar", children: [props.searchInput, filterForm.render("assignedToId"), props.actionBtn] }) }));
}
