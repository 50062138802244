import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { use, useGuardian } from "@bluelibs/x-ui";
import { dispatch, useCombiner } from "@root/store";
import Loader from "@root/bundles/UIAppBundle/components/Loader";
import { UITitle, UIP, } from "biuty/react";
import t from "@root/i18n";
import { ClientRequestTimeLine } from "@root/bundles/UIAppBundle/components/ClientRequestTimeLine/ClientRequestTimeLine.index";
import { AddComment } from "@bundles/UIAppBundle/components/Comments/AddComment";
import Tag from "@bundles/UIAppBundle/components/Tag";
import "@bundles/UIAppBundle/pages/JobEstimationsManagement/components/View/EstimateView.scss";
import { ClientRequestRelatedContactsCollection, ClientRequestsCollection } from "@bundles/UIAppBundle/collections";
import { ObjectId } from "@bluelibs/ejson";
export function EstimateView({ id: clientRequestId }) {
    var _a, _b;
    const guardian = useGuardian();
    const entities = useCombiner("jobEstimations");
    const queryResults = useCombiner("results", (newState) => newState["jobEstimations_view_page"]);
    const query = new URLSearchParams(window.location.search);
    const jobEstimationId = /^[0-9a-fA-F]{24}$/.test(query.get('je')) ? query.get('je') : null;
    const resource = entities[queryResults.results[0]] || null;
    // TODO replace by new permissions system.
    const hasRestrictedRole = !guardian.hasRole("SUPPORT") && !guardian.hasRole("ADMIN");
    const isAssignedToUser = `${resource === null || resource === void 0 ? void 0 : resource.user}` === `${guardian.state.user._id}`;
    const isEditableByUser = !hasRestrictedRole || !(resource === null || resource === void 0 ? void 0 : resource.user) || isAssignedToUser;
    React.useEffect(() => {
        if (jobEstimationId) {
            dispatch("results", "view", {
                from: "view_page",
                id: jobEstimationId,
                collection: "jobEstimations",
                options: {},
            });
        }
    }, [jobEstimationId]);
    const clientRequestRelatedContactsCollection = use(ClientRequestRelatedContactsCollection);
    const clientRequestCollection = use(ClientRequestsCollection);
    const [relatedContacts, setRelatedContacts] = React.useState([]);
    const [mission, setMission] = React.useState(null);
    React.useEffect(() => {
        clientRequestRelatedContactsCollection.find({
            filters: {
                clientRequestId: new ObjectId(clientRequestId),
            },
        }, {
            distanceFromPlaces: {
                placeId: 1,
                distance: 1,
            },
            isLocation: 1,
            address: {
                streetOne: 1,
                streetTwo: 1,
                zip: 1,
                town: 1,
                country: 1,
                geopoint: {
                    lat: 1,
                    lng: 1,
                },
                formattedAddress: 1,
            },
        })
            .then(setRelatedContacts);
        clientRequestCollection.findOne({
            filters: {
                _id: new ObjectId(clientRequestId),
            },
        }, {
            reference: 1,
            description: 1,
        })
            .then(setMission);
    }, []);
    // Loading results...
    if (mission === null) {
        return _jsx(Loader, {});
    }
    const descriptionTags = [];
    return (_jsxs("div", { className: "estimate-modal flex flex-col items-stretch vgap-3", children: [_jsx("div", { className: "green-modal-head", children: (_a = mission.reference) !== null && _a !== void 0 ? _a : "-" }), _jsx("hr", {}), _jsxs("div", { className: "estimate-modal__description flex flex-col vgap-2", children: [_jsxs("div", { className: "estimate-modal__description__header", children: [_jsx(UITitle, { level: "2", label: t("PAGES.ESTIMATES.EDIT.DESCRIPTION_TITLE") }), _jsx("div", { children: descriptionTags.map((tag) => (_jsx(Tag, { modifiers: "primary", label: tag }, tag))) })] }), _jsx(UIP, { label: (_b = mission.description) !== null && _b !== void 0 ? _b : "" }), _jsxs("div", { className: "mar-t-1 comments", children: [_jsx("p", { className: "fw-bold", children: "Commentaires internes avec le support" }), _jsx(AddComment, { oneLine: true, disabled: !isEditableByUser, clientRequestId: clientRequestId, jobEstimationId: jobEstimationId }), _jsx(ClientRequestTimeLine, { className: "mar-t-1", clientRequestId: clientRequestId, jobEstimationId: jobEstimationId, defaultLimit: 2, displayShowMore: true })] })] }), _jsx("hr", {})] }));
}
