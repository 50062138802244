import { i18n } from "@bundles/UIAppBundle/i18n";
import phrases from "./config/JobEstimations.i18n.json";
import { phrasesFr } from "./config/JobEstimations.fr.i18n";
i18n.push(phrases, phrasesFr);
i18n.push({
    en: {},
    // ! none of this is used : refactor before using this
    fr: {},
});
// You can override additional messages here by using i18n.push();
