import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, use, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { AgencyCreateForm } from "../../config/AgencyCreateForm";
import { formRenderItems } from "@bundles/UIAppBundle/pages/utils";
export function AgenciesCreate() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(AgencyCreateForm, { transient: true });
    form.build();
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.agencies.create.header"), onBack: () => window.history.back() }), _jsx(Ant.Card, { children: _jsxs(Ant.Form, { requiredMark: "optional", onFinish: (document) => form.onSubmit(document), children: [_jsx("div", { className: "input-row", children: formRenderItems(form, ["name", "agenciesTagId"]) }), _jsx("div", { className: "input-row", children: formRenderItems(form, ["phone", "email"]) }), formRenderItems(form, ["address"]), formRenderItems(form, ["siret"]), formRenderItems(form, ["vatNumber"]), _jsx(Ant.Form.Item, { children: _jsx(Ant.Button, { type: "primary", htmlType: "submit", children: t("generics.submit") }) })] }) })] }));
}
