import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useState } from "react";
import * as Ant from "antd";
import { PlusOutlined } from "@ant-design/icons";
export const FloatingActionButton = memo((props) => {
    var _a, _b, _c, _d;
    const [secondaryBtnsVisibility, setSecondaryBtnsVisibility] = useState(false);
    return (_jsx(_Fragment, { children: _jsxs("nav", { className: "fab-container", children: [(_a = props.secondaryBtns) === null || _a === void 0 ? void 0 : _a.map((button, idx) => {
                    return (_jsx(Ant.Tooltip, { className: secondaryBtnsVisibility ? "fab-item" : "fab-item-init", visible: secondaryBtnsVisibility, placement: "left", title: button.tooltip, children: _jsx(Ant.Button, { className: secondaryBtnsVisibility ? "fab-item" : "fab-item-init", type: "default", icon: button.icon, onClick: (e) => {
                                // e.stopPropagation();
                                setSecondaryBtnsVisibility(!secondaryBtnsVisibility);
                                button.onClick();
                            } }) }, idx));
                }), _jsx(Ant.Tooltip, { placement: "left", title: (_b = props.primaryBtn) === null || _b === void 0 ? void 0 : _b.tooltip, children: _jsx(Ant.Button, { className: secondaryBtnsVisibility
                            ? "fab-item fab-rotate"
                            : "fab-item-init", type: "primary", 
                        // shape="circle"
                        // size="large"
                        icon: (_d = (_c = props.primaryBtn) === null || _c === void 0 ? void 0 : _c.icon) !== null && _d !== void 0 ? _d : _jsx(PlusOutlined, {}), onClick: () => {
                            var _a;
                            setSecondaryBtnsVisibility(!secondaryBtnsVisibility);
                            (_a = props.primaryBtn) === null || _a === void 0 ? void 0 : _a.onClick;
                        } }) })] }) }));
});
