import { use } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { ClientRequestRelatedContactsCollection } from "../collections";
export const useClientRequestRelatedContactsByClientRequestId = ({ clientRequestId, }) => {
    const relatedContactsCollection = use(ClientRequestRelatedContactsCollection);
    const queryResult = relatedContactsCollection.useQuery({
        queryInput: { filters: { clientRequestId: new ObjectId(clientRequestId) } },
        body: {
            _id: 1,
            name: 1,
            profileType: 1,
            isAsker: 1,
            isPayer: 1,
            isLocation: 1,
            email: 1,
            address: {
                formattedAddress: 1,
            },
            locationIndex: 1,
            distanceFromPlaces: {
                distance: 1,
                duration: 1,
                placeId: 1,
                placeName: 1,
            },
            agency: {
                _id: 1,
                agenciesTag: {
                    _id: 1,
                    tag: 1,
                    description: 1,
                    color: 1,
                },
                name: 1,
                phone: 1,
                email: 1,
                address: {
                    formattedAddress: 1,
                },
                addresses: {
                    formattedAddress: 1,
                },
            },
            profile: {
                _id: 1,
                gender: 1,
                firstName: 1,
                lastName: 1,
                fullName: 1,
                phone: 1,
                email: 1,
                companyName: 1,
                isACompany: 1,
                address: {
                    formattedAddress: 1,
                    streetTwo: 1,
                },
                addresses: {
                    formattedAddress: 1,
                },
            },
        },
    });
    return queryResult;
};
