var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ClientRequestList = class ClientRequestList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "reference",
                title: t("management.client_requests.fields.reference"),
                key: "management.client_requests.fields.reference",
                dataIndex: ["reference"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "sourceLead",
                title: t("management.client_requests.fields.sourceLead"),
                key: "management.client_requests.fields.sourceLead",
                dataIndex: ["sourceLead"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "sourceLeadPhone",
                title: t("management.client_requests.fields.sourceLeadPhone"),
                key: "management.client_requests.fields.sourceLeadPhone",
                dataIndex: ["sourceLeadPhone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "nextFollowUp",
                title: t("management.client_requests.fields.nextFollowUp"),
                key: "management.client_requests.fields.nextFollowUp",
                dataIndex: ["nextFollowUp"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "nextFollowUpDate",
                title: t("management.client_requests.fields.nextFollowUpDate"),
                key: "management.client_requests.fields.nextFollowUpDate",
                dataIndex: ["nextFollowUpDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "activity",
                title: t("management.client_requests.fields.activity"),
                key: "management.client_requests.fields.activity",
                dataIndex: ["activity"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "emergencyLevel",
                title: t("management.client_requests.fields.emergencyLevel"),
                key: "management.client_requests.fields.emergencyLevel",
                dataIndex: ["emergencyLevel"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "status",
                title: t("management.client_requests.fields.status"),
                key: "management.client_requests.fields.status",
                dataIndex: ["status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "estimatedBy",
                title: t("management.client_requests.fields.estimatedBy"),
                key: "management.client_requests.fields.estimatedBy",
                dataIndex: ["estimatedBy"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "executedBy",
                title: t("management.client_requests.fields.executedBy"),
                key: "management.client_requests.fields.executedBy",
                dataIndex: ["executedBy"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "allInvoicesPaid",
                title: t("management.client_requests.fields.allInvoicesPaid"),
                key: "management.client_requests.fields.allInvoicesPaid",
                dataIndex: ["allInvoicesPaid"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.client_requests.fields.createdAt"),
                key: "management.client_requests.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.client_requests.fields.updatedAt"),
                key: "management.client_requests.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "payer",
                title: t("management.client_requests.fields.payer"),
                key: "management.client_requests.fields.payer",
                dataIndex: ["payer"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.CLIENT_REQUEST_RELATED_CONTACTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "archivedBy",
                title: t("management.client_requests.fields.archivedBy"),
                key: "management.client_requests.fields.archivedBy",
                dataIndex: ["archivedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            payer: "payer.name",
            quotations: "quotations.number",
            invoices: "invoices.number",
            archivedBy: "archivedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            reference: 1,
            sourceLead: 1,
            sourceLeadPhone: 1,
            nextFollowUp: 1,
            nextFollowUpDate: 1,
            activity: 1,
            emergencyLevel: 1,
            statusChangeHistory: {
                status: 1,
                updatedAt: 1,
                updatedById: 1,
            },
            status: 1,
            estimatedBy: {
                fullName: 1,
            },
            createdAt: 1,
            updatedAt: 1,
            subjectId: 1,
            payer: {
                _id: 1,
                name: 1,
            },
            archivedBy: {
                _id: 1,
                fullName: 1,
            },
            archivedById: 1,
        };
    }
};
ClientRequestList = __decorate([
    Service({ transient: true })
], ClientRequestList);
export { ClientRequestList };
