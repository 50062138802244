import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { use, useTranslate } from "@bluelibs/x-ui";
import { memo, useCallback } from "react";
import * as Ant from "antd";
import { DeleteOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";
import { ClientRequestRelatedContactsCollection } from "@bundles/UIAppBundle/collections";
import { useContactCardHooks } from "./ContactCard.hooks";
import { RelatedContactTags } from "@bundles/UIAppBundle/components/ClientRequestRelatedContacts/RelatedContactTags";
import { useApolloClient } from "@apollo/client";
export const ContactCard = memo((props) => {
    const { relatedContact, lookupKey, onTagToggle, onEditContact, name } = useContactCardHooks(props);
    const t = useTranslate();
    const relatedContactsCollection = use(ClientRequestRelatedContactsCollection);
    // TODO : refactor on delete modal
    const client = useApolloClient();
    const onDeleteContact = useCallback(() => {
        Ant.Modal.confirm({
            title: "Détacher le contact de la mission",
            okText: t("generics.delete"),
            cancelText: t("generics.cancel"),
            content: (_jsxs("div", { children: ["\u00CAtes-vous s\u00FBr de vouloir d\u00E9tacher", " ", _jsx("span", { className: "fw-bold", children: name }), " de la mission ?"] })),
            onOk: () => {
                relatedContactsCollection
                    .deleteOne(relatedContact._id)
                    .then(() => {
                    client.refetchQueries({
                        include: [
                            "ClientRequestRelatedContactsFind",
                            "ClientRequestsFindOne",
                        ],
                    });
                })
                    .catch(console.error);
            },
        });
    }, [relatedContact._id, relatedContact.name]);
    return (_jsx(_Fragment, { children: _jsxs(Ant.Card, { size: "small", className: "contact-card relative", children: [_jsxs("div", { className: "ant-card ant-card-bordered show-on-hover-flex card-actions", children: [_jsx(Ant.Button, { type: "link", href: `mailto:${relatedContact[lookupKey].email}?subject=${encodeURIComponent("Selfcity 👨‍🔧 - ") || ""}`, 
                            // &body=${encodeURIComponent("body") || ""}
                            disabled: !relatedContact[lookupKey].email, target: "_blank", icon: _jsx(MailOutlined, {}) }), _jsx(Ant.Button, { type: "link", onClick: onEditContact, icon: _jsx(EditOutlined, {}) }), _jsx(Ant.Button, { type: "link", onClick: onDeleteContact, icon: _jsx(DeleteOutlined, {}) })] }), _jsxs("div", { className: "mar-b-1", children: [_jsx("div", { className: "fw-bold mar-b-1", children: name }), relatedContact[lookupKey].email && (_jsx("div", { children: _jsx(Ant.Typography.Text, { copyable: true, children: relatedContact[lookupKey].email }) })), relatedContact[lookupKey].phone && (_jsx("div", { children: _jsx(Ant.Typography.Text, { copyable: true, children: relatedContact[lookupKey].phone }) })), relatedContact[lookupKey].address && (_jsx("div", { children: _jsx(Ant.Typography.Text, { copyable: true, children: relatedContact[lookupKey].address.formattedAddress }) }))] }), _jsx("div", { children: _jsx(RelatedContactTags, { relatedContact: relatedContact, onTagToggle: onTagToggle }) })] }) }));
});
