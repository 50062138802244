import { AgenciesCollection } from "@bundles/UIAppBundle/collections";
import { Routes } from "@bundles/UIAppBundle";
import { AntTableSmart } from "@bluelibs/x-ui-admin";
import { AgencyList } from "../../config/AgencyList";
export class AgenciesAntTableSmart extends AntTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = AgenciesCollection;
    }
    getBody() {
        return AgencyList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(AgencyList);
        list.build();
        // return [...list.rest(), this.getActionsColumn()];
        return [...list.rest()];
    }
    // getActionsColumn(): ColumnType<Agency> {
    //   return {
    //     title: this.i18n.t("generics.list_actions"),
    //     key: "actions",
    //     fixed: "right",
    //     width: 180,
    //     render: (_, model) => {
    //       return this.generateActions(model, {
    //         label: this.i18n.t("generics.list_actions"),
    //         icon: <DownOutlined />,
    //         items: this.getActionItems(),
    //       });
    //     },
    //   };
    // }
    getSortMap() {
        return AgencyList.getSortMap();
    }
    // getActionItems() {
    //   const actions = [];
    //   if (features.view) {
    //     actions.push({
    //       label: this.i18n.t("generics.view"),
    //       icon: <EyeOutlined />,
    //       action: (model) => {
    //         this.router.go(Routes.AGENCIES_VIEW, {
    //           params: { id: model._id.toString() },
    //         });
    //       },
    //     });
    //   }
    //   if (features.edit) {
    //     actions.push({
    //       label: this.i18n.t("generics.edit"),
    //       icon: <EditOutlined />,
    //       action: (model) => {
    //         this.router.go(Routes.AGENCIES_EDIT, {
    //           params: { id: model._id.toString() },
    //         });
    //       },
    //     });
    //   }
    //   if (features.delete) {
    //     actions.push({
    //       label: this.i18n.t("generics.delete"),
    //       icon: <DeleteOutlined />,
    //       confirm: this.i18n.t("generics.ask_confirmation"),
    //       action: (model) => {
    //         this.collection.deleteOne(model._id).then(() => {
    //           this.load();
    //         });
    //       },
    //     });
    //   }
    //   return actions;
    // }
    getTableProps() {
        return {
            ...super.getTableProps(),
            onRow: (record, rowIndex) => {
                return {
                    onClick: () => {
                        this.router.go(Routes.AGENCIES_VIEW, {
                            params: { id: record._id.toString() },
                        });
                    },
                };
            },
        };
    }
}
