import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from "axios";
import * as Ant from "antd";
import { newSmart, useUIComponents, useTranslate } from "@bluelibs/x-ui";
import { useCallback } from "react";
import { ClientRequestsAntTableSmart } from "./ClientRequestsTableSmart";
import { PlusOutlined } from "@ant-design/icons";
import { ClientRequestsListFilters } from "./ClientRequestsListFilters";
function redirectToBoilerService() {
    const token = window.localStorage.getItem("bluelibs-token");
    axios.request({
        method: "GET",
        headers: { "bluelibs-token": token },
        url: `${process.env.API_URL.replace("/graphql", "").concat('/ongoing-call')}`,
    }).then((response) => {
        window.open(`https://form.jotform.com/231022121025028${response.data.phone ? `?src=ringover&cmp=${response.data.phone}` : ''}`, '_blank');
    });
}
export function ClientRequestsList() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const getRowsPerPage = () => {
        const pageHeight = document.documentElement.clientHeight;
        const tableRowHeight = 45; // px
        const heightOfOtherDivs = 302; // px
        return Math.floor((pageHeight - heightOfOtherDivs) / tableRowHeight);
    };
    const [api, Provider] = newSmart(ClientRequestsAntTableSmart, {
        perPage: getRowsPerPage(),
        // filters: ClientRequestsAntTableSmart.defaultFilters,
        // body: { $: { filters: ClientRequestsAntTableSmart.defaultFilters } },
    });
    const onFiltersUpdate = useCallback((filters) => {
        api.setFilters({
            $and: [{ isArchived: filters.isArchived }]
                .concat(Array.isArray(filters.status) && filters.status.length > 0
                ? [
                    {
                        status: { $in: filters.status },
                    },
                ]
                : [])
                .concat(filters.reference.trim() !== ""
                ? [
                    {
                        $or: [
                            {
                                reference: new RegExp(`${filters.reference}`, "i"),
                            },
                            {
                                "invoices.number": new RegExp(`${filters.reference}`, "i"),
                            },
                            {
                                "quotations.number": new RegExp(`${filters.reference}`, "i"),
                            },
                        ],
                    },
                ]
                : []),
        });
    }, []);
    return (_jsxs(UIComponents.AdminLayout, { children: [api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") })), _jsx(Ant.Layout.Content, { children: _jsx(Provider, { children: _jsxs("div", { className: "page-client-requests-list", children: [_jsx(ClientRequestsListFilters, { onUpdate: onFiltersUpdate, newBoilerServiceMission: _jsx(Ant.Button, { type: "primary", className: "blue-btn", onClick: () => {
                                        redirectToBoilerService();
                                    }, icon: _jsx(PlusOutlined, {}), children: "Nouvel entretien chaudi\u00E8re" }, "1"), newMission: _jsx(Ant.Button, { type: "primary", onClick: () => {
                                        api.createNewClientRequest();
                                    }, icon: _jsx(PlusOutlined, {}), children: t("management.client_requests.list.create_btn") }, "1") }), _jsx(Ant.Table, { className: "table clickable", ...api.getTablePropsWithFilters(), size: "small" })] }) }) })] }));
}
