import moment from "moment";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import axios from "axios";
export const urlRegexExpression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i;
export const urlRegex = new RegExp(urlRegexExpression);
/**
 * Given a string, return the hash code of the string
 * @param str - The string to hash.
 * @returns The hash code of the string.
 */
export const hashCode = (str) => {
    // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};
/**
 * Given an integer, return a string of 6 characters representing the color
 * @param i - The color index.
 * @returns The hexadecimal representation of the color.
 */
export const intToRGB = (i) => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
};
/**
 * Given a string, return a color
 * @param str - The string to hash.
 */
export const getColorFromString = (str) => `#${intToRGB(hashCode(str))}`;
export const removeDuplicates = (arrayWithDuplicates) => Array.from(new Set(arrayWithDuplicates));
export const removeObjectIdDuplicates = (arrayWithDuplicates) => Array.from(new Set(arrayWithDuplicates.map((id) => id.toString()))).map((id) => new ObjectId(id));
/**
 * Given an object and a field name, return the value of the field
 * @param obj - The object to get the value from.
 * @param [fieldToGet] - The field to get from the object.
 * @returns The value of the nested field.
 */
export const getNestedValue = (obj = {}, fieldToGet = "") => {
    const sfield = fieldToGet.split(".");
    try {
        let value = obj[sfield.shift()];
        sfield.forEach((field) => {
            value = value[field];
        });
        return value;
    }
    catch {
        return undefined;
    }
};
/**
 * Is the first value of an array an object ? If yes, we assume the whole array is an array of objects
 * @param value - The value to check.
 */
export const isArrayOfObjects = (value) => Array.isArray(value) && value.length > 0 && isObjectPrototype(value[0]);
/**
 * Check if an object has no iterable key
 * @param {object} obj - object — The object to check.
 */
export const isEmptyObject = (obj) => obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    isObjectPrototype(obj);
/**
 * Returns `true` if the given value is neither null nor undefined and its prototype is the
 * `Object.prototype`
 * @param value - The value to check.
 */
export const isObjectPrototype = (value) => value !== null &&
    value !== undefined &&
    Object.getPrototypeOf(value) === Object.prototype;
/**
 * A function that takes an array of objects and returns a new array of
 * objects with the `__typename` property removed from each object
 * @param {T[]} array - The array to be transformed.
 */
export const arrayWithoutTypenameDeep = (array) => array.map((row) => Object.assign({}, withoutTypenameDeep(row)), {});
/**
 * It takes a key or an array of keys and returns a partial object without the given keys
 * @param {keyof T | (keyof T)[]} keys - The key or keys to remove from the object.
 * @returns An object with the same keys as the original object, but with the values of the keys that
 * were not specified in the first argument.
 */
export const without = (keys) => (object) => {
    if (typeof keys === "string") {
        const { [keys]: keyToRemove, ...rest } = object;
        return rest;
    }
    else {
        return Object.entries(object).reduce((acc, row) => {
            if (!keys.includes(row[0])) {
                acc[row[0]] = row[1];
            }
            return acc;
        }, {});
    }
};
export const withoutId = without("_id");
export const withoutLabel = without("label");
export const withoutTypename = without("__typename");
export const withoutTypenameNorId = without(["__typename", "_id"]);
/**
 * It removes the `__typename` field from the object and its nested objects and arrays
 * @param dict - The dictionary to be filtered.
 * @returns An object with the same keys as the original object, but with the __typename property
 * removed.
 */
export const withoutTypenameDeep = (dict) => {
    if (isObjectPrototype(dict)) {
        return Object.entries(dict).reduce((acc, [key, value]) => {
            if (key !== "__typename") {
                const clone = isObjectPrototype(value)
                    ? withoutTypenameDeep(value)
                    : isArrayOfObjects(value)
                        ? arrayWithoutTypenameDeep(value)
                        : value;
                acc[key] = clone;
            }
            return acc;
        }, {});
    }
    if (isArrayOfObjects(dict)) {
        return arrayWithoutTypenameDeep(dict);
    }
    return dict;
};
/**
 * Is the target an object and not an array?
 * @param target - The target value to be tested.
 */
export const isAnObject = (target) => typeof target === "object" && !Array.isArray(target) && target !== null;
/**
 * Given a key, return a function that returns the value of that key from an object
 * @param {string} keyToExtract - string
 */
export const extract = (keyToExtract) => (obj) => obj[keyToExtract];
export const extractId = extract("_id");
/**
 * Return a mask function to shallow clone an object keeping only the specified keys
 * @param {string[]} keys - an array of strings that represent the keys of the object you want to pick.
 * @returns An object with the properties of the target object that are in the keys array.
 */
export const pick = (keys) => (target) => {
    if (target) {
        return keys.reduce((obj, key) => {
            if (target.hasOwnProperty(key)) {
                obj[key] = target[key];
            }
            return obj;
        }, {});
    }
    return target;
};
/**
 * It takes a mask object and returns a function that takes an object and returns a partial object deeply filtered according to the mask
 * @param {PickMask} mask - PickMask
 * @returns An object with only the filtered keys from the target
 */
export const makePickWithMask = (mask) => {
    const maskKeys = Object.keys(mask);
    return (target) => {
        if (target) {
            return maskKeys.reduce((obj, maskKey) => {
                if (typeof mask[maskKey] === "number" && mask[maskKey]) {
                    if (target.hasOwnProperty(maskKey)) {
                        obj[maskKey] = target[maskKey];
                    }
                }
                else if (isObjectPrototype(mask[maskKey])) {
                    if (target.hasOwnProperty(maskKey)) {
                        const deepPicker = makePickWithMask(mask[maskKey]);
                        obj[maskKey] = Array.isArray(target[maskKey])
                            ? target[maskKey].map(deepPicker)
                            : deepPicker(target[maskKey]);
                    }
                }
                return obj;
            }, {});
        }
        return;
    };
};
/**
 * Takes a date and returns a "DD/MM/YYYY" format string
 * @param {Date} date - Date
 */
export const displayDate = (date) => date ? moment(date).format("DD/MM/YYYY") : "-";
/**
 * Get the preferred language of the user from the navigator
 * @returns The first language in the list of preferred languages.
 */
export const getPreferredLang = () => {
    if (navigator.languages != undefined)
        return navigator.languages[0];
    return navigator.language;
};
/**
 * Returns the language code of the user's preferred language
 * @returns The language code for the current locale.
 */
export const getLocaleLang = () => {
    const frLocaleLangRegEx = /fr*/g;
    const isFr = frLocaleLangRegEx.test(getPreferredLang());
    return isFr ? "fr" : getPreferredLang();
};
/**
 * It takes an error object as an argument, and if it has a message property, it displays that message
 * to the user (both on screen and in console). Otherwise, it displays the error's reason property
 * @param error - The error object.
 */
export const genericErrorHandler = (error) => {
    Ant.message.error(error.message || error.reason);
    console.error("[ERROR] handleSendByEmail", error);
};
export const getLocationContactAddress = (locationContact) => {
    const { locationIndex } = locationContact;
    if (locationContact.profile !== null) {
        return (locationContact.locationIndex || 0) === 0
            ? locationContact.profile.address || {}
            : locationContact.profile.addresses[locationIndex - 1] || {};
    }
    return (locationContact.locationIndex || 0) === 0
        ? locationContact.agency.address || {}
        : locationContact.agency.addresses[locationIndex - 1] || {};
};
// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
export const copyToClipboard = (text) => {
    // @ts-ignore
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        // @ts-ignore
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported &&
        document.queryCommandSupported("copy")) {
        const textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
};
export const getOngoingSubscriptions = async (payerId) => {
    const token = window.localStorage.getItem("bluelibs-token");
    return (await axios.request({
        method: "GET",
        headers: { "bluelibs-token": token },
        url: `${process.env.API_URL.replace("/graphql", "").concat(`/subscriptions/ongoingSubscriptions?payerId=${payerId}`)}`,
    })).data.ongoingSubscriptions;
};
