import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslate } from "@bluelibs/x-ui";
export const ALL = "ALL";
export const useTagsFilterHooks = (props) => {
    var _a;
    const t = useTranslate();
    const tags = useMemo(() => {
        const derivedTags = [...props.tags];
        if (!props.skipAllOption) {
            derivedTags.unshift({ title: ALL });
        }
        return derivedTags;
    }, [props.tags]);
    const [selectedParentStatusTag, setSelectedParentStatusTag] = useState(tags[0]);
    const [selectedChildStatus, setSelectedChildStatus] = useState(ALL);
    const resetSelectedStatusChildTagTitle = () => setSelectedChildStatus(ALL);
    const onParentTagClick = useCallback((tag) => {
        setSelectedParentStatusTag(tag);
        resetSelectedStatusChildTagTitle();
    }, []);
    // Pre-loads correct initial status (fetched from URL).
    useEffect(() => {
        var _a;
        if (props.value && props.value.length > 0) {
            const value = props.value[0];
            for (let i = 0; i < tags.length; i += 1) {
                const tag = tags[i];
                const childTag = (_a = tag.values) === null || _a === void 0 ? void 0 : _a.find((t) => { var _a; return t === value && ((_a = props.value) === null || _a === void 0 ? void 0 : _a.length) < 2; });
                if (tag.title === value || childTag) {
                    setSelectedParentStatusTag(tag);
                    if (childTag) {
                        setSelectedChildStatus(childTag);
                    }
                    break;
                }
            }
        }
    }, [(_a = props.value) === null || _a === void 0 ? void 0 : _a.length]);
    return {
        t,
        counts: props.counts,
        selectedParentStatusTag,
        selectedChildStatus,
        setSelectedChildStatus,
        onParentTagClick,
        tags,
    };
};
