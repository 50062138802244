import "./i18n";
import { ContactsOutlined } from "@ant-design/icons";
import { PROFILES_LIST as BASE_PROFILES_LIST, PROFILES_CREATE as BASE_PROFILES_CREATE, PROFILES_EDIT as BASE_PROFILES_EDIT, PROFILES_VIEW as BASE_PROFILES_VIEW, } from "./config/routes";
import { UserRoles } from "@root/api.types";
export const PROFILES_LIST = {
    ...BASE_PROFILES_LIST,
    path: "/profiles",
    menu: {
        ...BASE_PROFILES_LIST.menu,
        icon: ContactsOutlined,
        order: 2,
        roles: [UserRoles.ADMIN, UserRoles.SUPPORT],
    },
};
export const PROFILES_CREATE = {
    ...BASE_PROFILES_CREATE,
    path: "/profiles/create",
};
export const PROFILES_EDIT = {
    ...BASE_PROFILES_EDIT,
    path: "/profiles/:id/edit",
};
export const PROFILES_VIEW = {
    ...BASE_PROFILES_VIEW,
    path: "/profiles/:id/view",
};
