import "./i18n";
import { USERS_LIST as BASE_USERS_LIST, USERS_CREATE as BASE_USERS_CREATE, USERS_EDIT as BASE_USERS_EDIT, USERS_VIEW as BASE_USERS_VIEW, } from "./config/routes";
import { ADMIN_MENU } from "../const";
import { UserRoles } from "@root/api.types";
export const USERS_LIST = {
    ...BASE_USERS_LIST,
    path: "/users",
    menu: {
        ...BASE_USERS_LIST.menu,
        inject: ADMIN_MENU,
        roles: [UserRoles.ADMIN],
    },
};
export const USERS_CREATE = {
    ...BASE_USERS_CREATE,
};
export const USERS_EDIT = {
    ...BASE_USERS_EDIT,
};
export const USERS_VIEW = {
    ...BASE_USERS_VIEW,
};
