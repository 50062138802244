var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { AgenciesTagEditForm as BaseAgenciesTagEditForm } from "./AgenciesTagEditForm.base";
import { useTranslate } from "@bluelibs/x-ui";
import { setAllXFormElemsPlaceholders } from "../../utils";
import * as Ant from "antd";
import { AgenciesTagColor } from "@root/api.types";
import { colors } from "@root/utils/colors";
let AgenciesTagEditForm = class AgenciesTagEditForm extends BaseAgenciesTagEditForm {
    build() {
        super.build();
        const t = useTranslate();
        // Perform additional modifications such as updating rendering functions, labels, description
        setAllXFormElemsPlaceholders(this);
        const options = Object.values(AgenciesTagColor).map((option) => {
            var _a;
            return ({
                key: option,
                label: t(`management.agencies_tags.fields.color_enum.${option}`),
                value: option,
                style: { backgroundColor: (_a = colors[option]) === null || _a === void 0 ? void 0 : _a.hex },
            });
        });
        this.update("color", {
            render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.agencies_tags.fields.color"), options: options }) })),
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
AgenciesTagEditForm = __decorate([
    Service({ transient: true })
], AgenciesTagEditForm);
export { AgenciesTagEditForm };
