import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DownOutlined } from "@ant-design/icons";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import { Button, Dropdown, Menu, Space } from "antd";
import { Routes } from "../";
import { useLocation } from "react-router-dom";
import * as Ant from "antd";
const getPageHeaderName = (location) => {
    const locationWithoutFirstSlash = location.pathname.substring(1);
    const indexOfSlash = locationWithoutFirstSlash.indexOf("/");
    const pageName = (indexOfSlash == -1
        ? locationWithoutFirstSlash
        : locationWithoutFirstSlash.substring(0, indexOfSlash)).replace(new RegExp("-", "g"), "_");
    return pageName;
};
export function AdminTopHeader() {
    var _a;
    const guardian = useGuardian();
    const router = useRouter();
    const t = useTranslate();
    const { user } = guardian.state;
    const location = useLocation();
    if (!guardian.state.initialised || !user) {
        return null;
    }
    const menu = (_jsxs(Menu, { mode: "horizontal", defaultSelectedKeys: ["2"], children: [_jsx(Menu.Divider, {}), _jsx(Menu.Item, { onClick: () => router.go(Routes.CHANGE_PASSWORD), children: "Modifier mon mot de passe" }, "1"), _jsx(Menu.Item, { onClick: () => guardian.logout().then(() => {
                    router.go(Routes.LOGIN);
                }), children: "Se d\u00E9connecter" }, "2")] }));
    return (_jsxs(_Fragment, { children: [_jsx(Space, { align: "start", size: 80, children: _jsx(Ant.PageHeader, { className: "page-header", title: t(`management.${getPageHeaderName(location)}.list.header`) }) }), _jsx(Space, { align: "end", size: 8, children: _jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(Button, { icon: _jsx(DownOutlined, {}), children: (_a = user === null || user === void 0 ? void 0 : user.profile) === null || _a === void 0 ? void 0 : _a.firstName }) }) })] }));
}
