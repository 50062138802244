import { AgenciesTagsList } from "../components/List/AgenciesTagsList";
import { AgenciesTagsCreate } from "../components/Create/AgenciesTagsCreate";
import { AgenciesTagsEdit } from "../components/Edit/AgenciesTagsEdit";
import { AgenciesTagsView } from "../components/View/AgenciesTagsView";
import { SettingFilled } from "@ant-design/icons";
export const AGENCIES_TAGS_LIST = {
    path: "/admin/agencies-tags",
    component: AgenciesTagsList,
    menu: {
        key: "AGENCIES_TAGS_LIST",
        label: "management.agencies_tags.menu.title",
        icon: SettingFilled,
    },
};
export const AGENCIES_TAGS_CREATE = {
    path: "/admin/agencies-tags/create",
    component: AgenciesTagsCreate,
};
export const AGENCIES_TAGS_EDIT = {
    path: "/admin/agencies-tags/:id/edit",
    component: AgenciesTagsEdit,
};
export const AGENCIES_TAGS_VIEW = {
    path: "/admin/agencies-tags/:id/view",
    component: AgenciesTagsView,
};
