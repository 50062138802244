import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import * as Ant from "antd";
import { CalendarOutlined, CommentOutlined, DoubleRightOutlined, SendOutlined, } from "@ant-design/icons";
import { colors } from "@root/utils/colors";
const getIconStyle = (filterState, iconColor) => {
    return {
        borderRadius: 2,
        padding: 2,
        backgroundColor: iconColor.opacity(filterState ? 1 : 0.1),
        color: filterState ? "white" : iconColor.hex,
    };
};
export const TimeLineFilterTags = memo((props) => {
    const filterTagsParams = {
        commentsParams: {
            tooltipTitle: "commentaires",
            tagKey: "comments-tag-filter",
            filterState: props.commentsUseState.commentsFilter,
            setFilterState: props.commentsUseState.setCommentsFilter,
            icon: (_jsx(CommentOutlined, { style: getIconStyle(props.commentsUseState.commentsFilter, colors.selfcityYellow) })),
        },
        tripListsParams: {
            tooltipTitle: "déplacements",
            tagKey: "trip-lists-tag-filter",
            filterState: props.tripListsUseState.tripListsFilter,
            setFilterState: props.tripListsUseState.setTripListsFilter,
            icon: (_jsx(CalendarOutlined, { style: getIconStyle(props.tripListsUseState.tripListsFilter, colors.tripBlue) })),
        },
        followUpsParams: {
            tooltipTitle: "relances",
            tagKey: "follow-ups-tag-filter",
            filterState: props.followUpsUseState.followUpsFilter,
            setFilterState: props.followUpsUseState.setFollowUpsFilter,
            icon: (_jsx(SendOutlined, { style: getIconStyle(props.followUpsUseState.followUpsFilter, colors.selfcityGreen) })),
        },
        statusesParams: {
            tooltipTitle: "changement de status",
            tagKey: "statuses-tag-filter",
            filterState: props.statusesUseState.statusesFilter,
            setFilterState: props.statusesUseState.setStatusesFilter,
            icon: (_jsx(DoubleRightOutlined, { style: getIconStyle(props.statusesUseState.statusesFilter, colors.selfcityBlue) })),
        },
    };
    return (_jsxs("div", { className: "mar-b-1", children: [_jsx("span", { style: { color: "rgba(0,0,0,.45)" }, children: "Filtrer par : " }), Object.values(filterTagsParams).map((filterTagParams) => {
                return (_jsx(Ant.Tooltip, { title: filterTagParams.tooltipTitle, children: _jsx(Ant.Tag.CheckableTag, { className: "timeline-filter-tag", checked: filterTagParams.filterState, onChange: filterTagParams.setFilterState, children: filterTagParams.icon }, filterTagParams.tagKey) }));
            })] }));
});
