import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Badge, Menu as AntdMenu } from "antd";
import { use, useGuardian, useRouter, useTranslate, useUIComponents, } from "@bluelibs/x-ui";
import { MenuService } from "@bluelibs/x-ui-admin/dist/services/MenuService";
import { ADMIN_MENU } from "../pages/const";
import { UserRoles } from "@root/api.types";
import { INBOX_TASKS_LIST } from "../routes";
import axios from "axios";
import { CalendarOutlined } from "@ant-design/icons";
const AntdSubMenu = AntdMenu.SubMenu;
export function AdminMenu() {
    const menuService = use(MenuService);
    const guardian = useGuardian();
    const router = useRouter();
    const t = useTranslate();
    const Components = useUIComponents();
    if (!guardian.state.initialised) {
        return null;
    }
    // We filter on each render for now because it should be super fast.
    // Otherwise we would need to do it in each MenuItem which can be cumbersome.
    const items = menuService.items
        .filter((item) => {
        // if isAdminMenuItem
        if (item.key === ADMIN_MENU) {
            // setAdminMenuRoles
            item = { ...item, roles: [UserRoles.ADMIN] };
        }
        if (item.roles) {
            return guardian.hasRole(item.roles);
        }
        return true;
    })
        .sort((a, b) => a.order - b.order);
    // Detect which paths are active based on their logic
    const { pathname } = router.history.location;
    const selectedOrOpenKeys = getSelectedKeys(items, pathname);
    return (_jsxs(AntdMenu, { mode: "inline", defaultOpenKeys: selectedOrOpenKeys, defaultSelectedKeys: selectedOrOpenKeys, children: [items.map((item) => {
                return renderItem(item, router, t);
            }), _jsx(AntdMenu.Item, { onClick: () => {
                    window.location.href = global.NEW_APP_URL + `/agenda?token=${window.localStorage.getItem("bluelibs-token")}`;
                }, icon: React.createElement(CalendarOutlined), children: _jsx("span", { children: "Agenda" }) }, "agenda")] }));
}
export function renderItem(item, router, t) {
    if (item.subitems && item.subitems.length) {
        return (_jsx(AntdSubMenu, { title: _jsx(ItemRender, { item: item, t: t }), icon: item.icon ? React.createElement(item.icon) : undefined, onTitleClick: (e) => {
                if (item.path) {
                    router.history.push(item.path);
                }
            }, children: item.subitems.map((subitem) => {
                return renderItem(subitem, router, t);
            }) }, item.key));
    }
    return (_jsx(AntdMenu.Item, { onClick: () => {
            if (item.path) {
                router.history.push(item.path);
            }
        }, icon: item.icon ? React.createElement(item.icon) : undefined, children: _jsx(ItemRender, { item: item, t: t }) }, item.key));
}
function ItemRender(props) {
    const { item, t } = props;
    const [inboxTasksFiltersTotalCount, setInboxTasksFiltersTotalCount] = React.useState(null);
    React.useEffect(() => {
        if (item.key === INBOX_TASKS_LIST.menu.key)
            getInboxTasksFiltersTotalCount().then(setInboxTasksFiltersTotalCount);
    }, []);
    if (typeof item.label === "string") {
        if (item.key === INBOX_TASKS_LIST.menu.key) {
            return (_jsxs("div", { className: "flex justify-between items-center", children: [_jsx("span", { children: t(item.label) }), _jsxs("div", { className: "flex hgap-1", children: [_jsx(Badge, { count: inboxTasksFiltersTotalCount === null || inboxTasksFiltersTotalCount === void 0 ? void 0 : inboxTasksFiltersTotalCount.reachedDueDateTasks }), _jsx(Badge, { count: inboxTasksFiltersTotalCount === null || inboxTasksFiltersTotalCount === void 0 ? void 0 : inboxTasksFiltersTotalCount.tasksTotalNumber, style: { backgroundColor: "var(--ant-primary-color)" } })] })] }));
        }
        else {
            return _jsx("span", { children: t(item.label) });
        }
    }
    return React.createElement(item.label);
}
function getSelectedKeys(items, pathname) {
    const selectedKeys = [];
    items.forEach((item) => {
        if (item.isSelected) {
            if (item.isSelected(pathname)) {
                selectedKeys.push(item.key ? item.key : item.inject);
            }
        }
        else if (item.inject === pathname || item.key === pathname) {
            selectedKeys.push(item.key ? item.key : item.inject);
        }
        if (item.subitems) {
            const itemKeys = getSelectedKeys(item.subitems, pathname);
            selectedKeys.push(...itemKeys);
        }
    });
    return selectedKeys;
}
const getInboxTasksFiltersTotalCount = async () => {
    const token = window.localStorage.getItem("bluelibs-token");
    return (await axios.request({
        method: "GET",
        headers: { "bluelibs-token": token },
        url: `${process.env.API_URL.replace("/graphql", "").concat(`/inbox-tasks-filters-count/total`)}`,
    })).data.inboxTasksFiltersTotalCount;
};
