import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
// ###### 2 => React imports #############
import React, { memo } from "react";
import * as Ant from "antd";
// ###### 3 => Npm imports ###############
// ###### 4 => Local folder imports ######
// ###### 5 => Local app imports #########
import { useModalhooks } from "./Modal.hooks";
export const defaultOkBtnProps = { htmlType: "submit" };
export const Modal = memo((props) => {
    const hooks = useModalhooks(props);
    const t = useTranslate();
    if (props.modalRef) {
        props.modalRef.current = hooks;
    }
    return (_jsx(Ant.Modal, { ...props, title: _jsx("div", { className: "green-modal-head", children: props.title }), visible: hooks.visible, className: "green-modal custom-modal", closable: (props === null || props === void 0 ? void 0 : props.closable) ? props === null || props === void 0 ? void 0 : props.closable : false, cancelText: props.cancelText || t("forms.actions.cancel"), children: props.children }));
});
