var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { translateEnumValues } from "../../utils";
import { ProfileViewer as BaseProfileViewer } from "./ProfileViewer.base";
let ProfileViewer = class ProfileViewer extends BaseProfileViewer {
    build() {
        super.build();
        const { t } = this.i18n;
        this.remove("address.geopoint");
        translateEnumValues([
            {
                enumItemId: "gender",
                enumItemI18nPath: "management.profiles.fields.gender_enum",
            },
        ], t, this);
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
ProfileViewer = __decorate([
    Service({ transient: true })
], ProfileViewer);
export { ProfileViewer };
