import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ClientRequestPossibleStatus, } from "@root/api.types";
import { memo } from "react";
import { Card, Input, Select, Dropdown, Menu, Tooltip } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useUIComponents } from "@bluelibs/x-ui";
import { ClientRequestArchivingModal } from "../ClientRequestArchivingModal/ClientRequestArchivingModal.index";
import { useClientRequestSumupCardHooks } from "./ClientRequestSumupCard.hooks";
import clsx from "clsx";
export const defaultCREmergencyLevels = {
    AS_SOON_AS_POSSIBLE: 100,
    IN_THE_NEXT_FEW_DAYS: 50,
    I_M_FLEXIBLE: 0,
};
const clntReqDescTextAreaStyle = {
    height: "106px",
};
export const ClientRequestSumupCard = memo((props) => {
    const { t, xForm, clientRequest, emergencyLevelHooks, statusHooks, sourceLeadHooks, activityHooks, descriptionHooks, onArchiveClientRequest, restoreClientRequest, subjectHooks, } = useClientRequestSumupCardHooks(props);
    const UIComponents = useUIComponents();
    const menu = (_jsx(Menu, { children: clientRequest.isArchived ? (_jsx(Menu.Item, { onClick: restoreClientRequest, children: t("management.client_requests.actions.restore") }, "restoreMenuBtn")) : (_jsx(Menu.Item, { onClick: onArchiveClientRequest, children: t("management.client_requests.actions.archive") }, "archiveMenuBtn")) }));
    return (_jsxs(_Fragment, { children: [_jsx(Card, { size: "small", title: _jsx(Tooltip, { title: clientRequest.reference, children: _jsx("span", { className: "fs-1 fw-bold", children: clientRequest.reference }) }), extra: _jsxs("div", { className: "card-extra", children: [_jsx(Select, { showSearch: true, placeholder: t("management.client_requests.fields.sourceLead"), value: clientRequest.sourceLead, options: sourceLeadHooks.options, onSelect: sourceLeadHooks.onSelect, className: clsx({ warning: !clientRequest.sourceLead }) }), _jsx(Select, { showSearch: true, className: "blue-select", dropdownClassName: "blue-select-item", placeholder: t("management.client_requests.fields.status"), value: clientRequest.status ||
                                ClientRequestPossibleStatus.S_00100_CLIENT_REQUEST, options: statusHooks.options, onSelect: statusHooks.onSelect }), _jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(MoreOutlined, { className: "fs-3", size: 1 }) })] }), children: _jsx(Input.TextArea, { style: clntReqDescTextAreaStyle, defaultValue: clientRequest.description, onBlur: descriptionHooks.onBlur, placeholder: t("management.client_requests.fields.description") }) }), !clientRequest.isArchived && (_jsx(ClientRequestArchivingModal, { clientRequest: clientRequest }))] }));
});
