export const phrasesFr = {
    fr: {
        management: {
            admin: {
                list: {
                    header: "Tags de tâches",
                },
            },
            inbox_tags: {
                menu: {
                    title: "Tags de tâches",
                },
                fields: {
                    _id: "ID",
                    name: "Nom",
                    createdAt: "Créé le",
                    updatedAt: "Modifié le",
                },
                list: {
                    header: "Tags de tâches",
                    create_btn: "Nouveau tag",
                },
                create: {
                    header: "Créer un tag",
                },
                edit: {
                    header: "Éditer",
                },
                view: {
                    header: "Afficher",
                },
                delete_confirmation: "Vous avez supprimé le tag",
                create_confirmation: "Vous avez ajouté avec succès un nouveau tag",
                edit_confirmation: "Vous avez modifié avec succès le tag",
            },
        },
    },
};
