import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// ###### 1 => Framework imports ############
import { use, useTranslate } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
// ###### 2 => React imports #############
import React, { memo, useCallback } from "react";
import * as Ant from "antd";
import { CloseOutlined, EditOutlined, EyeOutlined, InfoOutlined, PlusOutlined, SendOutlined, } from "@ant-design/icons";
import CopyOutlinedIcon from "@ant-design/icons/CopyOutlined";
import { FollowUpsCollection, InboxTasksCollection, } from "@bundles/UIAppBundle/collections";
import { displayDate } from "@root/utils/lib";
import { DocumentTypePath, DownloadFileButton, getDocumentUrl, getQuotationFileNameAndUrl, } from "../DownloadFileButton";
import { QuotationStatusTag } from "../StatusTag/StatusTag.index";
import clsx from "clsx";
import { QuotationTag } from "../QuotationTag";
import { CardEmpty } from "../CardEmpty/CardEmpty.index";
import { ToolTippedButton } from "../ToolTippedButton/ToolTippedButton.index";
import { getFollowUpsTooltipString } from "../ClientRequestInvoicesCard/ClientRequestInvoicesCard.index";
import { useApolloClient } from "@apollo/client";
import { getTotalDiscountedPriceIT } from "@root/utils/prices";
import axios from "axios";
const downloadBtnProps = { type: "text" };
const selfcityBotId = new ObjectId('626adec3bfffbd0fec9e9117');
const preparationInboxTagIds = {
    production: '63454673a0025ef8bcd61f96',
    staging: '63846fb47619311376bf17b4',
    development: '6283a7f2ec50674d56b67cd5',
};
export const inboxTasksTitle = {
    askForDeposit: 'Acompte (Paiement 4x OU facture d’acompte) ⇒ Valider la tâche quand acompte payé',
    order: 'Passer les commandes (Castor + Fournisseur) ⇒ Valider la tâche quand commandes payées',
    makeAppointment: 'Prendre RDV avec le client (48h après réception matériel)',
    makeAppointmentWithDelivery: 'Prendre RDV avec le client  (48h après réception matériel) + Prévoir livraison',
    makeAppointmentWithClearing: 'Prendre RDV avec le client  (48h après réception matériel) + Prévoir débarrassage',
    makeAppointmentWithDeliveryAndClearing: 'Prendre RDV avec le client  (48h après réception matériel) + Prévoir livraison + Prévoir débarrassage',
};
export const createInboxTask = async (title, clientRequestId, inboxTasksCollection) => await inboxTasksCollection.insertOne({
    tagId: new ObjectId(preparationInboxTagIds[window.location.host === 'belett2.selfcity.fr' ? 'production' : 'staging']),
    clientRequestId: clientRequestId,
    dueDate: new Date(),
    priority: 1,
    title: title,
    done: false,
    // createdAt: new Date(),
    // updatedAt: new Date(),
    // createdById: selfcityBotId,
    // updatedById: selfcityBotId,
});
export const ClientRequestQuotationsCard = memo((props) => {
    var _a, _b, _c;
    const t = useTranslate();
    const inboxTasksCollection = use(InboxTasksCollection);
    const client = useApolloClient();
    const onOpenQuotationModal = useCallback((id) => {
        const token = window.localStorage.getItem("bluelibs-token");
        if (id) {
            window.location.href = global.NEW_APP_URL + '/missions/' + props.clientRequest._id + '/quotes/' + id + '/edit?token=' + token;
        }
        else {
            window.location.href = global.NEW_APP_URL + '/missions/' + props.clientRequest._id + '/quotes/create?token=' + token;
        }
    }, [`${props.clientRequest._id}`]);
    const onRejectQuotation = React.useCallback((quote) => {
        Ant.Modal.confirm({
            okText: "Confirmer",
            cancelText: "Annuler",
            content: _jsx("div", { children: "Marquer le devis comme refus\u00E9 ? Cette action est irr\u00E9versible." }),
            onOk: () => {
                const token = window.localStorage.getItem("bluelibs-token");
                axios.request({
                    method: "POST",
                    url: global.NEW_API_URL + '/quotes/' + quote._id + '/reject',
                    headers: { "Authorization": token },
                }).then(() => {
                    props.onReload();
                }).catch((e) => {
                    Ant.notification.warn({
                        message: 'Erreur',
                        description: e.response.data.error.code,
                    });
                });
            },
        });
    }, []);
    const followUpsCollection = use(FollowUpsCollection);
    const { data: followUps, loading: loadingFollowUps, 
    // refetch,
    error: followUpsError, } = followUpsCollection.useQuery({
        queryInput: {
            filters: { clientRequestId: new ObjectId(`${props.clientRequest._id}`) },
            options: { sort: { createdAt: -1 } },
        },
        body: {
            _id: 1,
            quotationId: 1,
            invoiceId: 1,
            createdAt: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
        },
    });
    const duplicateJobEstimation = React.useCallback((quote) => async () => {
        Ant.Modal.confirm({
            content: (_jsx("div", { children: "Es-tu s\u00FBr(e) de vouloir dupliquer l'estimation li\u00E9e au devis ?" })),
            okText: `Oui`,
            cancelText: `Annuler`,
            onOk: () => {
                const token = window.localStorage.getItem("bluelibs-token");
                axios.request({
                    method: "GET",
                    url: global.NEW_API_URL + '/estimates/' + quote._estimate._id + '?fields=*',
                    headers: { "Authorization": token },
                }).then((response) => {
                    return axios.request({
                        method: "POST",
                        url: global.NEW_API_URL + '/missions/' + quote._mission + '/estimates',
                        headers: { "Authorization": token },
                        data: {
                            dueDate: null,
                            assignedTo: response.data.assignedTo,
                            categories: response.data.categories,
                            packages: response.data.packages.map((pack) => ({
                                template: pack.template,
                                options: pack.options,
                                subject: pack.subject,
                            })),
                            attachments: [],
                            // TODO attachments
                        },
                    }).then(() => {
                        props.onReload();
                    });
                }).catch((e) => {
                    Ant.notification.warn({
                        message: 'Erreur',
                        description: e.response.data.error.code,
                    });
                });
            },
        });
    }, [(_a = props.jobEstimation) === null || _a === void 0 ? void 0 : _a._id]);
    return (_jsx(_Fragment, { children: _jsxs(Ant.Card, { size: "small", children: [_jsxs("div", { className: "flex spaced mar-b-2", children: [_jsx("div", { className: "fw-bold fs-2", children: "Devis" }), _jsx(Ant.Button, { className: "blue-btn", type: "primary", icon: _jsx(PlusOutlined, {}), onClick: () => onOpenQuotationModal(null), children: "Cr\u00E9er un devis" })] }), _jsx("div", { style: { overflow: "auto" }, children: ((_c = ((_b = props.quotes) !== null && _b !== void 0 ? _b : [])) === null || _c === void 0 ? void 0 : _c.length) > 0 ? (props.quotes.map((quote) => {
                        const hasBeenRefused = quote._status === 'REJECTED';
                        const addFollowUp = () => {
                            followUpsCollection
                                .insertOne({
                                clientRequestId: props.clientRequest._id,
                                quotationId: quote._id,
                            })
                                .then(() => {
                                props.onReload();
                            });
                        };
                        return (_jsxs("div", { className: clsx({
                                "opacity-50": hasBeenRefused,
                            }), children: [_jsxs("div", { children: [_jsx(QuotationTag, { quotation: quote }), quote._status !== 'SIGNED' && quote._status !== 'REJECTED' && (_jsx(Ant.Button, { type: "text", icon: _jsx(EditOutlined, {}), onClick: () => onOpenQuotationModal(quote._id) })), _jsx(DownloadFileButton, { downloadBtnProps: downloadBtnProps, ...getQuotationFileNameAndUrl({
                                                quotationId: quote._id,
                                                clientRequestId: props.clientRequest._id,
                                                quotationNumber: quote._number,
                                            }) }), _jsx(Ant.Tooltip, { title: "Visualiser le devis", children: _jsx(Ant.Button, { type: "text", icon: _jsx(EyeOutlined, {}), onClick: () => window.open(getDocumentUrl(DocumentTypePath.QUOTATION, quote._id)) }) }), _jsx(Ant.Tooltip, { style: { width: "max-content" }, overlayClassName: "display-linebreak", title: `Visualiser l'estimation`, children: _jsx(Ant.Button, { type: "text", icon: _jsx(InfoOutlined, {}), onClick: () => {
                                                    const token = window.localStorage.getItem("bluelibs-token");
                                                    window.location.href = global.NEW_APP_URL + '/missions/' + props.clientRequest._id + '/estimates/' + quote._estimate._id + '/edit?token=' + token;
                                                } }) }), _jsx(Ant.Tooltip, { style: { width: "max-content" }, overlayClassName: "display-linebreak", title: `Dupliquer l'estimation`, children: _jsx(Ant.Button, { type: "text", icon: _jsx(CopyOutlinedIcon, {}), onClick: duplicateJobEstimation(quote) }) }), quote._estimate.assignedTo && (_jsxs("span", { className: "fw-bold", style: { float: 'right' }, children: ["Estim\u00E9 par\u00A0 ", quote._estimate.assignedTo.firstName + ' ' + quote._estimate.assignedTo.lastName] }))] }), _jsxs("div", { className: "flex spaced a-center child-pad-r-1/2 nl", children: [_jsxs("div", { className: "fs-s ta-center", children: ["Cr\u00E9\u00E9 le\u00A0", _jsx("span", { className: "fw-bold", children: displayDate(quote._createdAt) })] }), quote._status !== 'TO_SEND' ? (_jsxs("div", { className: "w-1/4 fs-s ta-center", children: ["Envoy\u00E9 le\u00A0", _jsx("span", { className: "fw-bold", children: displayDate(quote._status === 'SENT' ? quote._updatedAt : new Date(new Date(quote._createdAt).getTime() + 5 * 60 * 1000)) })] })) : (_jsx("div", { className: "w-1/4", children: _jsx(Ant.Button, { className: "green-btn w-full", size: "small", disabled: quote._status !== 'TO_SEND', onClick: () => {
                                                    Ant.Modal.confirm({
                                                        okText: "Confirmer",
                                                        cancelText: "Annuler",
                                                        content: _jsx("div", { children: "Marquer le devis comme envoy\u00E9 ? Cette action est irr\u00E9versible." }),
                                                        onOk: () => {
                                                            const token = window.localStorage.getItem("bluelibs-token");
                                                            axios.request({
                                                                method: "POST",
                                                                url: global.NEW_API_URL + '/quotes/' + quote._id + '/send',
                                                                headers: { "Authorization": token },
                                                            }).then(() => {
                                                                props.onReload();
                                                            }).catch((e) => {
                                                                Ant.notification.warn({
                                                                    message: 'Erreur',
                                                                    description: e.response.data.error.code,
                                                                });
                                                            });
                                                        },
                                                    });
                                                }, children: "C'est envoy\u00E9" }) })), _jsx("div", { className: "flex spaced a-center w-1/4", children: followUps.filter((followUp) => `${followUp.quotationId}` === `${quote._id}`).length > 0 ? (_jsxs("div", { className: "w-full fs-s ta-center no-linebreak", children: ["Relanc\u00E9 le\u00A0", _jsx("span", { className: "fw-bold", children: displayDate(quote._updatedAt) }), followUps.filter((followUp) => `${followUp.quotationId}` === `${quote._id}`).length && (_jsx(ToolTippedButton, { title: getFollowUpsTooltipString(followUps.filter((followUp) => `${followUp.quotationId}` === `${quote._id}`), t), className: "green-btn ", type: "link", size: "small", icon: _jsx(SendOutlined, {}), onClick: addFollowUp, disabled: quote._status === 'SIGNED' || quote._status === 'REJECTED', children: followUps.filter((followUp) => `${followUp.quotationId}` === `${quote._id}`).length }))] })) : (_jsx(Ant.Button, { className: "green-btn w-full", size: "small", disabled: quote._status !== 'SENT', onClick: addFollowUp, children: "J'ai relanc\u00E9" })) }), _jsx("div", { className: "w-1/4", children: hasBeenRefused ? (_jsx(QuotationStatusTag, { status: quote._status })) : quote._status === 'SIGNED' ? (_jsxs("div", { className: "ta-center", children: [_jsx("span", { className: "fw-bold fc-sc-green", children: "Sign\u00E9\u00A0" }), _jsxs("span", { className: "fs-s", children: ["le", " ", _jsx("span", { className: "fw-bold", children: displayDate(quote._updatedAt) })] })] })) : (_jsx(Ant.Button, { className: "green-btn w-full", size: "small", disabled: quote._status === 'TO_SEND' || quote._status === 'REJECTED', onClick: () => {
                                                    Ant.Modal.confirm({
                                                        okText: "Confirmer",
                                                        cancelText: "Annuler",
                                                        content: _jsx("div", { children: "Marquer le devis comme sign\u00E9 ? Cette action est irr\u00E9versible." }),
                                                        onOk: () => {
                                                            const token = window.localStorage.getItem("bluelibs-token");
                                                            axios.request({
                                                                method: "POST",
                                                                url: global.NEW_API_URL + '/quotes/' + quote._id + '/sign',
                                                                headers: { "Authorization": token },
                                                            }).then(() => {
                                                                props.onReload();
                                                            }).catch((e) => {
                                                                Ant.notification.warn({
                                                                    message: 'Erreur',
                                                                    description: e.response.data.error.code,
                                                                });
                                                            });
                                                        },
                                                    });
                                                    (getTotalDiscountedPriceIT(quote.rows) / 100) > 350 && createInboxTask(inboxTasksTitle.askForDeposit, props.clientRequest._id, inboxTasksCollection);
                                                }, children: "C'est sign\u00E9 !" })) }), quote._status === 'SENT' && (_jsx(ToolTippedButton, { danger: true, title: "Refuser ce devis", size: "small", icon: _jsx(CloseOutlined, {}), onClick: () => onRejectQuotation(quote) }))] })] }, quote._id));
                    })) : (_jsx(CardEmpty, { description: "Pas de devis." })) })] }) }));
});
