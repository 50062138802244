import "./i18n";
import { AGENCIES_TAGS_LIST as BASE_AGENCIES_TAGS_LIST, AGENCIES_TAGS_CREATE as BASE_AGENCIES_TAGS_CREATE, AGENCIES_TAGS_EDIT as BASE_AGENCIES_TAGS_EDIT, AGENCIES_TAGS_VIEW as BASE_AGENCIES_TAGS_VIEW, } from "./config/routes";
import { ADMIN_MENU } from "../const";
import { UserRoles } from "@root/api.types";
export const AGENCIES_TAGS_LIST = {
    ...BASE_AGENCIES_TAGS_LIST,
    path: "/agencies-tags",
    menu: {
        ...BASE_AGENCIES_TAGS_LIST.menu,
        inject: ADMIN_MENU,
        roles: [UserRoles.ADMIN],
    },
};
export const AGENCIES_TAGS_CREATE = {
    ...BASE_AGENCIES_TAGS_CREATE,
};
export const AGENCIES_TAGS_EDIT = {
    ...BASE_AGENCIES_TAGS_EDIT,
};
export const AGENCIES_TAGS_VIEW = {
    ...BASE_AGENCIES_TAGS_VIEW,
};
