import { getNestedValue } from "./../../../../utils/lib";
export const emptyAddress = {
    streetOne: "",
    streetTwo: "",
    zip: "",
    town: "",
    country: "France",
    geopoint: null,
    formattedAddress: "",
};
const typesMap = {
    street_number: "streetNumber",
    postal_code: "zip",
    country: "country",
    route: "streetName",
    locality: "city",
};
export const convertLocationInput = (data) => {
    const location = data.address_components.reduce((result, component) => {
        const key = component.types.find((k) => typesMap[k]);
        if (key) {
            return {
                ...result,
                [typesMap[key]]: component.long_name,
            };
        }
        return result;
    }, {});
    const lat = data.geometry.location.lat();
    const lng = data.geometry.location.lng();
    return {
        streetOne: [location.streetNumber, location.streetName]
            .filter((i) => !!i)
            .join(" "),
        town: location.city,
        country: location.country,
        zip: location.zip,
        geopoint: { lat, lng },
        formattedAddress: data.formatted_address,
    };
};
export const getLocationInitialValue = (address) => {
    const googleValue = getNestedValue(address, "standards.google");
    if (googleValue) {
        return googleValue;
    }
    if (!address) {
        return undefined;
    }
    const result = [];
    if (address.streetOne) {
        result.push(address.streetOne);
    }
    if (address.town) {
        result.push(address.town);
    }
    // country is set in initial values so we don't want to display just "France"
    if (!result.length) {
        return undefined;
    }
    if (address.country) {
        result.push(address.country);
    }
    return result.filter((i) => !!i).join(", ");
};
