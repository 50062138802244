import "./i18n";
import { InboxOutlined } from "@ant-design/icons";
import { CLIENT_REQUESTS_LIST as BASE_CLIENT_REQUESTS_LIST, CLIENT_REQUESTS_CREATE as BASE_CLIENT_REQUESTS_CREATE, CLIENT_REQUESTS_EDIT as BASE_CLIENT_REQUESTS_EDIT, CLIENT_REQUESTS_VIEW as BASE_CLIENT_REQUESTS_VIEW, } from "./config/routes";
import { UserRoles } from "@root/api.types";
export const CLIENT_REQUESTS_LIST = {
    ...BASE_CLIENT_REQUESTS_LIST,
    path: "/client-requests",
    menu: {
        ...BASE_CLIENT_REQUESTS_LIST.menu,
        icon: InboxOutlined,
        order: 1,
        roles: [UserRoles.ADMIN, UserRoles.SUPPORT],
    },
};
export const CLIENT_REQUESTS_CREATE = {
    ...BASE_CLIENT_REQUESTS_CREATE,
    path: "/client-requests/create",
};
export const CLIENT_REQUESTS_EDIT = {
    ...BASE_CLIENT_REQUESTS_EDIT,
    path: "/client-requests/:id/edit",
};
export const CLIENT_REQUESTS_VIEW = {
    ...BASE_CLIENT_REQUESTS_VIEW,
    path: "/client-requests/:id/view",
    roles: [UserRoles.ADMIN, UserRoles.SUPPORT, UserRoles.PLUMBER],
};
