import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import * as Ant from "antd";
import moment from "moment";
import { FollowUpCard } from "./FollowUpCard.index";
import { colors } from "@root/utils/colors";
import { SendOutlined } from "@ant-design/icons";
const timelineItemStyle = { paddingBottom: "4px" };
const commentIconItemStyle = {
    borderRadius: 2,
    padding: 2,
    backgroundColor: colors.hexToRgbaString("#f0f7f4"),
    color: colors.selfcityGreen.hex,
};
export const TimelineFollowUpCard = memo((props) => {
    var _a, _b;
    return (_jsx("div", { children: _jsxs(Ant.Timeline.Item, { style: timelineItemStyle, dot: _jsx(SendOutlined, { style: commentIconItemStyle }), className: props.disabled ? "disabled" : "", children: [_jsxs(Ant.Typography.Text, { type: "secondary", className: "fs-s", children: [((_b = (_a = props.followUp) === null || _a === void 0 ? void 0 : _a.createdBy) === null || _b === void 0 ? void 0 : _b.fullName) || "Utilisateur supprimé", " -", " ", moment(props.followUp.createdAt).format("DD/MM/YYYY [à] HH[h]mm")] }), _jsx(FollowUpCard, { followUp: props.followUp })] }, props.followUp._id) }));
});
