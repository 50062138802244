import { ObjectId } from "@bluelibs/ejson";
import { use, useTranslate } from "@bluelibs/x-ui";
import { useCallback, useMemo } from "react";
import { ClientRequestsCollection } from "@bundles/UIAppBundle/collections";
import { ClientRequestActivity, ClientRequestSourceLead, ClientRequestPossibleStatus, } from "@root/api.types";
import { useOpenModal } from "@root/utils/hooks";
import { ClientRequestEditForm } from "@bundles/UIAppBundle/pages/ClientRequestsManagement/config/ClientRequestEditForm";
import { useApolloClient } from "@apollo/client";
export const defaultCREmergencyLevels = {
    AS_SOON_AS_POSSIBLE: 100,
    IN_THE_NEXT_FEW_DAYS: 50,
    I_M_FLEXIBLE: 0,
};
export const useClientRequestSumupCardHooks = (props) => {
    const t = useTranslate();
    const clientRequest = props.clientRequest;
    const clientRequestCollection = use(ClientRequestsCollection);
    const apolloClient = useApolloClient();
    const refetchClientRequest = useCallback(() => {
        apolloClient.refetchQueries({
            include: ["ClientRequestsFindOne"],
        });
    }, []);
    const emergencyLevelHooks = {
        options: useMemo(() => Object.entries(defaultCREmergencyLevels).map(([key, value]) => ({
            key,
            label: t(`management.client_requests.fields.emergencyLevel_enum.${key}`),
            value,
        })), []),
        onSelect: useCallback((_, { value }) => {
            clientRequestCollection
                .updateOne(clientRequest._id, { emergencyLevel: value }, clientRequestCollection.toQueryBody({ emergencyLevel: value }), 
            // @ts-ignore
            {
                optimisticResponse: {
                    ClientRequestsUpdateOne: {
                        _id: clientRequest._id,
                        __typename: "ClientRequest",
                        emergencyLevel: value,
                    },
                },
            })
                .then(refetchClientRequest);
        }, [clientRequestCollection, clientRequest._id]),
    };
    const statusHooks = {
        options: useMemo(() => Object.values(ClientRequestPossibleStatus)
            // TODO remove them from enums
            .filter((option) => option !==
            ClientRequestPossibleStatus.S_00680_INVOICE_COMPLICATED &&
            option !== ClientRequestPossibleStatus.S_00640_INVOICE_TO_CORRECT)
            .map((option) => ({
            key: option,
            label: t(`management.client_requests.fields.status_enum.${option}`),
            value: option,
        })), []),
        onSelect: useCallback((_, { value }) => {
            if (props.clientRequest.status !== value) {
                clientRequestCollection
                    .updateOne(clientRequest._id, { status: value }, { refetchBody: { status: 1 } })
                    .then(refetchClientRequest)
                    .then(() => window.location.reload());
            }
        }, [clientRequestCollection, clientRequest._id]),
    };
    const sourceLeadHooks = {
        options: useMemo(() => Object.values(ClientRequestSourceLead)
            .filter((option) => option !== ClientRequestSourceLead.OTHER)
            .map((option) => ({
            key: option,
            label: t(`management.client_requests.fields.sourceLead_enum.${option}`),
            value: option,
        })), []),
        onSelect: useCallback((_, { value }) => {
            clientRequestCollection
                .updateOne(clientRequest._id, {
                sourceLead: value,
            })
                .then(refetchClientRequest);
        }, [clientRequestCollection, clientRequest._id]),
    };
    const activityHooks = {
        options: useMemo(() => Object.values(ClientRequestActivity).map((option) => ({
            key: option,
            label: t(`management.client_requests.fields.activity_enum.${option}`),
            value: option,
        })), []),
        onSelect: useCallback((_, { value }) => {
            clientRequestCollection
                .updateOne(clientRequest._id, {
                activity: value,
            })
                .then(refetchClientRequest);
        }, [clientRequestCollection, clientRequest._id]),
    };
    const subjectHooks = {
        onSelect: useCallback((_, { value }) => {
            clientRequestCollection
                .updateOne(clientRequest._id, {
                subjectId: new ObjectId(value),
            })
                .then(refetchClientRequest);
        }, [clientRequestCollection, clientRequest._id]),
    };
    const descriptionHooks = {
        onBlur: (event) => {
            clientRequestCollection.updateOne(clientRequest._id, {
                description: event.target.value,
            });
        },
    };
    const openArchivingModal = useOpenModal("clientRequestArchive");
    const onArchiveClientRequest = useCallback(() => {
        openArchivingModal(clientRequest._id);
    }, []);
    const xForm = use(ClientRequestEditForm, { transient: true });
    xForm.build();
    const restoreClientRequest = useCallback(() => {
        const promise = xForm.onEditSubmit(props.clientRequest._id, {
            isArchived: false,
        });
        promise.then(() => {
            location.reload();
        });
    }, [xForm]);
    return {
        t,
        xForm,
        clientRequest,
        emergencyLevelHooks,
        statusHooks,
        sourceLeadHooks,
        activityHooks,
        subjectHooks,
        descriptionHooks,
        onArchiveClientRequest,
        restoreClientRequest,
    };
};
