var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { AgencyEditForm as BaseAgencyEditForm } from "./AgencyEditForm.base";
import { removeAllFormItemsLabels, replaceUIComponents, setAllXFormElemsPlaceholders, } from "../../utils";
import AddressComponent from "@bundles/UIAppBundle/components/Address/Address.lazy";
import PhoneNumber from "@bundles/UIAppBundle/components/PhoneNumber/PhoneNumber.lazy";
import * as Ant from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { defineOnCreateSubmit } from "@root/utils/forms";
let AgencyEditForm = class AgencyEditForm extends BaseAgencyEditForm {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
        setAllXFormElemsPlaceholders(this);
        replaceUIComponents({
            phone: _jsx(PhoneNumber, {}),
            address: _jsx(AddressComponent, {}),
        }, this);
        removeAllFormItemsLabels(this);
        this.update("name", {
            formItemProps: { style: { flex: 3 } },
            componentProps: {
                maxlength: 32,
            },
        });
        this.update("agenciesTagId", {
            formItemProps: { style: { flex: 1 } },
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
    onCreateSubmit(document) {
        return defineOnCreateSubmit(document, this, "agency");
    }
    // onCreateSubmit(document: Partial<Agency>): Promise<Partial<Agency> | void> {
    //   const { t } = this.i18n;
    //   return this.collection
    //     .insertOne(document)
    //     .then((res) => {
    //       Ant.notification.success({
    //         message: t("generics.success"),
    //         description: t("management.agency.create_confirmation"),
    //         icon: <SmileOutlined />,
    //       });
    //       return res;
    //     })
    //     .catch((err) => {
    //       Ant.notification.warn({
    //         message: t("generics.error"),
    //         description: t("generics.error_message"),
    //       });
    //     });
    // }
    onEditSubmit(_id, values) {
        const { t } = this.i18n;
        const promise = this.collection.updateOne(_id, values);
        promise.then((res) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.agencies.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            return res;
        });
        promise.catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: err.reason || err.message,
            });
        });
        return promise;
    }
};
AgencyEditForm = __decorate([
    Service({ transient: true })
], AgencyEditForm);
export { AgencyEditForm };
