import { useEffect } from "react";
import { useGuardian, useRouter, } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { hasRoles } from "../utils";
import { UserRoles } from "@root/api.types";
const redirectToHome = (guardian, router) => {
    const supportHomePage = Routes.CLIENT_REQUESTS_LIST;
    const plumberHomePage = Routes.KANBAN;
    const adminHomePage = Routes.KANBAN;
    const internalRouter = router !== null && router !== void 0 ? router : useRouter();
    const user = guardian.state.user;
    internalRouter.go(hasRoles([UserRoles.SUPPORT], user)
        ? supportHomePage
        : hasRoles([UserRoles.PLUMBER], user)
            ? plumberHomePage
            : hasRoles([UserRoles.ADMIN], user) && adminHomePage);
};
export function Home() {
    const guardian = useGuardian();
    const { initialised, isLoggedIn } = guardian.state;
    const router = useRouter();
    useEffect(() => {
        if (initialised) {
            // && !guardian.state.fetchingUserData
            if (isLoggedIn) {
                redirectToHome(guardian, router);
            }
            else {
                router.go(Routes.LOGIN);
            }
        }
    }, [guardian.state]);
    // const style = { minHeight: "100vh" };
    return null;
}
