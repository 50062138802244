import { jsx as _jsx } from "react/jsx-runtime";
import "reflect-metadata";
import { XUIProvider } from "@bluelibs/x-ui";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import { kernel } from "./kernel";
import "./styles.scss";
import { colors } from "@root/utils/colors";
// ? DYNAMIC THEMING DOC : https://ant.design/docs/react/customize-theme-variable
ConfigProvider.config({
    theme: {
        primaryColor: colors.selfcityGreen.hex,
    },
});
ReactDOM.render(_jsx(ConfigProvider, { children: _jsx(XUIProvider, { kernel: kernel }) }), document.getElementById("root"));
