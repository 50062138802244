import { ClientRequestRelatedContactProfileType } from "@root/api.types";
export const phrasesFr = {
    fr: {
        management: {
            client_request_related_contacts: {
                fields: {
                    _id: "ID",
                    profileType: "Type du Profile",
                    profileType_enum: {
                        [ClientRequestRelatedContactProfileType.TENANT]: "Locataire",
                        [ClientRequestRelatedContactProfileType.OWNER]: "Propriétaire",
                        [ClientRequestRelatedContactProfileType.OTHER]: "Autre",
                    },
                    isPayer: "Payeur",
                    isLocation: "Lieu d'inter",
                    isAsker: "Demandeur",
                    name: "Nom",
                    email: "Email",
                    isDeleted: "Supprimé",
                    isDeleted_description: "Ce champ est utilisé pour identifier si cet objet a été supprimé en douceur",
                    createdAt: "Crée à",
                    createdAt_description: "Date de création",
                    updatedAt: "Modifié à",
                    updatedAt_description: "Date de modification",
                    clientRequestId: "ID de la mission",
                    profileId: "ID du Profile",
                    agencyId: "ID de l'agence",
                    createdById: "ID du créateur",
                    createdById_description: "ID de l'utilisateur qui a créé le contact",
                    updatedById: "ID du modificateur",
                    updatedById_description: "ID de l'utilisateur qui a modifié le contact",
                    clientRequest: "Mission",
                    profile: "Profile",
                    agency: "Agence",
                    createdBy: "Créé par",
                    createdBy_description: "Représente l'utilisateur qui a créé ce contact",
                    updatedBy: "Modifié par",
                    updatedBy_description: "Représente l'utilisateur qui a modifié ce contact",
                },
                menu: {
                    title: "Contacts lié aux mission",
                },
                list: {
                    header: "Liste des contacts lié aux mission",
                    create_btn: "Nouveau contact lié aux mission",
                },
                create: {
                    header: "Créer un contact lié aux mission",
                },
                edit: {
                    header: "Modifier le contact lié aux mission",
                },
                view: {
                    header: "Voir le contact lié aux mission",
                },
                delete_confirmation: "Vous avez supprimé le contact lié à la mission",
                create_confirmation: "Vous avez ajouté avec succès un nouveau contact lié à la mission",
                edit_confirmation: "Vous avez modifié avec succès le contact lié à la mission",
                card: {
                    title: "Contacts & lieu d'intervention",
                    empty: "Aucun contact ajouté à ce jours.",
                    alerts: {
                        isLocationDefined: "“Lieu d’inter” non défini",
                        isPayerDefined: "“Payeur” non défini",
                        isOwnerOrTenantDefined: "“Propriétaire”/”Locataire” non défini",
                    },
                },
            },
        },
    },
};
