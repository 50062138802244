import { Collection, } from "@bluelibs/x-ui";
import { Comment } from "@root/api.types";
import { AppFileGroupsCollection, } from "@bluelibs/x-ui-admin";
import { ClientRequestsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class CommentsCollection extends Collection {
    getName() {
        return "Comments";
    }
    getInputs() {
        return {
            insert: "CommentInsertInput!",
            update: "CommentUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ClientRequestsCollection,
                name: "clientRequest",
                field: "clientRequestId",
            },
            {
                collection: () => AppFileGroupsCollection,
                name: "attachments",
                field: "attachmentsId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
