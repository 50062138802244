import "./i18n";
import { ADMIN_MENU } from "../const";
import { UserRoles } from "@root/api.types";
import { INBOX_TAGS_LIST as BASE_INBOX_TAGS_LIST, INBOX_TAGS_CREATE as BASE_INBOX_TAGS_CREATE, INBOX_TAGS_EDIT as BASE_INBOX_TAGS_EDIT, INBOX_TAGS_VIEW as BASE_INBOX_TAGS_VIEW, } from "./config/routes";
export const INBOX_TAGS_LIST = {
    ...BASE_INBOX_TAGS_LIST,
    menu: {
        ...BASE_INBOX_TAGS_LIST.menu,
        inject: ADMIN_MENU,
        roles: [UserRoles.ADMIN],
    },
};
export const INBOX_TAGS_CREATE = {
    ...BASE_INBOX_TAGS_CREATE,
};
export const INBOX_TAGS_EDIT = {
    ...BASE_INBOX_TAGS_EDIT,
};
export const INBOX_TAGS_VIEW = {
    ...BASE_INBOX_TAGS_VIEW,
};
