import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import * as Ant from "antd";
import moment from "moment";
import { TripCard } from "./TripCard.index";
import { colors } from "@root/utils/colors";
import { CalendarOutlined } from "@ant-design/icons";
import { useTripCardHooks } from "./TripCard.hooks";
const timelineItemStyle = { paddingBottom: "4px" };
export const TimelineTripCard = memo((props) => {
    var _a, _b;
    const { tripListState } = useTripCardHooks(props);
    const commentIconItemStyle = {
        borderRadius: 2,
        padding: 2,
        backgroundColor: tripListState.isUpcoming
            ? colors.tripBlue.hex
            : colors.tripBlue.opacity(0.15),
        color: tripListState.isUpcoming ? "white" : colors.tripBlue.hex,
    };
    return (_jsx("div", { children: _jsxs(Ant.Timeline.Item, { style: timelineItemStyle, dot: _jsx(CalendarOutlined, { style: commentIconItemStyle }), className: props.disabled || props.tripList.data._cancellationReason !== null ? "disabled" : "", children: [_jsxs(Ant.Typography.Text, { type: "secondary", className: "fs-s", children: [((_b = (_a = props.tripList) === null || _a === void 0 ? void 0 : _a.createdBy) === null || _b === void 0 ? void 0 : _b.fullName) || "Utilisateur supprimé", " -", " ", moment(props.tripList.data._createdAt).format("DD/MM/YYYY [à] HH[h]mm")] }), _jsx(TripCard, { tripList: props.tripList })] }, props.tripList._id) }));
});
