import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Link } from "react-router-dom";
import { useGuardian, useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { Button, Space, Row, Col, Alert, Card, } from "antd";
export function NotAuthorized() {
    var _a;
    const guardian = useGuardian();
    const router = useRouter();
    const style = { minHeight: "100vh" };
    const { user, isLoggedIn } = guardian.state;
    const firstName = (_a = user === null || user === void 0 ? void 0 : user.profile) === null || _a === void 0 ? void 0 : _a.firstName;
    return (_jsx(Row, { justify: "center", align: "middle", style: style, children: _jsx(Col, { sm: 24, md: 16, lg: 8, children: _jsxs(Card, { title: "Tu n\u2019es pas connect\u00E9", children: [isLoggedIn && _jsxs("p", { children: ["Vous \u00EAtes d\u00E9j\u00E0 connect\u00E9 en tant que", firstName, "."] }), _jsx(Alert, { type: "error", message: "Connecte toi \u00E0 Belett pour voir cette page \uD83D\uDE09" }), _jsxs("p", { children: [_jsx("br", {}), _jsxs(Space, { children: [isLoggedIn && (_jsx(Link, { to: router.path(Routes.HOME), children: _jsx(Button, { children: "Dashboard" }) })), !isLoggedIn && (_jsx(Link, { to: router.path(Routes.LOGIN), children: _jsx(Button, { children: "Se connecter \u00E0 Belett" }) }))] })] })] }) }) }));
}
