import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from "react";
import { Card } from "antd";
const breakSpaces = { whiteSpace: "break-spaces" };
const removeMarginBottom = { marginBottom: 0 };
const removePaddingBottom = { paddingBottom: 0 };
const seeMoreConfig = {
    rows: 3,
    expandable: true,
    symbol: "voir plus",
};
export const FollowUpCard = memo((props) => {
    return (_jsx(_Fragment, { children: _jsx(Card, { size: "small", className: "child-pad-1-i", children: `Le client vient d’être relancé sur ${!!props.followUp.quotationId
                ? `le devis ${props.followUp.quotationId}`
                : `la facture ${props.followUp.invoiceId}`}` }) }));
});
