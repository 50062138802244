import { ProfilesList } from "../components/List/ProfilesList";
import { ProfilesCreate } from "../components/Create/ProfilesCreate";
import { ProfilesEdit } from "../components/Edit/ProfilesEdit";
import { ProfilesView } from "../components/View/ProfilesView";
import { SettingFilled } from "@ant-design/icons";
export const PROFILES_LIST = {
    path: "/admin/profiles",
    component: ProfilesList,
    menu: {
        key: "PROFILES_LIST",
        label: "management.profiles.menu.title",
        icon: SettingFilled,
    },
};
export const PROFILES_CREATE = {
    path: "/admin/profiles/create",
    component: ProfilesCreate,
};
export const PROFILES_EDIT = {
    path: "/admin/profiles/:id/edit",
    component: ProfilesEdit,
};
export const PROFILES_VIEW = {
    path: "/admin/profiles/:id/view",
    component: ProfilesView,
};
