export const TEMP_ROUND = (number) => (
// Rounding with negative number does not give the same results as with positive ones, which is
// why we need to remove the sign before rounding and apply it afterward.
Math.sign(number) * Math.round(Math.abs(number) * 100) / 100);
// Returns total price including taxes, with the right rounding.
export const getPriceIT = (priceET, vat) => (
// Rounding with negative number does not give the same results as with positive ones, which is
// why we need to remove the sign before rounding and apply it afterward.
Math.sign(priceET) * Math.floor(Math.abs(priceET) * (100 + vat)) / 100);
// Returns discounted price excluding taxes, with the right rounding.
export const getDiscountedPriceET = (row) => (TEMP_ROUND(row.unitPriceET * row.quantity * (1 - row.discount / 100)));
// Returns price excluding taxes, with the right rounding.
export const getPriceET = (row) => (TEMP_ROUND(row.unitPriceET * row.quantity));
// Returns total price excluding taxes from given rows, with the right rounding.
export const getTotalPrice = (rows) => rows.reduce((totalET, row) => totalET + row.quantity * row.unitPrice, 0);
// Returns total price excluding taxes from given rows, with the right rounding.
export const getTotalDiscountedPriceET = (rows) => TEMP_ROUND(rows.reduce((totalET, row) => TEMP_ROUND(totalET + getDiscountedPriceET(row)), 0));
export const getDiscountedPrice = (row) => {
    return row.unitPriceIT
        ? Math.round(row.unitPriceIT * 100 / (100 + row.taxes) * row.quantity * (1 - row.discount / 100))
        : Math.round(row.unitPrice * row.quantity * (1 - row.discount / 100));
};
export const getDiscountedPriceIT = (row) => {
    return row.unitPriceIT
        ? Math.round(row.unitPriceIT * row.quantity * (1 - row.discount / 100))
        : Math.round(row.unitPrice * row.quantity * (1 - row.discount / 100) * (100 + row.taxes) / 100);
};
// Returns total price including taxes from given rows, with the right rounding.
export const getTotalDiscountedPriceIT = (rows) => {
    return rows.reduce((total, row) => total + getDiscountedPriceIT(row), 0);
};
export const getTotalDiscountedPrice = (rows) => {
    return rows.reduce((total, row) => total + getDiscountedPrice(row), 0);
};
// Returns total discount price excluding taxes from given rows, with the right rounding.
export const getTotalDiscountPriceET = (rows) => -TEMP_ROUND(rows.reduce((totalDiscount, row) => totalDiscount + row.unitPriceET * row.quantity * (row.discount / 100), 0));
export const getSupplyMarginPriceET = (supply) => {
    var _a, _b, _c;
    const margin = (_a = supply.margin) !== null && _a !== void 0 ? _a : 0;
    const quantity = (_b = supply.quantity) !== null && _b !== void 0 ? _b : 0;
    const unitPrice = (_c = supply.unitPrice) !== null && _c !== void 0 ? _c : 0;
    return TEMP_ROUND((margin / 100) * unitPrice * quantity);
};
// Returns margin price for given supplies, with the right rounding.
export const getSuppliesMarginPriceET = (supplies) => TEMP_ROUND(supplies.reduce((total, supply) => {
    return total + getSupplyMarginPriceET(supply);
}, 0));
export const getSupplyPriceET = (supply) => {
    var _a, _b, _c;
    const margin = (_a = supply.margin) !== null && _a !== void 0 ? _a : 0;
    const quantity = (_b = supply.quantity) !== null && _b !== void 0 ? _b : 0;
    const unitPrice = (_c = supply.unitPrice) !== null && _c !== void 0 ? _c : 0;
    return TEMP_ROUND(quantity * unitPrice * (1 + margin / 100));
};
// Returns total price excluding taxes for given supplies, with the right rounding.
export const getSuppliesPriceET = (supplies) => TEMP_ROUND(supplies.reduce((total, supply) => {
    return total + getSupplyPriceET(supply);
}, 0));
export const getTaskPriceET = (task) => {
    var _a, _b;
    const hours = (_a = task.hours) !== null && _a !== void 0 ? _a : 0;
    const hourlyRate = (_b = task.hourlyRate) !== null && _b !== void 0 ? _b : 0;
    return TEMP_ROUND(hourlyRate * hours);
};
// Returns total price excluding taxes for given tasks, with the right rounding.
export const getTasksPriceET = (tasks) => TEMP_ROUND(tasks.reduce((total, task) => {
    return total + getTaskPriceET(task);
}, 0));
export const getTotalEstimatePriceET = (supplies, tasks) => {
    const totalTasksPriceET = getTasksPriceET(tasks);
    const totalSuppliesPriceET = getSuppliesPriceET(supplies);
    return TEMP_ROUND(totalSuppliesPriceET + totalTasksPriceET);
};
